import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';

import {A11yModule} from '@angular/cdk/a11y';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { NgSelectModule } from '@ng-select/ng-select';

import {MaterialuiModule} from './materialui/materialui.module';
import { DatePipe } from '@angular/common';

import {HttpClientModule} from '@angular/common/http';

import { AuthGuard } from "./_service/auth.guard";
//JWT MODULE
import { JwtModule } from '@auth0/angular-jwt';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
// import {NgxMaterialToolsModule} from 'ngx-material-tools';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LandingAuthUserComponent } from './layouts/landing-auth-user/landing-auth-user.component';
import { LoginUserComponent } from './layouts/login-user/login-user.component';
import { DashboardComponent} from './dashboard/dashboard.component';
import { MotorquoteComponent} from './motorquote/motorquote.component';
import { CancelpaymentComponent } from './cancelpayment/cancelpayment.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
//START:INCLUDE SERVICES
import { JwtService } from "./_service/jwt.service";
import { GlobalService } from "./_service/global.service";
import { UserauthService } from './_service/userauth.service';
import { MotorquoteService } from './_service/motorquote.service';
// import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
// import { RecaptchaFormsModule } from 'ng-recaptcha';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { OcrmainModule } from './ocrmain/ocrmain.module';
import { MotorpaymentinvoiceComponent } from './motorpaymentinvoice/motorpaymentinvoice.component';
import { WinnerComponent } from './winner/winner.component'; 
// import{NgxSimpleCountdownModule} from 'ngx-simple-countdown';
import { UserregistrationComponent } from './userregistration/userregistration.component';
//END:INCLUDE SERVICES

export  function  tokenGetter() {
  return     localStorage.getItem('backend-token');
 }

  
export let options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    // NgxSimpleCountdownModule,
    NgxMatSelectSearchModule,
    NgSelectModule,
    ReactiveFormsModule,
    HttpModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,MaterialuiModule,
    HttpClientModule,
    // NgxMaterialToolsModule,
    // RecaptchaModule.forRoot(),
    // RecaptchaFormsModule,
    NgxMaskModule.forRoot(),
    OcrmainModule,
  
    

    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['localhost:4200'],
        blacklistedRoutes: ['http://localhost:4200/dashoard']
      }
    }),

    ServiceWorkerModule.register('motor_ins/ngsw-worker.js', { enabled: environment.production })

  ],
  exports: [
    A11yModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    PortalModule,
    ScrollingModule,
    
    
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LandingAuthUserComponent,LoginUserComponent,DashboardComponent,MotorquoteComponent,UserregistrationComponent, ThankyouComponent, CancelpaymentComponent,  MotorpaymentinvoiceComponent, WinnerComponent,
  ],
  providers: [AuthGuard,GlobalService,JwtService,UserauthService,DatePipe,MotorquoteService],
      
  bootstrap: [AppComponent]
})
export class AppModule { }
