//------------------------- FOR K2KEYSERVER ---------------------------------------------//
// export const config = {
//   apiUrl: 'http://win.k2key.in/medical_fidelity_united_api/index.php/', // FOR SERVER

//  // apiUrl: 'http://win.k2key.in/medical_fidelity_united_api_dev/index.php/', // FOR LOCAL

//   apiUrl2: 'http://win.k2key.in/medical_fidelity_united_api/index.php/',


//   server_name: "https://api.fidelityunited.ae/",
  
//   // apiUrl2: 'http://k2key.in/medical_fidelity_united_api/api/',
//   //paypalApiUrl:'https://api.sandbox.paypal.com/v1/'
  
// };

//----------------------- FOR FIDELITY PRODUCTION ----------------------------------------//
export const config = {
  apiUrl: 'https://api.fidelityunited.ae/index.php/', // FOR SERVER

 // apiUrl: 'http://win.k2key.in/medical_fidelity_united_api_dev/index.php/', // FOR LOCAL

  apiUrl2: 'https://api.fidelityunited.ae/index.php/',


    server_name: "https://api.fidelityunited.ae/index.php/",

    paymentGatewayUrl: "https://mybuddy.fidelityunited.ae/b2b-pay/api/v2/pay/", //Added by Anju for Payment gateway integration
  
};

//------------------------------------------ FOR FIDELITY TESTING -------------------------------//
//  export const config = {
//       apiUrl: 'https://apitest.fidelityunited.ae/index.php/', // FOR SERVER
//       apiUrl2: 'https://apitest.fidelityunited.ae/index.php/',
//       encKey: "202019$#@$^@1fed",
//       server_name: "https://apitest.fidelityunited.ae/index.php/",
//       logOutURL: 'https://onlinetest.fidelityunited.ae:8090/testportal/logout.aspx',
//       motor_hire_purchase_clause : "https://10.168.10.14:78/DocumentPrint/GID035.aspx?pEndtSrlNo=0&pUserLang=ENG&pLogo=Y&pOutType=pdf&pPolUid="

//      //  apiUrl: 'http://10.168.10.21/index.php/', // FOR SERVER
//      //  apiUrl2: 'http://10.168.10.21/index.php/',
//      //  encKey: "202019$#@$^@1fed",
//      //  server_name: "http://10.168.10.21/index.php/",
//      //  logOutURL: 'http://onlinetest.fidelityunited.ae:8090/testportal/logout.aspx',
//  };


//  export const config = {
//     apiUrl: 'http://ociapi.fidelityunited.ae/index.php/', // FOR SERVER for B2B
//     apiUrl2: 'http://ociapi.fidelityunited.ae/index.php/', //for B2C
//     encKey: "202019$#@$^@1fed",
//     server_name: "http://ociapi.fidelityunited.ae/index.php/",
//     logOutURL: 'http://ociportal.fidelityunited.ae/logout.aspx',
//     motor_hire_purchase_clause : "https://10.168.10.14:78/DocumentPrint/GID035.aspx?pEndtSrlNo=0&pUserLang=ENG&pLogo=Y&pOutType=pdf&pPolUid="
//    //  apiUrl: 'http://10.168.10.21/index.php/', // FOR SERVER
//    //  apiUrl2: 'http://10.168.10.21/index.php/',
//    //  encKey: "202019$#@$^@1fed",
//    //  server_name: "http://10.168.10.21/index.php/",
//    //  logOutURL: 'http://onlinetest.fidelityunited.ae:8090/testportal/logout.aspx',
// };

// ------------------------------------------- FOR K2KEY ONLINE TEST -------------------------------------//
//  export const config = {
//       apiUrl: 'http://win.k2key.in/online_fidelity_k2key/index.php/', // FOR SERVER
//       apiUrl2: 'http://win.k2key.in/online_fidelity_k2key/index.php/',
//       encKey: "202019$#@$^@1fed",
//       server_name: "http://k2key.in/main_insurance_project/",
//       logOutURL: 'http://efinadvisor.gearhostpreview.com/insurance/mdds/logout.aspx',
//       planBenefitID :  509  //For Roadside Assistance - Bronze
//  };
