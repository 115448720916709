import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-ocrmain',
  templateUrl: './ocrmain.component.html',
  styleUrls: ['./ocrmain.component.scss']
})
export class OcrmainComponent implements OnInit {

  constructor(public _route: Router,private route: ActivatedRoute) { }

  ngOnInit() {
  }


  withocr(){
    this._route.navigateByUrl('/MTOCR');
  }

  // withoutocr(){
  //   this._route.navigateByUrl('/motorquote');
  // }




}
