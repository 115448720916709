import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { catchError, tap, map } from 'rxjs/operators';
import {  of, throwError } from 'rxjs';
import { GlobalService } from "../_service/global.service";
import { config } from './../config';
// import { environment } from '../../environments/environment';

import { Router } from "@angular/router";
@Injectable({
  providedIn: 'root'
})
export class UserauthService {
  apiURL: string = config.apiUrl2;

  apiURL1: string = config.apiUrl;
  private loggedIn = false;
  

  constructor(private httpClient: HttpClient,public _globalService: GlobalService, public _route:Router) {}
  
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
}

 
  public projecttokenAuthorisation(token){
    var parameter={'token':token};
    return this.httpClient.post(`${this.apiURL}Webserviceauth/tokenAuthorisation`,parameter)
    
    .pipe(catchError(this.handleError), tap(res => {

     //let  response:any= res;
     let  response=this._globalService.convertResponseInJson(res);

        if (response.success) {
         
          if (response.data.access_token != null) {
           
            let  response_data=JSON.stringify(response.data);
            localStorage.setItem("currentUser", response_data);
            localStorage.setItem("backend-token", response.data.access_token);
           
         }
          
          this.loggedIn = true;
        } else {
          localStorage.clear();
          this.loggedIn = false;
        } 
        return response;
    }));


  }


  public loginUserAuth(username,password){

    return this.httpClient.post(`${this.apiURL}Webserviceauth/login_authentication`,{EmailAddress:username,Password:password})
    .pipe(catchError(this.handleError), tap(res => {

      let  response = this._globalService.convertResponseInJson(res);
 
            
         if (response.success) {
          
           if (response.data.access_token != null) {
            
             let  response_data=JSON.stringify(response.data);
            let  userInfo = {"UserRole":response.data.UserRole,
                              "AccessGroup":response.data.AccessGroup,
                              "EmailAddress": response.data.EmailAddress,
                              "CedantLogo": response.data.CedantLogo,
                              "PartnerName": response.data.PartnerName,
                              "UserName": response.data.UserName,
                              "BranchId": response.data.BranchId,
                              "User_ID": response.data.UserId,
                              "Partner_ID": response.data.PartnerId,
                              "Cedant_ID" : response.data.CedantId

                  }
            //  localStorage.setItem("UserRole", response.data.UserRole);
            //  localStorage.setItem("EmailAddress", response.data.EmailAddress);
            //  localStorage.setItem("CedantLogo", response.data.CedantLogo);
            //  localStorage.setItem("PartnerName", response.data.PartnerName);
            //  localStorage.setItem("UserName", response.data.UserName);
            localStorage.setItem("currentUser",JSON.stringify( userInfo));
             localStorage.setItem("backend-token", response.data.access_token);
            
          }
           
           this.loggedIn = true;
         } else {
           localStorage.clear();
           this.loggedIn = false;
         } 
         return response;
     }));
 
  }




  public userAuthLoginExist() {
   
    var backend_token=localStorage.getItem('backend-token');
   let loginStatus = (backend_token=='' || backend_token==null)?false:true;
   console.log(loginStatus);
   if(loginStatus == false){
     this._route.navigate(["/login"]);
   }
   else{
    
    this._route.navigate(["/dashboard"]);

  }

   }

  public getTokenData(){

    return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getTokenData`,{})
    .pipe(catchError(this.handleError), tap(res => {

      let  response = this._globalService.convertResponseInJson(res);
 
            console.log(response.response_message == "Success");
         if (response.response_message == "Success") {
          
           if (response.token.token != null) {
            
             let  response_data = JSON.stringify(response.token.token);
          
            //  localStorage.setItem("UserRole", response.data.UserRole);
            //  localStorage.setItem("EmailAddress", response.data.EmailAddress);
            //  localStorage.setItem("CedantLogo", response.data.CedantLogo);
            //  localStorage.setItem("PartnerName", response.data.PartnerName);
            //  localStorage.setItem("UserName", response.data.UserName);
          
             localStorage.setItem("backend-token", response.token.token);
            
          }
           
           this.loggedIn = true;
         } else {
           localStorage.clear();
           this.loggedIn = false;
         } 
         return response;
     }));
 
  }
 

}
