import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { MotorquoteService } from '../_service/motorquote.service';
import { GlobalService } from '../_service/global.service';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {

  quoteNum = 0; loader:boolean = false; 
  token = 0; orderNumber:any; orderToken:any; quoteNumber:any; payment_transaction_ID_status:any; payment_Transaction_ID:any;
  totalPremium :any; quoteDetail:any = []; showHideLoader:boolean = true; show_cancel_msg:boolean = false; trans_ref:any; payment_status:any;
  Token: string;
  payPolicyNum: any;
  policyNumber: string;

  constructor(public _route: Router,public _activatedroute: ActivatedRoute,public motorQuoteService:MotorquoteService,public globalService:GlobalService) { }

  ngOnInit() {


        this.showHideLoader = true;
        this.orderNumber =  localStorage.getItem("Payment_Order_ID");
        this.orderToken  =  localStorage.getItem("Payment_Order_Tokan");
       this.quoteNumber = localStorage.getItem('Payment_Quotation_Number');
       this.trans_ref = localStorage.getItem('Trans_Ref');
       this.Token = localStorage.getItem('TokenId');
       this.policyNumber =  localStorage.getItem('Policy_Number');

        // if( this.orderNumber == null ){
        //   this.showHideLoader = false;
        //   this._route.navigateByUrl('/ocrmain');
        //  }

        //  this.showHideLoader = false;
        const routeParam = this._activatedroute.snapshot.params;
        if(routeParam){
  
         this.token = routeParam.Token;
    
        }
         this.verifyQuatationPayment();

  }
  saveQuotationPayment(){
    this.motorQuoteService.saveQuotationPayment(this.orderToken,this.payment_transaction_ID_status,this.quoteNumber,this.response_msg,this.payment_Transaction_ID,'MT','B2C').subscribe(res =>{
      
      // setTimeout(() => {
            this.showHideLoader =false;
        // },5000);  

          if(res.res_code == 1){
            // this.payPolicyNum = res.result.pt_invoice_id;statement_reference
            localStorage.removeItem("Payment_Order_ID");
            localStorage.removeItem("Payment_Order_Tokan");
            localStorage.removeItem('Payment_Quotation_Number');
            localStorage.removeItem('Insured_Email');
            localStorage.removeItem('Insured_Mobile');
            localStorage.removeItem('Insured_Code');
             this.trans_ref = localStorage.getItem('Trans_Ref');
             this.motorQuoteService.sendPolicySuccessMail(this.quoteNumber).subscribe(response => {
                  console.log(response);
              });


          }
    },
    (error) => {
      this.showHideLoader =false;
        console.error(error.status);
             
     });
      
 

}
response_msg:any ='';
verifyQuatationPayment(){


          // this.motorQuoteService.verifyQuatationPayment(this.trans_ref).subscribe(res =>{
          //       this.payment_status = res.result;

          //      console.log("---------------------");
               
          //       if(typeof res.result.payment_result != undefined){

          //         this.quoteNumber =  this.payment_status.cart_id;
          //         console.log(this.quoteNumber)
          //         // this.quoteNumber =  this.payment_status.cart_id.replace('"','');
          //           //  console.log(1);
          //             if(res.result.payment_result.response_status != 'A'){
          //                 this.payment_transaction_ID_status = "CANCELLED";
          //             }
          //             if(res.result.payment_result.response_status == 'A'){
          //               this.payment_transaction_ID_status = "ISSUED";
          //           // }
          //          }
          //         }else{
          //          this.payment_transaction_ID_status = "CANCELLED";
          //         //  console.log(111);
                   
          //     }

          //     this.saveQuotationPayment();

          //   });

    this.motorQuoteService.verifyPayment(this.orderNumber).subscribe(res => {
        let response = res;
        this.response_msg = response.result.payment_result.response_code;
        this.quoteNumber = response.result.cart_id;
        this.payment_Transaction_ID = response.result.tran_ref;
        //this.payPolicyNum=response.result.statement_reference;

        if (res.res_code == 2 || response.result.tran_ref == null) {

            this.payment_transaction_ID_status = "CANCELLED";
            this.show_cancel_msg = true;
            this._route.navigateByUrl("/cancelpayment/" + this.response_msg);
        }
        else {
            if (res.result.tran_ref != null) {
                this.payment_transaction_ID_status = "ISSUED";
                this.saveQuotationPayment();
            }
        }
    });

    console.log('Quotation number : ' + this.quoteNumber);

}

donePayment(){
        this._route.navigateByUrl('/ocrmain');
  }
  goToURL(){
    this.motorQuoteService.webSiteLink().subscribe(res =>{

      location.href = res.fedility_website ;


    });

  }

}
