// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: 'https://apitest.fidelityunited.ae/index.php/', // FOR SERVER
  apiUrl2: 'https://apitest.fidelityunited.ae/index.php/',
  encKey: "202019$#@$^@1fed",
  server_name: "https://apitest.fidelityunited.ae/index.php/",
  logOutURL: 'https://onlinetest.fidelityunited.ae:8090/testportal/logout.aspx',
  motor_hire_purchase_clause : "https://10.168.10.14:78/DocumentPrint/GID035.aspx?pEndtSrlNo=0&pUserLang=ENG&pLogo=Y&pOutType=pdf&pPolUid="
};
