import { Injectable } from "@angular/core";
// import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from "rxjs";
import {MatSnackBar} from '@angular/material/snack-bar';



@Injectable()
export class GlobalService {
  userData:any=[];
  public changeUserInfo:BehaviorSubject<any> = new BehaviorSubject<any>(this.userData);
  public navChanged:BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public connectionStatusChanged:BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private _snackBar: MatSnackBar) {


  //  console.log(this.changeUserInfo.prev());

     console.log(this.changeUserInfo);
   
  }

  
  
  convertResponseInJson(res)
  {
    let  response:any= res;
    return response;
  }
   
  getLocalStorageData()
  {
    if (localStorage.getItem("backend-token") != null) {
     let retrievedData = localStorage.getItem("currentUser");
      this.userData = JSON.parse(retrievedData);
      this.changeUserInfo.next(this.userData)
      return this.userData;
    } 

   
  }
    /* START:VALIDATE DATA NUM/decimal with number  */
    checkValidInputData(event: any,type) {
      var pattern:any;
      if(type=='1'){
          pattern = /[0-9\+\-\ ]/;//validae only number
      }
      if(type=='2'){
         pattern= /^[0-9]*\.?[0-9]*$/;//validae decimal with number 
      }
      if(type=='3'){
        pattern= /^[A-Za-z0-9]+$/;//validae alphanumeric 
     }
     if(type=='4'){
        pattern= /^['A-Za-z0-9 \-\ \.\ \(\ \)\ \\\\ \#\ \/\ \,\']+$/;//validae alphanumeric with space ^[a-zA-Z \-\ \.\']+
      

      }

      let inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode != 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
    }

  


    /* END:VALIDATE DATA NUM/decimal with number  */
   
     /* START:VALIDATE ALL EVENTS  */
    preventAllEvents(e: any)
    {
      if (e.ctrlKey && (e.keyCode == 88 || e.keyCode == 67 || e.keyCode == 86)) {
       e.preventDefault();
      }
    }

    getHeader(){
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
         // Authorization:  'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6bnVsbCwiZGF0YSI6eyJmaXJzdG5hbWUiOiJRdWVlbnplbmQgV2ViIiwibGFzdG5hbWUiOiJTb2x1dGlvbiIsInVzZXJ0eXBlIjoiYWRtaW4iLCJlbWFpbCI6InRlc3QuYnVzaW5lc3N1c2VyMUBnbWFpbC5jb20iLCJpbWFnZSI6InBpY3R1cmVfclhUZkFfRGluZXNoIENsZyAyMDE0MTAzMV8xNDQxMDEuanBnIiwidXNlcmlkIjoiMiIsInVzZXJuYW1lIjoidGVzdC5idXNpbmVzc3VzZXIxQGdtYWlsLmNvbSIsImFjY291bnRpbmdfdXNlciI6MSwiZmlybV9pZCI6IjIiLCJpc19yZXZpZXdlciI6IjEifSwiaWF0IjoxNTc2MTQ5OTA3LCJleHAiOjE1NzYxNjc5MDd9.HnO0fF4FWu-mDQW9LzutzqvVHBZQHvBmiD9fZN1eJB4'
         Authorization:  'Bearer ' +localStorage.getItem("backend-token")
      
        })
      }
      return httpOptions;
    }

    getHeaderForFileUpload(){
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'multipart/form-data',
         // Authorization:  'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6bnVsbCwiZGF0YSI6eyJmaXJzdG5hbWUiOiJRdWVlbnplbmQgV2ViIiwibGFzdG5hbWUiOiJTb2x1dGlvbiIsInVzZXJ0eXBlIjoiYWRtaW4iLCJlbWFpbCI6InRlc3QuYnVzaW5lc3N1c2VyMUBnbWFpbC5jb20iLCJpbWFnZSI6InBpY3R1cmVfclhUZkFfRGluZXNoIENsZyAyMDE0MTAzMV8xNDQxMDEuanBnIiwidXNlcmlkIjoiMiIsInVzZXJuYW1lIjoidGVzdC5idXNpbmVzc3VzZXIxQGdtYWlsLmNvbSIsImFjY291bnRpbmdfdXNlciI6MSwiZmlybV9pZCI6IjIiLCJpc19yZXZpZXdlciI6IjEifSwiaWF0IjoxNTc2MTQ5OTA3LCJleHAiOjE1NzYxNjc5MDd9.HnO0fF4FWu-mDQW9LzutzqvVHBZQHvBmiD9fZN1eJB4'
         Authorization:  'Bearer ' +localStorage.getItem("backend-token")
      
        })
      }
      return httpOptions;
    }

    spinnerHide(){
      return true;
    }
  
    
    spinnerShow(){
      return false;
    }

    openSnackBar(message: string, action: string) {
     
      this._snackBar.open(message, action, {
        duration: 4000,
        verticalPosition:'bottom',
        horizontalPosition:'center',
        direction:'ltr',
        panelClass : ['snackbar']
      });
    }


  
}
