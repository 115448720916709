import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { ModalDirective } from 'angular-bootstrap-md';
import { CompressImageService } from 'app/_service/compressimage.service';
import { MotorquoteService } from 'app/_service/motorquote.service';
import { take, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
export interface Product {
  value: string;
  viewValue: string;
}
export interface  ModelYr{
  value: string;
  viewValue: string;
}
export interface  VehiMakes{
  value: string;
  viewValue: string;
}
export interface  VehiModel{
  value: string;
  viewValue: string;
}
interface Gender {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-userregistration',
  templateUrl: './userregistration.component.html',
  styleUrls: ['./userregistration.component.scss']
})

export class UserregistrationComponent implements OnInit {
  @ViewChild('stepper', { static: true })stepper:MatStepper;
  @ViewChild('ProgressModel', { static: true }) ProgressModel: ModalDirective;
  public emirateseModal:any = 0;
  motorSelectHide: boolean =false;
  genders: Gender[] = [
    { value: 'M', viewValue: 'Male' },
    { value: 'F', viewValue: 'Female' },
  ];
  insuredIndividualHide: boolean=false;
  docNameType: any;
  reg_card_front_flag: boolean;
  language_code = 'ENG';
  public showLoader = {
    reg_card_front: false,
    reg_card_back: false,
    emirates_Id_front: false,
    emirates_Id_back: false,
    driving_Lic_front: false,
    driving_Lic_back: false,
    Quotation: false,
    VehicleValue: false,
    vhclMake: false,
    vhclModel: false,
    vhclTrim: false,
    vhclBodyType: false,
    retrieveQuote: false,
    multipleDoc: false,
    supporting_Document: false,
    chasisNoButton: false,
    additionalDoc: false,
    referal: false,
    trade_Licence : false,
    TRN_Certificate : false,
  }
  checkreg_Card_Front_image: boolean;
  reg_card_back_flag: boolean;
  checkreg_Card_Back_image: boolean;
  SchemeCode: string | Blob;
  document_data: any;
  value: any;
  docUploadForm: any;
  public hideImages = {
    reg_card_front: false,
    reg_card_back: false,
    emirates_Id_front: false,
    emirates_Id_back: false,
    driving_Lic_front: false,
    driving_Lic_back: false,
  }
  tempDocArray: any = [];
  invalidEID: boolean;
  insuredDetailForm: FormGroup;
  countryArr: any;
  country = 'United Arab Emirates';
 
  cityArr: any;
  vhclColorArr: any;
  otp: any;
  progressModel: any;
  verifyotploader: boolean =false;
  verifyotploader1: boolean =false;
  wrongotp: boolean =false;
  linkedDetails: FormGroup;
  datalinked: any;
  datalinkedloader: boolean =true;
 
 

  constructor(private compressImage: CompressImageService,public motorQuoteService: MotorquoteService,public formBuilder: FormBuilder,) { }

  ngOnInit() {
    this.getAllFormData('');
    this.docUploadForm = this.formBuilder.group({
     
      docEmiratedIdFront: [''],
      emirated_ID_Front: ['',Validators.required],
      emirated_ID_FrontFilePath: '',
      emirated_ID_FrontFileType: '',

      docEmiratedIdBack: [''],
      emirated_ID_Back: ['' ,Validators.required],
      emirated_ID_BackFilePath: '',
      emirated_ID_BackFileType: '',

    

        
    });

    this.insuredDetailForm = this.formBuilder.group({

    
      // eid front form fields
    
      e_name: ['',Validators.required],
      e_nationality: ['',Validators.required],
      e_eid:['',Validators.required],
      e_expiryDate: ['',Validators.required],
      e_gender: ['',Validators.required],
     

      // Additional detail fields /^((050|052|054|055|056|057|058|059){1}([0-9]{7}))$/   //'[050|052|054|055|056|057|058|059]{1}[0-9]{7}'
      adtnl_detail_email: ['',Validators.compose([Validators.required,Validators.pattern('[a-zA-Z0-9.-_-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')])],
      // adtnl_detail_mbCode: ['', [Validators.required]],
      adtnl_detail_mobile: ['',Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^((050|052|054|055|056|057|058|059){1}([0-9]{7}))$/)])],
     

    });
    this.linkedDetails = this.formBuilder.group({

    
      // eid front form fields
    
      insuredname: ['',Validators.required],
      product: ['',Validators.required],
      policy_number: ['',Validators.required],
      policy_exp_date: ['',Validators.required],
     

    });
  }
  products: Product[] = [
    {value: 'motor', viewValue: 'Motor'},
    {value: 'home', viewValue: 'Home'},
    {value: 'travel', viewValue: 'Travel'}
  ];
  modelyrs: ModelYr[] = [
    {value: '2021', viewValue: '2021'},
    {value: '2020', viewValue: '2020'},
    {value: '1990', viewValue: '1990'},
    {value: '2000', viewValue: '2000'},
  ];
  vehimakes: VehiMakes[] = [
    {value: 'audi', viewValue: 'Audi'},
    {value: 'bmw', viewValue: 'BMW'},
    
  ];
  vehimodels: VehiModel[] = [
    {value: 'a1', viewValue: 'A1'},
    {value: 'a6', viewValue: 'A6'},
    
  ];
  openEmiratesUpload(){
    this.emirateseModal= 1;
   }
   emiratesModalClose(){
     this.emirateseModal= 0;
   }

   productHide(motorshow)
   {
     if(motorshow.value=="motor")
     {
      this.motorSelectHide = true;
     }
     else{
      // alert(1)
      this.motorSelectHide = false;
     }
    
   }
   showTempFile(tempFile,FiledName){

    // console.log(tempFile);
    // console.log(FiledName);
    // console.log(this.docNameType);
  
  
     var reader = new FileReader();
      reader.readAsDataURL(tempFile);
      var mimeType = tempFile.type;
      let fileType = '';
       if (mimeType.match(/image\/*/) == null) {
       fileType = "PDF";
      }else{
  
        fileType = "IMG";
  
      }
      reader.onload = (_event) => {
      
        if (this.docNameType == 'reg_Card_Front') {
          // alert(1)
          this.showLoader.reg_card_front = false;
      }
     
       if (this.docNameType == 'reg_Card_Back') {
          this.showLoader.reg_card_back = false;
      }
     
       if (this.docNameType == 'emirated_ID_Front') {
        this.showLoader.emirates_Id_front = false;
      }
     
       if (this.docNameType == 'emirated_ID_Back') {
         this.showLoader.emirates_Id_back = false;
       }
     
       if (this.docNameType == 'driving_Lic_Front') {
         this.showLoader.driving_Lic_front = false;
       }
     
       if (this.docNameType == 'driving_Lic_Back') {
         this.showLoader.driving_Lic_back = false;
       }
        // this.docUploadForm.get('reg_Card_FrontFilePath').setValue(reader.result);
      // console.log(FiledName)
  
       
          if (FiledName == 'emirated_ID_Front') {
            // this.hideImages.emirates_Id_front = true;
       
            this.docUploadForm.get('emirated_ID_FrontFilePath').setValue(reader.result);
            this.docUploadForm.get('emirated_ID_FrontFileType').setValue(fileType);
            // this.tempDocArray[2] = tempDocumentArray;
          }
          if (FiledName == 'emirated_ID_Back') {
            this.hideImages.emirates_Id_back = true;
            this.docUploadForm.get('emirated_ID_BackFilePath').setValue(reader.result);
            this.docUploadForm.get('emirated_ID_BackFileType').setValue(fileType);
            // this.tempDocArray[3] = tempDocumentArray;
          }
          
        
      }
  
  }
   onFileChange(event, docName, FiledName, files: FileList) {
    this.docNameType = FiledName;
    // console.log(event)
    // console.log(docName)
    // console.log(this.docNameType)
  
  
  
    if (this.docNameType == 'emirated_ID_Front') {
     this.showLoader.emirates_Id_front = true;
     this.docUploadForm.get('emirated_ID_Front').setValue('true')
   }
  
    if (this.docNameType == 'emirated_ID_Back') {
      this.showLoader.emirates_Id_back = true;
      this.docUploadForm.get('emirated_ID_Back').setValue('true')
    }
  
  
  //this.showTempFile(event.target.files[0],this.docNameType);
  setTimeout(() => {
    this.showTempFile(event.target.files[0],this.docNameType);
       }, 2000);
  
  var imageData = '';
  // console.log(event.target.files[0].type);
  if(event.target.files[0].type=="image/png" || "image/jpeg"==event.target.files[0].type){ 
    // console.log(`Image size before compressed: ${event.target.files[0].size} bytes.`);
    // alert(`Image size before compressed: ${event.target.files[0].size} bytes.`);
      this.compressImage.compress(event.target.files[0])
      .pipe(take(1))
      .subscribe(compressedImage => {
  
        
        // console.log(`Image size after compressed: ${compressedImage.size} bytes.`);
        // alert(`Image size after compressed: ${compressedImage.size} bytes.`);
  
  
  
        const formData = new FormData();
        formData.append('file', compressedImage);
        formData.append('docName', docName);
        formData.append('source', 'B2C');
        formData.append('stage', 'QUOTE');
        formData.append('quoteType', 'std');
        formData.append('schemeCode', this.SchemeCode);
    
    // Array.from(files).forEach(f => formData.append('file', f))
  
   // this.updateDocTemp(formData,FiledName);
  
    this.motorQuoteService.uploadDocuments(formData).subscribe(res => {
      let updateMemResponse = res;
      this.document_data = updateMemResponse.Document_data;
  
      if (updateMemResponse.res_code == 1) {
  
        // this.showLoader.reg_card_front = false;
        // this.showLoader.reg_card_back = false;
        // this.showLoader.emirates_Id_front = false;
        // this.showLoader.emirates_Id_back = false;
        // this.showLoader.driving_Lic_front = false;
        // this.showLoader.driving_Lic_back = false;
  
  
        let fileType = updateMemResponse.File.split(".");
        fileType = fileType[fileType.length - 1];
        fileType = fileType == "pdf" ? "PDF" : "IMG";
       // alert("filetype: "+ fileType)
        let formArrayValue: any = this.docUploadForm.value;
  
        formArrayValue[FiledName] = updateMemResponse.File;
          formArrayValue[FiledName + "FilePath"] = updateMemResponse.FileDir;
          let tempDocumentArray = {
            file_name: FiledName,
            file_dir: updateMemResponse.FileDir,
            docName: updateMemResponse.File,
          }
  
          if (fileType == "pdf") {
            formArrayValue[FiledName + "FileType"] = "PDF";
          } else {
            formArrayValue[FiledName + "FileType"] = "IMG";
          }
         
      //  console.log(FiledName)
         
          if (FiledName == 'emirated_ID_Front') {
            this.hideImages.emirates_Id_front = true;
       
            this.docUploadForm.get('emirated_ID_FrontFilePath').setValue(updateMemResponse.FileDir);
            this.docUploadForm.get('emirated_ID_FrontFileType').setValue(fileType);
            this.tempDocArray[2] = tempDocumentArray;
            this.showLoader.emirates_Id_front = false;
          }
          if (FiledName == 'emirated_ID_Back') {
            this.hideImages.emirates_Id_back = true;
            this.docUploadForm.get('emirated_ID_BackFilePath').setValue(updateMemResponse.FileDir);
            this.docUploadForm.get('emirated_ID_BackFileType').setValue(fileType);
            this.tempDocArray[3] = tempDocumentArray;
            this.showLoader.emirates_Id_back = false;
           
          }
         
  
         
  console.log(FiledName)
          formArrayValue["docRegCardFront"] = "";
          formArrayValue['docRegCardBack'] = "";
          formArrayValue["docEmiratedIdFront"] = "";
          formArrayValue['docEmiratedIdBack'] = "";
          formArrayValue["docDrivingLicFront"] = "";
          formArrayValue["docDrivingLicBack"] = "";
  
          if (this.document_data.Errocode == 106) {
            // alert(1)
           
              if (FiledName == 'emirated_ID_Front') {
                  this.hideImages.emirates_Id_front = false;
                  this.docUploadForm.get('emirated_ID_FrontFilePath').setValue('');
              }
              if (FiledName == 'emirated_ID_Back') {
                  this.hideImages.emirates_Id_back = false;
                  this.docUploadForm.get('emirated_ID_BackFilePath').setValue('');
              }
             
           
            Swal.fire("Invalid document type selected or the document resolution is very low to read the data.",
                 "Please upload another clean copy and try. Sorry for the inconvenience.", "error");
                 return false;
          }
  
          if (this.document_data.DocumentType == 'Emirates-Id' && this.document_data.DocumentSubType == 'Front') {
  
            this.getEmiratesIdData(1, this.document_data);
          }
          else if (this.document_data.DocumentType == 'Emirates-Id' && this.document_data.DocumentSubType == 'Back') {
  
            this.getEmiratesIdData(2, this.document_data);
          }
         
         
  
         
  
          else if (this.document_data.DocumentType == 'Emirates-Id' && this.document_data.DocumentSubType == 'Both') {
                
                this.getEmiratesIdData(1, this.document_data);
                this.getEmiratesIdData(2, this.document_data);
          }
  
         
  
      }
      
          if (updateMemResponse.response_code == 5) {
            this.showLoader.reg_card_front = false;
            this.showLoader.reg_card_back = false;
            this.showLoader.emirates_Id_front = false;
            this.showLoader.emirates_Id_back = false;
            this.showLoader.driving_Lic_front = false;
            this.showLoader.driving_Lic_back = false;
            Swal.fire("Please select valid file format.", "only .pdf, .jpg, .png and .jpeg file formats allowed.", 'error');
          }
  
          if (updateMemResponse.response_code == 6) {
            this.showLoader.reg_card_front = false;
            this.showLoader.reg_card_back = false;
            this.showLoader.emirates_Id_front = false;
            this.showLoader.emirates_Id_back = false;
            this.showLoader.driving_Lic_front = false;
            this.showLoader.driving_Lic_back = false;
            Swal.fire(updateMemResponse.response_status);
          }
  
    })
  })
  }
  else{
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    formData.append('docName', docName);
    formData.append('source', 'B2C');
    formData.append('stage', 'QUOTE');
    formData.append('quoteType', 'std');
    formData.append('schemeCode', this.SchemeCode);
    this.motorQuoteService.uploadDocuments(formData).subscribe(res => {
      let updateMemResponse = res;
      this.document_data = updateMemResponse.Document_data;
  
      if (updateMemResponse.res_code == 1) {
  
        // this.showLoader.reg_card_front = false;
        // this.showLoader.reg_card_back = false;
        // this.showLoader.emirates_Id_front = false;
        // this.showLoader.emirates_Id_back = false;
        // this.showLoader.driving_Lic_front = false;
        // this.showLoader.driving_Lic_back = false;
  
  
        let fileType = updateMemResponse.File.split(".");
        fileType = fileType[fileType.length - 1];
        fileType = fileType == "pdf" ? "PDF" : "IMG";
       // alert("filetype: "+ fileType)
        let formArrayValue: any = this.docUploadForm.value;
  
        formArrayValue[FiledName] = updateMemResponse.File;
          formArrayValue[FiledName + "FilePath"] = updateMemResponse.FileDir;
          let tempDocumentArray = {
            file_name: FiledName,
            file_dir: updateMemResponse.FileDir,
            docName: updateMemResponse.File,
          }
  
          if (fileType == "pdf") {
            formArrayValue[FiledName + "FileType"] = "PDF";
          } else {
            formArrayValue[FiledName + "FileType"] = "IMG";
          }
         
        
          if (FiledName == 'emirated_ID_Front') {
            this.hideImages.emirates_Id_front = true;
       
            this.docUploadForm.get('emirated_ID_FrontFilePath').setValue(updateMemResponse.FileDir);
            this.docUploadForm.get('emirated_ID_FrontFileType').setValue(fileType);
            this.tempDocArray[2] = tempDocumentArray;
          }
          if (FiledName == 'emirated_ID_Back') {
            this.hideImages.emirates_Id_back = true;
            this.docUploadForm.get('emirated_ID_BackFilePath').setValue(updateMemResponse.FileDir);
            this.docUploadForm.get('emirated_ID_BackFileType').setValue(fileType);
            this.tempDocArray[3] = tempDocumentArray;
          }
        
         
  
         
  
          formArrayValue["docRegCardFront"] = "";
          formArrayValue['docRegCardBack'] = "";
          formArrayValue["docEmiratedIdFront"] = "";
          formArrayValue['docEmiratedIdBack'] = "";
          formArrayValue["docDrivingLicFront"] = "";
          formArrayValue["docDrivingLicBack"] = "";
  
          if (this.document_data.Errocode == 106) {
            
            if (FiledName == 'emirated_ID_Front') {
              this.hideImages.emirates_Id_front = false;
              this.docUploadForm.get('emirated_ID_FrontFilePath').setValue('');
            }
            if (FiledName == 'emirated_ID_Back') {
              this.hideImages.emirates_Id_back = false;
              this.docUploadForm.get('emirated_ID_BackFilePath').setValue('');
            }
           
           
            Swal.fire("Invalid document type selected or the document resolution is very low to read the data.",
                 "Please upload another clean copy and try. Sorry for the inconvenience.", "error");
                 return false;
          }
  
          if (this.document_data.DocumentType == 'Emirates-Id' && this.document_data.DocumentSubType == 'Front') {
            // console.log('sdfhsfsjffsfs');
            this.getEmiratesIdData(1, this.document_data);
          }
          else if (this.document_data.DocumentType == 'Emirates-Id' && this.document_data.DocumentSubType == 'Back') {
  
            this.getEmiratesIdData(2, this.document_data);
          }
         
         
  
  
          else if (this.document_data.DocumentType == 'Emirates-Id' && this.document_data.DocumentSubType == 'Both') {
                
                this.getEmiratesIdData(1, this.document_data);
                this.getEmiratesIdData(2, this.document_data);
          }
  
  
      }
      
          if (updateMemResponse.response_code == 5) {
            this.showLoader.reg_card_front = false;
            this.showLoader.reg_card_back = false;
            this.showLoader.emirates_Id_front = false;
            this.showLoader.emirates_Id_back = false;
            this.showLoader.driving_Lic_front = false;
            this.showLoader.driving_Lic_back = false;
            Swal.fire("Please select valid file format.", "only .pdf, .jpg, .png and .jpeg file formats allowed.", 'error');
          }
  
          if (updateMemResponse.response_code == 6) {
            this.showLoader.reg_card_front = false;
            this.showLoader.reg_card_back = false;
            this.showLoader.emirates_Id_front = false;
            this.showLoader.emirates_Id_back = false;
            this.showLoader.driving_Lic_front = false;
            this.showLoader.driving_Lic_back = false;
            Swal.fire(updateMemResponse.response_status);
          }
  
    })
  }
  
  
  
  } 
  getEmiratesIdData(type, docData) {

    if (type == 1) {
  // console.log('eid');
      this.document_data.Data = docData.Data != '' ? docData.Data : this.document_data.Data;
  
      // if(this.document_data.Data['ID Number']=="Not Found" && this.document_data.Data['ID-NumberInArabic']!=''){ alert(1);
      //   this.motorQuoteService.translateInEnglish(this.document_data.Data['ID-NumberInArabic']).subscribe(res => {
  
      //   });
      // }
  
     
  
      if (this.document_data.Data['ID Number'] == "Not Found") {
            this.insuredDetailForm.get('e_eid').setValue('');
      } else{
              this.insuredDetailForm.get('e_eid').setValue(this.document_data.Data['ID Number']);
              this.checkEID(this.document_data.Data['ID Number'])
              // console.log(this.insuredDetailForm.value.e_eid);
      }
  
      if(this.document_data.Data['Name'] == "Not Found" ){
  
        this.insuredDetailForm.get('e_name').setValue('');
  
     } else{  
       this.insuredDetailForm.get('e_name').setValue(this.document_data.Data['Name']); 
       this.linkedDetails.get('insuredname').setValue(this.document_data.Data['Name']); 
       }
      
  
      // Nationality
      let nationality = this.document_data.Data['Nationality'];
      console.log(nationality)
      var indexNationality = this.countryArr.findIndex(function (obj, k) {
      
        return obj.CountryName === nationality;
      });
      console.log(indexNationality)
      let nationalVal = this.countryArr[indexNationality];
      this.insuredDetailForm.get('e_nationality').setValue(nationalVal);
               
    }
  
    if (type == 2) {
      this.document_data.Data = docData.Data != '' ? docData.Data : this.document_data.Data;
  
      let dateDob: string = this.document_data.Data['Date of Birth'];
      dateDob = dateDob.replace(/-/gi, "/");
  
      let dateExp: string = this.document_data.Data['Expiry Date'];
      dateExp = dateExp.replace(/-/gi, "/");
  
      //this.insuredDetailForm.get('e_cardNumber').setValue(this.document_data.Data['Card Number']);
      this.insuredDetailForm.get('e_expiryDate').setValue(this.dateConvert(dateExp));
  
      let gender = this.document_data.Data['Sex']
      //Gender
      var indexGender = this.genders.findIndex(function (obj, k) {
        return obj.value === gender;
      });
      let gnderVal = this.genders[indexGender];
      this.insuredDetailForm.get('e_gender').setValue(gnderVal);
  
     
  
    }
  
  
  
  }

  dateConvert(inputFormat) {

    let vDOEntryArray = inputFormat.split('/');
    let DOEntry = new Date();
    DOEntry.setDate(vDOEntryArray[0]);
    DOEntry.setMonth(vDOEntryArray[1] - 1);
    DOEntry.setFullYear(vDOEntryArray[2]);
  
    return DOEntry;
  
  }
 

  //------------------------------- VALIDATION FOR EMIRATES ID ----------------------------------------------//
 checkEID(value) {
  value = this.insuredDetailForm.value.e_eid;
  this.value = value;
 

  this.value = this.value.replace(/-/g, "");
  if (/[^0-9-]+/.test(this.value))
    this.invalidEID = true;


      if (this.value.length != 15) {
     
        this.invalidEID = true;
        // this.insuredDetailForm.get("eIDCheck").setValue('');
        return this.invalidEID;
      }
  if (this.value == "111111111111111" || this.value == "000000000000000" || this.value == "222222222222222" || this.value == "333333333333333") {
    this.invalidEID = false;
    this.insuredDetailForm.get("eIDCheck").setValue('1');
 
    return this.invalidEID;
  }

  else {
    //The Luhn Algorithm.
    var nCheck = 0, nDigit = 0, bEven = false;
    this.value = this.value.replace(/\D/g, "");


    //784-1982-6961498-2

    for (let n = this.value.length - 1; n >= 0; n--) {

      var cDigit = this.value.charAt; nDigit = parseInt(this.value[n]);

      if (bEven) {
        if ((nDigit *= 2) > 9)
          nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;



    }

    if ((nCheck % 10) == 0) {
      //if valid, ok, check next
      this.invalidEID = false;
     // this.insuredDetailForm.get("eIDCheck").setValue(1);
    
      return this.invalidEID;

    }
    else {
      this.invalidEID = true;
     // this.insuredDetailForm.get("eIDCheck").setValue('');
     
      return this.invalidEID;
      //alert('Invalid Emirates ID. Please enter valid emirates Id including (-) dash/hypen.');
    }



  }

}
 //--------------------------------- GET ALL FORM DROPDOWN DATA -----------------------------
 getAllFormData(year) {
  this.motorQuoteService.getAllFormData('COMPREHENSIVE', 'INDIVIDUAL', '0', this.language_code, this.country,year).subscribe(res => {

    this.cityArr = res.cityData;
    this.vhclColorArr = res.vehicleColorsData;
    this.countryArr = res.countryData;
   


    

   
    // this.personalDetailForm.get("licIssueContry").setValue(this.countryArr[32]);


    // this.additionalDetailForm.get("nationality").setValue(this.countryArr[32]);
   

  });

  this.motorQuoteService.getBankDetail().subscribe(res => {

    // this.bankDetail = res.bankdetailsData;
    // this.filteredBank.next(this.bankDetail.slice());

  });
}
VerifyKYC(ProgressModel){
  this.verifyotploader = true;
  if(this.insuredDetailForm.invalid){
    this.insuredDetailForm.markAllAsTouched();
    this.verifyotploader = false;
    return false
  }
  this.progressModel = ProgressModel;
  this.motorQuoteService.sendotp(this.insuredDetailForm.value.adtnl_detail_email).subscribe(result=>{
    if(result.response_code == 1){
      this.verifyotploader = false;
      ProgressModel.show();
    }
   
  })
  
}

// ----------------------------------Verify Otp------------------------------
verifyOtp(){
  this.verifyotploader1 = true;
  this.motorQuoteService.verifyOtp(this.insuredDetailForm.value.adtnl_detail_email,this.otp).subscribe(result=>{
    if(result.response_code == 1){
      this.stepper.next();
      this.ProgressModel.hide();
      this.verifyotploader1 = false;
    }else{
      this.wrongotp = true;
      this.verifyotploader1 = false;
    }

  })
}

processDocument(stepper){
  console.log(stepper)
  if(this.docUploadForm.invalid){
    Swal.fire("", "Please upload the Documents", 'error'); 

  }else{


  stepper.next();
}
}

linked(){
  this.motorQuoteService.linkedpolicy(this.linkedDetails.value.insuredname,this.linkedDetails.value.policy_number,this.convertDate(this.linkedDetails.value.policy_exp_date)).subscribe(result=>{
    if(result.response_code == 1){
      Swal.fire("", "Policy Linked Successfully", "success");
      this.datalinked = result.response_data;
      this.datalinkedloader =false;

    }else{
      this.datalinkedloader =true;
      Swal.fire("", "Invalid credentials. Please contact Fidelity United at 800 842", "error");
    }

  })
}
//----------- CONVERT DATE INTO DD/MM/YYYY FORMAT ------------//
convertDate(inputFormat) {
  function pad(s) { return (s < 10) ? '0' + s : s; }
  var d = new Date(inputFormat);
  //return ([ d.getFullYear(),pad(d.getMonth()+1),pad(d.getDate())].join('-'));
  return ([pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-'));

}

}
