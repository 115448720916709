import { Component, OnInit, Input,Output, ViewEncapsulation ,ViewChild,EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UserauthService } from "../../_service/userauth.service";
import { Router,ActivatedRoute } from "@angular/router";
import { GlobalService } from "../../_service/global.service";
import { environment } from '../../../environments/environment';



@Component({
  selector: "app-basic-login",
  
  templateUrl: "./login-user.component.html",
  styleUrls: ["./login-user.component.scss"]
})
export class LoginUserComponent implements OnInit {
  
   public _loginForm: FormGroup;
   public  _submitted: boolean = false;
   public  _errorMessage: string = "";$msgErrr='';
   _formErrors:any;
  domain_location=window.location.origin;
  public tockenCheck:any ='';
  loaderStatus:boolean = true;

  
 
  
  constructor(
    private _staffService: UserauthService,
    private _router: Router,
    private _formBuilder: FormBuilder,private route: ActivatedRoute,public _globalService: GlobalService
  ) {
    
    this._loginForm = _formBuilder.group({
      email: ["", Validators.required],
      password: [
        "",
        Validators.compose([Validators.required])
      ]
    });
    this._loginForm.valueChanges.subscribe(data => this.onValueChanged(data));
  }
   
  public _setFormErrors(errorFields: any): void {
    for (let key in errorFields) {
      // skip loop if the property is from prototype
      if (!errorFields.hasOwnProperty(key)) continue;

      let message = errorFields[key];
      this._formErrors[key].valid = false;
      this._formErrors[key].message = message;
    }
  }
  public _resetFormErrors(): void {
    this._formErrors = {
      email: { valid: true, message: "" },
      password: { valid: true, message: "" }
    };
  }

  public _isValid(field): boolean {
    let isValid: boolean = false;

    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this._loginForm.controls[field].touched == false) {
      isValid = true;
    }
    // If the field is touched and valid value, then it is considered as valid.
    else if (
      this._loginForm.controls[field].touched == true &&
      this._loginForm.controls[field].valid == true
    ) {
      isValid = true;
    }
    return isValid;
  }
  public onValueChanged(data?: any) {
    if (!this._loginForm) {
      return;
    }
    const form = this._loginForm;
    for (let field in this._formErrors) {
      // clear previous error message (if any)
      let control = form.get(field);
      if (control && control.dirty) {
        this._formErrors[field].valid = true;
        this._formErrors[field].message = "";
      }
    }
  }
 
  ngOnInit() {
    
    document.querySelector("body").setAttribute("themebg-pattern", "theme1");
    this._staffService.userAuthLoginExist();
   
  }
   
 
  getParameters(): void {
    this.route.queryParams
      .subscribe(result => {
        console.log('result', result);
      },
        error => {
          console.log('error', error);
        });
  }

  loginUserAuth(){
    this._submitted = true;
     this._errorMessage='';
    this._staffService.loginUserAuth(this._loginForm.value.email,this._loginForm.value.password).subscribe(res =>{
     let  result=this._globalService.convertResponseInJson(res);
     if (result.status=='true') {
      this._router.navigate(["/dashboard"]);
     }
     else {
    
       this._errorMessage = "Invalid Token..";
       this._submitted = false;
       this.loaderStatus = false;
       this.loaderStatus =  this._globalService.spinnerShow();
       setTimeout(
         function() {
           this._errorMessage = "";
          
         }.bind(this),
        2200
       );
     }
   });
  
  }
  
  public onSubmit(elementValues: any) {
    // this._submitted = true;
    // this._errorMessage='';
    // this._staffService
    //   .login(elementValues.email, elementValues.password)
    //   .subscribe(
    //     result => {
    //       if (result.status=='true') {
          
    //         if(localStorage.getItem("usertype")=='saasadmin'){
    //           this._router.navigate(["/dashboard/admin"]);
    //         }else{
    //           // this.getAccountingFirm();
    //           // this.firmPickModal.show(); 

    //          this._router.navigate(["/dashboard"]);
    //         }
    //       //this._router.navigate(["/process/currenttask"]);
    //      // this._router.navigate(["/dashboard"]);
    //       } else {
            
    //         this._errorMessage = "Username or password is incorrect.";

    //         this._submitted = false;
    //       }
    //     },
    //     error => {
    //       this._submitted = false;
    //       // Validation error
    //       if (error.status == 422) {
    //         this._resetFormErrors();
    //         // this._errorMessage = "There was an error on submission. Please check again.";
    //         let errorFields = JSON.parse(error.data.message);
    //         this._setFormErrors(errorFields);
    //       } else {
    //         this._errorMessage = error.data;
    //       }
    //     }
    //   );
  }
}
