import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OcrmainComponent } from './ocrmain.component';
import { WithocrComponent } from './withocr/withocr.component';
import { WithoutocrComponent } from './withoutocr/withoutocr.component';

const routes: Routes = [ 


  {
  path: 'motoradvquote',
  component: OcrmainComponent,
  },
  {
  path: 'motoradvquote/:quoteNum/:emailId/:mobileNo',
  component: OcrmainComponent,
  },

  {
    path: 'withoutocr',
    component: OcrmainComponent,
  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OcrmainRoutingModule { }
