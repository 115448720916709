import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { MotorquoteService } from '../_service/motorquote.service';
import Swal from 'sweetalert2';
import { GlobalService } from '../_service/global.service';

@Component({
  selector: 'app-cancelpayment',
  templateUrl: './cancelpayment.component.html',
  styleUrls: ['./cancelpayment.component.scss']
})
export class CancelpaymentComponent implements OnInit {

  quotenumber:any; paymentLoader: boolean = false; payment_cancel_reason : any; res_code = 0; errorMsg:any;
  trans_ref: any;
  orderNumber: string;

  constructor(public _route: Router,public _activatedroute: ActivatedRoute,public motorQuoteService: MotorquoteService,public globalService:GlobalService) { }

  ngOnInit() {
    this.orderNumber =  localStorage.getItem("Payment_Order_ID");
    this.paymentLoader = this.globalService.spinnerHide();
    this.quotenumber = localStorage.getItem('Payment_Quotation_Number');
    
    const routeParam = this._activatedroute.snapshot.params;
    if(routeParam){
     this.res_code = routeParam.Res_Code;
     this.getPaymentErrorMsg(this.res_code);
    }
console.log(this.orderNumber)
  }

  goBackto(){
    this._route.navigateByUrl('/motorquote');
  }

  retryPayment(){
    
    let return_url = "http://k2key.in/motor_ins//dashboard";
    let site_url = "http://k2key.in/motor_ins/" + this._route.url;

    this.paymentLoader = this.globalService.spinnerShow();
   // this.showHideLoader.additionalDetail = true;

      //Modified by Anju for payment gateway revamp
      this.motorQuoteService.paymentAuthNew(this.quotenumber, site_url, return_url, 'MT', '1', '', 'B2C', 'adv').subscribe(res => {
          let payRes = res;

          if (payRes.status == 'Failed') {
              Swal.fire(payRes.res_msg);
              // this.paymentLoader = this.globalService.spinnerHide();
              // this.showLoader.Quotation = false;
              return false;
          }

          else if (payRes.status == 'Success') {
              // alert(payRes.result.p_id)
              //this.paymentLoader = this.globalService.spinnerHide();
              // this.showLoader.Quotation = false;
              // localStorage.setItem('Payment_Order_ID',res.result.tran_ref);
              localStorage.setItem('Payment_Order_ID', payRes.data.tran_ref);
              localStorage.setItem('Payment_Order_Tokan', payRes.data.token);
              localStorage.setItem('Payment_Quotation_Number', this.quotenumber);

              window.location.href = payRes.data.redirect_url;
          }

      });
  }

  getPaymentErrorMsg(res_code){
    this.motorQuoteService.getPaymentErrorMsg('PAYMENT',res_code,'ENG').subscribe(res =>{
    // this.motorQuoteService.getPaymentErrorMsg(this.orderNumber,res_code,'ENG').subscribe(res =>{
          this.errorMsg = res.errorMessage[0].ErrorMessage;

    });
  }

  retrieveQuote(){
   
      //  this._route.navigateByUrl('/motorquote/' + this.quotenumber);
      this._route.navigateByUrl('/motoradvquote/' + this.quotenumber);
  
  }


}
