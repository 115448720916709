import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { catchError, tap, map } from 'rxjs/operators';
import {  of, throwError, observable } from 'rxjs';
import { GlobalService } from "../_service/global.service";
import { config } from './../config';
// import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class IssuepolicyService {

    apiURL: string = config.apiUrl;
   
    constructor(private httpClient: HttpClient,public _globalService: GlobalService) {}
    
  //   private handleError<T> (operation = 'operation', result?: T) {
  //     return (error: any): Observable<T> => {

  //       // TODO: send the error to remote logging infrastructure
  //       console.error(error); // log to console instead

  //       // Let the app keep running by returning an empty result.
  //       return of(result as T);
  //     };
  // }

  public handleError(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
      } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.log(errorMessage);
      return throwError(errorMessage);
  }

  //------------ List of dropdown data FOR ISSUPOLICY ---------//
  public getQuotationFormData():Observable<any> {

    return this.httpClient.post(`${this.apiURL}Webservice/getQuotationFormData`,{},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);

           }));
  }

  //-------------------getB2CRenewalPolicyList----------------------------------//
  public getB2CRenewalPolicyList(date,data):Observable<any> {
    return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getB2CRenewalPolicyList`,{core_policy_number:data.core_policy_number,expire_date:date,mobile_number:data.mobile_number,email:data.email},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
          }));
  }

 //------------- FOR GET QUOTE --------------//
  public getQuote(policyHolderFormData):Observable<any> {

    return this.httpClient.post(`${this.apiURL}Webservice/getQuots`,{policyHolderFormData:policyHolderFormData},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);

           }));
  }

//--------------- FOR GET PLANS OF PERTICULAR QUOTE NUMBER ---------------//
public getPlans(quotationNumber):Observable<any> {

  return this.httpClient.post(`${this.apiURL}Webservice/getPlansByQuotataionNumber`,{QuotationNumber:quotationNumber},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

         }));
}

//---------- List of dropdown data FOR RETRIEVE QUOTE --------------//
public retrieveQuoteFormData():Observable<any> {

  return this.httpClient.post(`${this.apiURL}Webservice/retrieveQuotationFormApi`,{},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

         }));
}

//----------- Quotation List for Retrieve Quote -----------------//
public retrieveQuoteList(id,fromDate,toDate,quotNum,phName,status):Observable<any> {

  return this.httpClient.post(`${this.apiURL}Webservice/getQuotationListForRetrieve`,{Id:id,fromDate:fromDate,toDate:toDate,quoteRefNum:quotNum,policyHolderName:phName,status:status},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

         }));
}
//----------- GET POLICY LIST FOR REVIEW  -----------------//
public getPolicyListReview(id,fromDate,toDate,search,userId):Observable<any> {

  return this.httpClient.post(`${this.apiURL}Webservice/getpolicyListReview`,{product_code:id,from_date:fromDate,to_date:toDate,search_text:search,user_id:userId},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

         }));
}

//--------------- get Quotation Details For RetrieveByQuoteNo -----------------//
// public getMembersDetailByQuoteNo(quoteNum):Observable<any> {

//   return this.httpClient.post(`${this.apiURL}Webservice/getMembersDetailByQuoteNo`,{QuotationNumber:quoteNum},this._globalService.getHeader())
//         .pipe(catchError(this.handleError), tap(res => {
//           let  response=this._globalService.convertResponseInJson(res);

//          }));
// }
//-------------------- update premimum and Plan -------
public updatePlanAndPrimum(planId,primum,DiscountPercent):Observable<any> {

  return this.httpClient.post(`${this.apiURL}quotationController/updatePlanAndPrimum`,{plan:planId,primum:primum,DiscountPercent:DiscountPercent},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

         }));
}
//------------- get member's detail -------------//
public getMembersDetailByQuoteNo(quoteNum):Observable<any> {

  return this.httpClient.post(`${this.apiURL}Webservice/getMembersDetailByQuoteNo`,{QuotationNumber:quoteNum},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

         }));
}

//----------------- Update Quote -------------//
public updateQuote(quoteNum,policyHolderFormData):Observable<any> {

  return this.httpClient.post(`${this.apiURL}Webservice/updateQuotation`,{QuotationNumber:quoteNum,policyHolderFormData:policyHolderFormData},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

         }));
}

public getQuestionnaire():Observable<any> {

  return this.httpClient.post(`${this.apiURL}Webservice/getQuestionnaire`,{},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}

//--------------- Update members additional detail ------------- //

public updateMemAdditionalDetail(memList,memberDetailFormData,remark):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MemberController/updateMembersAdditionalDetails`,{memList:memList,memberDetailFormData:memberDetailFormData,remark:remark},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

         }));
}

public saveMemberFiles(formData ):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MemberController/saveMembersFiles`,formData)
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

         }));
}

public saveMemberMedicalHistory(selectedMember,medicalHistory,quotationNumber,bloodGp,email,partnerID):Observable<any> {
  
  let jsonDataSend=[];
  medicalHistory.forEach((item,index) => {
    jsonDataSend.push({id:index,data:item});
    
  });
    return this.httpClient.post(`${this.apiURL}MemberController/saveMemberMedicalHistory`,{MemberID:selectedMember.MemberID,formData:jsonDataSend,quotationNumber:quotationNumber,bloodGp:bloodGp,email:email,partnerID:partnerID},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
           }));
  }

  //--------------------- PDF generate for Medical History Form------------//
  public medicalHistoryFormPdfGenerate(quotationNumber,memberId):Observable<any> {

    return this.httpClient.post(`${this.apiURL}GeneratePdf/medicalHistoryFormPDFGenerate`,{quotationNumber:quotationNumber,memberId:memberId},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
           }));
  }

  //--------------------- PDF generate for Quotation Plans------------//
  public quotationPlanPDFGenerate(quotationNumber):Observable<any> {

    return this.httpClient.post(`${this.apiURL}QuotationPdf/quotationPlanPDFGenerate`,{quotationNumber:quotationNumber},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));
  }


  public getpartnerBranch(partnerID){
    return this.httpClient.post(`${this.apiURL}Webservice/partnerBranch`,{partnerID:partnerID},this._globalService.getHeader())
    .pipe(catchError(this.handleError), tap(res => {
      let  response=this._globalService.convertResponseInJson(res);

     }));
  }

  //--------------------- CHECK MEMBER DUPLICATE ------------//
  public duplicateMember(passportNum,emiratesID,uidNum):Observable<any> {

    return this.httpClient.post(`${this.apiURL}MemberController/checkDuplicateMember`,{PassportNumber:passportNum,EmiratesId:emiratesID,UIDNumber:uidNum},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));
  }

  //---------------- webservice for Work Location -------------//
  public getMemberWorkLocation(visaregion):Observable<any> {

    return this.httpClient.post(`${this.apiURL}MemberController/getMemberWorkLocation`,{VisaRegion:visaregion},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));
  }

   //---------------- webservice for Residential Location -------------//
   public getMemberResLocation(visaregion):Observable<any> {

    return this.httpClient.post(`${this.apiURL}MemberController/getMemberResidentialLocation`,{VisaRegion:visaregion},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));
  }

  //------------------- GET DASHBOARD DATA ------------------------//
  public getDashboaedData(user_id,partner_id,lob_code):Observable<any> {

    return this.httpClient.post(`${this.apiURL}DashboardController/getDashboardData`,{user_id:user_id,partner_id:partner_id,lob_code:lob_code},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));
  }

   //------------------- FOR PAYMENT ------------------------//
   public paymentAuth(QuotationNumber,site_url,return_url,project_type,partner_id,policy_number,source,b2c_type):Observable<any> {

    return this.httpClient.post(`${this.apiURL}PaymentWebservice/open_pay_page_values`,{QuotationNumber:QuotationNumber,site_url:site_url,return_url:return_url,project_type:project_type,partner_id:partner_id,policy_number:policy_number,source:source,b2c_type:b2c_type},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));
  }


  //-----------------SAVE QUOTATION PAYMENT -------------------//
  public saveQuotationPayment(token,eventType,quoteNumber,responseMsg,transactionID):Observable<any> {

    return this.httpClient.post(`${this.apiURL}PaymentWebservice/saveQuatationPayment`,{PaymentLogId:token,eventType:eventType,QuotationNumber:quoteNumber,ResponseMessage:responseMsg,TransactionID:transactionID},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));
  }

  //-------------------- VERIFY QUOTATION PAYMENT ----------------//
  public verifyQuatationPayment(orderNumber):Observable<any> {

    return this.httpClient.post(`${this.apiURL}PaymentWebservice/verifyQuatationPayment`,{orderNumber:orderNumber},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));
  }

  //------------------------ UPLOAD MAF --------------------//
  public uploadMAF(quotationNumber,MemberID,filePath,fileName,processType):Observable<any> {

    return this.httpClient.post(`${this.apiURL}MemberController/uploadMAFMedicalHistory`,{quotationNumber:quotationNumber,MemberID:MemberID,filePath:filePath,fileName:fileName,processType:processType},this._globalService.getHeader())
          .pipe(catchError(this.handleError), tap(res => {
            let  response=this._globalService.convertResponseInJson(res);
  
            }));
  }
 //--------------------- SEND PAYMENT LINK TO CUSTOMER -------------//
 public sendPaymentLink(quotationNumber):Observable<any> {

  return this.httpClient.post(`${this.apiURL}PaymentWebservice/sendQuatationPaymentLink`,{QuotationNumber:quotationNumber},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
  } 
//-------------------------- View Policy --------------------------//
public getPliciesByUser(partnerId,userName,lob,fromDate,toDate):Observable<any> {

  return this.httpClient.post(`${this.apiURL}PolicyController/getPoliciesByUser`,{partnerid:partnerId,username:userName,lob:lob,from_date:fromDate,to_date:toDate},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
  }
//------------------------ View Policy Details -----------------------//
public getCustomerPolicyDetails(partnerId,userName,policyNumber,lobCode):Observable<any> {

  return this.httpClient.post(`${this.apiURL}PolicyController/getCustomerPolicyDetails`,{partnerId:partnerId,userName:userName,polNumber:policyNumber,lobCode:lobCode},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
  }
//------------------------ INDIVIDUAL CERTIFICATE PDF -----------------------//
public individualCertPDF(quotationNumber,memberId):Observable<any> {

  return this.httpClient.post(`${this.apiURL}Memberedcertificate_new/memberCertificatePDFGenerate`,{quotationNumber:quotationNumber,memberId:memberId},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
  }

//------------------------ VIRTUAL CARD PDF -----------------------//
public virtualCardPDF(quotationNumber,memberId):Observable<any> {

  return this.httpClient.post(`${this.apiURL}GeneratePdf/memberVirtualCardPdf`,{quotationNumber:quotationNumber,memberId:memberId},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
  }
//------------------------------ SEND MAF MAIL TO CUSTOMER -----------------------------------------------//
public sendMafMailToCustomer(quotationNumber,partner_id,memberDetails,email):Observable<any> {
  return this.httpClient.post(`${this.apiURL}MemberController/saveMAFLogsData`,{quotationNumber:quotationNumber,partner_id:partner_id,memberDetails:memberDetails,email:email},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
  }
//------------------------------- VALIDATE MAF ACCESS -----------------------------------------//
public validateMAFAccess(quotationNumber,partner_id,user_token):Observable<any> {
  return this.httpClient.post(`${this.apiURL}MemberController/validateMAFAccess`,{quotationNumber:quotationNumber,partner_id:partner_id,user_token:user_token},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
  }
//------------------------------- FOR REDIRECT PAGES -----------------------------------------//
public redirectPages():Observable<any> {
  return this.httpClient.post(`${this.apiURL}Webservice/getRedirectionLink`,{},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
  }
//------------------------------- FOR VALIDATE USER ACCESS -----------------------------------------//
public validateUserAccess(productPageName,lobCode):Observable<any> {
  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/checkAccess`,{productPageName:productPageName,lobCode:lobCode},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
  }
//------------------------------------ FOR GET MEDICAL RECIEPT  -------------------------------------------------//
public getMedicalReceipt(webQuoteNum,lobCode,user_name,policy_number,partnerId):Observable<any> {

  return this.httpClient.post(`${this.apiURL}Webservice/getPaymentReceipt`,{quotation_number:webQuoteNum,lob:lobCode,user_name:user_name,policy_number:policy_number,partnerId:partnerId},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
}
//------------------------------------ FOR GET MEDICAL RECIEPT  -------------------------------------------------//
public getPolicyPeriod(visa_region):Observable<any> {

  return this.httpClient.post(`${this.apiURL}Webservice/getPolicyPeriod`,{visa_region:visa_region},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
}


//------------------------------------ FOR GET MEDICAL MemberID  -------------------------------------------------//
public getMemberID(visa_region):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MemberController/getCustMemberID`,{visa_region:1},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
}
//------------------------------------ FOR POLICY APPROVE  -------------------------------------------------//
public policyApproval(quoteNum):Observable<any> {

  return this.httpClient.post(`${this.apiURL}PaymentWebservice/policyApproval`,{quatationNumber:quoteNum},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
}
//------------------------------------ FOR RECEIPT GENERATION  -------------------------------------------------//
public generateReceipt(quoteNum,transID):Observable<any> {

  return this.httpClient.post(`${this.apiURL}PaymentWebservice/receiptGeneration`,{quatationNumber : quoteNum,transaction_id : transID},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
}

//------------------------------- FOR GET QUOTATION DETAIL FOR RETRIEVE QUOTE ------------------------------------//
public getDataForRetrieveQuote(quoteNum):Observable<any> {

  return this.httpClient.post(`${this.apiURL}Webservice/getQuotationDetailsForRetrieveByQuoteNo`,{QuotationNumber : quoteNum},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
}
//-------------------------------  FOR VERIFY PAYMENT NEW ------------------------------------//
public verifyPayment(tran_ref):Observable<any> {

  return this.httpClient.post(`${this.apiURL}PaymentWebservice/verifyPayment`,{tran_ref : tran_ref},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
}
//------------------------------ FOR GET QUOTATION DETAIL ---------------------------------------//
public getQuotationDetail(view_type,reference_number,partner_id):Observable<any> {

  return this.httpClient.post(`${this.apiURL}HomeInsuranceWebservice/getQuotationDetails`,{view_type:view_type,quotation_number:reference_number,partner_id:partner_id},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}


 
}
