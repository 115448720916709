import { Component} from '@angular/core';
import {Observable, Subscription, fromEvent} from 'rxjs';
import { GlobalService } from './_service/global.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;
  connectionStatus: string;

  constructor(public _globalService: GlobalService) {}

  ngOnInit() {
   
 
          this.onlineEvent = fromEvent(window, 'online');
          this.offlineEvent = fromEvent(window, 'offline');
          this.subscriptions.push(this.onlineEvent.subscribe(e => {
          this.connectionStatusMessage = 'Back to online';
          this.connectionStatus = 'online';

          this._globalService.connectionStatusChanged.next('online');
                Swal.fire("", "Back to online", "success");
               // alert('Back to online');
                console.log('Online...',e);
           }));

          this.subscriptions.push(this.offlineEvent.subscribe(e => {
          this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
          this.connectionStatus = 'offline';

          this._globalService.connectionStatusChanged.next('offline');
                Swal.fire("", "Connection lost! You are not connected to internet", "error");
               // alert('Connection lost! You are not connected to internet');
                console.log('Offline...',e);
          }));

  }

}
