import { Component, OnInit } from '@angular/core';
import { MotorquoteService } from 'app/_service/motorquote.service';
import {Observable} from 'rxjs/Rx';
@Component({
  selector: 'app-winner',
  templateUrl: './winner.component.html',
  styleUrls: ['./winner.component.scss']
})
export class WinnerComponent implements OnInit {
  winnername: any;
  winnervochureno: any;
  message: number;
  value: any =5;
  hidelodaer: boolean =false;
  
  constructor(public motorquoteservice:MotorquoteService) { 
    Observable.interval(1000)
    
    .map((x) => x-1)
    
    .subscribe((x) => {
      this.message = x;
    });

  }

  ngOnInit() {
  //   $('#count-example').jQuerySimpleCounter({
  //     start:  100,
  //     end:    0,
  //   });
  }

  getWinnerDetails(){
    // console.log(modal)
    this.hidelodaer =true;
    this.motorquoteservice.getWinnerDetails().subscribe(result=>{
      this.winnername =result.winnername;
      this.winnervochureno=result.winnervochureno;
      
     

    })
    setTimeout(() => {
      this.hidelodaer=false;
},9000);
console.log(this.hidelodaer)

  }

}
