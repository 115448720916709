import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-withoutocr',
  templateUrl: './withoutocr.component.html',
  styleUrls: ['./withoutocr.component.scss']
})
export class WithoutocrComponent implements OnInit {

  constructor(public _route: Router) { }

  ngOnInit() {
  }

}
