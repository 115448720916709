import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { catchError, tap, map } from 'rxjs/operators';
import {  of, throwError, observable } from 'rxjs';
import { GlobalService } from "../_service/global.service";
import { config } from './../config';
import { environment } from '../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class MotorquoteService {

    apiURL: string = config.apiUrl; 
    paymentAPIURL = config.paymentGatewayUrl;//Added by Anju for new payment gateway
   
  constructor(private httpClient: HttpClient,public _globalService: GlobalService, private _snackBar: MatSnackBar) {}

  public openSnackBar(message: string, action: string) {
     
    this._snackBar.open(message, action, {
      duration: 6000,
      verticalPosition:'top',
      horizontalPosition:'center',
      direction:'ltr',
      panelClass : ['snackbar']
    });
  }

  public handleError(error) {
   
  let   errorMessage :any = {};
      if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
       } else {
              // server-side error
              errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.error(error.status);
        if(error.status == 0){
              //window.alert("There is server error. Please try again later");
              Swal.fire("There is server error", "Please try again later", "error");
        }
      //   if(error.status == 500){
      //         Swal.fire("Sorry, an error occured", "Please try again later", "error");
      //  }
        

        return throwError(errorMessage);


    // if (error.status == 0) {
    //  errorMessage = {
    //     success: false,
    //     status: 0,
    //     data: "Sorry, there was a connection error occurred. Please try again."
    //   };
    // } else {
    //   errorMessage = error.json();
    // }
    // return Observable.throw(errorMessage);

    
}

//--------------getplanDetails------------------//
public getplanDetails(motorValuesArray,policyDetailsArray):Observable<any> {
  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getRenewalPremium`,{motorValuesArray:motorValuesArray,policyDetailsArray:policyDetailsArray},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);
        }));
}

//------------------getPolicyDetailForRenewal------------------------//
public getPolicyDetailForRenewal(cedant_partner_id,quotation_number,core_policy_number,view_type,source):Observable<any> {
  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getPolicyDetailsForRenewal`,{cedant_partner_id:cedant_partner_id,quotation_number:quotation_number,core_policy_number:core_policy_number,view_type:view_type,source:source},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);
        }));
}

//------------------------------------- FOR GET ALL FORM DROPDOWN DATA -----------------------------------------//
  public getAllFormData(policy_type,registration_type,vechile_make_id,language_code,country,model_year):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getVechileMasterData`,{policy_type:policy_type,registration_type:registration_type,vechile_make_id:vechile_make_id,language_code:language_code,country:country,model_year:model_year},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
  }

//---------------------------------------------------------- FOR GET VEHICLE NCD --------------------------------------------------//
public getVehicleNcd(language_code,policy_type):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getVechileNCD`,{language_code:language_code,policy_type:policy_type},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
  }

//--------------------------------------------------------- FOR GET VEHICLE MODEL --------------------------------------------------//
public getVehicleModel(vechile_reg_type,make_name_obj,language_code,vechile_body_type,year):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getVechileModel`,{vechile_reg_type:vechile_reg_type,make_name:make_name_obj,language_code:language_code,vechile_body_type:vechile_body_type,model_year:year},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
  }
// //-------------------------------------- GET TOKEN DATA ---------------------------------------------------//
//   public getTokenData():Observable<any> {

//     return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getTokenData`,{},this._globalService.getHeader())
//           .pipe(catchError(this.handleError), tap(res => {
//             let  response=this._globalService.convertResponseInJson(res);
  
//           }));
//   }
//------------------------------------------------ FOR GET MOTOR BODY TYPE ------------------------------------------------------------//
public getMotorBodyType(policy_type,registration_type,vechile_model_id,language_code,model_year,model_name):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getMotorBodyType`,{policy_type:policy_type,registration_type:registration_type,vechile_model_id:vechile_model_id,language_code:language_code,model_year:model_year,model_name:model_name},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
  }
//------------------------------------------------FOR GET VEHICLE SPECIFICATION ------------------------------------------------//
public getVhclSpecification(language_code,vechile_model_id):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getVechilesSpecification`,{language_code:language_code,vechile_model_id:vechile_model_id},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//-------------------------------------------- For INSERT QUOTATION ------------------------------------------------------//
public getInsertQuote(motorValuesArray,policyDetailsArray):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/insertQuotation`,{motorValuesArray:motorValuesArray,policyDetailsArray:policyDetailsArray},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//-------------------------------------------  SAVE QUOTATION WITH PLAN --------------------------------------------------//
public insertPlanData(quotationNumber,product_code,totalFixedPrimum,totalVariablePremium,policyFee,totalPreimum,planData,benefit_data,repairType,savePlanDetail,insType,source,scheme_code):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/saveQuoteWithPlan`,
                              {quotationNumber:quotationNumber,product_code:product_code,totalFixedPrimum:totalFixedPrimum ,
                                totalVariablePremium:totalVariablePremium,
                                policyFee:policyFee,
                                totalPreimum:totalPreimum,
                                planData:planData,
                                benefit_data:benefit_data,
                                repairType:repairType,
                                savePlanDetail:savePlanDetail,
                                insuranceType:insType,
                                source:source,
                                scheme_code:scheme_code},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
         // let  response=this._globalService.convertResponseInJson(res);

        }));
}


//-------------------------------------------  SAVE QUOTATION WITH PLAN Email--------------------------------------------------//
public insertPlanDataEmail(quotationNumber,product_code,totalFixedPrimum,totalVariablePremium,policyFee,totalPreimum,planData,benefit_data,repairType,savePlanDetail,insType,source,scheme_code):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/saveQuoteWithPlanEmail`,
                              {quotationNumber:quotationNumber,product_code:product_code,totalFixedPrimum:totalFixedPrimum ,
                                totalVariablePremium:totalVariablePremium,
                                policyFee:policyFee,
                                totalPreimum:totalPreimum,
                                planData:planData,
                                benefit_data:benefit_data,repairType:repairType, savePlanDetail:savePlanDetail,insuranceType:insType,
                                source:source,
                                scheme_code:scheme_code},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
         // let  response=this._globalService.convertResponseInJson(res);

        }));
}
//-------------------------------------------- For INSERT Policy ------------------------------------------------------//
public saveAdditionalInfo(QuotNo,WebQuotNo,insr_mortgage_bank,is_survey,insured_name,insured_type,vechile_engine_no,gender,source,PaymentMode ):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/saveAdditionalInfo`,{QuotNo:QuotNo,WebQuotNo:WebQuotNo,insr_mortgage_bank:insr_mortgage_bank,is_survey:is_survey,insured_name:insured_name,insured_type:insured_type,vechile_engine_no:vechile_engine_no,gender:gender,source:source,PaymentMode:PaymentMode },this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//------------------------------------------------ UPLOAD DRIVING LICENCE DOC -------------------------------------------//
public uploadDrivLicDoc(QuotNo,driving_license):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/uploadDrivingLicense`,{QuotNo:QuotNo,driving_license:driving_license},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//------------------------------------------------ UPLOAD EMIRATES ID DOC -------------------------------------------//
public uploadEmiratesIdDoc(QuotNo,emirates_documents):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/uploadEmiratesIdDocuments`,{QuotNo:QuotNo,emirates_documents:emirates_documents},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//------------------------------------------------ UPLOAD REGISTRATION CARD DOC -------------------------------------------//
public uploadRegistrationCardDoc(QuotNo,registration_documents):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/uploadMulkiyaOrRegistrationCard`,{QuotNo:QuotNo,registration_documents:registration_documents},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//------------------------------------------------- UPLOAD DOCUMENTS ----------------------------------------------------//
public uploadDocuments(formData):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/uploadDocuments`,formData)
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//---------------------------------------------- Validate Chassis number -----------------------------------------------//
public validateChassisNumber(chassis_number):Observable<any> {
  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/validateChassisNumber`,{chassis_number:chassis_number},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//--------------------------------------------- QUOTATION DETAIL PDF -----------------------------------------------//
public getQuoteDetailPDF(quotation_number,source):Observable<any> {
  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getQuotationDetailsPDF`,{quotation_number:quotation_number,source:source},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}

//--------------------------------------------- SAVE POLICY DETAIL -----------------------------------------------//
public savePolicyDetail(policyDetailsArray,quotationDetailsArray):Observable<any> {
  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/savePolicyDetails`,{policyDetailsArray:policyDetailsArray,quotationDetailsArray:quotationDetailsArray},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//--------------------------------------------- GET BANKDETAIL -----------------------------------------------//
public getBankDetail():Observable<any> {
  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getBankdetails`,{},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
 //------------------- FOR PAYMENT ------------------------//
 public paymentAuth(QuotationNumber,site_url,return_url,project_type,partner_id,policy_number,source,b2c_type):Observable<any> {

  return this.httpClient.post(`${this.apiURL}PaymentWebservice/open_pay_page_values`,{QuotationNumber:QuotationNumber,site_url:site_url,return_url:return_url,project_type:project_type,partner_id:partner_id,policy_number:policy_number,source:source,b2c_type:b2c_type},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));
}

//-----------------SAVE QUOTATION PAYMENT -------------------//
public saveQuotationPayment(token,eventType,quoteNumber,responseMsg,transactionID,project_Code,source):Observable<any> {

  return this.httpClient.post(`${this.apiURL}PaymentWebservice/saveQuatationPayment`,{PaymentLogId:token,eventType:eventType,QuotationNumber:quoteNumber,ResponseMessage:responseMsg,TransactionID:transactionID,project_Code:project_Code,source:source},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));
}

//-------------------- VERIFY QUOTATION PAYMENT ----------------//
public verifyQuatationPayment(orderNumber):Observable<any> {

  return this.httpClient.post(`${this.apiURL}PaymentWebservice/verifyQuatationPayment`,{orderNumber:orderNumber},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));
}

//-------------------- Get Motor Valuation ----------------//
public getMotorValuation(vechileForm):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getValuation`,{vechileForm:vechileForm},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));
}

//-------------------- Get Motor Valuation ----------------//
public getDetailsByVIN(chasisNo):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getDetailsByVIN`,{chasisNo:chasisNo},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));
}
//-------------------------------------------- For UPDATE QUOTATION ------------------------------------------------------//
public updateQuotation(motorValuesArray,policyDetailsArray):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/updateQuotation`,{motorValuesArray:motorValuesArray,policyDetailsArray:policyDetailsArray},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//-------------------------------------------- For Mail  ------------------------------------------------------//
public sendMail(web_quotation_number,source):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/sendEmail`,{web_quotation_number:web_quotation_number,source:source},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//-------------------------------------------- For RETREIVE QUOTE ------------------------------------------------------//
public getRetrieveQuote(quotation_number,mobile_code,mobile_number,insured_email,app_source,sourceType=''):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getQuotationDetailsForCustomer`,
  { quotation_number:quotation_number,
    mobile_code:mobile_code,
    mobile_number:mobile_number,
    insured_email:insured_email,
    app_source:app_source,
    sourceType:sourceType

  },this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//------------------------------------------- FOR PLATE CODE DATA --------------------------------------------------------------//
public getPlateCode(language_code,plate_source):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getPlateCode`,{language_code:language_code,plate_source:plate_source},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//-------------------------------------------- FOR PAYMENT ERROR MESSAGES -------------------------------------------------------//
public getPaymentErrorMsg(section,response_code,language):Observable<any> {
  return this.httpClient.post(`${this.apiURL}PaymentWebservice/getErrorMessageDuringPayment`,{section:section,response_code:response_code,language:language},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//-------------------------------------------- GET DATA FOR EDITQUOTE DOCUMENTS -------------------------------------------------------//
public getDataForEditQuoteDoc(quoteNum):Observable<any> {
  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getDataForEditQuoteDoc`,{quotation_number:quoteNum},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//-------------------------------------------- GET TERMS AND CONDITIONS -------------------------------------------------------//
public getTermsConditions(product):Observable<any> {
  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getTermsAndCondition`,{product:product},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}

//-------------------- VERIFY QUOTATION PAYMENT ----------------//
public webSiteLink():Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getFedilityWebsite`,{},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));
}
//-------------------- GET VEHICLE MAKE DATA ----------------//
public getVhclMake(policy_type,registration_type,vechile_make_id,language_code,country,model_year,scheme_code):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getVechileMakeValues`,{policy_type:policy_type,registration_type:registration_type,vechile_make_id:vechile_make_id,language_code:language_code,country:country,model_year:model_year,scheme_code:scheme_code},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));
}
//--------------------------------------- FOR GET DEDUCTIBLE ------------------------------------------------------------//
public getDeductible(deductibleArray):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getDeductible`,{deductibleArray:deductibleArray},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
} 
//--------------------------------------- FOR GET MOTOR INS REQUIRED DATA  ----------------------------------------------------------//
public getMotorRequiredData(key,vhclAge,vhclYear,polStartYear,vhclMake,schmeCode,quote_status,web_quote_number):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/motorInsuranceRequiredData`,{key:key,vechile_age:vhclAge,vechile_year:vhclYear,policy_start_date_year:polStartYear,vechile_make_array:vhclMake,scheme_code:schmeCode,quote_status:quote_status,web_quote_number:web_quote_number},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));  
} 
//------------------------------------------ FOR GET INDUSTRY TYPE ------------------------------------------------//
public getIndustryTypes(lob):Observable<any> {
  return this.httpClient.post(`${this.apiURL}Webservice/getIndustryTypes`,{LOBCode : lob},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}

//-------------------- get discount ------------//
public getDiscountOnSelfDeclaration(quotation_number,lob_code):Observable<any> {

  return this.httpClient.post(`${this.apiURL}Webservice/PromoDisPercent`,{quotation_number:quotation_number,lob_code:lob_code},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

          }));
}

//---------------------------- Upload document temp ---------------------//
public uploadDocumentsTemp(formData):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/uploadDocumentTemp`,formData)
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}

//---------------------------- Get self declaration data -------------------------------//
public getSelfDeclarartionData():Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getSelfDeclarationData`,{},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//---------------------------------- FOR SPECIAL DISCOUNT ------------------------------------//
public getSpecialDiscount():Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/SpecialDiscount`,{},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//---------------------------------- FOR SPECIAL DISCOUNT ------------------------------------//
public sendPaymentLink(quotationNumber,project_type,source,emailId):Observable<any> {

  return this.httpClient.post(`${this.apiURL}PaymentWebservice/sendQuatationPaymentLink`,{QuotationNumber:quotationNumber,project_type:project_type,source:source,emailId:emailId},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
public getRetrieveQuote1(quotation_number,app_source,type):Observable<any> {
 
  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/getQuotationDetailsForCustomer`,
  { quotation_number:quotation_number,
    app_source:app_source,
    type:type

  },this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}





// -----------------------------------------------Upload Document For Non-OCR--------------------------------------------------

public uploadDocumentWithoutOCR(formData):Observable<any> {

  return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/uploadPolDocument`,formData)
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
// -----------------------------------------------Winner Details--------------------------------------------------

public getWinnerDetails():Observable<any> {

  return this.httpClient.post(`${this.apiURL}webservice/GetRamadanCampaign21Winner`,'')
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//-------------------------------------------- Verify OTP ------------------------------------------------------//
public verifyOtp(email,otp):Observable<any> {

  return this.httpClient.post(`http://win.k2key.in/motorFileUploadAndKYC/index.php/Webservice/varifyOTP`,{email:email,otp:otp},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
//-------------------------------------------- Verify OTP ------------------------------------------------------//
public sendotp(email):Observable<any> {

  return this.httpClient.post(`http://win.k2key.in/motorFileUploadAndKYC/index.php/Webservice/sendOTP`,{email:email},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}
public linkedpolicy(insuredName,policyNo,expiryDate):Observable<any> {

  return this.httpClient.post(`http://win.k2key.in/motorFileUploadAndKYC/index.php/Webservice/LinkPolicy`,{insuredName:insuredName,policyNo:policyNo,expiryDate:expiryDate},this._globalService.getHeader())
        .pipe(catchError(this.handleError), tap(res => {
          let  response=this._globalService.convertResponseInJson(res);

        }));
}


    //------------------- FOR PAYMENT ------------------------//
    //Added method by Anju for new Payment API Integration
    public paymentAuthNew(
        QuotationNumber,
        site_url,
        return_url,
        project_type,
        partner_id,
        policy_number,
        quote_type,
        source
    ): Observable<any> {
        return this.httpClient
            .post(
                `${this.paymentAPIURL}pay-page`,
                {
                    QuotationNumber: QuotationNumber,
                    site_url: site_url,
                    return_url: return_url,
                    project_type: project_type,
                    partner_id: partner_id,
                    policy_number: policy_number,
                    quote_type: quote_type,
                    source: source,
                },
                this._globalService.getHeader()
            )
            .pipe(
                catchError(this.handleError),
                tap((res) => {
                    let response = this._globalService.convertResponseInJson(res);
                    console.log(response);
                })
            );
    }


    //Added method by Anju for new Payment API Integration
    public verifyPayment(orderNumber): Observable<any> {
        return this.httpClient
            .get(this.paymentAPIURL + 'pay-status/' + orderNumber)
            .pipe(
                catchError(this.handleError),
                tap((res) => {
                    let response = this._globalService.convertResponseInJson(res);
                })
            );
    }

    public sendPolicySuccessMail(quotationNumber): Observable<any> {

        return this.httpClient.post(`${this.apiURL}MotorInsuranceWebservice/SendPolicySuccessMail`, { quotationNumber: quotationNumber })
            .pipe(catchError(this.handleError), tap(res => {
                let response = this._globalService.convertResponseInJson(res);

            }));
    }

}
