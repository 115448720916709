import { Component, OnInit } from '@angular/core';
 import * as Chartist from 'chartist';
 import { ChartOptions, ChartType, ChartDataSets, } from 'chart.js';
// import { Label } from 'ng2-charts';
 import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, Color } from 'ng2-charts';
 
import { GlobalService } from  '../_service/global.service';
import {MotorquoteService } from '../_service/motorquote.service';
import { UserauthService } from '../_service/userauth.service';

// import {MaterialuiModule} from '../materialui/materialui.module';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
// Pie
public pieChartOptions: ChartOptions = {
  responsive: true,
};
public pieChartLabels: Label[] = [
  ['Dubai'],
  ['Abu Dhabi'],
  ['Ras Al Khaimah'],
  ['Fujairah'],
  ['Others']
]
//public pieChartData: SingleDataSet = [300, 500, 100,300,600];
public pieChartData: SingleDataSet = [0, 0, 0,0,1];
public pieChartType: ChartType = 'pie'; 
public pieChartLegend = true; 
public pieChartPlugins = [];   public quotation2:any = [];  public quotation3:any = []; 
//pieChartColors: { backgroundColor: ['#ffa726','#ffa726','#ffa726'], borderColor: string; }[];

showMatch:boolean= false ;
totalMonthlyQuotedPremium:number = 0;
monthlyQuotationCount:number = 0;
totalYearlyQuotePremium:number = 0;
yearlyQuotationCount:number = 0;

totalMonthlyIssuedPremium:number = 0;
monthlyIssuedQuoteCount:number = 0;
totalYearlyIssuedPremium:number = 0;
yearlyIssuedQuoteCount:number = 0;

pendingApproval:any = []; issued:any = []; quotation:any=[]; public dashboardData:any = []; weekArr:any = [];
data:any; currentMonth:any = new Date(); currentYear:any; todate:any =  new Date();
   

constructor( public globalService:GlobalService,public motorQuoteService:MotorquoteService, public userAuthService : UserauthService, public _globalService:GlobalService ) {
  monkeyPatchChartJsTooltip();
  monkeyPatchChartJsLegend();
}


public chartColors: Array<any> = [
  { // first color
    backgroundColor: '#ffa726',
    borderColor: '#ffa726',
    pointBackgroundColor: '#ffa726',
    pointBorderColor: '#ffa726',
    pointHoverBackgroundColor: '#ffa726',
    pointHoverBorderColor: '#ffa726'
  },
  { // second color
    backgroundColor: '#26c6da',
    borderColor: '#26c6da',
    pointBackgroundColor: '#26c6da',
    pointBorderColor: '#26c6da',
    pointHoverBackgroundColor: '#26c6da',
    pointHoverBorderColor: '#26c6da'
  },
  { // Third color #66bb6a
    backgroundColor: '#66bb6a',
    borderColor: '#66bb6a',
    pointBackgroundColor: '#66bb6a',
    pointBorderColor: '#66bb6a',
    pointHoverBackgroundColor: '#66bb6a',
    pointHoverBorderColor: '#66bb6a'
  }
];


public pieChartColors = [
  {
    backgroundColor: [
      '#ffa726',
      '#1f3e7b',
      '#66bb6a',
      '#26c6da',
      '#ab47bc',
      
    ]
  }
];



ngOnInit() {

  this.data=this.globalService.getLocalStorageData();

  this.currentMonth  = new Date().getMonth() + 1;

  this.currentYear = new Date().getFullYear();
  console.log( this.currentMonth );

  this. getDashboardData();
  this.getTokenData();

    /* ----------==========     Daily Sales Chart initialization For Documentation    ==========---------- */

    const dataDailySalesChart: any = {
        labels: ['M', 'T', 'W', 'T'],
        series: [
            [12, 17, 7, 17]
        ],
        colors:["#333", "#222", "#111", "#000"]
    };

   const optionsDailySalesChart: any = {
        lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0
        }),
        low: 0,
        high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: { top: 0, right: 0, bottom: 0, left: 0},
    }

    var dailySalesChart = new Chartist.Pie('#dailySalesChart', dataDailySalesChart, {
      donut: true,
      donutWidth: 60,
      startAngle: 270,
      total: 200,
      showLabel: false
    });

  this.startAnimationForLineChart(dailySalesChart);


    /* ----------==========     Completed Tasks Chart initialization    ==========---------- */

    const dataCompletedTasksChart: any = {
        labels: ['12p', '3p', '6p', '9p', '12p', '3a', '6a', '9a'],
        series: [
            [230, 750, 450, 300, 280, 240, 200, 190]
        ]
    };

   const optionsCompletedTasksChart: any = {
        lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0
        }),
        low: 0,
        high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: { top: 0, right: 0, bottom: 0, left: 0}
    }

    var completedTasksChart = new Chartist.Line('#completedTasksChart', dataCompletedTasksChart, optionsCompletedTasksChart);

    // start animation for the Completed Tasks Chart - Line Chart
    this.startAnimationForLineChart(completedTasksChart);



    /* ----------==========     Emails Subscription Chart initialization    ==========---------- */

    var datawebsiteViewsChart = {
      labels: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
      series: [
        [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]

      ]
    };
    var optionswebsiteViewsChart = {
        axisX: {
            showGrid: false
        },
        low: 0,
        high: 1000,
        chartPadding: { top: 0, right: 5, bottom: 0, left: 0}
    };
    var responsiveOptions: any[] = [
      ['screen and (max-width: 640px)', {
        seriesBarDistance: 5,
        axisX: {
          labelInterpolationFnc: function (value) {
            return value[0];
          }
        }
      }]
    ];
   let r = Math.floor(Math.random() * 200);
   let g = Math.floor(Math.random() * 200);
   let b = Math.floor(Math.random() * 200);
   let color = 'rgb(' + r + ', ' + g + ', ' + b + ')';
    var websiteViewsChart = new Chartist.Pie('#websiteViewsChart', {
      series: [{
          value: 20,
         // className: "myclass1"
        },
        {
          value: 10,
         // className: "myclass2"
        },
        {
          value: 30,
          //className: "myclass3"
        },
        {
          value: 40,
          //className: "myclass4"
        }]
    }, {
     
    
    });
    //CHART COLOR.
  let  pieChartColor:any = [
    {
        backgroundColor: ['rgba(30, 169, 224, 0.8)',
        'rgba(255,165,0,0.9)',
        'rgba(139, 136, 136, 0.9)',
        'rgba(255, 161, 181, 0.9)',
        'rgba(255, 102, 0, 0.9)'
        ]
    }
]
    var data = {
      labels: ['Cananas', 'Xpples', 'Zrapes','Red','Yellow'],
      series: [10, 20, 40,60,10],
      //color:pieChartColor[0]
      backgroundColor: [
        "#f38b4a",
        "#56d798",
        "#ff8397",
        "#6970d5", "#6970d5"  
      ],
    };
    console.log(data);
    var  options = {
      labelInterpolationFnc: function(value) {
        return value[0]
      }
    };
    
  
    
    new Chartist.Pie('#websiteViewsChart', data, options, responsiveOptions);


   // start animation for the Emails Subscription Chart
    this.startAnimationForBarChart(websiteViewsChart);

}

  public barChartOptions: ChartOptions = {
    responsive: true,
  };
 
 //public barChartLabels: Label[] = ['Week1', 'Week2', 'Week3'];

 public barChartLabels:any = [];
 


  public barChartType: ChartType = 'bar';
 //public chartColors=["#17a2b8", "#1f3e7b", "#28a745"]
  public barChartLegend = true;
  public barChartPlugins = [];

  // public barChartData: ChartDataSets[] = [


     
  //   { data: [500,90,60], label: 'Quotation' },
  //   { data: [75,64,24], label: 'PendingAproval' },
  //   { data: [50,30,40], label: 'Issued' }

   
   
  // ];
  
  public barChartData: ChartDataSets[] = [  ];


   


  // constructor() {
    
  //  }



  startAnimationForLineChart(chart){
      let seq: any, delays: any, durations: any;
      seq = 0;
      delays = 80;
      durations = 500;

      chart.on('draw', function(data) {
        if(data.type === 'line' || data.type === 'area') {
          data.element.animate({
            d: {
              begin: 600,
              dur: 700,
              from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
              to: data.path.clone().stringify(),
              easing: Chartist.Svg.Easing.easeOutQuint
            }
          });
        } else if(data.type === 'point') {
              seq++;
              data.element.animate({
                opacity: {
                  begin: seq * delays,
                  dur: durations,
                  from: 0,
                  to: 1,
                  easing: 'ease'
                }
              });
          }
      });

      seq = 0;
  };
  startAnimationForBarChart(chart){
      let seq2: any, delays2: any, durations2: any;

      seq2 = 0;
      delays2 = 80;
      durations2 = 500;
      chart.on('draw', function(data) {
        if(data.type === 'bar'){
            seq2++;
            data.element.animate({
              opacity: {
                begin: seq2 * delays2,
                dur: durations2,
                from: 0,
                to: 1,
                easing: 'ease'
              }
            });
        }
      });

      seq2 = 0;
  };
 
 

  getDashboardData(){


    this.barChartData = [
       { data: [0,0,0,0,0], label: 'Quotation' },
       { data: [0,0,0,0,0], label: 'PendingAproval' },
       { data: [0,0,0,0,0], label: 'Issued' }

      // { data: this.quotation, label: 'Quotation' },
      // { data: this.pendingApproval, label: 'PendingAproval' },
      // { data: this.issued, label: 'Issued' }


 ];
 


  }

  getWeekData(){
          
  }
  //-------------------------- GET TOKEN DATA -------------------------
  getTokenData(){

        this.userAuthService.getTokenData().subscribe(res =>{
          let  result=this._globalService.convertResponseInJson(res);
        });
  }

}
