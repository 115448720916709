import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OcrmainRoutingModule } from './ocrmain-routing.module';
import { OcrmainComponent } from './ocrmain.component';
import { WithocrComponent } from './withocr/withocr.component';
import { WithoutocrComponent } from './withoutocr/withoutocr.component';
import {MaterialuiModule} from '../materialui/materialui.module';
import { MDBBootstrapModule } from 'angular-bootstrap-md';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ComponentsModule } from 'app/components/components.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [OcrmainComponent, WithocrComponent, WithoutocrComponent],
  imports: [
    CommonModule,
    OcrmainRoutingModule,
    MaterialuiModule,
    MDBBootstrapModule,
    NgxMatSelectSearchModule,
    ComponentsModule,
    ReactiveFormsModule,
  ]
})
export class OcrmainModule { }
