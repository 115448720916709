import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { GlobalService } from '../_service/global.service';
import { MotorquoteService } from '../_service/motorquote.service';
import { DateAdapter, MatStepper, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { UserauthService } from '../_service/userauth.service';
import Swal from 'sweetalert2';
import { AppDateAdapter, APP_DATE_FORMATS } from '../motorquote/date.adapter';
import { Router, ActivatedRoute } from '@angular/router';
import { config } from '../config';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material';
import { take, takeUntil } from 'rxjs/operators';
import { Observable, Subscription, fromEvent } from 'rxjs';
import {ModalDirective} from 'angular-bootstrap-md';

interface InsVehiBy {
  value: string;
  viewValue: string;
  code: string;
}
interface Code {
  value: string;
  viewValue: string;
}

interface DrivExp {
  value: string;
  viewValue: string;
}

interface VehiTrim {
  value: string;
  viewValue: string;
}
interface VehiModelYr {
  value: string;
  label: string;
}
interface BrandNew {
  value: string;
  viewValue: string;
}
interface VehiSeatCap {
  value: string;
  viewValue: string;
}
interface VehiCylinder {
  value: string;
  viewValue: string;
  code: string;
}
interface InsType {
  value: string;
  viewValue: string;
  WebProdCode: string;
  CoreProdCode: string;
}
interface YesNo {
  value: string;
  viewValue: string;
}
interface RepairType {
  value: string;
  viewValue: string;
}
interface MotBankNam {
  value: string;
  viewValue: string;
}
interface Gender {
  value: string;
  viewValue: string;
}
interface ExpiryMonth {
  value: string;
  viewValue: string;
}

interface Country {
  id: number;
  CountryName: string;
  Active: boolean;
  CountryCode: string;
  CurrencyCode: string;
  CRS_NATION_CODE: number;
  CRS_Country_Map_Code: number;
}

interface Make {
  VehicleMakeId: number;
  VehicleMakeName: string;
  Active: boolean;
  IsReferral: number;
  CRS_VEH_CODE: number;
}

interface Model {

  VehicleModelId: number;
  VehicleModelName: string;
  Active: boolean;
  SeatingCapacityExDriver: number;
  BodyTypeId: number;
  CRS_MODEL_CODE: number;
  EDATA_MODEL_CODE: string;
}

interface PlateCategory {

  label: string;
  value: string;
}

interface VichleColor {

  ColorId: number;
  ColorName: string;
  CRS_COLOR_CODE: string;
}

interface MortageBank {

  Id: number;
  InstituteName: string;
  InstituteCode: string
  CRS_Bank_Map_Code: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};
import {
  state,
  style,
  transition,
  animate,
  trigger,
  AUTO_STYLE
} from "@angular/animations";
import { CompressImageService } from '../../app/_service/compressimage.service';
@Component({
  selector: 'app-motorquote',
  templateUrl: './motorquote.component.html',
  styleUrls: ['./motorquote.component.scss'],
  providers: [
    // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    // { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          transform: "translate3d(0, 0, 0)"
        })
      ),
      state(
        "out",
        style({
          transform: "translate3d(100%, 0, 0)"
        })
      ),
      transition("in => out", animate("400ms ease-in-out")),
      transition("out => in", animate("400ms ease-in-out"))
    ]),
    trigger("slideOnOff", [
      state(
        "on",
        style({
          transform: "translate3d(0, 0, 0)"
        })
      ),
      state(
        "off",
        style({
          transform: "translate3d(100%, 0, 0)"
        })
      ),
      transition("on => off", animate("400ms ease-in-out")),
      transition("off => on", animate("400ms ease-in-out"))
    ]),
    trigger("visibility", [
      state(
        "shown",
        style({
          opacity: 1
        })
      ),
      state(
        "hidden",
        style({
          opacity: 0
        })
      ),
      transition("* => *", animate(".25s"))
    ]),
    trigger("mobileMenuTop", [
      state(
        "no-block, void",
        style({
          overflow: "hidden",
          height: "0px"
        })
      ),
      state(
        "yes-block",
        style({
          height: AUTO_STYLE
        })
      ),
      transition("no-block <=> yes-block", [animate("400ms ease-in-out")])
    ])
  ],
})



export class MotorquoteComponent implements OnInit {


  /****************** selection search country ***********/



  /** control for the MatSelect filter keyword  */
  public country1FilterCtrl: FormControl = new FormControl();
  public nationalFilterCtrl: FormControl = new FormControl();
  public makeFilterCtrl: FormControl = new FormControl();
  public modelFilterCtrl: FormControl = new FormControl();

  public yearFilterCtrl: FormControl = new FormControl();
  public plateFilterCtrl: FormControl = new FormControl();
  public vchColorFilterCtrl: FormControl = new FormControl();
  public mortageBankFilterCtrl: FormControl = new FormControl();
  public codeFilterCtrl: FormControl = new FormControl();


  /** list of banks filtered by search keyword */
  public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
  public filteredNationCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
  public filteredMakes: ReplaySubject<Make[]> = new ReplaySubject<Make[]>(1);
  public filteredModels: ReplaySubject<Model[]> = new ReplaySubject<Model[]>(1);
  public filteredYears: ReplaySubject<VehiModelYr[]> = new ReplaySubject<VehiModelYr[]>(1);
  public filteredPlateCat: ReplaySubject<PlateCategory[]> = new ReplaySubject<PlateCategory[]>(1);
  public filteredVchColor: ReplaySubject<VichleColor[]> = new ReplaySubject<VichleColor[]>(1);
  public filteredBank: ReplaySubject<MortageBank[]> = new ReplaySubject<MortageBank[]>(1);
  public filteredCode: ReplaySubject<Code[]> = new ReplaySubject<Code[]>(1);

  paymentMode ="ONL";




  @ViewChild('stepper', { static: true })stepper:MatStepper
  @ViewChild('frame', { static: false }) frame: ModalDirective;
  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  conditionType: any;
  showEmailLoader: boolean=false;


  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'Y';
    }

    return value;
  }
  status_type: any =0;
  captchaValidation: boolean =false;
  Msg: any ='Please  Enter Valid Captcha !';
  captchasucess: boolean =false;
  vehicleValueLimit = { isSet: false, startLimit: 0, mediumLimit: 0, endLimit: 0 }
  insvehibys: any = [
    { value: 'Individual', viewValue: 'Individual', code: '100' },
    { value: 'Corporate', viewValue: 'Corporate', code: '200' },
  ];
  codes: Code[] = [
    { value: '050', viewValue: '050' },
    { value: '052', viewValue: '052' },
    { value: '054', viewValue: '054' },
    { value: '055', viewValue: '055' },
    { value: '056', viewValue: '056' },
    { value: '057', viewValue: '057' },
    { value: '058', viewValue: '058' },
  ];

  drivexps: DrivExp[] = [
    { value: 'Yes', viewValue: 'Yes' },
    { value: 'No', viewValue: 'No' },
  ];

    vehimodelyrs: VehiModelYr[] = [
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
    { value: '2021', label: '2021' },
    { value: '2020', label: '2020' },
    { value: '2019', label: '2019' },
    { value: '2018', label: '2018' },
    { value: '2017', label: '2017' },
    { value: '2016', label: '2016' },
    { value: '2015', label: '2015' },
    { value: '2014', label: '2014' },
    { value: '2013', label: '2013' },
    { value: '2012', label: '2012' },
    { value: '2011', label: '2011' },
    { value: '2010', label: '2010' },
    { value: '2009', label: '2009' },
    { value: '2008', label: '2008' },
    { value: '2007', label: '2007' },
    { value: '2006', label: '2006' },
    { value: '2005', label: '2005' },
    { value: '2004', label: '2004' },
    { value: '2003', label: '2003' },
    { value: '2002', label: '2002' },
    { value: '2001', label: '2001' },
    { value: '2000', label: '2000' },
  ];
  brandnews: BrandNew[] = [
    { value: 'Yes', viewValue: 'Yes' },
    { value: 'No', viewValue: 'No' },
  ];
  vehiseatcaps: any = [
    { value: '1', viewValue: '1' },
    { value: '3', viewValue: '3' },
    { value: '4', viewValue: '4' },
    { value: '5', viewValue: '5' },
    { value: '6', viewValue: '6' },
    { value: '7', viewValue: '7' },
    { value: '8', viewValue: '8' },
  ];
  vehicylinders: VehiCylinder[] = [
    { value: '4', viewValue: '4 Cylinder', code: '1004' },
    { value: '6', viewValue: '6 Cylinder', code: '1006' },
    { value: '8', viewValue: '8 Cylinder', code: '1008' },
    { value: '10', viewValue: '10 Cylinder', code: '1010' },
    { value: '12', viewValue: '12 Cylinder', code: '1012' },
  ];
  instypes: InsType[] = [
    { value: '100', viewValue: 'Comprehensive', WebProdCode: 'MT00061', CoreProdCode: '4001_1' },
    { value: '200', viewValue: 'Third Party Liability', WebProdCode: 'MTTPL0065', CoreProdCode: '4021' },
  ];
  yesnos: YesNo[] = [
    { value: 'Yes', viewValue: 'Yes' },
    { value: 'No', viewValue: 'No' },
  ];

  repairtypes: RepairType[] = [
    { value: 'Agency', viewValue: 'Agency' },
    { value: 'Garage', viewValue: 'Garage' },
  ];

  motbanknams: MotBankNam[] = [
    { value: 'MBN', viewValue: 'ABC Bank' },
    { value: 'MBN', viewValue: 'DEF Bank' },
    { value: 'MBN', viewValue: 'GHI Bank' },
  ];
  genders: Gender[] = [
    { value: 'M', viewValue: 'Male' },
    { value: 'F', viewValue: 'Female' },
  ];
  expmonths: ExpiryMonth[] = [
    { value: 'EXPMON', viewValue: 'January' },
    { value: 'EXPMON', viewValue: 'February' },
    { value: 'EXPMON', viewValue: 'March' },
    { value: 'EXPMON', viewValue: 'April' },
    { value: 'EXPMON', viewValue: 'May' },
    { value: 'EXPMON', viewValue: 'June' },
    { value: 'EXPMON', viewValue: 'July' },
    { value: 'EXPMON', viewValue: 'August' },
    { value: 'EXPMON', viewValue: 'September' },
    { value: 'EXPMON', viewValue: 'October' },
    { value: 'EXPMON', viewValue: 'November' },
    { value: 'EXPMON', viewValue: 'December' },
  ];

  NCDArr = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5+', label: '5+' },

  ]

  activePolicyTypeArr = [
    { value: 'Yes', label: 'No  Insurance' },
    { value: 'No', label: 'Comprehensive' },
    { value: 'Yes', label: 'Third Party Liability' }

  ];

  plateCatArray: PlateCategory[] = [
    { label: "Private", value: "Private" },
    // { label: "Police", value: "Police" },
    // { label: "Public (Driving Learning)", value: "Public (Driving Learning)" },
    // { label: "Protocol", value: "Protocol" },
    // { label: "Public (Transport/Taxi)", value: "Public (Transport/Taxi)" },
    // { label: "Trade Plate", value: "Trade Plate" },
    // { label: "Export", value: "Export" },
    // { label: "Diplomatic Association", value: "Diplomatic Association" },
    // { label: "International Organization", value: "International Organization" },
    // { label: "Delegate", value: "Delegate" },
    // { label: "Classical", value: "Classical" },
    // { label: "Others", value: "Others" },
  ];


  maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
  minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 71));
  UAELicMinDate = new Date(new Date().setFullYear(new Date().getFullYear() - 50));
  policyMinDate = new Date(Date.now());

  policyMaxDate = new Date(new Date().setDate(new Date().getDate() + 13));
  regMinDate = new Date(2000, 0, 1);
  regMaxDate = new Date(2023, 0, 1);// Updated regmax date to 2023 by Anju


  //DECLARE FORMGROUPS
  personalDetailForm: FormGroup; vehicleDetailForm: FormGroup; coverDetailForm: FormGroup; additionalDetailForm: FormGroup; retrieveQuoteForm: FormGroup;
  quotationForm: FormGroup; policyRenewForm: FormGroup; public subscriptions: Subscription[] = [];

  //DECLARE ARRAYS
  vhclMakeArr: Make[]; countryArr: Country[]; vhclColorArr: VichleColor[]; cityArr: any = []; vhclModelArr: Model[]; vhclBodyTypeArr: any = []; vehitrims: any = []; tempRepairTypeArr: any = [];
  coverData: any = []; NCDData: any = []; benfPremIdArray: any = []; savePlanDetailArr: any = []; quoteDetailArr: any = []; bankDetail: MortageBank[]; quoteDetailDataForCUst: any = []; plateCodeArray: any = [];
  public termsAndCondition :any = [];
tempBenefitData:any = [];
  //DECLARE VARIABLES
  public policy_type: any; reg_type: any; vehicle_mk_id: any; language_code = 'ENG'; country = 'United Arab Emirates'; selectedCover: any; notSelectedCover: any; checkedOptCover: boolean; no_Of_Doors: any;
  checked: boolean; coverPremium: any; validChassisNum: boolean = false; webQuoteNumber: any = ''; quoteNumber: any; invalidEID: boolean = false; value: any = 0; plan_Name: any; plan_Id: any;
  paymentLoader: boolean = false; totalPremiumeithDisc: any; validtnMsg1: boolean = false; docNameType: any; mulOption: any; quoteDetail: any; public policyFee: number; vehicleValue: any = '';
  licFrontLoader: boolean = false; licBackLoader: boolean = false; regFrontLoader: boolean = false; regBackLoader: boolean = false; eidFrontLoader: boolean = false; eidBackLoader: boolean = false;
  delQuoteLoader: boolean = false; otherDocLoader: boolean = false; selected = new FormControl(0); minTCF: any; maxTCF: any; blankChassis: boolean = false; age: any; UAELicAge: any;
  accept_terms: boolean = false; chassis_No_Details: any; check_chassis_No: boolean = false; selectedStepper = new FormControl(0); ret_quote_num: any = ''; VATAMT: any;
  Total_Primium: any;  getVechileDetailData: any; tempTotalFixPremium: number;  totalFixPremium: number = 0; totalVariablePremium: number = 0;
  totalDiscount: number = 0; totalPreimum: number = 0; product_code = "MT00061"; public checkStepper: boolean = false; emailID: any; mobile_No: any; code: any; optionalBenefit: any; vhclModelYearVal:any = ''; retrieveFlag:boolean = false;
  vhcleValueFlag :boolean = false; retrieveVehcleMake:any; SchemeCode:any = 'ALL'; trans_ref:any;  specialDiscountAmt:any;
  accept_self_declaration_1:boolean = false; accept_self_declaration_2:boolean = false;accept_self_declaration_3:boolean = false;
  accept_self_declaration_4:boolean = false; accept_self_declaration_5:boolean = false; tempTotalLoadFixPrem:any;
  floatingSelectedIndex: any;
  public showFloatingopenicon:boolean=true;
  public premiumopenicon:boolean=true;
  public showFloatingcloseicon:boolean=false;
  public premiumcloseicon:boolean=false;
  public showFloating :boolean=true;
  public showpremiumFloating :boolean=true;
  //DATES
  toDay = new Date(Date.now());
  minyear = new Date().getFullYear() - 1;
  maxYear = new Date().getFullYear() + 1;
  year = new Date().getFullYear();
  nextDate = new Date(Date.now() + 1);
  PrevDate = new Date(Date.now() - 2);
  showHideLoader = {
    vhclMake: false,
    vhclModel: false,
    vhclBodyTypeArr: false,
    vehitrims: false,
    personalDetail: false,
    vehicleDetail: false,
    coverDetail: false,
    Quotation: false,
    additionalDetail: false,
    chasisNoButton: false,
    VehicleValue: false,
    retrieveQuote: false,
    policyRenew: false
  };

  validtnMsg = {
    personalDetail: false,
    vehicleDetail: false,
    coverDetail: false,
    Quotation: false,
    additionalDetail: false,
    retrieveQuote: false,
    policyRenew: false

  }
  iSRetriveQuote: boolean;
  editableStep = { personalDetail: true, vehicleDetail: true, coverDetail: true, Quotation: true, additionalDetail: true }
  constructor(public _route: Router, public _activatedroute: ActivatedRoute, public formBuilder: FormBuilder, public globalService: GlobalService, public motorQuoteService: MotorquoteService, public userAuthService: UserauthService,public compressImage:CompressImageService) { }

  ngOnInit() {

    this.licFrontLoader = this.globalService.spinnerHide();
    this.licBackLoader = this.globalService.spinnerHide();
    this.regFrontLoader = this.globalService.spinnerHide();
    this.regBackLoader = this.globalService.spinnerHide();
    this.eidFrontLoader = this.globalService.spinnerHide();
    this.eidBackLoader = this.globalService.spinnerHide();
    this.delQuoteLoader = this.globalService.spinnerHide();
    this.otherDocLoader = this.globalService.spinnerHide();

    this.iSRetriveQuote = false;

    this.getSelfDeclarartionData();

    //   /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*&/

    this.personalDetailForm = this.formBuilder.group({
      vhclOwnBy: ['', Validators.required],
      name: [''],
      companyName: [''],
      dob: [''],
      email: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9.-_-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')])],
     
      mobileNum: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      licIssueContry: [''],
      countryFilterCtrl: [''],
      drivExpInUAE: [''],
      NCD: [''],
      recaptchaReactive: ['',],
      recaptchaReactiveByUser: ['',],
      optionBExtra: [{ disabled: true, value: '' }, Validators.required]

    });

    this.vehicleDetailForm = this.formBuilder.group({
      chassisNum: [''],
      vhclModelYr: ['', Validators.required],
      brandNw: [''],
      vhclMake: ['', Validators.required],
      vhclModel: ['', Validators.required],
      vhclTrim: ['', Validators.required],
      vhclBodyType: ['', Validators.required],
      vhclCylinder: ['', Validators.required],
      vhclSeatCapcity: ['', Validators.required],
      regDate: [''],
      regplace: ['', Validators.required],
      insType: ['', Validators.required],
      vhclValue: Number[''],
      repairType: [''],
      vhclTPLCoverage: ['', Validators.required],
      vhclGCCSpecifird: ['', Validators.required],
      vhclModified: ['', Validators.required],
      isChassisValidate: [''],
      promo_code: ['']

    });

    this.coverDetailForm = this.formBuilder.group({

    });

    this.quotationForm = this.formBuilder.group({
      quoteStepValdtn: ['', Validators.required]

    });

    this.additionalDetailForm = this.formBuilder.group({
      chassisNum: [''],
      vhclColor: ['', Validators.required],
      vhclPlateCategory: [''],
      plateCode: ['', Validators.required],
      plateNumber: ['', Validators.required],
      engineNum: [''],
      motgagedBankName: [''],
      polStartDate: [new Date(Date.now()), Validators.required],
      gender: ['', Validators.required],
      emiratesID: ['', [Validators.required]],
      nationality: ['', Validators.required],
      address: ['', [Validators.required, Validators.pattern('.*[A-Za-z0-9 -,#.)(]{5,200}$')]],
      area: ['', [Validators.required, Validators.pattern('.*[A-Za-z0-9 -,#.)(]{5,100}$')]],
      poBoxNum: [''],
      drivingLicense: ['', [Validators.required]],
      TCFnum: [''],

      docDriving_License_Front: [''],
      driving_License_Front: ['', [Validators.required]],
      driving_License_FrontFilePath: '',
      driving_License_FrontFileType: '',

      docDriving_license_Back: [''],
      driving_license_Back: ['', [Validators.required]],
      driving_license_BackFilePath: '',
      driving_license_BackFileType: '',

      docEID_Front: [''],
      EID_Front: ['', [Validators.required]],
      EID_FrontFilePath: '',
      EID_FrontFileType: '',

      docEID_Back: [''],
      EID_Back: ['', [Validators.required]],
      EID_BackFilePath: '',
      EID_BackFileType: '',

      docReg_Card_Front: [''],
      Reg_Card_Front: [''],
      Reg_Card_FrontFilePath: '',
      Reg_Card_FrontFileType: '',

      docReg_Card_Back: [''],
      Reg_Card_Back: [''],
      Reg_Card_BackFilePath: '',
      Reg_Card_BackFileType: '',

      docDealerQuote: [''],
      dealer_quote: [''],
      dealer_quoteFilePath: '',
      dealer_quoteFileType: '',

      docOther: [''],
      other_DOc: [''],
      other_DOcFilePath: '',
      other_DOcFileType: '',

      isChassisValidate: ['']

      // otherForm: ['',Validators.required]
    });

    this.retrieveQuoteForm = this.formBuilder.group({

      web_Quote_Num: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9.-_-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')])],
      // code: ['', Validators.required],
      mob_Num: ['', Validators.required],
      //isRetriveValidate : ['',Validators.required]


    });

    this.policyRenewForm = this.formBuilder.group({
      policyNum: ['', [Validators.required, Validators.maxLength(15), Validators.pattern('^[a-zA-Z0-9 \/\']+')]],
      polExpDate: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9.-_-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')])],
      code: ['', Validators.required],
      mobile: ['', Validators.required]

    });


    this.personalDetailForm.controls['vhclOwnBy'].valueChanges.subscribe(obj => {
      if (obj.value == 'Individual') {
        this.personalDetailForm.controls['name'].setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(150), Validators.pattern('^[a-zA-Z \-\ \.\']+')]);
        this.personalDetailForm.controls['dob'].setValidators([Validators.required]);
        this.personalDetailForm.controls['licIssueContry'].setValidators([Validators.required]);
        this.personalDetailForm.controls['licIssueContry'].setValidators([Validators.required]);
        this.personalDetailForm.controls['drivExpInUAE'].setValidators([Validators.required]);
        this.personalDetailForm.controls['NCD'].setValidators([Validators.required]);
        this.personalDetailForm.controls['companyName'].setValidators(null);

      }
      else if (obj.value == 'Corporate') {
        this.personalDetailForm.controls['companyName'].setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(150), Validators.pattern('^[a-zA-Z \-\ \.\']+')]);
        this.personalDetailForm.controls['name'].setValidators(null);
        this.personalDetailForm.controls['dob'].setValidators(null);
        this.personalDetailForm.controls['licIssueContry'].setValidators(null);
        this.personalDetailForm.controls['drivExpInUAE'].setValidators(null);
        this.personalDetailForm.controls['NCD'].setValidators(null);
      }

      this.personalDetailForm.controls['name'].updateValueAndValidity();
      this.personalDetailForm.controls['companyName'].updateValueAndValidity();
      this.personalDetailForm.controls['licIssueContry'].updateValueAndValidity();
      this.personalDetailForm.controls['dob'].updateValueAndValidity();
      this.personalDetailForm.controls['drivExpInUAE'].updateValueAndValidity();
      this.personalDetailForm.controls['NCD'].updateValueAndValidity();

    });

    this.vehicleDetailForm.controls['brandNw'].valueChanges.subscribe(obj => {

      if (obj == 0 || obj == '') {
        // this.vehicleDetailForm.controls['regDate'].setValidators([Validators.required]);
        this.additionalDetailForm.controls['dealer_quote'].setValidators(null);
        this.additionalDetailForm.controls['Reg_Card_Front'].setValidators([Validators.required]);
        this.additionalDetailForm.controls['Reg_Card_Back'].setValidators([Validators.required]);


      }
      else {
        // this.vehicleDetailForm.controls['regDate'].setValidators(null);
        this.additionalDetailForm.controls['dealer_quote'].setValidators([Validators.required]);
        this.additionalDetailForm.controls['Reg_Card_Front'].setValidators(null);
        this.additionalDetailForm.controls['Reg_Card_Back'].setValidators(null);

      }

      // this.vehicleDetailForm.controls['regDate'].updateValueAndValidity();
      this.additionalDetailForm.controls['dealer_quote'].updateValueAndValidity();
      this.additionalDetailForm.controls['Reg_Card_Front'].updateValueAndValidity();
      this.additionalDetailForm.controls['Reg_Card_Back'].updateValueAndValidity();
    });

    this.vehicleDetailForm.controls['insType'].valueChanges.subscribe(obj => {
      if (obj.viewValue == 'Comprehensive') {

        this.vehicleDetailForm.controls['repairType'].setValidators([Validators.required]);
        this.vehicleDetailForm.controls['vhclValue'].setValidators([Validators.required,Validators.min(this.vehicleValueLimit.startLimit), Validators.max(this.vehicleValueLimit.endLimit)]);


      }
      else {

        this.vehicleDetailForm.controls['repairType'].setValidators(null);
        this.vehicleDetailForm.controls['vhclValue'].setValidators(null);

      }

      this.vehicleDetailForm.controls['repairType'].updateValueAndValidity();
      this.vehicleDetailForm.controls['vhclValue'].updateValueAndValidity();

    });

    this.vehicleDetailForm.controls['regplace'].valueChanges.subscribe(obj => {

      if (obj.CityName == 'Dubai') {

        this.additionalDetailForm.controls['TCFnum'].setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(8)]);
      }
      else {
        this.additionalDetailForm.controls['TCFnum'].setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10)]);
      }

      this.additionalDetailForm.controls['TCFnum'].updateValueAndValidity();

    });


    const statusModSub = this.globalService.connectionStatusChanged.subscribe(data => {

      if (data == 'online') {

        this.getTokenData();
      }
      else {
      }


    });
    this. Captcha();
    this.subscriptions.push(statusModSub);

    this.getTokenData();

    const routeParam = this._activatedroute.snapshot.params;
    if (routeParam.Quote_No) {
      this.ret_quote_num = routeParam.Quote_No;
    }


    this.paymentLoader = this.globalService.spinnerHide();

    this.tempRepairTypeArr = this.repairtypes;



    // listen for search field value changes
    this.country1FilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterCountry();
      });


    // listen for search field value changes
    this.nationalFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterNationalCountry();
      });

    this.makeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterMake();
      });

    this.modelFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterModel();
      });

    this.filteredYears.next(this.vehimodelyrs.slice());

    this.yearFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterYear();
      });



    this.filteredPlateCat.next(this.plateCatArray.slice());

    this.plateFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterPlateCat();
      });


    this.vchColorFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterVechColor();
      });

    this.mortageBankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterMortageBank();
      });


    this.filteredCode.next(this.codes.slice());
    this.codeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterCode();
      });

      if(localStorage.getItem("retriveOCR")=="NONOCR"){
        setTimeout(() => {
          
  
          this.status_type =localStorage.getItem("EmailStatus")
          this.retrieveQuoteForm.get('web_Quote_Num').setValue(localStorage.getItem("retriveOCRQuote"));
          this.retrieveQuoteForm.get('email').setValue(localStorage.getItem("retriveOCRemail"));
          this.retrieveQuoteForm.get('mob_Num').setValue(localStorage.getItem("retriveOCRmob_Num"));
          localStorage.setItem("retriveOCRQuote",'');
          localStorage.setItem("retriveOCRemail",'');
          localStorage.setItem("retriveOCRCode",'');
          localStorage.setItem("retriveOCR",'');
          
          
          // this.retrieveQuoteForm.get('web_Quote_Num').setValue(localStorage.getItem("retriveOCRemail"));
  
            // localStorage.getItem("retriveOCRCode");
            // localStorage.getItem("retriveOCRmob_Num");
            // localStorage.getItem("retriveOCRemail");
        this.getRetrieveQuote(1);
  
      }, 5000);
  
    }



  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  get f() { return this.personalDetailForm.controls; }
  get g() { return this.vehicleDetailForm.controls; }
  get h() { return this.coverDetailForm.controls; }
  get k() { return this.additionalDetailForm.controls; }
  get r() { return this.retrieveQuoteForm.controls; }
  get n() { return this.policyRenewForm.controls; }


  //-------------------------- GET TOKEN DATA -------------------------
  getTokenData() {

    this.showHideLoader.personalDetail = true;
    this.userAuthService.getTokenData().subscribe(res => {
      let result = this.globalService.convertResponseInJson(res);
      if (result.response_code == 1) {
        this._route.navigate(["/MT_Quote"]);
        this.getAllFormData('');
        this.getVhclNCD();
        this.showHideLoader.personalDetail = false;

      }
    },
    (error) => {
      this.showHideLoader.personalDetail = false;
        console.error(error.status);
             
     });
  }

  insertQuote(stepper, type) {

    // console.log(this.personalDetailForm);

    if (this.personalDetailForm.invalid) {
      this.validtnMsg.personalDetail = true;
      return false;
    }
    if(this.status_type == 0){
      if(this.Msg == 'Please  Enter Valid Captcha !' ){
        Swal.fire("", "Please  Enter Valid Captcha !", 'error');

        return false;
      }
    } 

    if (this.age > 71 || this.age < 18) {
      return false;
    }

    this.validtnMsg.personalDetail = false;

    if (type != 0)
      stepper.next();
      // this. Captcha();
      // this.ValidCaptcha();
      // this.Msg = 'Please  Enter Valid Captcha !'

  }
  //--------------------------------- GET ALL FORM DROPDOWN DATA -----------------------------
  getAllFormData(year) {
    this.motorQuoteService.getAllFormData('COMPREHENSIVE', 'INDIVIDUAL', '0', this.language_code, this.country,year).subscribe(res => {

      this.cityArr = res.cityData;
      this.vhclColorArr = res.vehicleColorsData;
      this.countryArr = res.countryData;
     

      this.filteredVchColor.next(this.vhclColorArr.slice());
      

     
      // this.personalDetailForm.get("licIssueContry").setValue(this.countryArr[32]);
      this.filteredCountries.next(this.countryArr.slice());

      // this.additionalDetailForm.get("nationality").setValue(this.countryArr[32]);
      this.filteredNationCountries.next(this.countryArr.slice());

    });

    this.motorQuoteService.getBankDetail().subscribe(res => {

      this.bankDetail = res.bankdetailsData;
      this.filteredBank.next(this.bankDetail.slice());

    });
  }

  //------------------------------ GET VEHICLE MAKE DATA ---------------------------------//
  getVhclMakeData(year,type){
    this.motorQuoteService.getVhclMake('COMPREHENSIVE', 'INDIVIDUAL', '0', this.language_code, this.country,year,this.SchemeCode).subscribe(res => {
      this.vhclMakeArr = res.vechileMakeValuesData;

     
      if (type == 1 && typeof (this.vhclMakeArr) != undefined && typeof (this.vhclMakeArr) != "undefined") {
        
        //VEHICLE Make ----- filter
            let makeVal
            this.vhclMakeArr.forEach((item, index) => {

                      if (item.VehicleMakeName == this.getVechileDetailData.VehicleDetails.General.Make) {
                                  makeVal = item;
                        }
              });
              this.vehicleDetailForm.get('vhclMake').setValue(makeVal);
              this.chassis_No_Details.General.Make = makeVal.VehicleMakeId;
              this.getVhclModel(makeVal.VehicleMakeId, 2,year);
         }

         if (type == 2 && typeof (this.vhclMakeArr) != undefined && typeof (this.vhclMakeArr) != "undefined") {
        
                //VEHICLE Make ----- filter
                let makeVal
                this.vhclMakeArr.forEach((item, index) => {
    
                          if (item.VehicleMakeName == this.retrieveVehcleMake) {
                                      makeVal = item;
                            }
                  });
                this.vehicleDetailForm.get('vhclMake').setValue(makeVal);
                this.quoteDetail.VehicleMakeId = makeVal.VehicleMakeId;
                this.getVhclModel(makeVal.VehicleMakeId, 0,year);
  
           }

         this.onVehiclAgeChange(year);

          if(res.response_code == 1){
            this.filteredMakes.next(this.vhclMakeArr.slice());
          }

           
      let d = new Date(this.vehicleDetailForm.value.regDate);
      let firstRegYear: number = d.getFullYear();

      var p = new Date(this.additionalDetailForm.value.polStartDate);
      let policyStartDateYear = new Date().getFullYear();
      let vhclYear = this.vehicleDetailForm.value.vhclModelYr.value;

      //  current year - model year + 1 <= 3 ------agency & garage else garage  05 - Oct - 2020

        if((Number(policyStartDateYear) - Number(vhclYear) + 1) <= 3){

            this.repairtypes = [];
            this.repairtypes = this.tempRepairTypeArr;
            this.vehicleDetailForm.get('repairType').setValue(this.repairtypes[0]);
        }else{
        
            this.repairtypes = [];
            this.repairtypes.push(this.tempRepairTypeArr[1]);
            this.vehicleDetailForm.get('repairType').setValue(this.repairtypes[0]);
        }

    });
        
    if(this.vehicleDetailForm.value.vhclModelYr.value >=(this.minyear - 1) && this.vehicleDetailForm.value.insType.value == 100 &&  this.vehicleDetailForm.value.brandNw != 1 ){
        // alert(1)
        this.vehicleDetailForm.controls['regDate'].setValidators([Validators.required]);

    }else{
     
      //this.vehicleDetailForm.get('regdate').setValue(new Date);
   
      this.vehicleDetailForm.controls['regDate'].setValidators(null);
     
    }

   this.vehicleDetailForm.controls['regDate'].updateValueAndValidity();

  }
  //------------------------------ GET VEHICLE NCD ---------------------------------------------
  getVhclNCD() {
    this.motorQuoteService.getVehicleNcd(this.language_code, 'MOTOR').subscribe(res => {

      this.NCDData = res.vechileNCDData;
      if (res.response_code == 1) {
        if (this.ret_quote_num != '') {
          this.getRetrieveQuote(2);
        }
      }

    });
  }

  //------------------------------ GET VEHICLE MODEL -----------------------------------------
  getVhclModel(vehclMkId, type,year) {


    if (type == 0)
      vehclMkId = this.retrieveQuoteForm.value.web_Quote_Num == '' ? vehclMkId : this.quoteDetail.VehicleMakeId;

      this.showHideLoader.vhclModel = true;
      this.showHideLoader.vhclMake = true;
      this.motorQuoteService.getVehicleModel('P', this.vehicleDetailForm.value.vhclMake, this.language_code, null,this.vehicleDetailForm.value.vhclModelYr.value).subscribe(res => {

      this.vhclModelArr = res.vechileModelData;
    
      if (type == 0 && typeof (this.vhclModelArr) != undefined && typeof (this.vhclModelArr) != "undefined") {


        let ModalName = this.quoteDetail.VehicleModel;

        var indexModel = this.vhclModelArr.findIndex(function (obj, k) {
          return obj.VehicleModelName === ModalName;
        });
        let modelVal = this.vhclModelArr[indexModel];
        this.vehicleDetailForm.get('vhclModel').setValue(modelVal);

        this.getMotorBodyType(modelVal.VehicleModelId, 0,year,modelVal.VehicleModelName);

      }

      if (type == 2 && typeof (this.vhclModelArr) != undefined && typeof (this.vhclModelArr) != "undefined") {
        let ModalName = (this.chassis_No_Details.General.Make == 34 || this.chassis_No_Details.General.Make == 7) ? this.chassis_No_Details.General.Trim : this.chassis_No_Details.General.Model;
        var indexModel = this.vhclModelArr.findIndex(function (obj, k) {
          return obj.VehicleModelName === ModalName;
        });
        let modelVal = this.vhclModelArr[indexModel];
        this.vehicleDetailForm.get('vhclModel').setValue(modelVal);
        this.getMotorBodyType(modelVal.VehicleModelId, 2,year,modelVal.VehicleModelName);

      }

      if (res.response_code == 1) {
        this.filteredModels.next(this.vhclModelArr.slice()); // FOr ng select search
      }
      this.showHideLoader.vhclMake = false;
      this.showHideLoader.vhclModel = false;

     
   

        
        
    });
  }

  //------------------------------- GET MOTOR BODY TYPE AND VEHICLE SPECIFICATION------------------------------------------
  getMotorBodyType(vhclModelId, type,year,modelName) {

    if (typeof (vhclModelId) == "undefined" || typeof (vhclModelId) == undefined) return false;

    //  this.quoteDetail.VehicleModel

    this.showHideLoader.vhclBodyTypeArr = true;
    this.showHideLoader.vehitrims = true;
    this.motorQuoteService.getMotorBodyType('COMPREHENSIVE', 'INDIVIDUAL', vhclModelId, this.language_code,year,modelName).subscribe(res => {

      this.vhclBodyTypeArr = res.vechileMotoBodyTypeData;
      this.vehitrims = res.vechilesSpecificationData;

      if (type == 0 && typeof (this.vhclBodyTypeArr) != undefined && typeof (this.vhclBodyTypeArr) != "undefined") {

        //VEHICLE TRIM ------- not given
        let trimName = this.quoteDetail.TrimName;
        let bodyType = this.quoteDetail.BodyType;

        var indexTrim = this.vehitrims.findIndex(function (obj, k) {
          return obj.VehicleSpecName === trimName;
        });

        let trimVal = this.vehitrims[indexTrim];
        this.vehicleDetailForm.get('vhclTrim').setValue(trimVal);

        //BODY TYPE
        var indexbodyType = this.vhclBodyTypeArr.findIndex(function (obj, k) {
          return obj.BodyTypeName === bodyType;
        });
        let bdTyVal = this.vhclBodyTypeArr[indexbodyType];
        this.vehicleDetailForm.get('vhclBodyType').setValue(bdTyVal);

        this.vhcleValueFlag = true;
      }


      if (type == 2 && typeof (this.vhclBodyTypeArr) != undefined && typeof (this.vhclBodyTypeArr) != "undefined") {

        //VEHICLE TRIM ------- not given

        let trimName = this.chassis_No_Details.General.Trim + " " + "-" + " " + this.chassis_No_Details.Technical.EngineDisplacementNominal.Value;
        let bodyType = this.chassis_No_Details.General.BodyType;

        var indexTrim = this.vehitrims.findIndex(function (obj, k) {
          return obj.VehicleSpecName === trimName;
        });

        let trimVal = this.vehitrims[indexTrim];
        this.vehicleDetailForm.get('vhclTrim').setValue(trimVal);

        //BODY TYPE
        var indexbodyType = this.vhclBodyTypeArr.findIndex(function (obj, k) {
          return obj.BodyTypeName === bodyType;
        });
        let bdTyVal = this.vhclBodyTypeArr[indexbodyType];
        this.vehicleDetailForm.get('vhclBodyType').setValue(bdTyVal);

      
        this.updateValuation();


      }


      if (type == 1)
        this.vehicleDetailForm.get('vhclBodyType').setValue(this.vhclBodyTypeArr[0]);
      this.showHideLoader.vhclBodyTypeArr = false;
      this.showHideLoader.vehitrims = false;


    });

    // this.motorQuoteService.getVhclSpecification(this.language_code,vhclModelId).subscribe(res =>{

    // });

  }


  calculateAge(dateOfBirth) {

    let timeDiff = Math.abs(Date.now() - dateOfBirth);
    return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);

  }
  //-----------------------------------------------------------------------------------------------
  onVehiclAgeChange(vhclYear) {

 
    if(this.retrieveFlag == false){
      // alert(2)
      // this.vehicleDetailForm.get('regDate').setValue('');
      let vhclNextYear = Number(vhclYear.value) + 1;
      let vhclPrevYear = Number(vhclYear.value) - 1;
  
      this.regMinDate = new Date(vhclPrevYear, 6, 1);
      this.regMaxDate = new Date(vhclNextYear, 5, 30);
      // this.regMinDate = new Date(new Date().setFullYear(new Date().getFullYear()));
      // this.regMaxDate = new Date(new Date().setFullYear(new Date().getFullYear() - (vhclYear.label + 1)));
  
      let vhclAge = this.year - vhclYear.value;

    
        if (vhclAge == 0) {
    
          this.repairtypes = [];
          this.repairtypes.push(this.tempRepairTypeArr[0]);
          this.vehicleDetailForm.get('repairType').setValue(this.repairtypes[0]);
    
        }
        else if (vhclAge == 1) {
          this.repairtypes = [];
          this.repairtypes = this.tempRepairTypeArr;
          this.vehicleDetailForm.get('repairType').setValue(this.repairtypes[1]);
          // this.vehicleDetailForm.get('brandNw').setValue(1);
    
      }
      else {
  
        this.repairtypes = [];
        this.repairtypes.push(this.tempRepairTypeArr[1]);
        this.vehicleDetailForm.get('repairType').setValue(this.repairtypes[0]);
        // this.vehicleDetailForm.get('brandNw').setValue(0);
  
      }
      this.vehicleDetailForm.get('brandNw').setValue('');

    }else{
      this.retrieveFlag = false;
    }
      
    
   

  }


  checkBrandNew(){
    if(this.vehicleDetailForm.value.brandNw == 1){
      this.vehicleDetailForm.controls['regDate'].setValidators(null);
    }
    this.vehicleDetailForm.controls['regDate'].updateValueAndValidity();
  }
  //------------------------------- GET MOTOR  Quoatation ------------------------------------------


   getQuotation(stepper, type, frame) {
    this.checkStepper = true;
       //frame.hide();
    if (this.vehicleDetailForm.invalid) {
      this.validtnMsg.vehicleDetail = true;
      return false;
    }
    

    if (this.vehicleDetailForm.value.vhclModelYr.value >= (this.minyear - 1) && this.vehicleDetailForm.value.insType.value == 100 && this.vehicleDetailForm.value.brandNw != 1) {
          this.vehicleDetailForm.controls['regDate'].setValidators([Validators.required]);
         
          if (this.vehicleDetailForm.value.brandNw != 1) {    
                 
            if (this.regMaxDate < this.vehicleDetailForm.value.regDate || this.regMinDate > this.vehicleDetailForm.value.regDate) {
           
              return false;
            }
          }
    } else {
     
      this.vehicleDetailForm.controls['regDate'].setValidators(null);
    }
    
    this.vehicleDetailForm.controls['regDate'].updateValueAndValidity();

    if(Number(this.vehicleDetailForm.value.vhclValue) < Number(this.vehicleValueLimit.startLimit) || Number(this.vehicleDetailForm.value.vhclValue) > Number(this.vehicleValueLimit.endLimit)) {
            this.validtnMsg.vehicleDetail = true;
            return false;
    }

    // this.showHideLoader.vehicleDetail = true;
    this.showHideLoader.Quotation = true;
    this.validtnMsg.vehicleDetail = false;
   
    if (type != 0){
      stepper.next();
    }
     
    let name = this.personalDetailForm.value.vhclOwnBy.code == '100' ? this.personalDetailForm.value.name : this.personalDetailForm.value.companyName;
    let vehicleType = this.personalDetailForm.value.vhclOwnBy.code == '100' ? { code: '1001', value: 'Private' } : { code: '1009', value: 'Commercial' };
    let policy_expire_date = this.vehicleDetailForm.value.vhclTPLCoverage == 'No' ? this.nextDate : this.PrevDate;
    let brand_new = (this.vehicleDetailForm.value.vhclModelYr.value != this.maxYear || this.vehicleDetailForm.value.vhclModelYr.value != this.year || this.vehicleDetailForm.value.vhclModelYr.value != this.minyear) ? { code: '0', value: 'N' } : { code: '1', value: 'Y' };
    //let regDate = this.vehicleDetailForm.value.brandNw == 1 ? this.convertDate(this.toDay) : this.convertDate(this.vehicleDetailForm.value.regDate);

    let regDate: any;
    if (this.vehicleDetailForm.value.brandNw == 1) {
      regDate = this.convertDate(this.toDay);
    }
    else if (this.vehicleDetailForm.value.vhclModelYr.value < (this.minyear - 1)) {
      regDate = this.convertDate(new Date(this.vehicleDetailForm.value.vhclModelYr.value, 0, 1));
    }
    else {
      regDate = this.convertDate(this.vehicleDetailForm.value.regDate);
    }

    let modifyValue = this.existVehiclevalue == 1 ? this.existVehiclevalue : null;
    let dob = this.personalDetailForm.value.vhclOwnBy.code == '100' ? this.convertDate(this.personalDetailForm.value.dob) : '';
    let repairType = this.vehicleDetailForm.value.insType.value == 100 ? this.vehicleDetailForm.value.repairType : { value: 'Garage', viewValue: 'Garage' };
    let vehicle_value = this.vehicleDetailForm.value.insType.value == 100 ? this.vehicleDetailForm.value.vhclValue : 0;
    let motorArrayQuote = {
      insured_type: this.personalDetailForm.value.vhclOwnBy,
      insured_name: name,
      gender: "",
      dob: dob,
      prospect_age: this.calculateAge(this.personalDetailForm.value.dob),
      nationality: this.personalDetailForm.value.licIssueContry, // check if we need to add in html
      email: this.personalDetailForm.value.email,
      mobile: this.personalDetailForm.value.mobileNum,
      // code: this.personalDetailForm.value.mobileCode,
      UAE_lic_age: this.convertDate(this.personalDetailForm.value.drivExpInUAE),
      UAELicAge: this.UAELicAge,
      license_country: this.personalDetailForm.value.licIssueContry,
      NCD: this.personalDetailForm.value.NCD,

      brand_new: brand_new,
      model_year: this.vehicleDetailForm.value.vhclModelYr,
      make: this.vehicleDetailForm.value.vhclMake,
      model: this.vehicleDetailForm.value.vhclModel,
      body_type: this.vehicleDetailForm.value.vhclBodyType,
      cylinders: this.vehicleDetailForm.value.vhclCylinder,
      passenger: this.vehicleDetailForm.value.vhclSeatCapcity,
      registered_date: regDate,
      registered_place: this.vehicleDetailForm.value.regplace,
      trim: this.vehicleDetailForm.value.vhclTrim,
      chassis_no: this.vehicleDetailForm.value.chassisNum,

      repair_type: repairType,
      sum_insured: vehicle_value,
      GCCSpecified: this.vehicleDetailForm.value.vhclGCCSpecifird,
      vhclModified: this.vehicleDetailForm.value.vhclModified,
      policy_type: this.vehicleDetailForm.value.insType,
      ExistingCover: this.vehicleDetailForm.value.vhclTPLCoverage,
      policy_expire_date: this.convertDate(policy_expire_date),
      product_code: "MT00061",    // MT00061 - If Comprehensive // MTTPL0065 - If TPL
      customer_id: "0",
      lead_id: "0",

      NCD_docs: "No",
      registration_type: vehicleType,
      VEHENGINESIZE: '1',
      modifiedVhclValue: modifyValue,
      SchemeCode: this.SchemeCode,
      source: 'B2C',
       //Added by Anju to satisfy Referral Conditions
      reg_ins_exp_date: this.convertDate(new Date().setDate(new Date().getDate() + 1)),
      emirates_id: "111-1111-1111111-1",
      start_date: this.convertDate(new Date()),

      promoCode : this.vehicleDetailForm.value.promo_code,
      selfDecl : this.accept_self_declaration_1+","+this.accept_self_declaration_2+","+this.accept_self_declaration_3+","+this.accept_self_declaration_4+","+this.accept_self_declaration_5


    };


    this.quoteDetailArr = motorArrayQuote;

    this.motorQuoteService.getInsertQuote(motorArrayQuote,'').subscribe(res => {
      this.PlanDataJson.planBenefitData.benefit_data = [];
      this.showHideLoader.vehicleDetail = false;
      this.showHideLoader.Quotation = false;
      this.checkStepper = false;
      if (res.response_code == 0) {

        Swal.fire("Sorry! Plan not available.", " ", "error");

        stepper.previous();
      }

      else if (res.response_code == 2) {

        // Swal.fire("Sorry, an error occurred while processing your request. Kindly try again or contact Fidelity United at 800 842.", "Your contact and vehicle details are sent to our back-office team and one of our representative will contact you shortly.", "error");
        Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "An email will be sent to you shortly. If you need further assistance call us at 800-842", "error");

        stepper.previous();
      }
      else if (res.response_code == 4) {

        // Swal.fire("Sorry, an error occurred while processing your request. Kindly try again or contact Fidelity United at 800 842.", "Your contact and vehicle details are sent to our back-office team and one of our representative will contact you shortly.", "error");
        Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "An email will be sent to you shortly. If you need further assistance call us at 800-842", "error");

        stepper.previous();
      }

      else if (res.response_code_ == 400 || res.response_code == 5) {
      
        //Swal.fire("Sorry, an error occurred while processing your request. Kindly try again or contact Fidelity United at 800 842.", "Your contact and vehicle details are sent to our back-office team and one of our representative will contact you shortly.", "error");
        Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "An email will be sent to you shortly. If you need further assistance call us at 800-842", "error");

        stepper.previous();
      }

      else {

        // this.editableStep.personalDetail = false ;
        // this.editableStep.vehicleDetail = false ;
        // this.editableStep.coverDetail = false ;

        // this.coverData = res.quotationData.Covers;

        //  this.policyFee = this.vehicleDetailForm.value.insType.value == 200 ? 51 : 1;
        this.policyFee = 1;
        this.totalVariablePremium = 0;
        this.PlanDataJson = res;
        this.totalFixPremium = 0;

        if(this.PlanDataJson.planBenefitData.data == [] || this.PlanDataJson.planBenefitData.benefit_data == null){
            Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "An email will be sent to you shortly. If you need further assistance call us at 800-842", "error");
            stepper.previous();
        }

        this.plan_Name = this.PlanDataJson.planBenefitData.data[0].PlanName;
        this.plan_Id = this.PlanDataJson.planBenefitData.data[0].PlanId;
        this.benfPremIdArray = this.PlanDataJson.planBenefitData.data;
        this.webQuoteNumber = this.PlanDataJson.webQuotationNumber;
        this.quoteNumber = this.PlanDataJson.quotationNumber;
        this.minTCF = this.vehicleDetailForm.value.regplace.CityName == 'Dubai' ? 8 : 10;
        this.maxTCF = this.vehicleDetailForm.value.regplace.CityName == 'Dubai' ? 8 : 10;

       

        this.PlanDataJson.planBenefitData.data.forEach((item, index) => {

          if (item.PlanBenefitType == 'OPTIONAL' && item.MultiOptionFlag == 1) {
            this.mulOption = item.multipleOptionData[0];
            this.PlanDataJson.planBenefitData.data[index].benefitSelectedData = item.multipleOptionData[0];
            this.PlanDataJson.planBenefitData.data[index].multiOptID = item.multipleOptionData[0].BenefitId;
            this.PlanDataJson.planBenefitData.data[index].Price = item.multipleOptionData[0].Price;
            this.PlanDataJson.planBenefitData.data[index].checked = false;


          }
       
        });
      
       // this.addOptionalBenigits(1, 2);
       // let discount = this.personalDetailForm.value.NCD.NCDDisc / 100;
        // let discount = 0 ;


        let discount = this.PlanDataJson.thirdPartyAPIData.NcdDiscount.NcdAmnt;
        this.tempBenefitData = this.PlanDataJson.planBenefitData.benefit_data;
        this.PlanDataJson.planBenefitData.benefit_data.forEach((item, index) => {

          this.totalFixPremium = Number(this.totalFixPremium) + Number(item.premium);
          //  if(this.PlanDataJson.planBenefitData.benefit_data[index].plan_name != 'POLICY FEE'){
      //    this.PlanDataJson.planBenefitData.benefit_data[index].premium = (item.premium - item.premium * discount);   comment on 26 Oct - in save quote with plan API we have to send without benefits without discount 
          //  }


        });

        this.tempTotalFixPremium = this.totalFixPremium;
        this.totalFixPremium = Math.round(this.tempTotalFixPremium - discount);
        this.totalFixPremium = Math.round(this.totalFixPremium + this.policyFee);
        this.tempTotalLoadFixPrem = this.totalFixPremium;
        this.VATAMT = (this.totalFixPremium + this.totalVariablePremium) * 0.05;
        this.Total_Primium = this.totalFixPremium + this.totalVariablePremium + this.VATAMT;

        if(this.accept_self_declaration_1 || this.accept_self_declaration_2 || this.accept_self_declaration_3 || this.accept_self_declaration_4 || this.accept_self_declaration_5){
                this.getDiscountOnSelfDeclaration();
                if(this.totalFixPremium==0 ||  this.totalFixPremium==null)
                this.getDiscountOnSelfDeclaration();
        }
        
      if(this.vehicleDetailForm.value.insType.value == '100' &&  this.totalFixPremium <= 900 ){
       
          Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for ew.", "An email will be sent to you shortly. If you need further assistance call us at 800-842", "error");
          stepper.previous();
      }

     if(this.vehicleDetailForm.value.insType.value == '200' &&  this.totalFixPremium <= 500 ){
     
         Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "An email will be sent to you shortly. If you need further assistance call us at 800-842", "error");
         stepper.previous();
      }

     



        //this.VATAMT = (this.totalFixPremium + this.totalVariablePremium + this.policyFee) * 0.05;
        //this.Total_Primium = this.totalFixPremium + this.totalVariablePremium + this.policyFee + this.VATAMT;


        // this.totalFixPremium = this.totalFixPremium + 1 ; //AED 1 added for RSA cover


        // if(type != 0)
        //    stepper.next();
      }

    });

    // this.motorQuoteService.getVhclSpecification(this.language_code,vhclModelId).subscribe(res =>{

    // });

  }

  //----------- CONVERT DATE INTO DD/MM/YYYY FORMAT ------------//
  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat);
    //return ([ d.getFullYear(),pad(d.getMonth()+1),pad(d.getDate())].join('-'));
    return ([pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-'));

  }

  checkedCoverData() {
   
  }
  valueChange(data, item, $event) {
    //set the two-way binding here for the specific unit with the event
    data[item].checked = $event.checked;

  }

  PlanDataJson: any = {

    "quotationNumber": "",
    "productCode": "",

    "planBenefitData": {

      "BenefitId": 497,
      "PlanBenefitId": 1440,
      "PlanName": "Elite",
      "BenefitName": "National Ambulance Service (per person)",
      "PlanId": 52,
      "PerSeat": 0,
      "Deductible": null,
      "MinContribution": 0,
      "Rate": null,
      "Price": 0,
      "IsIncluded": 1,
      "PlanBenefitType": "INCLUDED",
      "CRS_BENEFIT_CODE": "40013",
      "DisplayOrder": 18,
      "CoverType": "C",
      "SumCovered": "AED 6,770",
      "MultiOptionFlag": null,
      "MultiOptionFlagName": null
    },
    "data": []


  };

  onMulOptionDataChange(eve, i, mulOption) {

    this.PlanDataJson.planBenefitData.data[i].benefitSelectedData = eve;
    this.PlanDataJson.planBenefitData.data[i].multiOptID = eve.BenefitId;
    this.PlanDataJson.planBenefitData.data[i].Price = eve.Price;
    this.PlanDataJson.planBenefitData.data[i].checked = false;
    this.addOptionalBenigits(1, 2);
  }

 

  callChangeStepper(event: any, stepper) {
    //  return false;
    this.floatingSelectedIndex = event.selectedIndex ;
    if (event.selectedIndex == 1) {

      this.insertQuote(stepper, 0);
    }

    if (event.selectedIndex == 2) {
     
      if (this.checkStepper == false) {
     
        if (this.webQuoteNumber == '' && this.retrieveQuoteForm.value.web_Quote_Num == '') {
          this.getQuotation(stepper, 0,'');
         
        }
        else{
          this.updateQuotation(stepper, 0,'');
         
        }
         
      }

    }

    if (event.selectedIndex == 3) {

      this.getQuotePlan(stepper, 0);
    }

  }
  ckhBk: any = [];
  addOptionalBenigits(id, event) {

    this.totalVariablePremium = 0;
    this.optionalBenefit = "" ;
    this.PlanDataJson.planBenefitData.data.forEach((item, index) => {

      if (item.checked == true && item.PlanBenefitType == 'OPTIONAL' && item.MultiOptionFlag!=1) {
        this.optionalBenefit = this.optionalBenefit+","+item.BenefitId;
        this.totalVariablePremium = Number(this.totalVariablePremium + item.Price);
       
      }

      if (item.checked == true && item.PlanBenefitType == 'OPTIONAL' && item.MultiOptionFlag==1) {
        this.optionalBenefit = this.optionalBenefit+","+this.mulOption.BenefitId;
        this.totalVariablePremium = Number(this.totalVariablePremium + item.Price);
       
      }

     

    });

    
  }

  checkOptionalBenefit(benefitId) { 


    if(typeof (benefitId) != undefined && typeof (benefitId) != "undefined" ){


    let benefitIdArray = benefitId.split(',');

    this.totalVariablePremium = 0;
    benefitIdArray.forEach((item1, index1) => {

      this.PlanDataJson.planBenefitData.data.forEach((item, index) => {
        if (item.BenefitId == item1) {
          this.PlanDataJson.planBenefitData.data[index].checked = true;
          this.totalVariablePremium = Number(this.totalVariablePremium + item.Price);
        }


      });

      this.PlanDataJson.planBenefitData.data.forEach((item, index) => {

        if (item.PlanBenefitType == 'OPTIONAL' && item.MultiOptionFlag == 1) {

          item.multipleOptionData.forEach((multiItem, multiIndex) => {
            if (multiItem.BenefitId == item1) {


              this.mulOption = item.multipleOptionData[multiIndex];
              this.PlanDataJson.planBenefitData.data[index].checked = true;
              this.PlanDataJson.planBenefitData.data[index].multiOptID = item.multipleOptionData[multiIndex].BenefitId;
              this.PlanDataJson.planBenefitData.data[index].Price = item.multipleOptionData[multiIndex].Price;

            }


          });

        }

      });

    });
    
  }
  this.addOptionalBenigits(1, 2);
  }

  public vatAMt:any; total_premium:any;
  getQuotePlan(stepper, type) {

    this.quotationForm.get('quoteStepValdtn').setValue('1');

    this.showHideLoader.additionalDetail = true;

    // let vatAmt = (this.totalFixPremium + this.totalVariablePremium + this.policyFee) * 0.05;
    // let total_premium = this.totalFixPremium + this.totalVariablePremium + this.policyFee + vatAmt;

    let vatAMt = (this.totalFixPremium + this.totalVariablePremium) * 0.05;
    this.vatAMt = vatAMt;
    let total_premium = this.totalFixPremium + this.totalVariablePremium + vatAMt
    this.total_premium = total_premium;

    this.savePlanDetailArr = {
      quotation_number: this.webQuoteNumber,
      plan_id: this.plan_Id,
      total_premium: total_premium,
      base_premium: this.totalFixPremium,
      optional_benefit_premiun: this.totalVariablePremium,
      admin_fees: this.policyFee,
      CRS_quote_number: this.quoteNumber,
      service_fees: 0,
      product_code: this.vehicleDetailForm.value.insType,
      VAT: vatAMt,
      benefitPreId: this.benfPremIdArray,


    }

    this.additionalDetailForm.get('docDriving_license_Back').setValue('');
    this.additionalDetailForm.get('docDriving_License_Front').setValue('');
    this.additionalDetailForm.get('docReg_Card_Front').setValue('');
    this.additionalDetailForm.get('docReg_Card_Back').setValue('');
    this.additionalDetailForm.get('docEID_Front').setValue('');
    this.additionalDetailForm.get('docEID_Back').setValue('');
    this.additionalDetailForm.get('docDealerQuote').setValue('');
    this.additionalDetailForm.get('docOther').setValue('');
    

    this.motorQuoteService.insertPlanData(this.PlanDataJson.quotationNumber, this.PlanDataJson.PlanDataJson, this.totalFixPremium,
      this.totalVariablePremium,
      this.policyFee,
      this.totalPreimum,
      this.PlanDataJson.planBenefitData.data,
      this.tempBenefitData,
      this.vehicleDetailForm.value.repairType,
      this.savePlanDetailArr,
      this.vehicleDetailForm.value.insType.viewValue,
      'B2C',
      this.SchemeCode
    ).subscribe(res => {

      this.showHideLoader.additionalDetail = false;
      //  this.editableStep.Quotation = false ;



    });

    if (type != 0)
      stepper.next();

  }


  getQuotePlanEmail() {
    this.showHideLoader.Quotation = true;
    // this.policyFee = this.vehicleDetailForm.value.insType.value == 200 ? 50 : 0;
    // let vatAmt = (this.totalFixPremium + this.totalVariablePremium + this.policyFee) * 0.05;
    // let total_premium = this.totalFixPremium + this.totalVariablePremium + this.policyFee + vatAmt;

    let vatAMt = (this.totalFixPremium + this.totalVariablePremium) * 0.05;
    let total_premium = this.totalFixPremium + this.totalVariablePremium + vatAMt;

    this.savePlanDetailArr = {
      quotation_number: this.webQuoteNumber,
      plan_id: this.plan_Id,
      total_premium: total_premium,
      base_premium: this.totalFixPremium,
      optional_benefit_premiun: this.totalVariablePremium,
      admin_fees: this.policyFee,
      CRS_quote_number: this.quoteNumber,
      service_fees: 0,
      product_code: this.vehicleDetailForm.value.insType,
      VAT: vatAMt,
      benefitPreId: this.benfPremIdArray,


    }

    this.motorQuoteService.insertPlanDataEmail(this.PlanDataJson.quotationNumber, this.PlanDataJson.PlanDataJson, this.totalFixPremium,
      this.totalVariablePremium,
      this.policyFee,
      this.totalPreimum,
      this.PlanDataJson.planBenefitData.data,
      this.tempBenefitData,
      this.vehicleDetailForm.value.repairType,
      this.savePlanDetailArr,
      this.vehicleDetailForm.value.insType.viewValue,
      'B2C',
      this.SchemeCode
    ).subscribe(res => {

      this.sendMail();
      this.showHideLoader.Quotation = false;
      //  this.editableStep.Quotation = false ;

      Swal.fire("", "Thank you for choosing Fidelity United for insuring your car. We have sent an email to your registered email with all the quotation details", 'success');

    });
  }

  sendMail() {
    this.motorQuoteService.sendMail(this.webQuoteNumber,'B2C').subscribe(res => {

    });
  }
  //----------------------------------------- SAVE ADDITIONAL INFO (THIRD PARTY API)--------------------------------------------------------
  saveAdditionalInfo(type) {

    if (type == 2 ) {
      if (this.accept_terms == false) {
        return;
      }
    }

    if (type == 1 ) {
      if (this.vehicleDetailForm.value.chassisNum == '') {

        this.additionalDetailForm.controls['chassisNum'].setValidators([Validators.required]);
        this.additionalDetailForm.controls['isChassisValidate'].setValidators([Validators.required]);
      }
      else {

        this.additionalDetailForm.controls['chassisNum'].setValidators(null);
        this.additionalDetailForm.controls['isChassisValidate'].setValidators(null);
      }

      this.additionalDetailForm.controls['chassisNum'].updateValueAndValidity();
      this.additionalDetailForm.controls['isChassisValidate'].updateValueAndValidity();


      if (this.additionalDetailForm.invalid) {

        this.validtnMsg.additionalDetail = true;
        return false;
      }

      if (this.invalidEID == true) {

        return;
      }
    }
   
    this.validtnMsg.additionalDetail = false;
    let name = this.personalDetailForm.value.vhclOwnBy.code == '100' ? this.personalDetailForm.value.name : this.personalDetailForm.value.companyName;

    this.showHideLoader.additionalDetail = true;

    this.motorQuoteService.saveAdditionalInfo(this.quoteNumber, this.webQuoteNumber, this.additionalDetailForm.value.motgagedBankName, 'N', name, this.vehicleDetailForm.value.insType, this.additionalDetailForm.value.engineNum, this.additionalDetailForm.value.gender.value,'B2C',this.paymentMode).subscribe(res => {
    
      this.additionalDetailForm.get('docDriving_license_Back').setValue('');
      this.additionalDetailForm.get('docDriving_License_Front').setValue('');
      this.additionalDetailForm.get('docReg_Card_Front').setValue('');
      this.additionalDetailForm.get('docReg_Card_Back').setValue('');
      this.additionalDetailForm.get('docEID_Front').setValue('');
      this.additionalDetailForm.get('docEID_Back').setValue('');
      this.additionalDetailForm.get('docDealerQuote').setValue('');
      this.additionalDetailForm.get('docOther').setValue('')
    
      if (res.response_code == 1) {
     
        if (type == 1 ) {
          this.savePolicyDetail(1);
        }

        if (type == 2 ) {
          // alert(1)
          Swal.fire("Your details will be saved and you will be re-directed to Payment gateway page", " Do not click back or click any button", 'success');
          this.savePolicyDetail(2);
        }
        if(type == 3){
          this.sendPaymentLink();
        }


        this.showHideLoader.additionalDetail = false;
      }

    });
   

    //stepper.next();

  }
  //------------------------------------------ UPLOAD DRIVING LICENCE DOC (Third party API) -------------------------------------------------
  uploadDrivLicDoc(doc) {

    this.motorQuoteService.uploadDrivLicDoc(this.PlanDataJson.quotationNumber, doc).subscribe(res => {


    });
  }
  //-------------------- FOR DOCUMENTS UPLOAD ------------------------//
  onFileChange(event, docName, FiledName, files: FileList) {

    //this.webQuoteNumber = 'QMT0010610000052471';

    this.docNameType = docName;
    if (this.docNameType == 'drivingLicenseFront') {
      this.licFrontLoader = this.globalService.spinnerShow();

    }
    else if (this.docNameType == 'drivingLicenseBack') {
      this.licBackLoader = this.globalService.spinnerShow();
    }
    else if (this.docNameType == 'RegCardFront') {
      // alert(1)
      this.regFrontLoader = this.globalService.spinnerShow();
    }
    else if (this.docNameType == 'RegCardBack') {
      this.regBackLoader = this.globalService.spinnerShow();
    }
    else if (this.docNameType == 'EIDFront') {
      this.eidFrontLoader = this.globalService.spinnerShow();
    }
    else if (this.docNameType == 'EIDBack') {
      this.eidBackLoader = this.globalService.spinnerShow();
    }
    else if (this.docNameType == 'dealerquote') {
      this.delQuoteLoader = this.globalService.spinnerShow();
    }
    else if (this.docNameType == 'otherDoc') {
      this.otherDocLoader = this.globalService.spinnerShow();
    }

    // console.log(`Image size before compressed: ${event.target.files[0].size} bytes.`);
    // alert(`Image size before compressed: ${event.target.files[0].size} bytes.`);
      this.compressImage.compress(event.target.files[0])
      .pipe(take(1))
      .subscribe(compressedImage => {
  
        
        // console.log(`Image size after compressed: ${compressedImage.size} bytes.`);

    const formData = new FormData();
    formData.append('file', compressedImage);
    formData.append('QuotationNumber', this.webQuoteNumber);
    formData.append('docName', docName);
    formData.append('source', 'B2C');
    formData.append('quoteType', 'adv');
    Array.from(files).forEach(f => formData.append('file', f))

    this.motorQuoteService.uploadDocumentWithoutOCR(formData).subscribe(res => {
      let updateMemResponse = res;
      if (updateMemResponse.response_code == 1) {

        this.licFrontLoader = this.globalService.spinnerHide();
        this.licBackLoader = this.globalService.spinnerHide();
        this.regFrontLoader = this.globalService.spinnerHide();
        this.regBackLoader = this.globalService.spinnerHide();
        this.eidFrontLoader = this.globalService.spinnerHide();
        this.eidBackLoader = this.globalService.spinnerHide();
        this.delQuoteLoader = this.globalService.spinnerHide();
        this.otherDocLoader = this.globalService.spinnerHide();


        let fileType = updateMemResponse.File.split(".");
        fileType = fileType[fileType.length - 1];
        fileType = fileType == "pdf" ? "PDF": "IMG";
        let formArrayValue: any = this.additionalDetailForm.value;
       
        formArrayValue[FiledName] = updateMemResponse.File;
        formArrayValue[FiledName + "FilePath"] = updateMemResponse.FileDir;
// console.log(FiledName)
        if(FiledName == 'Reg_Card_Front'){
          // alert(2)
         
          this.additionalDetailForm.get('Reg_Card_FrontFilePath').setValue(updateMemResponse.FileDir);
          this.additionalDetailForm.get('Reg_Card_Front').setValue(updateMemResponse.File);
          this.additionalDetailForm.get('Reg_Card_FrontFileType').setValue(fileType);
          
       }
        if(FiledName == 'Reg_Card_Back'){
           
            this.additionalDetailForm.get('Reg_Card_BackFilePath').setValue(updateMemResponse.FileDir);
            this.additionalDetailForm.get('Reg_Card_Back').setValue(updateMemResponse.File);
            this.additionalDetailForm.get('Reg_Card_BackFileType').setValue(fileType);
        }
       if(FiledName == 'EID_Front'){
         
           this.additionalDetailForm.get('EID_FrontFilePath').setValue(updateMemResponse.FileDir);
           this.additionalDetailForm.get('EID_Front').setValue(updateMemResponse.File);
           this.additionalDetailForm.get('EID_FrontFileType').setValue(fileType);
       }
        if(FiledName == 'EID_Back'){
            
            this.additionalDetailForm.get('EID_BackFilePath').setValue(updateMemResponse.FileDir);
            this.additionalDetailForm.get('EID_Back').setValue(updateMemResponse.File);
            this.additionalDetailForm.get('EID_BackFileType').setValue(fileType);
        }
        if(FiledName == 'driving_License_Front'){
          
            this.additionalDetailForm.get('driving_License_FrontFilePath').setValue(updateMemResponse.FileDir);
            this.additionalDetailForm.get('driving_License_Front').setValue(updateMemResponse.File);
            this.additionalDetailForm.get('driving_License_FrontFileType').setValue(fileType);

       }
        if(FiledName == 'driving_license_Back'){
          
           this.additionalDetailForm.get('driving_license_BackFilePath').setValue(updateMemResponse.FileDir);
           this.additionalDetailForm.get('driving_license_Back').setValue(updateMemResponse.File);
           this.additionalDetailForm.get('driving_license_BackFileType').setValue(fileType);
       }
       if(FiledName == 'dealer_quote'){
          
          this.additionalDetailForm.get('dealer_quoteFilePath').setValue(updateMemResponse.FileDir);
          this.additionalDetailForm.get('dealer_quote').setValue(updateMemResponse.File);
          this.additionalDetailForm.get('dealer_quoteFileType').setValue(fileType);
      }
      if(FiledName == 'other_DOc'){
          
          this.additionalDetailForm.get('other_DOcFilePath').setValue(updateMemResponse.FileDir);
          this.additionalDetailForm.get('other_DOc').setValue(updateMemResponse.File);
          this.additionalDetailForm.get('other_DOcFileType').setValue(fileType);
      }


        formArrayValue["docDriving_License_Front"] = "";
        formArrayValue["docDriving_license_Back"] = "";
        formArrayValue["docReg_Card_Front"] = "";
        formArrayValue["docReg_Card_Back"] = "";
        formArrayValue["docEID_Front"] = "";
        formArrayValue["docEID_Back"] = "";
        formArrayValue["docDealerQuote"] = "";
        formArrayValue["docOther"] = "";
      
      }
      if (updateMemResponse.response_code == 5) {
        this.licFrontLoader = this.globalService.spinnerHide();
        this.licBackLoader = this.globalService.spinnerHide();
        this.regFrontLoader = this.globalService.spinnerHide();
        this.regBackLoader = this.globalService.spinnerHide();
        this.eidFrontLoader = this.globalService.spinnerHide();
        this.eidBackLoader = this.globalService.spinnerHide();
        this.delQuoteLoader = this.globalService.spinnerHide();
        this.otherDocLoader = this.globalService.spinnerHide();
        Swal.fire("Please select valid file format.", "only .pdf, .jpg, .png and .jpeg file formats allowed.", 'error');
      }

      if (updateMemResponse.response_code == 6) {
        this.licFrontLoader = this.globalService.spinnerHide();
        this.licBackLoader = this.globalService.spinnerHide();
        this.regFrontLoader = this.globalService.spinnerHide();
        this.regBackLoader = this.globalService.spinnerHide();
        this.eidFrontLoader = this.globalService.spinnerHide();
        this.eidBackLoader = this.globalService.spinnerHide();
        this.delQuoteLoader = this.globalService.spinnerHide();
        this.otherDocLoader = this.globalService.spinnerHide();
        Swal.fire(updateMemResponse.response_status);
      }

    },
      (error) => {
        if (error.error.status == 500) {
          Swal.fire("Sorry, an error occured", "Please try again later", "error");
        }
        this.licFrontLoader = this.globalService.spinnerHide();
        this.licBackLoader = this.globalService.spinnerHide();
        this.regFrontLoader = this.globalService.spinnerHide();
        this.regBackLoader = this.globalService.spinnerHide();
        this.eidFrontLoader = this.globalService.spinnerHide();
        this.eidBackLoader = this.globalService.spinnerHide();
        this.delQuoteLoader = this.globalService.spinnerHide();
        this.otherDocLoader = this.globalService.spinnerHide();
      }
    )
  }

    );


  }
  //----------------------------------- VIEW QUOTE PDF -------------------------------------------
  viewQuotePDF() {
    this.showHideLoader.Quotation = true;
    //this.policyFee = this.vehicleDetailForm.value.insType.value == 200 ? 50 : 0;
    // let vatAmt = (this.totalFixPremium + this.totalVariablePremium + this.policyFee) * 0.05;
    // let total_premium = this.totalFixPremium + this.totalVariablePremium + this.policyFee + vatAmt;

    let vatAMt = (this.totalFixPremium + this.totalVariablePremium) * 0.05;
    let total_premium = this.totalFixPremium + this.totalVariablePremium + vatAMt;

    this.savePlanDetailArr = {
      quotation_number: this.webQuoteNumber,
      plan_id: this.plan_Id,
      total_premium: total_premium,
      base_premium: this.totalFixPremium,
      optional_benefit_premiun: this.totalVariablePremium,
      admin_fees: this.policyFee,
      CRS_quote_number: this.quoteNumber,
      service_fees: 0,
      product_code: this.vehicleDetailForm.value.insType,
      VAT: vatAMt,
      benefitPreId: this.benfPremIdArray,


    }

    this.motorQuoteService.insertPlanDataEmail(this.PlanDataJson.quotationNumber, this.PlanDataJson.PlanDataJson, this.totalFixPremium,
      this.totalVariablePremium,
      this.policyFee,
      this.totalPreimum,
      this.PlanDataJson.planBenefitData.data,
      this.tempBenefitData,
      this.vehicleDetailForm.value.repairType,
      this.savePlanDetailArr,
      this.vehicleDetailForm.value.insType.viewValue,
      'B2C',
      this.SchemeCode
    ).subscribe(res => {


      this.motorQuoteService.getQuoteDetailPDF(this.webQuoteNumber,'B2C').subscribe(res => {
        this.showHideLoader.Quotation = false;
        setTimeout(
          function () {

          }.bind(this),
          600
        );
        let viewPDF = res;
        if (viewPDF.response_code == 1) {
          window.open(viewPDF.pdfPath, "_blank");
        }
      });
      //  this.editableStep.Quotation = false ;



    });

  }

  //------------------------------------- VALIDATE CHASSIS NUMBER ------------------------------------------------------------//
  validateChassisNum(chassisNo, stepper, type) {
    // this.validChassisNum = true;

    if (chassisNo == '') {
      this.blankChassis = true;
      return false;
    }

    this.vehicleDetailForm.get('isChassisValidate').setValue('');
    this.additionalDetailForm.get('isChassisValidate').setValue('');
    let stepperD = stepper;



    this.blankChassis = false;
    this.showHideLoader.chasisNoButton = true;
    this.motorQuoteService.validateChassisNumber(chassisNo).subscribe(res => {
      let validateRes = res;

      if (validateRes.validateChassisNumber == '1') {
        Swal.fire(chassisNo + " " + "This Chassis number is already available. Please contact Fidelity United at 800 842", "error");
        this.showHideLoader.chasisNoButton = false;
        this.vehicleDetailForm.get('isChassisValidate').setValue('');
        this.additionalDetailForm.get('isChassisValidate').setValue('');
        this.vehicleDetailForm.get('chassisNum').setValue('');
        this.additionalDetailForm.get('chassisNum').setValue('');
        return false;
      } else {

        this.motorQuoteService.getDetailsByVIN(chassisNo).subscribe(res => {
          this.showHideLoader.chasisNoButton = false;
          this.getVechileDetailData = res.vechileData;
          this.chassis_No_Details = this.getVechileDetailData.VehicleDetails;

          if (res.vechileData.StatusCode == 400 || res.vechileData.StatusCode == 422 || res.vechileData.ExpiresIn == 1200) {
            Swal.fire(" ", "Invalid Chassis No.", "error");
            if (type == 1) {
              this.vehicleDetailForm.get('isChassisValidate').setValue('');
              this.vehicleDetailForm.get('vhclMake').setValue('');
              this.vehicleDetailForm.get('vhclModelYr').setValue('');
              this.vehicleDetailForm.get('vhclSeatCapcity').setValue('');
              this.vehicleDetailForm.get('vhclGCCSpecifird').setValue('');
              this.vehicleDetailForm.get('vhclModel').setValue('');
              this.vehicleDetailForm.get('vhclTrim').setValue('');
              this.vehicleDetailForm.get('vhclBodyType').setValue('');
              this.vehicleDetailForm.get('vhclCylinder').setValue('');
              this.vehicleDetailForm.get('vhclValue').setValue('');
              this.vehicleDetailForm.get('chassisNum').setValue('');

            }
            if (type == 2) {
              this.additionalDetailForm.get('isChassisValidate').setValue('');
              this.additionalDetailForm.get('chassisNum').setValue('');
            }

          }

          else {

            if (this.additionalDetailForm.value.chassisNum != '') {

              let validateFlag = false;
              let genData = this.getVechileDetailData.VehicleDetails;
              let msg = [];
              if (this.getVechileDetailData.VehicleDetails.General.ModelYear != this.vehicleDetailForm.value.vhclModelYr.value) {
                msg.push("Model Year");
              }
              if (this.getVechileDetailData.VehicleDetails.General.Make.toLowerCase() != this.vehicleDetailForm.value.vhclMake.VehicleMakeName.toLowerCase()) {
                msg.push("Vehicle Make");
              }
              if (this.getVechileDetailData.VehicleDetails.General.Model != null && this.getVechileDetailData.VehicleDetails.General.Model.toLowerCase() != this.vehicleDetailForm.value.vhclModel.VehicleModelName.toLowerCase()) { //Added null checks by Anju
                msg.push("Vehicle Model");
              }

                if (this.getVechileDetailData.VehicleDetails.General.Trim != null && this.getVechileDetailData.VehicleDetails.General.Trim != "" && this.getVechileDetailData.VehicleDetails.General.Trim.toLowerCase() != this.vehicleDetailForm.value.vhclTrim.VehicleSpecCode.toLowerCase()) { //Added null checks by Anju
                msg.push("Vehicle Trim");
              }


                //Added null check by Anju for avoiding error
                if (this.getVechileDetailData.VehicleDetails.General.ModelYear != this.vehicleDetailForm.value.vhclModelYr.value
                    || (this.getVechileDetailData.VehicleDetails.General.Make != null && this.getVechileDetailData.VehicleDetails.General.Make.toLowerCase() != this.vehicleDetailForm.value.vhclMake.VehicleMakeName.toLowerCase())
                    || (this.getVechileDetailData.VehicleDetails.General.Model != null && this.getVechileDetailData.VehicleDetails.General.Model.toLowerCase() != this.vehicleDetailForm.value.vhclModel.VehicleModelName.toLowerCase())
                    || (this.getVechileDetailData.VehicleDetails.General.Trim != null && this.getVechileDetailData.VehicleDetails.General.Trim.toLowerCase() != this.vehicleDetailForm.value.vhclTrim.VehicleSpecCode.toLowerCase())


              ) {
                Swal.fire("Sorry,the vehicle details did not match with the data from Chassis Number Following Values are incorrect", JSON.stringify(msg), "error");
                //Swal.fire('Vehicle detail is incorrect !!!', 'Motor ' + JSON.stringify(msg) + " is wrong.", 'error');
                //  Swal.fire("Vehicle detail is incorrect !!!");
                this.additionalDetailForm.get('isChassisValidate').setValue('');
                stepperD.previous();
                stepperD.previous();
              } else {
                this.additionalDetailForm.get('isChassisValidate').setValue('1');
                //Vehicle Color 
                if (this.getVechileDetailData.VehicleDetails.General.ColourEN != '') {
                  let colorVal;
                  this.vhclColorArr.forEach((item, index) => {
                    if (item.ColorName == this.getVechileDetailData.VehicleDetails.General.ColourEN) {
                      colorVal = item;
                    }
                  });

                  this.additionalDetailForm.get('vhclColor').setValue(colorVal);
                }


                let enginNo = this.getVechileDetailData.VehicleDetails.General.EngineNo;
                this.additionalDetailForm.get('engineNum').setValue(enginNo);

                this.no_Of_Doors = this.getVechileDetailData.VehicleDetails.General.NoOfDoors;


              }
            }


            if (this.vehicleDetailForm.value.chassisNum != '') {
              this.vehicleDetailForm.get('isChassisValidate').setValue('1');

              localStorage.setItem('vehcl_make', this.getVechileDetailData.VehicleDetails.General.Make);
              localStorage.setItem('vehcl_model', this.getVechileDetailData.VehicleDetails.General.Model);
              localStorage.setItem('vehcl_model_year', this.getVechileDetailData.VehicleDetails.General.ModelYear);
              localStorage.setItem('vehcl_trim', this.getVechileDetailData.VehicleDetails.General.Trim);
              localStorage.setItem('vehcl_body', this.getVechileDetailData.VehicleDetails.General.BodyType);
              localStorage.setItem('vehcl_trim_val', this.getVechileDetailData.VehicleDetails.Technical.EngineDisplacementNominal.Value);


              
              

              //VEHICLE YEAR
              let yearVal;
              this.vehimodelyrs.forEach((item, index) => {

                if (item.label == this.getVechileDetailData.VehicleDetails.General.ModelYear) {
                  yearVal = item;
                }
              });

              this.vehicleDetailForm.get('vhclModelYr').setValue(yearVal);
              
              this.getVhclMakeData(yearVal,1)
              

              //Vehicle Cylinders
              let cylVal;
              this.vehicylinders.forEach((item, index) => {

                if (item.value == this.getVechileDetailData.VehicleDetails.Technical.EngineCylinders) {
                  cylVal = item;
                }
              });
              this.vehicleDetailForm.get('vhclCylinder').setValue(cylVal);

              //Passenger Count
              let PasgerVal;
              this.vehiseatcaps.forEach((item, index) => {

                if (item.value == (this.getVechileDetailData.VehicleDetails.General.NoOfSeats - 1)) {
                  PasgerVal = item;
                }
              });
              this.vehicleDetailForm.get('vhclSeatCapcity').setValue(PasgerVal);

              let gcc_specification = this.getVechileDetailData.VehicleDetails.General.Region == 'GCC' ? 'No' : 'Yes';
              this.vehicleDetailForm.get('vhclGCCSpecifird').setValue(gcc_specification);

              localStorage.setItem('GCC_Specification', this.getVechileDetailData.VehicleDetails.General.Region);

              //Vehicle Color 
              if (this.getVechileDetailData.VehicleDetails.General.ColourEN != '') {
                let colorVal;
                this.vhclColorArr.forEach((item, index) => {
                  if (item.ColorName == this.getVechileDetailData.VehicleDetails.General.ColourEN) {
                    colorVal = item;
                  }
                });

                this.additionalDetailForm.get('vhclColor').setValue(colorVal);
              }

              let enginNo = this.getVechileDetailData.VehicleDetails.General.EngineNo;
              this.additionalDetailForm.get('engineNum').setValue(enginNo);

              this.no_Of_Doors = this.getVechileDetailData.VehicleDetails.General.NoOfDoors;
            }


          }


        });

      }
    });
  }


getMakeData(year,makeId){

}

  //------------------------------- VALIDATION FOR EMIRATES ID ----------------------------------------------//
  checkEID(i) {
    this.value = i;

    this.value = this.value.replace(/-/g, "");
    if (/[^0-9-]+/.test(this.value))
      this.invalidEID = true;

    if (this.value.length != 15) {
      this.invalidEID = true;
      return this.invalidEID;
    }
    if (this.value == "111111111111111" || this.value == "000000000000000" || this.value == "222222222222222" || this.value == "333333333333333") {
      this.invalidEID = false;

      return this.invalidEID;
    }

    else {
      //The Luhn Algorithm.
      var nCheck = 0, nDigit = 0, bEven = false;
      this.value = this.value.replace(/\D/g, "");


      //784-1982-6961498-2

      for (let n = this.value.length - 1; n >= 0; n--) {

        var cDigit = this.value.charAt; nDigit = parseInt(this.value[n]);

        if (bEven) {
          if ((nDigit *= 2) > 9)
            nDigit -= 9;
        }

        nCheck += nDigit;
        bEven = !bEven;



      }

      if ((nCheck % 10) == 0) {
        //if valid, ok, check next
        this.invalidEID = false;

        return this.invalidEID;

      }
      else {
        this.invalidEID = true;

        //alert('Invalid Emirates ID. Please enter valid emirates Id including (-) dash/hypen.');
      }



    }

  }
  //--------- VALIDATION FOR NUMBERS & DECIMAL NUM ------------//
  checkValidInputData(event: any, type) {

    this.globalService.checkValidInputData(event, type);
  }
  preventAllEvents(event: any) {

    this.globalService.preventAllEvents(event);
  }

  //------------------------------------- SAVE POLICY DETAIL ---------------------------------------------//
  savePolicyDetail(type) {
    //this._route.navigateByUrl('/motorquote/thankyou');

    let policyDetail = {
      quotation_number: this.webQuoteNumber,
      CRS_quotation_number: this.quoteNumber,
      traffic_code: this.additionalDetailForm.value.TCFnum,
      license: this.additionalDetailForm.value.drivingLicense,
      emirates: this.vehicleDetailForm.value.regplace,
      address: this.additionalDetailForm.value.address,
      PO_box: this.additionalDetailForm.value.poBoxNum,
      occupation: '',
      national_id: this.additionalDetailForm.value.emiratesID,
      arabic_name: '',
      home_office_number: '',
      start_date: this.convertDate(this.additionalDetailForm.value.polStartDate),
      end_date: '22/12/2019',
      color: this.additionalDetailForm.value.vhclColor,
      chassis_no: this.additionalDetailForm.value.chassisNum,
      engine: this.additionalDetailForm.value.engineNum,
      plate: '54687',
      mortgage: this.additionalDetailForm.value.motgagedBankName,
      payment_mode: 'ONLINE',
      named_driver: this.personalDetailForm.value.name,
      additional_driver_under_age: '0',
      IP_address: '',
      mobile: this.personalDetailForm.value.mobileNum,
      code: '',
      driving_License_Front: this.additionalDetailForm.value.driving_License_Front,
      driving_license_Back: this.additionalDetailForm.value.driving_license_Back,
      Reg_Card_Front: this.additionalDetailForm.value.Reg_Card_Front,
      Reg_Card_Back: this.additionalDetailForm.value.Reg_Card_Back,
      EID_Front: this.additionalDetailForm.value.EID_Front,
      EID_Back: this.additionalDetailForm.value.EID_Back,
      dealer_quote: this.additionalDetailForm.value.dealer_quote,
      vhclPlateCategory: this.additionalDetailForm.value.vhclPlateCategory,
      plateCode: this.additionalDetailForm.value.plateCode,
      plateNumber: this.additionalDetailForm.value.plateNumber,
      area: this.additionalDetailForm.value.area,
      noOfDoors: this.no_Of_Doors,
      gender: this.additionalDetailForm.value.gender.value,
      nationality: this.additionalDetailForm.value.nationality
    }

    this.motorQuoteService.savePolicyDetail(policyDetail, this.quoteDetailArr).subscribe(res => {
      this.additionalDetailForm.get('docDriving_license_Back').setValue('');
      this.additionalDetailForm.get('docDriving_License_Front').setValue('');
      this.additionalDetailForm.get('docReg_Card_Front').setValue('');
      this.additionalDetailForm.get('docReg_Card_Back').setValue('');
      this.additionalDetailForm.get('docEID_Front').setValue('');
      this.additionalDetailForm.get('docEID_Back').setValue('');
      this.additionalDetailForm.get('docDealerQuote').setValue('');
      this.additionalDetailForm.get('docOther').setValue('');


      if (type == 1) {
      
        Swal.fire("Your details are saved successfully.", "Your Quotation Reference Number is " + this.webQuoteNumber + ". If you need any assistance please call us at 800-842.", "success");
      }

      if (type == 2) {

        let server_name = config.server_name;
        let return_url = "http://k2key.in/motor_ins//dashboard";
        let site_url = "http://k2key.in/motor_ins/" + this._route.url;

        //this.paymentLoader = this.globalService.spinnerShow();
        this.showHideLoader.additionalDetail = true;

        //----------------------------- NEW PAYMENT GATEWAY -----------------------------------------//
      //   this.motorQuoteService.paymentAuth(this.webQuoteNumber,site_url,return_url,'MT','1','','B2C','adv').subscribe(res => {
      //     let payRes = res;
      //     this.trans_ref = payRes.result.tran_ref;
  
      //     localStorage.setItem('Trans_Ref',this.trans_ref);
      //     localStorage.setItem('TokenId',payRes.result.token);
         
  
      //     if(payRes.res_code == 1){
      //           window.location.href = payRes.result.redirect_url;
      //     }     
         
      // });

       //----------------------------- OLD PAYMENT GATEWAY -----------------------------------------//
      //  alert(1)
      //  console.log(1)
            //Modified by Anju for new payment gateway
            this.motorQuoteService.paymentAuthNew(this.webQuoteNumber, site_url, return_url, 'MT', '1', '', 'B2C', 'adv').subscribe(res => {
                let payRes = res;
                // alert(2)
                if (payRes.status == 'Failed') {
                    Swal.fire(payRes.res_msg);
                    // this.paymentLoader = this.globalService.spinnerHide();
                    this.showHideLoader.additionalDetail = false;
                    return false;
                }

                else if (payRes.status == 'Success') {
                    // alert(1)
                    //this.paymentLoader = this.globalService.spinnerHide();
                    this.showHideLoader.additionalDetail = false;
                    // console.log(payRes.result.p_id);
                    // console.log(payRes.result.transaction_id);
                    // localStorage.setItem('Payment_Order_ID',res.result.tran_ref);
                    localStorage.setItem('Payment_Order_ID', payRes.data.tran_ref);
                    localStorage.setItem('Payment_Order_Tokan', payRes.data.token);
                    localStorage.setItem('Payment_Quotation_Number', this.webQuoteNumber);
                    localStorage.setItem('Insured_Email', this.personalDetailForm.value.email);
                    localStorage.setItem('Insured_Mobile', this.personalDetailForm.value.mobileNum);
                    // localStorage.setItem('Insured_Code', this.personalDetailForm.value.mobileCode.value);
                    window.location.href = payRes.data.redirect_url;


                }


            });
        }


    });

    }

  public existVehiclevalue: any;
  updateValuation() {

if(this.vhcleValueFlag == false){
    if (this.vehicleDetailForm.value.vhclMake != '' && this.vehicleDetailForm.value.vhclModel != '' && this.vehicleDetailForm.value.vhclTrim != '' && this.vehicleDetailForm.value.vhclBodyType != '') {

      this.showHideLoader.VehicleValue = true;

      this.motorQuoteService.getMotorValuation(this.vehicleDetailForm.value).subscribe(res => {


        if (res.StatusCode == 200) {
          this.existVehiclevalue = 0;
          this.vehicleValueLimit.isSet = true;
          this.vehicleValueLimit.startLimit = res.Valuation.Low;
          this.vehicleValueLimit.endLimit = res.Valuation.High;


          this.vehicleDetailForm.controls['vhclValue'].setValidators([Validators.required, Validators.min(res.Valuation.Low), Validators.max(res.Valuation.High)]);
          this.vehicleDetailForm.controls['vhclValue'].updateValueAndValidity();
          this.vehicleDetailForm.get('vhclValue').setValue(res.Valuation.Medium);

          //   this.vehicleDetailForm.vhclValue


        } else {

          this.existVehiclevalue = 1;
          this.vehicleValueLimit.startLimit = 0;
          this.vehicleValueLimit.endLimit = 1000000;
          this.vehicleDetailForm.controls['vhclValue'].setValidators([Validators.required]);
          this.vehicleDetailForm.controls['vhclValue'].updateValueAndValidity();
          this.vehicleDetailForm.get('vhclValue').setValue('');
          this.vehicleValueLimit.isSet = false;

        }

        this.showHideLoader.VehicleValue = false;

      });
    }

  }else{
      this.vhcleValueFlag = false;
  }


}

  changeChasisValidation(type) {

    // commented for timing
    this.vehicleDetailForm.get('isChassisValidate').setValue('');
    this.additionalDetailForm.get('isChassisValidate').setValue('');
    if (type == 1) {
      this.vehicleDetailForm.get('vhclModelYr').setValue('');
      this.vehicleDetailForm.get('vhclMake').setValue('');
      this.vehicleDetailForm.get('vhclModel').setValue('');
      this.vehicleDetailForm.get('vhclTrim').setValue('');
      this.vehicleDetailForm.get('vhclBodyType').setValue('');
      this.vehicleDetailForm.get('vhclCylinder').setValue('');
      this.vehicleDetailForm.get('vhclSeatCapcity').setValue('');

    }


    localStorage.removeItem("vehcl_make");
    localStorage.removeItem("vehcl_model");
    localStorage.removeItem('vehcl_model_year');
    localStorage.removeItem('vehcl_trim');
    localStorage.removeItem('vehcl_body');
    localStorage.removeItem('vehcl_trim_val');


  }


  /************** Country Filter */

  private filterCountry() {
    if (!this.countryArr) {
      return;
    }
    // get the search keyword
    let search = this.country1FilterCtrl.value;
    if (!search) {
      this.filteredCountries.next(this.countryArr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCountries.next(
      this.countryArr.filter(bank => bank.CountryName.toLowerCase().indexOf(search) > -1)
    );
  }


  private filterNationalCountry() {
    if (!this.countryArr) {
      return;
    }
    // get the search keyword
    let search = this.nationalFilterCtrl.value;
    if (!search) {
      this.filteredNationCountries.next(this.countryArr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredNationCountries.next(
      this.countryArr.filter(bank => bank.CountryName.toLowerCase().indexOf(search) > -1)
    );
  }




  private filterMortageBank() {
    if (!this.bankDetail) {
      return;
    }

    // get the search keyword
    let search = this.mortageBankFilterCtrl.value;

    if (!search) {
      this.filteredBank.next(this.bankDetail.slice());
      return;
    } else {
      search = search.toLowerCase();
    }


    // filter the banks
    this.filteredBank.next(
      this.bankDetail.filter(bank => bank.InstituteName.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterVechColor() {
    if (!this.vhclColorArr) {
      return;
    }

    // get the search keyword
    let search = this.vchColorFilterCtrl.value;
    if (!search) {
      this.filteredVchColor.next(this.vhclColorArr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }


    // filter the banks
    this.filteredVchColor.next(
      this.vhclColorArr.filter(bank => bank.ColorName.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterPlateCat() {
    if (!this.plateCatArray) {
      return;
    }

    // get the search keyword
    let search = this.plateFilterCtrl.value;
    if (!search) {
      this.filteredPlateCat.next(this.plateCatArray.slice());
      return;
    } else {
      search = search.toLowerCase();
    }


    // filter the banks
    this.filteredPlateCat.next(
      this.plateCatArray.filter(bank => bank.label.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterCode() {
    if (!this.codes) {
      return;
    }

    // get the search keyword
    let search = this.codeFilterCtrl.value;
    if (!search) {
      this.filteredCode.next(this.codes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }


    // filter the banks
    this.filteredCode.next(
      this.codes.filter(bank => bank.viewValue.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterYear() {
    if (!this.vehimodelyrs) {
      return;
    }

    // get the search keyword
    let search = this.yearFilterCtrl.value;
    if (!search) {
      this.filteredYears.next(this.vehimodelyrs.slice());
      return;
    } else {
      search = search.toLowerCase();
    }


    // filter the banks
    this.filteredYears.next(
      this.vehimodelyrs.filter(bank => bank.label.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterMake() {
    if (!this.vhclMakeArr) {
      return;
    }
    // get the search keyword
    let search = this.makeFilterCtrl.value;
    if (!search) {
      this.filteredMakes.next(this.vhclMakeArr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredMakes.next(
      this.vhclMakeArr.filter(bank => bank.VehicleMakeName.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterModel() {
    if (!this.vhclModelArr) {
      return;
    }
    // get the search keyword
    let search = this.modelFilterCtrl.value;
    if (!search) {
      this.filteredModels.next(this.vhclModelArr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredModels.next(
      this.vhclModelArr.filter(bank => bank.VehicleModelName.toLowerCase().indexOf(search) > -1)
    );
  }

  updateQuotation(stepper, type, frame) {
    this.checkStepper = true;
    //frame.hide();
    if (this.vehicleDetailForm.invalid) {
      this.validtnMsg.vehicleDetail = true;
      return false;
    }
// console.log("Testing----");
    if(this.vehicleDetailForm.value.insType.value == 100 && (Number(this.vehicleDetailForm.value.vhclValue) < Number(this.vehicleValueLimit.startLimit) || Number(this.vehicleDetailForm.value.vhclValue) > Number(this.vehicleValueLimit.endLimit))) {

      this.validtnMsg.vehicleDetail = true;
      return false;
    }


    if (this.vehicleDetailForm.value.vhclModelYr.value >= (this.minyear - 1) && this.vehicleDetailForm.value.insType.value == 100 && this.vehicleDetailForm.value.brandNw != 1) {
          this.vehicleDetailForm.controls['regDate'].setValidators([Validators.required]);
     
      if (this.vehicleDetailForm.value.brandNw != 1) {    
            if (this.regMaxDate < this.vehicleDetailForm.value.regDate || this.regMinDate > this.vehicleDetailForm.value.regDate) {
              return false;
            }
      }
    } else {
          this.vehicleDetailForm.controls['regDate'].setValidators(null);
      }
    this.vehicleDetailForm.controls['regDate'].updateValueAndValidity();

   
    this.showHideLoader.vehicleDetail = true;
    this.showHideLoader.Quotation = true;
    if (type != 0){
      stepper.next();
    }
     
    let webQuoteNum = this.retrieveQuoteForm.value.web_Quote_Num == '' ? this.webQuoteNumber : this.retrieveQuoteForm.value.web_Quote_Num;

    let name = this.personalDetailForm.value.vhclOwnBy.code == '100' ? this.personalDetailForm.value.name : this.personalDetailForm.value.companyName;
    let vehicleType = this.personalDetailForm.value.vhclOwnBy.code == '100' ? { code: '1001', value: 'Private' } : { code: '1009', value: 'Commercial' }
    let policy_expire_date = this.vehicleDetailForm.value.vhclTPLCoverage == 'No' ? this.nextDate : this.PrevDate;
    let brand_new = (this.vehicleDetailForm.value.vhclModelYr.value != this.maxYear || this.vehicleDetailForm.value.vhclModelYr.value != this.year || this.vehicleDetailForm.value.vhclModelYr.value != this.minyear) ? { code: '0', value: 'N' } : { code: '1', value: 'Y' };
    //let regDate = this.vehicleDetailForm.value.brandNw == 1 ? this.convertDate(this.toDay) : this.convertDate(this.vehicleDetailForm.value.regDate);

    let regDate: any;
    if (this.vehicleDetailForm.value.brandNw == 1) {
      regDate = this.convertDate(this.toDay);
    }
    else if (this.vehicleDetailForm.value.vhclModelYr.value < (this.minyear - 1)) {
      regDate = this.convertDate(new Date(this.vehicleDetailForm.value.vhclModelYr.value, 0, 1));
    }
    else {
      regDate = this.convertDate(this.vehicleDetailForm.value.regDate);
    }

    let modifyValue = this.existVehiclevalue == 1 ? this.existVehiclevalue : null;
    let vehicle_value = this.vehicleDetailForm.value.insType.value == 100 ? this.vehicleDetailForm.value.vhclValue : 0;
    let motorArrayQuote = {
      quotation_number: this.quoteNumber,
      web_quote_number: webQuoteNum,
      insured_type: this.personalDetailForm.value.vhclOwnBy,
      insured_name: name,
      gender: "",
      dob: this.convertDate(this.personalDetailForm.value.dob),
      prospect_age: this.calculateAge(this.personalDetailForm.value.dob),
      nationality: this.personalDetailForm.value.licIssueContry, // check if we need to add in html
      email: this.personalDetailForm.value.email,
      mobile: this.personalDetailForm.value.mobileNum,
      code: this.personalDetailForm.value.mobileCode,
      UAE_lic_age: this.convertDate(this.personalDetailForm.value.drivExpInUAE),
      UAELicAge: this.UAELicAge,
      license_country: this.personalDetailForm.value.licIssueContry,
      NCD: this.personalDetailForm.value.NCD,

      brand_new: brand_new,
      model_year: this.vehicleDetailForm.value.vhclModelYr,
      make: this.vehicleDetailForm.value.vhclMake,
      model: this.vehicleDetailForm.value.vhclModel,
      body_type: this.vehicleDetailForm.value.vhclBodyType,
      cylinders: this.vehicleDetailForm.value.vhclCylinder,
      passenger: this.vehicleDetailForm.value.vhclSeatCapcity,
      registered_date: regDate,
      registered_place: this.vehicleDetailForm.value.regplace,
      trim: this.vehicleDetailForm.value.vhclTrim,
      chassis_no: this.vehicleDetailForm.value.chassisNum,

      repair_type: this.vehicleDetailForm.value.repairType,
      sum_insured: vehicle_value,
      GCCSpecified: this.vehicleDetailForm.value.vhclGCCSpecifird,
      vhclModified: this.vehicleDetailForm.value.vhclModified,
      policy_type: this.vehicleDetailForm.value.insType,
      ExistingCover: this.vehicleDetailForm.value.vhclTPLCoverage,
      policy_expire_date: this.convertDate(policy_expire_date),

      customer_id: "0",
      lead_id: "0",

      NCD_docs: "No",
      registration_type: vehicleType,
      VEHENGINESIZE: '1',
      modifiedVhclValue: modifyValue,
      SchemeCode: this.SchemeCode,
      source: 'B2C',
      promoCode: this.vehicleDetailForm.value.promo_code,
      //Added by Anju for mandtory check in PHP API
      reg_ins_exp_date: this.convertDate(new Date().setDate(new Date().getDate() + 1)),
      emirates_id: "111-1111-1111111-1",
      start_date: this.convertDate(new Date()),
      selfDecl : this.accept_self_declaration_1+","+this.accept_self_declaration_2+","+this.accept_self_declaration_3+","+this.accept_self_declaration_4+","+this.accept_self_declaration_5

    };




    this.quoteDetailArr = motorArrayQuote;

    this.motorQuoteService.updateQuotation(motorArrayQuote,'').subscribe(res => {
      this.PlanDataJson.planBenefitData.benefit_data = [];
      this.showHideLoader.vehicleDetail = false;
      this.showHideLoader.Quotation = false;
      this.checkStepper = false;
      if (res.response_code == 0) {


      //  this.vehicleDetailForm.get('vhclTPLCoverage').setValue('');
       // this.vehicleDetailForm.get('vhclGCCSpecifird').setValue('');

        Swal.fire("Sorry! Plan not available.", " ", "error");

        stepper.previous();
      }

      else if (res.response_code == 4) {

       // this.vehicleDetailForm.get('vhclTPLCoverage').setValue('');
      //  this.vehicleDetailForm.get('vhclGCCSpecifird').setValue('');

        // Swal.fire("Sorry, we could not find any suitable plan based on the data entered. Please try again or contact Fidelity United at 800 842", " ", "error");
        Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "An email will be sent to you shortly. If you need further assistance call us at 800-842", "error");

        stepper.previous();
      }

      else if (res.response_code_ == 400 || res.response_code == 5) {
       // this.vehicleDetailForm.get('vhclTPLCoverage').setValue('');
      //  this.vehicleDetailForm.get('vhclGCCSpecifird').setValue('');

        // Swal.fire("Sorry, an error occurred while processing your request. Kindly try again or contact Fidelity United at 800 842", " ", "error");
        Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "An email will be sent to you shortly. If you need further assistance call us at 800-842", "error");

        stepper.previous();
      }

      else {

        // this.policyFee = this.vehicleDetailForm.value.insType.value == 200 ? 0 : 0;
        // this.policyFee = this.vehicleDetailForm.value.insType.value == 200 ? 51 : 1;
        this.policyFee = 1;
        this.totalVariablePremium = 0;
        this.PlanDataJson = res;
        this.totalFixPremium = 0;

        if(this.PlanDataJson.planBenefitData.data == [] || this.PlanDataJson.planBenefitData.benefit_data == null){
            Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "An email will be sent to you shortly. If you need further assistance call us at 800-842", "error");
            stepper.previous();
        }

        this.plan_Name = this.PlanDataJson.planBenefitData.data[0].PlanName;
        this.plan_Id = this.PlanDataJson.planBenefitData.data[0].PlanId;
        this.benfPremIdArray = this.PlanDataJson.planBenefitData.data;
        this.webQuoteNumber = this.PlanDataJson.webQuotationNumber;
        this.quoteNumber = this.PlanDataJson.quotationNumber;
        this.minTCF = this.vehicleDetailForm.value.regplace.CityName == 'Dubai' ? 8 : 10;
        this.maxTCF = this.vehicleDetailForm.value.regplace.CityName == 'Dubai' ? 8 : 10;

        
        this.PlanDataJson.planBenefitData.data.forEach((item, index) => {

          if (item.PlanBenefitType == 'OPTIONAL' && item.MultiOptionFlag == 1) {
            this.mulOption = item.multipleOptionData[0];
            this.PlanDataJson.planBenefitData.data[index].benefitSelectedData = item.multipleOptionData[0];
            this.PlanDataJson.planBenefitData.data[index].multiOptID = item.multipleOptionData[0].BenefitId;
            this.PlanDataJson.planBenefitData.data[index].Price = item.multipleOptionData[0].Price;
            this.PlanDataJson.planBenefitData.data[index].checked = false;


          }
        
        });
     
        this.checkOptionalBenefit(this.optionalBenefit);
   
        let discount = this.PlanDataJson.thirdPartyAPIData.NcdDiscount.NcdAmnt;
        this.tempBenefitData = this.PlanDataJson.planBenefitData.benefit_data;
        this.PlanDataJson.planBenefitData.benefit_data.forEach((item, index) => {

          this.totalFixPremium = Number(this.totalFixPremium) + Number(item.premium);
        //  this.PlanDataJson.planBenefitData.benefit_data[index].premium = (item.premium - item.premium * discount);  comment on 26 Oct - in save quote with plan API we have to send without benefits without discount 
        });


        this.tempTotalFixPremium = this.totalFixPremium;

 
        this.totalFixPremium = Math.round(this.tempTotalFixPremium - discount);
        this.totalFixPremium = Math.round(this.totalFixPremium + this.policyFee);
        this.tempTotalLoadFixPrem = this.totalFixPremium;
        this.VATAMT = (this.totalFixPremium + this.totalVariablePremium) * 0.05;
        this.Total_Primium = this.totalFixPremium + this.totalVariablePremium + this.VATAMT;

        if(this.accept_self_declaration_1 || this.accept_self_declaration_2 || this.accept_self_declaration_3 || this.accept_self_declaration_4 || this.accept_self_declaration_5){
           this.getDiscountOnSelfDeclaration();
           
            if(this.totalFixPremium==0 ||  this.totalFixPremium==null)
                this.getDiscountOnSelfDeclaration();
        }
        if(this.vehicleDetailForm.value.insType.value == '100' &&  this.totalFixPremium <= 900 ){
        
             Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for ew.", "An email will be sent to you shortly. If you need further assistance call us at 800-842", "error");
             stepper.previous();
        }

        if(this.vehicleDetailForm.value.insType.value == '200' &&  this.totalFixPremium <= 500 ){
        
          Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "An email will be sent to you shortly. If you need further assistance call us at 800-842", "error");
            stepper.previous();
        }

        //this.VATAMT = (this.totalFixPremium + this.totalVariablePremium + this.policyFee) * 0.05;
        //this.Total_Primium = this.totalFixPremium + this.totalVariablePremium + this.policyFee + this.VATAMT;


        // if(type !=0 )
        //     stepper.next();

      }

    });

  }

  //-------------------------------------------------- GET RETRIEVE QUOTE DATA -----------------------------------------------------------------------
  getRetrieveQuote(type) {
    this.check_chassis_No = false;
    if (type == 1) {
      if (this.retrieveQuoteForm.invalid) {
        this.validtnMsg.retrieveQuote = true;
        return false;
      }
    }
    if (type == 2) {
      this.emailID = localStorage.getItem('Insured_Email');
      this.code = localStorage.getItem('Insured_Code');
      this.mobile_No = localStorage.getItem('Insured_Mobile');
    }

    this.resetAllForms();

    let mob_Num = type == 2 ? this.mobile_No : this.retrieveQuoteForm.value.mob_Num;
    let email = type == 2 ? this.emailID : this.retrieveQuoteForm.value.email;
    let code = ''
    let web_Quote_Num = type == 2 ? this.ret_quote_num : this.retrieveQuoteForm.value.web_Quote_Num;

    if (type == 2) {
      this.showHideLoader.personalDetail = true;
    }

    this.validtnMsg.retrieveQuote = false;
    this.showHideLoader.retrieveQuote = true;



    // this.retrieveQuoteForm.get('isRetriveValidate').setValue('');

    this.motorQuoteService.getRetrieveQuote(web_Quote_Num, code, mob_Num, email, 'BTCPORTAL').subscribe(res => {

      this.showHideLoader.retrieveQuote = false;
      this.showHideLoader.personalDetail = false;
      if (res.response_code == 1) {
        if(res.quotationDetailsDataForCustomer[0].QuoteType=="OCR"){
          localStorage.setItem("retriveOCR",'OCR');
          localStorage.setItem("retriveOCRQuote",web_Quote_Num);
          localStorage.setItem("retriveOCRCode",code);
          localStorage.setItem("retriveOCRmob_Num",mob_Num);
          localStorage.setItem("retriveOCRemail",email);
          this._route.navigate(["/motoradvquote"]);
          
        return false ;
        }

        Swal.fire("Your quotation has been successfully retrieved.", "Kindly note the premium may change based on the company pricing policy", "success");

        //  this.retrieveQuoteForm.get('isRetriveValidate').setValue('1');
        this.selected.setValue(0);

        this.getDataForEditQuoteDocs(web_Quote_Num);
        this.retrieveFlag = true;
        this.vhcleValueFlag = true;
        // this.webQuoteNumber = this.retrieveQuoteForm.value.web_Quote_Num;
        this.webQuoteNumber = web_Quote_Num;
        this.quoteDetailDataForCUst = res.quotationDetailsDataForCustomer;
        let quoteDetail = this.quoteDetailDataForCUst[0];
        this.quoteDetail = this.quoteDetailDataForCUst[0];
        this.vehicleValue = quoteDetail.SumInsured;
        this.optionalBenefit = quoteDetail.SelectedBenefits;
        this.quoteDetail.plateCode = quoteDetail.PlateCode;

        this.personalDetailForm.get('name').setValue(quoteDetail.InsuredName);
        this.personalDetailForm.get('companyName').setValue(quoteDetail.InsuranceCompanyName);
        this.personalDetailForm.get('dob').setValue(new Date(quoteDetail.InsuredDOB));
        this.personalDetailForm.get('email').setValue(quoteDetail.InsuredEmail);
        this.personalDetailForm.get('mobileNum').setValue(quoteDetail.InsuredMobile);
        this.personalDetailForm.get('drivExpInUAE').setValue(new Date(quoteDetail.ResidenceCountryLicenseAge));
     
        this.calucateAge(this.personalDetailForm.value.drivExpInUAE, 2);
          
        if(this.vehicleDetailForm.value.brandNw==1){
          this.vehicleDetailForm.get('vhclTPLCoverage').setValue(this.activePolicyTypeArr[1]);
        }else{
        if(quoteDetail.CurrentInsurance=="Yes")
        {
          this.vehicleDetailForm.get('vhclTPLCoverage').setValue(this.activePolicyTypeArr[2]);
        } else {
          this.vehicleDetailForm.get('vhclTPLCoverage').setValue(this.activePolicyTypeArr[1]);

        }
      }

      // if(this.vehicleDetailForm.value.brandNw!=1){
      //   console.log(quoteDetail.FirstRegistrationDate)
      //   this.vehicleDetailForm.get('regDate').setValue(new Date(quoteDetail.FirstRegistrationDate));
      // }
      
        //Vehicle own by  
        var indexOwnBy = this.insvehibys.findIndex(function (obj, k) {
          return obj.value === quoteDetail.InsuredType;
        });
        let ownByVal = this.insvehibys[indexOwnBy];
        this.personalDetailForm.get('vhclOwnBy').setValue(ownByVal);

        //Mobile Code
        // var indexCode = this.codes.findIndex(function (obj, k) {
        //   return obj.viewValue === quoteDetail.MobileCode;
        // });
        // let codeVal = this.codes[indexCode];
        // this.personalDetailForm.get('mobileCode').setValue(codeVal);

        //NCD
        var indexNCD = this.NCDData.findIndex(function (obj, k) {
          return obj.NCDDescription === quoteDetail.NCD;
        });
        let NCDVal = this.NCDData[indexNCD];
        this.personalDetailForm.get('NCD').setValue(NCDVal);

        //Driving Lic Country
        var indexLicCnty = this.countryArr.findIndex(function (obj, k) {
          return obj.CountryName === quoteDetail.FirstDrivingLicenseCountry;
        });
        let licContVal = this.countryArr[indexLicCnty];
        this.personalDetailForm.get('licIssueContry').setValue(licContVal);

        this.vehicleDetailForm.get('regDate').setValue(new Date(quoteDetail.FirstRegistrationDate));
        this.vehicleDetailForm.get('brandNw').setValue(quoteDetail.IsBrandNew);
        this.vehicleDetailForm.get('vhclGCCSpecifird').setValue(quoteDetail.HasGCCSpecification);
        this.vehicleDetailForm.get('vhclModified').setValue(quoteDetail.IsModified);
        this.vehicleDetailForm.get('vhclValue').setValue(this.vehicleValue);

        this.vehicleValueLimit.startLimit = quoteDetail.ValuationLow;
        this.vehicleValueLimit.endLimit = quoteDetail.ValuationHigh;
        this.vehicleValueLimit.isSet = true;

       
        //VEHICLE YEAR
        let yearVal;
        this.vehimodelyrs.forEach((item, index) => {
          if (item.label == quoteDetail.VehicleModelYear) {
            yearVal = item;
          }
        });
        this.vehicleDetailForm.get('vhclModelYr').setValue(yearVal);
        this.vhclModelYearVal = quoteDetail.VehicleModelYear;
        this.retrieveVehcleMake = quoteDetail.VehicleMake;
        this.getVhclMakeData(yearVal,2);
    
        //REG PLACE
        var indexPlace = this.cityArr.findIndex(function (obj, k) {
          return obj.CityName === quoteDetail.RegistrationPlace;
        });
        let placeal = this.cityArr[indexPlace];
        this.vehicleDetailForm.get('regplace').setValue(placeal);


        this.vehicleDetailForm.patchValue({ regplace: placeal });

        this.quoteDetail.regplace = placeal.CityName;
        this.getPlateCode(this.quoteDetail.regplace, 2);

        //Policy Type
        var indexInsType = this.instypes.findIndex(function (obj, k) {
          return obj.viewValue === quoteDetail.PolicyType;
        });
        let insVal = this.instypes[indexInsType];
        this.vehicleDetailForm.get('insType').setValue(insVal);

        //Vehicle Cylinders
        var indexCylinder = this.vehicylinders.findIndex(function (obj, k) {
          return obj.value === quoteDetail.VehicleCylinders;
        });
        let cylVal = this.vehicylinders[indexCylinder];
        this.vehicleDetailForm.get('vhclCylinder').setValue(cylVal);

        //Passenger Count
        var indexPasger = this.vehiseatcaps.findIndex(function (obj, k) {
          return obj.value == quoteDetail.PassengersCount;
        });


        let PasgerVal = this.vehiseatcaps[indexPasger];

        this.vehicleDetailForm.get('vhclSeatCapcity').setValue(PasgerVal);

        //Active Policy
        // var indexPol = this.activePolicyTypeArr.findIndex(function (obj, k) {
        //   return obj.value === quoteDetail.CurrentInsurance;
        // });
        // let polVal = this.activePolicyTypeArr[indexPol];
        // this.vehicleDetailForm.get('vhclTPLCoverage').setValue(polVal);

        this.vehicleDetailForm.get('chassisNum').setValue(quoteDetail.ChassisNumber)
        if (this.vehicleDetailForm.value.chassisNum != '' && this.vehicleDetailForm.value.chassisNum != null ) {
                  this.check_chassis_No = true;

          //    this.checkChassisNo();
        }
        // return false ;
        
        // ADDITIONAL INFORMATION


        this.additionalDetailForm.get('emiratesID').setValue(quoteDetail.EmiratesIdNumber);
        this.additionalDetailForm.get('engineNum').setValue(quoteDetail.EngineNumber);
        this.additionalDetailForm.get('poBoxNum').setValue(quoteDetail.POBox);
        this.additionalDetailForm.get('plateNumber').setValue(quoteDetail.RegistrationNumber);
        this.additionalDetailForm.get('area').setValue(quoteDetail.Area);
        this.additionalDetailForm.get('TCFnum').setValue(quoteDetail.TrafficFileNo);
        this.additionalDetailForm.get('drivingLicense').setValue(quoteDetail.LicenceNumber);
        this.additionalDetailForm.get('address').setValue(quoteDetail.InsuredAddress);

        //Mortagage bank
      if(quoteDetail.Mortgage != '' || quoteDetail.Mortgage != null) { 
         var indexBank = this.bankDetail.findIndex(function (obj, k) {
          return obj.InstituteName === quoteDetail.Mortgage;
        });
        let bankVal = this.bankDetail[indexBank];
        if(quoteDetail.Mortgage=="Not Mortgaged")
        this.additionalDetailForm.get('motgagedBankName').setValue(this.bankDetail[0]);
        else
        this.additionalDetailForm.get('motgagedBankName').setValue(bankVal);
      }else {
        this.additionalDetailForm.get('motgagedBankName').setValue('');
      }


        //Nationality
        var indexNationality = this.countryArr.findIndex(function (obj, k) {
          return obj.CountryName === quoteDetail.InsuredNationality;
        });
        let nationalVal = this.countryArr[indexNationality];
        this.additionalDetailForm.get('nationality').setValue(nationalVal);

        //Plate category
        var indexPlCat = this.plateCatArray.findIndex(function (obj, k) {
          return obj.label === quoteDetail.PlateCategory;
        });
        let plCatVal = this.plateCatArray[indexPlCat];
        this.additionalDetailForm.get('vhclPlateCategory').setValue(plCatVal.value);

        //Vehicle Color
        var indexColor = this.vhclColorArr.findIndex(function (obj, k) {
          return obj.ColorName === quoteDetail.VehicleColour;
        });
        let clrVal = this.vhclColorArr[indexColor];
        this.additionalDetailForm.get('vhclColor').setValue(clrVal);
        //Gender
        var indexGender = this.genders.findIndex(function (obj, k) {
          return obj.value === quoteDetail.InsuredGender;
        });
        let gnderVal = this.genders[indexGender];
        this.additionalDetailForm.get('gender').setValue(gnderVal);

        this.iSRetriveQuote = true;

      }



      else {
        //  this.retrieveQuoteForm.get('isRetriveValidate').setValue('');
        Swal.fire('Sorry we could not find details based on entered search criteria.', 'Kindly review and try again or contact Fidelity United at 800 842 or email at info@fidelityunited.ae', 'error');
        this.retrieveQuoteForm.reset();
        this.webQuoteNumber = '';
        return false;
      }




    },
      (error) => {

        this.showHideLoader.retrieveQuote = false;
      });
      if(this.status_type == 1){
        this.personalDetailForm.get('recaptchaReactiveByUser').setValue('gT8e7ez');
       
        // if(this.status_email == 1){
          setTimeout(() => {
            console.log(this.stepper)
          this.insertQuote(this.stepper,1)
          this.showHideLoader.personalDetail =false;
        }, 3000);
        // }
        setTimeout(() => {
          this.showEmailLoader =false;
        console.log(this.frame)
        this.updateQuotation(this.stepper,1,this.frame)
        localStorage.setItem("EmailStatus",'0');
      }, 7000);
      
      }



  }
  public editQuoteDocs: any; fileType: any;
  //---------------------------- GET DOCUMENTS DATA FOR RETRIEVE -----------------------------------------------------------//
  getDataForEditQuoteDocs(quoteNumber) {

    this.motorQuoteService.getDataForEditQuoteDoc(quoteNumber).subscribe(res => {
      this.editQuoteDocs = res.getDataForEditQuoteDoc;

      this.editQuoteDocs.forEach((item, index) => {
        this.fileType = item.DocFileName.split(".");
        this.fileType = this.fileType[this.fileType.length - 1];

        if (item.DocumentType == "drivingLicenseFront") {
          this.licFrontLoader = this.globalService.spinnerHide();
          this.additionalDetailForm.get('driving_License_Front').setValue('1');
          if (this.fileType == 'pdf') {
            this.additionalDetailForm.get('driving_license_BackFileType').setValue('PDF');
            this.additionalDetailForm.get('driving_License_FrontFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
          }
          else {
            this.additionalDetailForm.get('driving_license_BackFileType').setValue('IMG');
            this.additionalDetailForm.get('driving_License_FrontFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
          }
        }

        if (item.DocumentType == "drivingLicenseBack") {
          this.licBackLoader = this.globalService.spinnerHide();
          this.additionalDetailForm.get('driving_license_Back').setValue('1');
          if (this.fileType == 'pdf') {
            this.additionalDetailForm.get('driving_License_FrontFileType').setValue('PDF');
            this.additionalDetailForm.get('driving_license_BackFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
          }
          else {
            this.additionalDetailForm.get('driving_License_FrontFileType').setValue('IMG');
            this.additionalDetailForm.get('driving_license_BackFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
          }
        }

        if (item.DocumentType == "RegCardFront") {
          this.regFrontLoader = this.globalService.spinnerHide();
          this.additionalDetailForm.get('Reg_Card_Front').setValue('1');
          if (this.fileType == 'pdf') {
            this.additionalDetailForm.get('Reg_Card_FrontFileType').setValue('PDF');
            this.additionalDetailForm.get('Reg_Card_FrontFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
          }
          else {
            this.additionalDetailForm.get('Reg_Card_FrontFileType').setValue('IMG');
            this.additionalDetailForm.get('Reg_Card_FrontFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
          }
        }

        if (item.DocumentType == "RegCardBack") {
          this.regBackLoader = this.globalService.spinnerHide();
          this.additionalDetailForm.get('Reg_Card_Back').setValue('1');
          if (this.fileType == 'pdf') {
            this.additionalDetailForm.get('Reg_Card_BackFileType').setValue('PDF');
            this.additionalDetailForm.get('Reg_Card_BackFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
          }
          else {
            this.additionalDetailForm.get('Reg_Card_BackFileType').setValue('IMG');
            this.additionalDetailForm.get('Reg_Card_BackFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
          }
        }

        if (item.DocumentType == "EIDFront") {
          this.eidFrontLoader = this.globalService.spinnerHide();
          this.additionalDetailForm.get('EID_Front').setValue('1');
          if (this.fileType == 'pdf') {
            this.additionalDetailForm.get('EID_FrontFileType').setValue('PDF');
            this.additionalDetailForm.get('EID_FrontFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
          }
          else {
            this.additionalDetailForm.get('EID_FrontFileType').setValue('IMG');
            this.additionalDetailForm.get('EID_FrontFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
          }
        }

        if (item.DocumentType == "EIDBack") {
          this.eidBackLoader = this.globalService.spinnerHide();
          this.additionalDetailForm.get('EID_Back').setValue('1');
          if (this.fileType == 'pdf') {
            this.additionalDetailForm.get('EID_BackFileType').setValue('PDF');
            this.additionalDetailForm.get('EID_BackFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
          }
          else {
            this.additionalDetailForm.get('EID_BackFileType').setValue('IMG');
            this.additionalDetailForm.get('EID_BackFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
          }
        }
      });



    });

  }
  //----------------------------------------VALIDATION FOR AGE ------------------------------------------------//
  calucateAge(inputData, type) {

    let timeDiff = Math.abs(Date.now() - inputData);
    if (type == 1) {
      this.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    }

    if (type == 2) {
      this.UAELicAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    }

  }

  renewPolicyData() {

    if (this.policyRenewForm.invalid) {
      this.validtnMsg.policyRenew = true;
      return false;
    }

    //this.showHideLoader.policyRenew = true;


  }

  getPlateCode(regPlaceId, type) {

    let plateSource = this.retrieveQuoteForm.value.web_Quote_Num == '' ? regPlaceId : this.quoteDetail.regplace;

    if(typeof (plateSource) != undefined && typeof (plateSource) != "undefined" ){
    this.motorQuoteService.getPlateCode(this.language_code, plateSource).subscribe(res => {

      this.plateCodeArray = res.plateCodeData;

      if (type == 2 && typeof (this.plateCodeArray) != undefined && typeof (this.plateCodeArray) != "undefined") {
        //Plate COde
        let plateCode = this.quoteDetail.plateCode;

        var indexPlCode = this.plateCodeArray.findIndex(function (obj, k) {
          return obj.PlateCode === plateCode;
        });
        let plCodeVal = this.plateCodeArray[indexPlCode];
        this.additionalDetailForm.get('plateCode').setValue(plCodeVal);
      }
    });

  }


  }

  resetAllForms() {

    this.personalDetailForm.get('name').setValue('');
    this.personalDetailForm.get('companyName').setValue('');
    this.personalDetailForm.get('dob').setValue('');
    this.personalDetailForm.get('email').setValue('');
    this.personalDetailForm.get('mobileNum').setValue('');
    this.personalDetailForm.get('drivExpInUAE').setValue('');
    this.personalDetailForm.get('vhclOwnBy').setValue('');
    // this.personalDetailForm.get('mobileCode').setValue('');
    this.personalDetailForm.get('NCD').setValue('');
    this.personalDetailForm.get('licIssueContry').setValue('');

    this.vehicleDetailForm.get('vhclValue').setValue('');
    this.vehicleDetailForm.get('regDate').setValue('');
    this.vehicleDetailForm.get('brandNw').setValue('');
    this.vehicleDetailForm.get('vhclGCCSpecifird').setValue('');
    this.vehicleDetailForm.get('vhclModified').setValue('');
    this.vehicleDetailForm.get('vhclValue').setValue('');
    this.vehicleDetailForm.get('vhclMake').setValue('');
    this.vehicleDetailForm.get('vhclModel').setValue('');
    this.vehicleDetailForm.get('vhclModelYr').setValue('');
    this.vehicleDetailForm.get('regplace').setValue('');
    this.vehicleDetailForm.get('insType').setValue('');
    this.vehicleDetailForm.get('vhclCylinder').setValue('');
    this.vehicleDetailForm.get('vhclSeatCapcity').setValue('');
    this.vehicleDetailForm.get('vhclTPLCoverage').setValue('');

  }


  termsAndConditions(frame,type) {
    this.conditionType=type;
    if(type == 1){
      if (this.vehicleDetailForm.value.chassisNum == '') {

        this.additionalDetailForm.controls['chassisNum'].setValidators([Validators.required]);
        this.additionalDetailForm.controls['isChassisValidate'].setValidators([Validators.required]);
      }
      else {
  
        this.additionalDetailForm.controls['chassisNum'].setValidators(null);
        this.additionalDetailForm.controls['isChassisValidate'].setValidators(null);
      }
  
      this.additionalDetailForm.controls['chassisNum'].updateValueAndValidity();
      this.additionalDetailForm.controls['isChassisValidate'].updateValueAndValidity();
  
  
      if (this.additionalDetailForm.invalid) {
  
        this.validtnMsg.additionalDetail = true;
        return false;
      }
  
      if (this.invalidEID == true) {
  
        return;
      }
      frame.show();
      this.getTermsConditions();
    }
    else if( type ==2){
      if (this.vehicleDetailForm.value.chassisNum == '') {

        this.additionalDetailForm.controls['chassisNum'].setValidators([Validators.required]);
        this.additionalDetailForm.controls['isChassisValidate'].setValidators([Validators.required]);
      }
      else {
  
        this.additionalDetailForm.controls['chassisNum'].setValidators(null);
        this.additionalDetailForm.controls['isChassisValidate'].setValidators(null);
      }
  
      this.additionalDetailForm.controls['chassisNum'].updateValueAndValidity();
      this.additionalDetailForm.controls['isChassisValidate'].updateValueAndValidity();
  
  
      if (this.additionalDetailForm.invalid) {
  
        this.validtnMsg.additionalDetail = true;
        return false;
      }
  
      if (this.invalidEID == true) {
  
        return;
      }
  
        this.saveAdditionalInfo(3);
    

    }
   

  }

  checkTermsCond(stepper) {
    this.accept_terms = !this.accept_terms;

  }


  checkChassisNo() {

    this.check_chassis_No = !this.check_chassis_No;

    if (this.check_chassis_No == true) {

      this.vehicleDetailForm.controls['chassisNum'].setValidators([Validators.required]);
      this.vehicleDetailForm.controls['isChassisValidate'].setValidators([Validators.required]);
    }
    else {
      this.vehicleDetailForm.get('chassisNum').setValue('');
      this.vehicleDetailForm.controls['chassisNum'].setValidators(null);
      this.vehicleDetailForm.controls['isChassisValidate'].setValidators(null);
    }

    this.vehicleDetailForm.controls['chassisNum'].updateValueAndValidity();
    this.vehicleDetailForm.controls['isChassisValidate'].updateValueAndValidity();
  }
  nonEditableFields(type) {
    //this.vehicleDetailForm.value.chassisNum != null && this.vehicleDetailForm.value.vhclGCCSpecifird != null && this.vehicleDetailForm.value.vhclTrim != undefined && this.vehicleDetailForm.value.vhclBodyType  != null && this.vehicleDetailForm.value.vhclCylinder  != null && this.vehicleDetailForm.value.vhclSeatCapcity  != null

    if (this.vehicleDetailForm.value.chassisNum != '' && this.vehicleDetailForm.value.isChassisValidate == 1) {
      let vhclMake = localStorage.getItem("vehcl_make");
      let vhclModel = localStorage.getItem("vehcl_model");
      let vhclModelYear = localStorage.getItem("vehcl_model_year");
      let vhclTrim = localStorage.getItem("vehcl_trim");
      let vhclTrimVal = localStorage.getItem("vehcl_trim_val");
      let vhclBody = localStorage.getItem("vehcl_body");
      let gccSpecification = localStorage.getItem("GCC_Specification");


      if ((vhclMake != '' || vhclMake != null) && type == 1) {

        return true;
      }

      else if ((vhclModel != '' || vhclModel != null) && type == 2) {

        return true;
      }

      else if ((vhclModelYear != '' || vhclModelYear != null) && type == 3) {

        return true;
      }

      else if ((vhclTrim != '' || vhclTrim != null) && (vhclTrimVal == '' || vhclTrimVal == null) && type == 4) {

        return true;
      }

      else if ((vhclBody != '' || vhclBody != null) && type == 5) {

        return true;
      }

      else if ((gccSpecification != '' || gccSpecification != null) && type == 6) {

        return true;
      }

      else
        false;

    }

    //  if(type == 1 && this.vehicleDetailForm.value.chassisNum != '' && this.vehicleDetailForm.value.vhclGCCSpecifird != '' && this.vehicleDetailForm.value.vhclTrim != undefined && this.vehicleDetailForm.value.vhclBodyType  != '' && this.vehicleDetailForm.value.vhclCylinder  != '' && this.vehicleDetailForm.value.vhclSeatCapcity  != ''){
    //       return true;
    //   }

    //   else{
    //     return false;
    //   }
  }
  callSteps(stepper, e) {

    // console.log(e);
    if (e == 1) {
      stepper.selectedIndex = 0;


    }


  }

  public showTerms :boolean = false;
  getTermsConditions(){

        this.showTerms = true;
        this.motorQuoteService.getTermsConditions('B2C').subscribe(res =>{
              if(res.response_code == 1){
                this.showTerms = false;
                this.termsAndCondition = res.termsAndCondition;
              }
                
        });
  }

  togglefloating(){
    this.showFloating=!this.showFloating;
    this.showFloatingcloseicon=!this.showFloatingcloseicon;
    this.showFloatingopenicon=!this.showFloatingopenicon;
  }
  togglepremiumfloating(){
  this.showpremiumFloating=!this.showpremiumFloating;
  this.premiumcloseicon=!this.premiumcloseicon;
  this.premiumopenicon=!this.premiumopenicon;
}
 checkSelfDeclarationCondition(type,event){
      if(type == 1){
         this.accept_self_declaration_1 = event.checked;
      }

      if(type == 2){
         this.accept_self_declaration_2 = event.checked;
      }

      if(type == 3){
        this.accept_self_declaration_3 = event.checked;
      } 

     if(type == 4){
        this.accept_self_declaration_4 = event.checked;
     } 
   
    if(type == 5){
       this.accept_self_declaration_5 = event.checked;
    }
      
      
  }

  continueOnSelfDeclarion(stepper,type, frame){
          if(this.webQuoteNumber == ''){
                this.getQuotation(stepper,1, frame);
          }else{
                this.updateQuotation(stepper,1, frame);
          }
  }
    //Added by Anju for Get  Quote flow without decalaration
    continueOnCreateQuotation(stepper) {
        if (this.webQuoteNumber == '') {
            this.getQuotation(stepper, 1, '');
        } else {
            this.updateQuotation(stepper, 1, '');
        }
    }
  // openSelfDeclartnModal(frame){
  //        frame.show();
  // }

  selfDeclarationDiscount:number;

  getDiscountOnSelfDeclaration(){
       this.motorQuoteService.getDiscountOnSelfDeclaration(this.webQuoteNumber,'MT').subscribe(res =>{

        // console.log("-----");
            this.selfDeclarationDiscount = res.PromoDiscount;
            // console.log(this.selfDeclarationDiscount);
            this.specialDiscountAmt = res.SpecialDiscount ;
            // this.specialDiscount();
            // console.log("-------");
            // console.log(this.selfDeclarationDiscount);
            const  copy = JSON.parse(JSON.stringify(this.tempBenefitData));
            
            let base_Primium = copy;
            let  discountAmt = 0;
            this.PlanDataJson.planBenefitData.benefit_data = this.tempBenefitData;
            this.totalFixPremium = this.tempTotalLoadFixPrem;
            // console.log(this.totalFixPremium);
            let discount:number = Number(this.PlanDataJson.thirdPartyAPIData.NcdDiscount.NcdAmnt);
            let policyFee:number = 0 ;
            // console.log(discount);
            this.tempBenefitData.forEach((item, index) => {
                if (this.vehicleDetailForm.value.insType.value == 200 && item.Code == '40099') {
                    //get policy fee
                    policyFee = item.premium ;
                }
            });
            
                this.tempBenefitData.forEach((item, index) => {
            
                  // FOR COMPRENSIVE
                  if (this.vehicleDetailForm.value.insType.value == 100 && item.Code == '40001') {
              
                   let newBasePremium = this.totalFixPremium;
                 
                  
                    discountAmt = Math.round(((this.selfDeclarationDiscount * Number(newBasePremium)) / 100));   //FOR BY PERCENT
                        // console.log(discountAmt);
                    
                        let totalPrem:number = 0 ;
                        totalPrem += newBasePremium;
                        totalPrem += discount ;
                        totalPrem -= discountAmt;
                       
                              if(Number(base_Primium[1].premium) > Number(base_Primium[0].premium)){ 
                                    totalPrem -= parseInt(base_Primium[0].premium);   
                              }else{
                                    totalPrem -= parseInt(base_Primium[1].premium);
                              }
                 
                              this.PlanDataJson.planBenefitData.benefit_data[index].premium = totalPrem;
                    
                  }
            
                  // FOR TPL
                  if (this.vehicleDetailForm.value.insType.value == 200 && item.Code == '40002') {
            
                    let newBasePremium = this.totalFixPremium;
                 
              
                          discountAmt = Math.round(((this.selfDeclarationDiscount * Number(newBasePremium)) / 100));  //FOR BY PERCENT
                          //  console.log(discountAmt);discountAmt
                            let totalPrem:number = 0 ;
                          
                            if(this.PlanDataJson.planBenefitData.benefit_data[index].Code == 40002){
                                    totalPrem = parseInt(base_Primium[index].premium) - discountAmt;   
                            }
            
                              this.PlanDataJson.planBenefitData.benefit_data[index].premium = totalPrem;
                   
                  
                  }
            
                });
            
                this.totalFixPremium = Math.round(Number(this.totalFixPremium) - Number(discountAmt) - Number(this.specialDiscountAmt));
               
                this.VATAMT = (this.totalFixPremium + this.totalVariablePremium) * 0.05;
                this.Total_Primium = Number(this.totalFixPremium) + Number(this.totalVariablePremium) + Number(this.VATAMT);
                this.tempBenefitData =  copy  ;
          });
  }

  getSelfDeclarartionData(){
        this.motorQuoteService.getSelfDeclarartionData().subscribe(res =>{

        });
  }

// specialDiscount(){
//     this.motorQuoteService.getSpecialDiscount().subscribe(res =>{
//             if(res.response_code == 1){
//                 this.specialDiscountAmt = res.special_discount;
//             }
//     });
// }
// ------------FUNCTION FOR SEND PAYMENT LINK---------------------
sendPaymentLink()
{
  this.motorQuoteService.sendPaymentLink(this.webQuoteNumber,'MT','B2C',this.personalDetailForm.value.email).subscribe(res =>{
    this._route.navigate(["/motorquote"]);
    Swal.fire("", "Thank you for choosing Fidelity United for insuring your car. We have sent an email to your registered email with a payment link", 'success');

  });
}


// ---------------------------------------------Captcha Code---------------------------------------------------
Captcha(){
  var alpha = new Array('A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',
  'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z', 
      '0','1','2','3','4','5','6','7','8','9');
  var i;
  for (i=0;i<6;i++){
      var a = alpha[Math.floor(Math.random() * alpha.length)];
      var b = alpha[Math.floor(Math.random() * alpha.length)];
      var c = alpha[Math.floor(Math.random() * alpha.length)];
      var d = alpha[Math.floor(Math.random() * alpha.length)];
      var e = alpha[Math.floor(Math.random() * alpha.length)];
      var f = alpha[Math.floor(Math.random() * alpha.length)];
      var g = alpha[Math.floor(Math.random() * alpha.length)];
                   }
      var code = a + ' ' + b + ' ' + ' ' + c + ' ' + d + ' ' + e + ' '+ f + ' ' + g;
      // document.getElementById("mainCaptcha").innerHTML = code;
      console.log(code)
      console.log( this.personalDetailForm)
      this.personalDetailForm.get('recaptchaReactive').setValue(code);
      this.personalDetailForm.get('recaptchaReactiveByUser').setValue('');
      
      // document.getElementById("mainCaptcha").value = code
    }

    removeSpaces(string){
      return string.split(' ').join('');
    }

    ValidCaptcha(){
   
     var string1 = this.removeSpaces(this.personalDetailForm.value.recaptchaReactive);
     var string2 = this.removeSpaces(this.personalDetailForm.value.recaptchaReactiveByUser);
     if (string1 == string2){
      this.captchaValidation=false;
      this.captchasucess=true;
       // alert(this.Msg);
       this.Msg="You have Entered Valid Captcha";
       console.log(this.Msg)
            return true;
            
     }else{   
      this.captchaValidation=true;
      this.captchasucess=false;
       // alert(this.Msg); 
       this.Msg="Please  Enter Valid Captcha !"  ; 
       console.log(this.Msg) 
          return false;
          }
          
}

}
