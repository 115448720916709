
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { GlobalService } from '../../_service/global.service';
import { MotorquoteService } from '../../_service/motorquote.service';
import { DateAdapter, MatStepper, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { UserauthService } from '../../_service/userauth.service';
import Swal from 'sweetalert2';
// import { AppDateAdapter, APP_DATE_FORMATS } from '../../motorquote/date.adapter';
import { Router, ActivatedRoute } from '@angular/router';
import { config } from '../../config';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material';
import { take, takeUntil } from 'rxjs/operators';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { AppDateAdapter, APP_DATE_FORMATS } from '../../motorquote/date.adapter';
import { CompressImageService } from '../../_service/compressimage.service';
interface InsVehiBy {
  value: string;
  viewValue: string;
  code: string;
}
interface Code {
  value: string;
  viewValue: string;
}

interface DrivExp {
  value: string;
  viewValue: string;
}

interface VehiTrim {
  value: string;
  viewValue: string;
}
interface VehiModelYr {
  value: string;
  label: string;
}
interface BrandNew {
  value: string;
  viewValue: string;
}
interface VehiSeatCap {
  value: string;
  viewValue: string;
}
interface VehiCylinder {
  value: string;
  viewValue: string;
  code: string;
}
interface InsType {
  value: string;
  viewValue: string;
  WebProdCode: string;
  CoreProdCode: string;
}
interface YesNo {
  value: string;
  viewValue: string;
}
interface RepairType {
  value: string;
  viewValue: string;
}
interface MotBankNam {
  value: string;
  viewValue: string;
}
interface Gender {
  value: string;
  viewValue: string;
}
interface ExpiryMonth {
  value: string;
  viewValue: string;
}

interface Country {
  id: number;
  CountryName: string;
  Active: boolean;
  CountryCode: string;
  CurrencyCode: string;
  CRS_NATION_CODE: number;
  CRS_Country_Map_Code: number;
}

interface Make {
  VehicleMakeId: number;
  VehicleMakeName: string;
  Active: boolean;
  IsReferral: number;
  CRS_VEH_CODE: number;
}

interface Model {

  VehicleModelId: number;
  VehicleModelName: string;
  Active: boolean;
  SeatingCapacityExDriver: number;
  BodyTypeId: number;
  CRS_MODEL_CODE: number;
  EDATA_MODEL_CODE: string;
}

interface PlateCategory {

  label: string;
  value: string;
}

interface VichleColor {

  ColorId: number;
  ColorName: string;
  CRS_COLOR_CODE: string;
}

interface MortageBank {

  Id: number;
  InstituteName: string;
  InstituteCode: string
  CRS_Bank_Map_Code: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};
import {
  state,
  style,
  transition,
  animate,
  trigger,
  AUTO_STYLE
} from "@angular/animations";
import { findIndex } from 'rxjs-compat/operator/findIndex';

@Component({
  selector: 'app-withocr',
  templateUrl: './withocr.component.html',
  styleUrls: ['./withocr.component.scss'],
  providers: [
    // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
    // { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          transform: "translate3d(0, 0, 0)"
        })
      ),
      state(
        "out",
        style({
          transform: "translate3d(100%, 0, 0)"
        })
      ),
      transition("in => out", animate("400ms ease-in-out")),
      transition("out => in", animate("400ms ease-in-out"))
    ]),
    trigger("slideOnOff", [
      state(
        "on",
        style({
          transform: "translate3d(0, 0, 0)"
        })
      ),
      state(
        "off",
        style({
          transform: "translate3d(100%, 0, 0)"
        })
      ),
      transition("on => off", animate("400ms ease-in-out")),
      transition("off => on", animate("400ms ease-in-out"))
    ]),
    trigger("visibility", [
      state(
        "shown",
        style({
          opacity: 1
        })
      ),
      state(
        "hidden",
        style({
          opacity: 0
        })
      ),
      transition("* => *", animate(".25s"))
    ]),
    trigger("mobileMenuTop", [
      state(
        "no-block, void",
        style({
          overflow: "hidden",
          height: "0px"
        })
      ),
      state(
        "yes-block",
        style({
          height: AUTO_STYLE
        })
      ),
      transition("no-block <=> yes-block", [animate("400ms ease-in-out")])
    ])
  ],
})



export class WithocrComponent implements OnInit {


  /****************** selection search country ***********/



  /** control for the MatSelect filter keyword  */
  public country1FilterCtrl: FormControl = new FormControl();
  public nationalFilterCtrl: FormControl = new FormControl();
  public makeFilterCtrl: FormControl = new FormControl();
  public modelFilterCtrl: FormControl = new FormControl();

  public yearFilterCtrl: FormControl = new FormControl();
  public plateFilterCtrl: FormControl = new FormControl();
  public vchColorFilterCtrl: FormControl = new FormControl();
  public mortageBankFilterCtrl: FormControl = new FormControl();
  public codeFilterCtrl: FormControl = new FormControl();






  /** list of banks filtered by search keyword */
  public filteredCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
  public filteredNationCountries: ReplaySubject<Country[]> = new ReplaySubject<Country[]>(1);
  public filteredMakes: ReplaySubject<Make[]> = new ReplaySubject<Make[]>(1);
  public filteredModels: ReplaySubject<Model[]> = new ReplaySubject<Model[]>(1);
  public filteredYears: ReplaySubject<VehiModelYr[]> = new ReplaySubject<VehiModelYr[]>(1);
  public filteredPlateCat: ReplaySubject<PlateCategory[]> = new ReplaySubject<PlateCategory[]>(1);
  public filteredVchColor: ReplaySubject<VichleColor[]> = new ReplaySubject<VichleColor[]>(1);
  public filteredBank: ReplaySubject<MortageBank[]> = new ReplaySubject<MortageBank[]>(1);
  public filteredCode: ReplaySubject<Code[]> = new ReplaySubject<Code[]>(1);


  paymentMode ="ONL";


  @ViewChild('stepper', { static: true })stepper:MatStepper
  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
 
  document_data: any;
  docUploadForm: FormGroup;
  tempDocArray: any = [];
  vehicleData: any = [];
  nofDoors: any;
  fileUploadFlag: boolean;
  public chnageData = {
    rg_vhcl_make: ''
  }
  checkChassisValidation: boolean;
  GCC_Specification: any = '';
  expandedDetails: boolean =true;
  item: any = [
    {
      number: 1, 
      open: true
    },];
  emailId: any;
  mobileNo: any;
  quotationumber: any;
  reg_card_front_flag: boolean =false;
  reg_card_back_flag: boolean =false;
  driving_Lic_Front_flag: boolean =false;
  driving_Lic_Back_flag: boolean =false;
  checkreg_Card_Front_image: boolean;
  checkreg_Card_Back_image: boolean;
  checkdriving_Lic_Front_image: boolean;
  checkdriving_Lic_Back_image: boolean;
  stepper1: any;
  ProgressModel: any;
  type: any;
  docProperty: string; // Added by Anju for OCR API Integration 
  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'Y';
    }

    return value;
  }

  vehicleValueLimit = { isSet: false, startLimit: 0, mediumLimit: 0, endLimit: 0 }
  insvehibys: any = [
    { value: 'Individual', viewValue: 'Individual', code: '100' },
    { value: 'Corporate', viewValue: 'Corporate', code: '200' },
  ];
  codes: Code[] = [
    { value: '050', viewValue: '050' },
    { value: '052', viewValue: '052' },
    { value: '054', viewValue: '054' },
    { value: '055', viewValue: '055' },
    { value: '056', viewValue: '056' },
    { value: '057', viewValue: '057' },
    { value: '058', viewValue: '058' },
  ];

  drivexps: DrivExp[] = [
    { value: 'Yes', viewValue: 'Yes' },
    { value: 'No', viewValue: 'No' },
  ];

    vehimodelyrs: VehiModelYr[] = [
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
    { value: '2021', label: '2021' },
    { value: '2020', label: '2020' },
    { value: '2019', label: '2019' },
    { value: '2018', label: '2018' },
    { value: '2017', label: '2017' },
    { value: '2016', label: '2016' },
    { value: '2015', label: '2015' },
    { value: '2014', label: '2014' },
    { value: '2013', label: '2013' },
    { value: '2012', label: '2012' },
    { value: '2011', label: '2011' },
    { value: '2010', label: '2010' },
    { value: '2009', label: '2009' },
    { value: '2008', label: '2008' },
    { value: '2007', label: '2007' },
    { value: '2006', label: '2006' },
    { value: '2005', label: '2005' },
    { value: '2004', label: '2004' },
    { value: '2003', label: '2003' },
    { value: '2002', label: '2002' },
    { value: '2001', label: '2001' },
    { value: '2000', label: '2000' },
  ];
  brandnews: BrandNew[] = [
    { value: 'Yes', viewValue: 'Yes' },
    { value: 'No', viewValue: 'No' },
  ];
  vehiseatcaps: any = [
    { value: '1', viewValue: '1' },
    { value: '3', viewValue: '3' },
    { value: '4', viewValue: '4' },
    { value: '5', viewValue: '5' },
    { value: '6', viewValue: '6' },
    { value: '7', viewValue: '7' },
    { value: '8', viewValue: '8' },
  ];
  vehicylinders: VehiCylinder[] = [
    { value: '4', viewValue: '4 Cylinder', code: '1004' },
    { value: '6', viewValue: '6 Cylinder', code: '1006' },
    { value: '8', viewValue: '8 Cylinder', code: '1008' },
    { value: '10', viewValue: '10 Cylinder', code: '1010' },
    { value: '12', viewValue: '12 Cylinder', code: '1012' },
  ];
  instypes: InsType[] = [
    { value: '100', viewValue: 'Comprehensive', WebProdCode: 'MT00061', CoreProdCode: '4001_1' },
    { value: '200', viewValue: 'Third Party Liability', WebProdCode: 'MTTPL0065', CoreProdCode: '4021' },
  ];
  yesnos: YesNo[] = [
    { value: 'Yes', viewValue: 'Yes' },
    { value: 'No', viewValue: 'No' },
  ];

  repairtypes: RepairType[] = [
    { value: 'Agency', viewValue: 'Agency' },
    { value: 'Garage', viewValue: 'Garage' },
  ];

  motbanknams: MotBankNam[] = [
    { value: 'MBN', viewValue: 'ABC Bank' },
    { value: 'MBN', viewValue: 'DEF Bank' },
    { value: 'MBN', viewValue: 'GHI Bank' },
  ];
  genders: Gender[] = [
    { value: 'M', viewValue: 'Male' },
    { value: 'F', viewValue: 'Female' },
  ];
  expmonths: ExpiryMonth[] = [
    { value: 'EXPMON', viewValue: 'January' },
    { value: 'EXPMON', viewValue: 'February' },
    { value: 'EXPMON', viewValue: 'March' },
    { value: 'EXPMON', viewValue: 'April' },
    { value: 'EXPMON', viewValue: 'May' },
    { value: 'EXPMON', viewValue: 'June' },
    { value: 'EXPMON', viewValue: 'July' },
    { value: 'EXPMON', viewValue: 'August' },
    { value: 'EXPMON', viewValue: 'September' },
    { value: 'EXPMON', viewValue: 'October' },
    { value: 'EXPMON', viewValue: 'November' },
    { value: 'EXPMON', viewValue: 'December' },
  ];

  NCDArr = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5+', label: '5+' },

  ]

  activePolicyTypeArr = [
    { value: 'Yes', label: 'No  Insurance' },
    { value: 'No', label: 'Comprehensive' },
    { value: 'Yes', label: 'Third Party Liability' }

  ];

  plateCatArray: PlateCategory[] = [
    { label: "Private", value: "Private" },
    { label: "Police", value: "Police" },
    { label: "Public (Driving Learning)", value: "Public (Driving Learning)" },
    { label: "Protocol", value: "Protocol" },
    { label: "Public (Transport/Taxi)", value: "Public (Transport/Taxi)" },
    { label: "Trade Plate", value: "Trade Plate" },
    { label: "Export", value: "Export" },
    { label: "Diplomatic Association", value: "Diplomatic Association" },
    { label: "International Organization", value: "International Organization" },
    { label: "Delegate", value: "Delegate" },
    { label: "Classical", value: "Classical" },
    { label: "Others", value: "Others" },
  ];

  transactionTypeArray = [
    {value: '0', label: 'New Vehicle'},
    {value: '1', label: 'Renewal'},
    {value: '2', label: 'Change in Ownership'},
  ]



  maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
  minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 71));
  UAELicMinDate = new Date(new Date().setFullYear(new Date().getFullYear() - 50));
  policyMinDate = new Date(Date.now());

  policyMaxDate = new Date(new Date().setDate(new Date().getDate() + 13));
  regMinDate = new Date(2000, 0, 1);
  regMaxDate = new Date(2023, 0, 1);// Updated regmax date to 2023 by Anju
  reg_exp_min_date = new Date(Date.now());
  reg_exp_max_date = new Date(new Date().setDate(new Date().getDate() + 30));
  prevInsMaxDate = new Date(new Date().setMonth(new Date().getMonth() + 2));


  //DECLARE FORMGROUPS
  personalDetailForm: FormGroup; vehicleDetailForm: FormGroup; coverDetailForm: FormGroup; additionalDetailForm: FormGroup; retrieveQuoteForm: FormGroup;
  quotationForm: FormGroup; policyRenewForm: FormGroup; public subscriptions: Subscription[] = [];

  //DECLARE ARRAYS
  vhclMakeArr: Make[]; countryArr: Country[]; vhclColorArr: VichleColor[]; cityArr: any = []; vhclModelArr: Model[]; vhclBodyTypeArr: any = []; vehitrims: any = []; tempRepairTypeArr: any = [];
  coverData: any = []; NCDData: any = []; benfPremIdArray: any = []; savePlanDetailArr: any = []; quoteDetailArr: any = []; bankDetail: MortageBank[]; quoteDetailDataForCUst: any = []; plateCodeArray: any = [];
  public termsAndCondition :any = []; repairs: any[]; tempTransactionTypeArr : any = []; industryTypeArr: any = []; retrieveData: any = []; tempBenefitData:any = []; selfDeclarartionArr :any = []; selfDeclQueArr:any = [];

  //DECLARE VARIABLES
  public policy_type: any; reg_type: any; vehicle_mk_id: any; language_code = 'ENG'; country = 'United Arab Emirates'; selectedCover: any; notSelectedCover: any; checkedOptCover: boolean; no_Of_Doors: any;
  checked: boolean; coverPremium: any; validChassisNum: boolean = false; webQuoteNumber: any = ''; quoteNumber: any; invalidEID: boolean = false; value: any = 0; plan_Name: any; plan_Id: any;
  paymentLoader: boolean = false; totalPremiumeithDisc: any; validtnMsg: boolean = true; docNameType: any; mulOption: any; quoteDetail: any; public policyFee: number; vehicleValue: any = '';
  licFrontLoader: boolean = false; licBackLoader: boolean = false; regFrontLoader: boolean = false; regBackLoader: boolean = false; eidFrontLoader: boolean = false; eidBackLoader: boolean = false;
  delQuoteLoader: boolean = false; otherDocLoader: boolean = false; selected = new FormControl(0); minTCF: any; maxTCF: any; blankChassis: boolean = false; age: any; UAELicAge: any;
  accept_terms: boolean = false; chassis_No_Details: any; check_chassis_No: boolean = false; selectedStepper = new FormControl(0); ret_quote_num: any = ''; VATAMT: any;
  Total_Primium: any;  getVechileDetailData: any; tempTotalFixPremium: number;  totalFixPremium: number = 0; totalVariablePremium: number = 0;  multipleImg: any = ''; ImagedocumentType: any; showAllDocImg: any = 0;
  totalDiscount: number = 0; totalPreimum: number = 0; product_code = "MT00061"; public checkStepper: boolean = false; emailID: any; mobile_No: any; code: any; optionalBenefit: any; vhclModelYearVal:any = ''; retrieveFlag:boolean = false;
  vhcleValueFlag :boolean = false; retrieveVehcleMake:any; SchemeCode:any = 'ALL'; insuredDetailForm:FormGroup; retrieve_repair_type = ''; quoteStatus: any;
  floatingSelectedIndex: any; vehicle_age:any; selfDeclarationDiscount:any; tempTotalLoadFixPrem:any; public trans_ref:any; selfDeclLevel1 :any; selfDeclLevel2:any;   specialDiscountAmt:any;
  accept_self_declaration_1:boolean = false; accept_self_declaration_2:boolean = false;accept_self_declaration_3:boolean = false;
  accept_self_declaration_4:boolean = false; accept_self_declaration_5:boolean = false;
  public showFloatingopenicon:boolean=true;
  public premiumopenicon:boolean=true;
  public showFloatingcloseicon:boolean=false;
  public premiumcloseicon:boolean=false;
  public showFloating :boolean=true;
  public showpremiumFloating :boolean=true;
  //DATES
  toDay = new Date(Date.now());
  minyear = new Date().getFullYear() - 1;
  maxYear = new Date().getFullYear() + 1;
  year = new Date().getFullYear();
  nextDate = new Date(Date.now() + 1);
  PrevDate = new Date(Date.now() - 2);

  public showLoader = {
    reg_card_front: false,
    reg_card_back: false,
    emirates_Id_front: false,
    emirates_Id_back: false,
    driving_Lic_front: false,
    driving_Lic_back: false,
    Quotation: false,
    VehicleValue: false,
    vhclMake: false,
    vhclModel: false,
    vhclTrim: false,
    vhclBodyType: false,
    retrieveQuote: false,
    multipleDoc: false,
    supporting_Document: false,
    chasisNoButton: false,
    additionalDoc: false,
    referal: false,
    trade_Licence : false,
    TRN_Certificate : false,
  }

  validtnMsg1 = {
    personalDetail: false,
    vehicleDetail: false,
    coverDetail: false,
    Quotation: false,
    additionalDetail: false,
    retrieveQuote: false,
    policyRenew: false

  }

  iSRetriveQuote: boolean;
  editableStep = { personalDetail: true, vehicleDetail: true, coverDetail: true, Quotation: true, additionalDetail: true }
  constructor(public _route: Router, public _activatedroute: ActivatedRoute, public formBuilder: FormBuilder, public globalService: GlobalService, public motorQuoteService: MotorquoteService, public userAuthService: UserauthService,private compressImage: CompressImageService) { }

public hideImages = {
  reg_card_front: false,
  reg_card_back: false,
  emirates_Id_front: false,
  emirates_Id_back: false,
  driving_Lic_front: false,
  driving_Lic_back: false,
}

  ngOnInit() {
  
    this.licFrontLoader = this.globalService.spinnerHide();
    this.licBackLoader = this.globalService.spinnerHide();
    this.regFrontLoader = this.globalService.spinnerHide();
    this.regBackLoader = this.globalService.spinnerHide();
    this.eidFrontLoader = this.globalService.spinnerHide();
    this.eidBackLoader = this.globalService.spinnerHide();
    this.delQuoteLoader = this.globalService.spinnerHide();
    this.otherDocLoader = this.globalService.spinnerHide();

    this.iSRetriveQuote = false;
    this.tempTransactionTypeArr = this.transactionTypeArray;

    this.docUploadForm = this.formBuilder.group({
      docRegCardFront: [''],
      reg_Card_Front:[''],
      reg_Card_FrontFilePath: ['',[Validators.required]],
      reg_Card_FrontFileType: '',

      docRegCardBack: [''],
      reg_Card_Back: [''],
      reg_Card_BackFilePath: ['',[Validators.required]],
      reg_Card_BackFileType: '',

      docEmiratedIdFront: [''],
      emirated_ID_Front: [''],
      emirated_ID_FrontFilePath: '',
      emirated_ID_FrontFileType: '',

      docEmiratedIdBack: [''],
      emirated_ID_Back: [''],
      emirated_ID_BackFilePath: '',
      emirated_ID_BackFileType: '',

      docDrivingLicFront: [''],
      driving_Lic_Front: [''],
      driving_Lic_FrontFilePath: ['',[Validators.required]],
      driving_Lic_FrontFileType: '',

      docDrivingLicBack: [''],
      driving_Lic_Back: [''],
      driving_Lic_BackFilePath: ['',[Validators.required]],
      driving_Lic_BackFileType: '',

        
    });

    this.insuredDetailForm = this.formBuilder.group({

      // reg card front form fields
      rg_model_year: ['', [Validators.required]],
      rg_num_passenger: ['', [Validators.required]],
      rg_origin: [''],
      rg_vhcl_make: ['', [Validators.required]],
      rg_vhcl_model: ['', [Validators.required]],
      rg_gvm: [''],
      rg_engin_num: ['',[Validators.required]],
      rg_chassis_num: ['',[Validators.required]],
      // reg card back form fields
      rg_traffic_plate_num: [''],
      rg_place_issue: [ '', [Validators.required]],
      rg_TC_num: ['', [Validators.required]],
      rg_type: ['', [Validators.required]],
      rg_plateCode: [''],
      rg_expiry_date: ['', [Validators.required]],
      rg_reg_date: ['', [Validators.required]],
      rg_ins_exp: ['', [Validators.required]],
      rg_policy_num: [''],
      rg_mortgage: [''],
      // eid front form fields
      e_eid: [''],
      e_name: ['',[Validators.required]],
      e_nationality: [''],
      e_cardNumber: [''],
      e_expiryDate: [''],
      e_gender: ['',[Validators.required]],
      e_arabic_name: [''],
      // eid back form fields
    
      // driving lic front form fields
      d_driv_lic_num: [''],
      d_dob: [''],
      d_issue_date: [''],
      d_expiry_date: [''],
      d_place_issue: [''],
      d_TC_number: [''],
      d_lic_type: [''],

      // Additional detail fields /^((050|052|054|055|056|057|058|059){1}([0-9]{7}))$/   //'[050|052|054|055|056|057|058|059]{1}[0-9]{7}'
      adtnl_detail_email: ['',Validators.compose([Validators.required,Validators.pattern('[a-zA-Z0-9.-_-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')])],
      // adtnl_detail_mbCode: ['', [Validators.required]],
      adtnl_detail_mobile: ['',Validators.compose([Validators.required,Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^((050|052|054|055|056|057|058|059){1}([0-9]{7}))$/)])],
      adtnl_detail_NCD: ['', [Validators.required]],
      adtnl_detail_repairTye: [''],
      adtnl_detail_vhclValue: [''],
      adtnl_detail_trim: ['', [Validators.required]],
      adtnl_detail_bodyType: ['', [Validators.required]],
      adtnl_detail_cylinder: ['', [Validators.required]],
      adtnl_detail_gccSpecified: ['', [Validators.required]],
      adtnl_detail_vhclModified: ['', [Validators.required]],
      adtnl_detail_brandNew: [''],
      adtnl_detail_area: [''],
      adtnl_detail_poBoxNum: [''],
      adtnl_detail_insType: ['', [Validators.required]],
      isChassisValidate: [''],
      adtnl_detail_vhclOwnBy: ['', [Validators.required]],
      polStartDate: [new Date(Date.now()), Validators.required],
      vhclTPLCoverage: ['', [Validators.required]],
      promoCode: [''],
      vhclColor: [''],
      eIDCheck:['', [Validators.required]],
      CC : [''],
      loading_capacity : [''],
      deductible : [''],

      //For Corporate : insured vehicle own by
      TRN_num : [''],
      trade_lic_num : [''],
      industry_type : [''],
      vat_register : ['No'],
      transaction_type : ['',[Validators.required]],

      // Added Partner And Branch

      partner:[''],
      branch:[''],

    });

    this.quotationForm = this.formBuilder.group({
      quoteStepValdtn: ['', Validators.required]

    });

    this.additionalDetailForm = this.formBuilder.group({
      chassisNum: [''],
      vhclColor: ['', Validators.required],
      vhclPlateCategory: [''],
      plateCode: ['', Validators.required],
      plateNumber: ['', Validators.required],
      engineNum: [''],
      motgagedBankName: [''],
      polStartDate: [new Date(Date.now()), Validators.required],
      gender: ['', Validators.required],
      emiratesID: ['', [Validators.required]],
      nationality: ['', Validators.required],
      address: ['', [Validators.required, Validators.pattern('.*[A-Za-z0-9 -,#.)(]{5,200}$')]],
      area: ['', [Validators.required, Validators.pattern('.*[A-Za-z0-9 -,#.)(]{5,100}$')]],
      poBoxNum: [''],
      drivingLicense: ['', [Validators.required]],
      TCFnum: [''],

      isChassisValidate: ['']

      // otherForm: ['',Validators.required]
    });

    this.retrieveQuoteForm = this.formBuilder.group({

      web_Quote_Num: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9.-_-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')])],
      mob_Num: ['', Validators.required],
      //isRetriveValidate : ['',Validators.required]


    });

    this.policyRenewForm = this.formBuilder.group({
      policyNum: ['', [Validators.required, Validators.maxLength(15), Validators.pattern('^[a-zA-Z0-9 \/\']+')]],
      polExpDate: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9.-_-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')])],
      code: ['', Validators.required],
      mobile: ['', Validators.required]

    });


    const statusModSub = this.globalService.connectionStatusChanged.subscribe(data => {

      if (data == 'online') {

        this.getTokenData();
      }
      else {
      }


    });

    this.subscriptions.push(statusModSub);

    this.getTokenData();

    if( this.SchemeCode == '106'){
      this.insuredDetailForm.get('adtnl_detail_insType').setValue( { value: '200', viewValue: 'Third Party Liability', WebProdCode: 'MTTPL0065', CoreProdCode: '4021' });
    }else {
      this.insuredDetailForm.get('adtnl_detail_insType').setValue( { value: '100', viewValue: 'Comprehensive', WebProdCode: 'MT00061', CoreProdCode: '4001_1' });
    }

    this.insuredDetailForm.get('adtnl_detail_brandNew').setValue('0');

    if (this.insuredDetailForm.value.adtnl_detail_brandNew == 1) {
     
      this.transactionTypeArray = [];
      this.transactionTypeArray.push(this.tempTransactionTypeArr[0]);
      this.insuredDetailForm.get('transaction_type').setValue(this.transactionTypeArray[0]);
  }else{
      this.transactionTypeArray = [];
      this.transactionTypeArray.push(this.tempTransactionTypeArr[1]);
      this.transactionTypeArray.push(this.tempTransactionTypeArr[2]);
      this.insuredDetailForm.get('transaction_type').setValue(this.transactionTypeArray[0]);
  }
  this.onChangeTransactionType(this.insuredDetailForm.value.transaction_type.label);

    const routeParam = this._activatedroute.snapshot.params;
    if (routeParam.Quote_No) {
      this.ret_quote_num = routeParam.Quote_No;
      // console.log(this.ret_quote_num)
    }

    const routeParam1 = this._activatedroute.snapshot.params;
    if (routeParam1.quoteNum !=null) {
    this.selected.setValue(1);

      this.quotationumber = routeParam1.quoteNum;
      this.emailId = routeParam1.emailId;
      this.mobileNo = routeParam1.mobileNo;
      this.retrieveQuoteForm.get('web_Quote_Num').setValue(this.quotationumber)
      this.retrieveQuoteForm.get('email').setValue(this.emailId)
      this.retrieveQuoteForm.get('mob_Num').setValue(this.mobileNo)
      // console.log(this.ret_quote_num)
      // console.log(this.emailId)
      // console.log(this.mobileNo)
    }


    this.paymentLoader = this.globalService.spinnerHide();

    this.tempRepairTypeArr = this.repairtypes;



    // listen for search field value changes
    this.country1FilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterCountry();
      });


    // listen for search field value changes
    this.nationalFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterNationalCountry();
      });

    this.makeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterMake();
      });

    this.modelFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterModel();
      });

    this.filteredYears.next(this.vehimodelyrs.slice());

    this.yearFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterYear();
      });



    this.filteredPlateCat.next(this.plateCatArray.slice());

    this.plateFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterPlateCat();
      });


    this.vchColorFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterVechColor();
      });

    this.mortageBankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterMortageBank();
      });


    this.filteredCode.next(this.codes.slice());
    this.codeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterCode();
      });
     if(localStorage.getItem("retriveOCR")=="OCR"){
      setTimeout(() => {
        

          
        this.retrieveQuoteForm.get('web_Quote_Num').setValue(localStorage.getItem("retriveOCRQuote"));
        this.retrieveQuoteForm.get('email').setValue(localStorage.getItem("retriveOCRemail"));
        this.retrieveQuoteForm.get('mob_Num').setValue(localStorage.getItem("retriveOCRCode")+localStorage.getItem("retriveOCRmob_Num"));
        localStorage.setItem("retriveOCRQuote",'');
        localStorage.setItem("retriveOCRemail",'');
        localStorage.setItem("retriveOCRCode",'');

        
        
        // this.retrieveQuoteForm.get('web_Quote_Num').setValue(localStorage.getItem("retriveOCRemail"));

          // localStorage.getItem("retriveOCRCode");
          // localStorage.getItem("retriveOCRmob_Num");
          // localStorage.getItem("retriveOCRemail");
      this.getRetrieveQuoteData();

    }, 5000);

  }
  const routeParam2 = this._activatedroute.snapshot.params;
  if (routeParam2.quotationNumber !=null) {
    // alert(1)
    this.quotationumber = routeParam2.quotationNumber;
    this.retrieveQuoteForm.get('web_Quote_Num').setValue(this.quotationumber)
    // this.retrieveQuoteForm.get('email').setValue(this.emailId)
    // this.retrieveQuoteForm.get('mob_Num').setValue(this.mobileNo)
    // this.retrieveQuoteForm.get('web_Quote_Num').setValue(localStorage.getItem("retriveOCRQuote"));
    this.retrieveQuoteForm.get('email').setValue(localStorage.getItem("EmailAddress"));
    this.retrieveQuoteForm.get('mob_Num').setValue(localStorage.getItem("MobileNumber"));
    // console.log(localStorage.getItem("MobileNumber"))
    this.getRetrieveQuoteData();
  }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  get f() { return this.insuredDetailForm.controls; }
  get P() { return this.personalDetailForm.controls; }
  get g() { return this.vehicleDetailForm.controls; }
  get h() { return this.coverDetailForm.controls; }
  get k() { return this.additionalDetailForm.controls; }
  get r() { return this.retrieveQuoteForm.controls; }
  get n() { return this.policyRenewForm.controls; }


  //-------------------------- GET TOKEN DATA -------------------------
  getTokenData() {

    // this.showLoader.personalDetail = true;
    this.userAuthService.getTokenData().subscribe(res => {
      let result = this.globalService.convertResponseInJson(res);
      // if (result.response_code == 1) {
      //   this._route.navigate(["/motorquote"]);
        this.getAllFormData('');
        this.getVhclNCD();
        this.getIndustryTypes();
      //   this.showLoader.personalDetail = false;

      // }
    },
    (error) => {
      // this.showLoader.personalDetail = false;
        console.error(error.status);
             
     });
  }

  insertQuote(stepper, type) {

    if (this.personalDetailForm.invalid) {
      this.validtnMsg1.personalDetail = true;
      return false;
    }

    if (this.age > 71 || this.age < 18) {
      return false;
    }

    this.validtnMsg1.personalDetail = false;

    if (type != 0)
      stepper.next();

  }
  //--------------------------------- GET ALL FORM DROPDOWN DATA -----------------------------
  getAllFormData(year) {
    this.motorQuoteService.getAllFormData('COMPREHENSIVE', 'INDIVIDUAL', '0', this.language_code, this.country,year).subscribe(res => {

      this.cityArr = res.cityData;
      this.vhclColorArr = res.vehicleColorsData;
      this.countryArr = res.countryData;
     

      this.filteredVchColor.next(this.vhclColorArr.slice());
      

     
      // this.personalDetailForm.get("licIssueContry").setValue(this.countryArr[32]);
      this.filteredCountries.next(this.countryArr.slice());

      // this.additionalDetailForm.get("nationality").setValue(this.countryArr[32]);
      this.filteredNationCountries.next(this.countryArr.slice());

    });

    this.motorQuoteService.getBankDetail().subscribe(res => {

      this.bankDetail = res.bankdetailsData;
      this.filteredBank.next(this.bankDetail.slice());

    });
  }

//------------------------------ GET VEHICLE NCD ---------------------------------------------
   getVhclNCD() {
      this.motorQuoteService.getVehicleNcd(this.language_code, 'MOTOR').subscribe(res => {
            this.NCDData = res.vechileNCDData;
      
      });
  }

//---------------------------------- GET INDUSTRY TYPE --------------------------------------------------------//
   getIndustryTypes(){
    this.motorQuoteService.getIndustryTypes('MT').subscribe(res => {
            this.industryTypeArr = res.industries;
    });
}

//------------------------------ GET VEHICLE MODEL -----------------------------------------
getVhclModel(vehclMkId, type, year) {

  // this.onVehiclAgeChange(year);

   this.showLoader.vhclModel = true;
 
     this.motorQuoteService.getVehicleModel('P', this.insuredDetailForm.value.rg_vhcl_make, this.language_code, null, year.value).subscribe(res => {

       this.vhclModelArr = res.vechileModelData;
       if (res.response_code == 1) {

         this.filteredModels.next(this.vhclModelArr.slice()); // FOr ng select search
       }

         if ((type == 2 || type == 1) && typeof (this.vhclModelArr) != undefined && typeof (this.vhclModelArr) != "undefined") {

             let trim = type == 2 ? this.vehicleData.General.Trim : this.quoteDetail.TrimCode;
             let model = type == 2 ? this.vehicleData.General.Model : this.quoteDetail.VehicleModel;
             let ModalName = (vehclMkId == 34 || vehclMkId == 7) ? trim : model;

             var indexModel = this.vhclModelArr.findIndex(function (obj, k) {

                 return obj.VehicleModelName === ModalName;
             });
             let modelVal = this.vhclModelArr[indexModel];

             this.insuredDetailForm.get('rg_vhcl_model').setValue(modelVal);
          if (modelVal != null && modelVal != undefined) {
                 if (type == 2)
                     this.getMotorBodyType(modelVal.VehicleModelId, 2, year, modelVal.VehicleModelName);
                 else
                     this.getMotorBodyType(modelVal.VehicleModelId, 1, year, modelVal.VehicleModelName);
             }
         }
       if (type == 3 && typeof (this.vhclModelArr) != undefined && typeof (this.vhclModelArr) != "undefined") {

         let ModalName = (this.chassis_No_Details.General.Make == 34 || this.chassis_No_Details.General.Make == 7) ? this.chassis_No_Details.General.Trim : this.chassis_No_Details.General.Model;
            
           if(ModalName != '' && ModalName != null){
                 var indexModel = this.vhclModelArr.findIndex(function (obj, k) {
                   return obj.VehicleModelName === ModalName;
                 });
                 let modelVal = this.vhclModelArr[indexModel];
               this.insuredDetailForm.get('rg_vhcl_model').setValue(modelVal);
               if (modelVal != null && modelVal != undefined) {
                   this.getMotorBodyType(modelVal.VehicleModelId, 3, year, modelVal.VehicleModelName);
               }
             }

       }
       this.showLoader.vhclModel = false;

       var d = new Date(this.insuredDetailForm.value.rg_reg_date);
       let firstRegYear: number = d.getFullYear();

       var p = new Date(this.insuredDetailForm.value.polStartDate);
       let policyStartDateYear: number = p.getFullYear();
       let vhclYear = this.insuredDetailForm.value.rg_model_year;

       let timeDiff = Math.abs(this.insuredDetailForm.value.rg_reg_date - this.insuredDetailForm.value.polStartDate);
       let days_difference =  Math.floor(timeDiff / (1000 * 60 * 60 * 24));
     
       let  D1 = Math.ceil(1 + (days_difference/397)); //this will give you years
       let  D2 = (Number(policyStartDateYear) - Number(vhclYear.value)) + 1;
       let vehicle_age = Math.max(D2,D1);
       let D3 = Math.abs(D2 - D1);
       if(D3 == 1){
           vehicle_age = D1;
       }
       else{
           vehicle_age = Math.max(D2,D1);
       }

       if (vehicle_age >= 5) {
         if ((this.insuredDetailForm.value.rg_vhcl_make.VehicleMakeName == "BMW" || this.insuredDetailForm.value.rg_vhcl_make.VehicleMakeName == "MINI") && this.SchemeCode == '104') {
       
         } else {
         
           this.insuredDetailForm.get('adtnl_detail_repairTye').setValue(this.repairtypes[0]);

         }
       }
       this.getDeductibleValue();
     });
   
 }

 //------------------------------- GET MOTOR BODY TYPE AND VEHICLE SPECIFICATION------------------------------------------
 getMotorBodyType(vhclModelId, type, year, modelName) {

  if (typeof (vhclModelId) == "undefined" || typeof (vhclModelId) == undefined) return false;

  this.showLoader.vhclTrim = true;
  this.showLoader.vhclBodyType = true;

  this.motorQuoteService.getMotorBodyType(this.insuredDetailForm.value.adtnl_detail_insType.viewValue, this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.viewValue, vhclModelId, this.language_code, year, modelName).subscribe(res => {

    this.vhclBodyTypeArr = res.vechileMotoBodyTypeData;
    this.vehitrims = res.vechilesSpecificationData;

    if (res.response_code == 1 || res.response_code == 2) {
      this.showLoader.vhclTrim = false;
      this.showLoader.vhclBodyType = false;
    }

    if ((type == 2 || type == 1) && typeof (this.vhclBodyTypeArr) != undefined && typeof (this.vhclBodyTypeArr) != "undefined") {

      //VEHICLE TRIM 
      let trimName = type == 2 ? this.vehicleData.General.Trim + " " + "-" + " " + this.vehicleData.Technical.EngineDisplacementNominal.Value.trim() : this.quoteDetail.TrimName;
      let bodyType = type == 2 ? this.vehicleData.General.BodyType : this.quoteDetail.BodyType;

      var indexTrim = this.vehitrims.findIndex(function (obj, k) {
        return obj.VehicleSpecName === trimName;
      });

      let trimVal = this.vehitrims[indexTrim];
      this.insuredDetailForm.get('adtnl_detail_trim').setValue(trimVal);

      //BODY TYPE
      var indexbodyType = this.vhclBodyTypeArr.findIndex(function (obj, k) {
        return obj.BodyTypeName === bodyType;
      });
      let bdTyVal = this.vhclBodyTypeArr[indexbodyType];
      this.insuredDetailForm.get('adtnl_detail_bodyType').setValue(bdTyVal);

    }

    if (type == 3 && typeof (this.vhclBodyTypeArr) != undefined && typeof (this.vhclBodyTypeArr) != "undefined") {
      //VEHICLE TRIM ------- not given

      let trimName = this.chassis_No_Details.General.Trim + " " + "-" + " " + this.chassis_No_Details.Technical.EngineDisplacementNominal.Value.trim();
      let bodyType = this.chassis_No_Details.General.BodyType;

      var indexTrim = this.vehitrims.findIndex(function (obj, k) {
        return obj.VehicleSpecName === trimName;
      });

      let trimVal = this.vehitrims[indexTrim];
      this.insuredDetailForm.get('adtnl_detail_trim').setValue(trimVal);

      //BODY TYPE
      var indexbodyType = this.vhclBodyTypeArr.findIndex(function (obj, k) {
        return obj.BodyTypeName === bodyType;
      });
      let bdTyVal = this.vhclBodyTypeArr[indexbodyType];
      this.insuredDetailForm.get('adtnl_detail_bodyType').setValue(bdTyVal);
    }

    this.updateValuation();
   // this.getTonnage();
  });

}


  calculateAge(dateOfBirth) {

    let timeDiff = Math.abs(Date.now() - dateOfBirth);
    return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);

  }
//--------------------------------- ON MODEL YEAR CHANGE -------------------------------------//
onVehiclAgeChange(vhclYear) {


  var d = new Date(this.insuredDetailForm.value.rg_reg_date);
  let firstRegYear: number = d.getFullYear();

  var p = new Date(this.insuredDetailForm.value.polStartDate);
  let policyStartDateYear: number = p.getFullYear();

  let vhclNextYear = Number(vhclYear.value) + 1;
  let vhclPrevYear = Number(vhclYear.value) - 1;

  let schemeYear = Number(firstRegYear) - Number(vhclYear.value);

  this.regMinDate = new Date(vhclPrevYear, 4, 1);
  //this.regMaxDate = new Date(vhclNextYear, 1, 28);
  this.regMaxDate = new Date();

  let vhclAge = this.year - vhclYear.value;


  let timeDiff = Math.abs(this.insuredDetailForm.value.rg_reg_date - this.insuredDetailForm.value.polStartDate);
  let days_difference =  Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  let  D1 = Math.ceil(1 + (days_difference/397)); //this will give you years
  let  D2 = (Number(policyStartDateYear) - Number(vhclYear.value)) + 1;
     this.vehicle_age = Math.max(D2,D1);
  let D3 = Math.abs(D2 - D1);
  if(D3 == 1){
    this.vehicle_age = D1;
  }
  else{
    this.vehicle_age = Math.max(D2,D1);
  }

    this.motorQuoteService.getMotorRequiredData('AgencyLogic',this.vehicle_age,Number(vhclYear.value),Number(policyStartDateYear),this.insuredDetailForm.value.rg_vhcl_make,this.SchemeCode,this.quoteStatus,this.webQuoteNumber).subscribe(res =>{
          
            let response = res;
            this.repairs = response.repair_type_data;
            this.repairtypes=this.repairs
           
            this.insuredDetailForm.get('adtnl_detail_repairTye').setValue(this.repairtypes[0]); 
            
            if(this.retrieve_repair_type != ''){
                  var indRepairType;
                  this.repairtypes.forEach((item, index) => {
                    if (item.value.toLowerCase() == this.quoteDetail.RepairType.toLowerCase()) {
                          indRepairType = item;
                    }
                  });
                 
                  this.insuredDetailForm.get('adtnl_detail_repairTye').setValue(indRepairType);
            }

    });

}


  checkBrandNew(){
    if(this.vehicleDetailForm.value.brandNw == 1){
      this.vehicleDetailForm.controls['regDate'].setValidators(null);
    }
    this.vehicleDetailForm.controls['regDate'].updateValueAndValidity();
  }
  //------------------------------- GET MOTOR  Quoatation ------------------------------------------

public discount:any = 0;
  getQuotation(stepper, type, frame) {

   

    this.checkStepper = true;
    //frame.hide();


    this.showLoader.Quotation = true;
    if (type != 0){
         stepper.next();
    }
    
    let modifyValue = this.existVehiclevalue == 1 ? this.existVehiclevalue : null;
   // let dob = this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.code == '100' ? this.convertDate(this.insuredDetailForm.value.d_dob) : '';
    let repairType = this.insuredDetailForm.value.adtnl_detail_insType.value == 100 ? this.insuredDetailForm.value.adtnl_detail_repairTye : { value: 'Garage', viewValue: 'Garage' };
    let vehicle_value = this.insuredDetailForm.value.adtnl_detail_insType.value == 100 ? this.insuredDetailForm.value.adtnl_detail_vhclValue : 0;
    let brand_new = (this.insuredDetailForm.value.rg_model_year.value != this.maxYear || this.insuredDetailForm.value.rg_model_year.value != this.year || this.insuredDetailForm.value.rg_model_year.value != this.minyear) ? { code: '0', value: 'N' } : { code: '1', value: 'Y' };
    let vehicleType = this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.code == '100' ? { code: '1001', value: 'Private' } : { code: '1001', value: 'Private' };
    let policy_expire_date = this.insuredDetailForm.value.vhclTPLCoverage == 'No' ? this.nextDate : this.PrevDate;
    let emiratesId = this.insuredDetailForm.value.e_eid.replace(/-/gi, "");
    // console.log(this.calucateAge(this.insuredDetailForm.value.d_dob, 1));
    
    //let regDate = this.vehicleDetailForm.value.brandNw == 1 ? this.convertDate(this.toDay) : this.convertDate(this.vehicleDetailForm.value.regDate);

    let motorArrayQuote = {
     
      insured_type: this.insuredDetailForm.value.adtnl_detail_vhclOwnBy,
      insured_name: this.insuredDetailForm.value.e_name,
      gender: this.insuredDetailForm.value.e_gender,
      dob: this.convertDate(this.insuredDetailForm.value.d_dob),
      prospect_age: this.calucateAge(this.insuredDetailForm.value.d_dob, 1),
      nationality: this.insuredDetailForm.value.e_nationality,
      email: this.insuredDetailForm.value.adtnl_detail_email,
      mobile: this.insuredDetailForm.value.adtnl_detail_mobile,
      code: this.insuredDetailForm.value.adtnl_detail_mbCode,
      UAE_lic_age: this.convertDate(this.insuredDetailForm.value.d_issue_date),
      UAELicAge: this.calucateAge(this.insuredDetailForm.value.d_issue_date, 2),
      license_country: this.insuredDetailForm.value.e_nationality,
      NCD: this.insuredDetailForm.value.adtnl_detail_NCD,
      model_year: this.insuredDetailForm.value.rg_model_year,
      make: this.insuredDetailForm.value.rg_vhcl_make,
      model: this.insuredDetailForm.value.rg_vhcl_model,
      trim: this.insuredDetailForm.value.adtnl_detail_trim,
      body_type: this.insuredDetailForm.value.adtnl_detail_bodyType,
      cylinders: this.insuredDetailForm.value.adtnl_detail_cylinder,
      passenger: this.insuredDetailForm.value.rg_num_passenger,
      registered_date: this.convertDate(this.insuredDetailForm.value.rg_reg_date),
      GCCSpecified: this.insuredDetailForm.value.adtnl_detail_gccSpecified,
      vhclModified: this.insuredDetailForm.value.adtnl_detail_vhclModified,
      registered_place: this.insuredDetailForm.value.rg_place_issue,
      chassis_no: this.insuredDetailForm.value.rg_chassis_num,
      repair_type: repairType,
      sum_insured: vehicle_value,
      emirates_id: emiratesId,
      policy_type: this.insuredDetailForm.value.adtnl_detail_insType,
      ExistingCover: this.insuredDetailForm.value.vhclTPLCoverage,
      policy_expire_date: this.convertDate(policy_expire_date),
      brand_new: brand_new,
      customer_id: "0",
      lead_id: "0",
      NCD_docs: "No",
      registration_type: vehicleType,
      VEHENGINESIZE: '1',
      source: 'B2C',
      document_data: this.tempDocArray,
     // multiple_doc_data: this.tempMultipleDocData,
      GVM: this.insuredDetailForm.value.rg_gvm,
      origin: this.insuredDetailForm.value.rg_origin,
      policy_num: this.insuredDetailForm.value.rg_policy_num,
      eid_card_num: this.insuredDetailForm.value.e_cardNumber,
      reg_ins_exp_date: this.convertDate(this.insuredDetailForm.value.rg_ins_exp),
      reg_exp_date: this.convertDate(this.insuredDetailForm.value.rg_expiry_date),
      eid_exp_date: this.convertDate(this.insuredDetailForm.value.e_expiryDate),
      driv_lic_issue_date: this.convertDate(this.insuredDetailForm.value.d_issue_date),
      driv_lic_exp_date: this.convertDate(this.insuredDetailForm.value.d_expiry_date),
      driv_lic_issuePlace: this.insuredDetailForm.value.d_place_issue,
      name_in_arabic: this.insuredDetailForm.value.e_arabic_name,
      traffic_code: this.insuredDetailForm.value.rg_TC_num,
      license: this.insuredDetailForm.value.d_driv_lic_num,
      mortgage: this.insuredDetailForm.value.rg_mortgage,
      plate_number: this.insuredDetailForm.value.rg_traffic_plate_num,
      plate_code: this.insuredDetailForm.value.rg_plateCode,
      plate_category: this.insuredDetailForm.value.rg_type,
      driv_lic_TC_number: this.insuredDetailForm.value.d_TC_number,
      driv_lic_type: this.insuredDetailForm.value.d_lic_type,
      vehicleColor: this.insuredDetailForm.value.vhclColor,
      SchemeCode: this.SchemeCode,
      modifiedVhclValue: modifyValue,
      start_date: this.convertDate(this.insuredDetailForm.value.polStartDate), //Modified date conversion by Anju for B2C Stabilization
      policy_status : 'NEW',
      deductible_value : this.insuredDetailForm.value.deductible,
      TRN_number : this.insuredDetailForm.value.TRN_num,
      trade_Lic_number : this.insuredDetailForm.value.trade_lic_num,
      industry_type : this.insuredDetailForm.value.industry_type,
      transaction_type : this.insuredDetailForm.value.transaction_type.label,
      selfDecl : this.accept_self_declaration_1+","+this.accept_self_declaration_2+","+this.accept_self_declaration_3+","+this.accept_self_declaration_4+","+this.accept_self_declaration_5,
      motor_quote_type:'OCR'
    };


    this.quoteDetailArr = motorArrayQuote;
    var gender = "";
    if(this.insuredDetailForm.value.e_gender){
      gender =  this.insuredDetailForm.value.e_gender.value;

    }

    let policyDetail = {
      
      quotation_number: this.webQuoteNumber,
      CRS_quotation_number: this.quoteNumber,
      traffic_code: this.insuredDetailForm.value.rg_TC_num,
      license: this.insuredDetailForm.value.d_driv_lic_num,
      emirates: this.insuredDetailForm.value.rg_place_issue,
      address: this.insuredDetailForm.value.adtnl_detail_address,
      PO_box: this.insuredDetailForm.value.adtnl_detail_poBoxNum,
      occupation: '',
      national_id: this.insuredDetailForm.value.e_eid,
      arabic_name: '',
      home_office_number: '',
      start_date: this.convertDate(this.insuredDetailForm.value.polStartDate),
      end_date: '22/12/2019',
      color: '',
      chassis_no: this.insuredDetailForm.value.rg_chassis_num,
      engine: this.insuredDetailForm.value.rg_engin_num,
      plate: '54687',
      mortgage: this.insuredDetailForm.value.rg_mortgage,
      payment_mode: 'ONLINE',
      named_driver: this.insuredDetailForm.value.e_name,
      additional_driver_under_age: '0',
      IP_address: '',
      mobile: this.insuredDetailForm.value.adtnl_detail_mobile,
      code: this.insuredDetailForm.value.adtnl_detail_mbCode,
      driving_License_Front: this.insuredDetailForm.value.driving_Lic_Front,
      driving_license_Back: '',
      Reg_Card_Front: this.insuredDetailForm.value.reg_Card_Front,
      Reg_Card_Back: this.insuredDetailForm.value.reg_Card_Back,
      EID_Front: this.insuredDetailForm.value.emirated_ID_Front,
      EID_Back: this.insuredDetailForm.value.emirated_ID_Backk,
      dealer_quote: '',
      vhclPlateCategory: this.insuredDetailForm.value.rg_type,
      plateCode: this.insuredDetailForm.value.rg_plateCode,
      plateNumber: this.insuredDetailForm.value.rg_traffic_plate_num,
      area: this.insuredDetailForm.value.adtnl_detail_area,
      noOfDoors: this.nofDoors,
      gender: gender,
      nationality: this.insuredDetailForm.value.e_nationality,
      source :'B2C'

    }
    localStorage.setItem('EmailAddress', this.insuredDetailForm.value.adtnl_detail_email);
    localStorage.setItem('MobileNumber',this.insuredDetailForm.value.adtnl_detail_mobile);
    this.motorQuoteService.getInsertQuote(motorArrayQuote,policyDetail).subscribe(res => {

    if (type != 0){
        stepper.next();
    }
    this.PlanDataJson.planBenefitData.benefit_data = [];

  
    if (res.response_code == 0) {
      this.showLoader.Quotation = false;
      this.showLoader.retrieveQuote = false;
      Swal.fire("Sorry! Plan not available.", " ", "error");

      stepper.previous();
    }

    else if (res.response_code == 4) {
      this.webQuoteNumber = res.web_quotation_number;
      this.showLoader.Quotation = false;
      this.showLoader.retrieveQuote = false;
   //   this.savePolicyDetail(1);
      Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "An email will be sent to you shortly. If you need further assistance call us at 800-842", "error");

      stepper.previous();
    }
    else if (res.response_code == 2) {
      this.webQuoteNumber = res.web_quotation_number;
      this.showLoader.Quotation = false;
      this.showLoader.retrieveQuote = false;
   //   this.savePolicyDetail(1);
      Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "An email will be sent to you shortly. If you need further assistance call us at 800-842", "error");

      stepper.previous();
    }

    else if (res.response_code_ == 400 || res.response_code == 5) {
      this.showLoader.Quotation = false;
      this.showLoader.retrieveQuote = false;
      this.webQuoteNumber = res.web_quotation_number;
   //   this.savePolicyDetail(1);
      Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "An email will be sent to you shortly. If you need further assistance call us at 800-842", "error");
      stepper.previous();
    }

    else if(res.response_code_r == 7){
      this.webQuoteNumber = res.web_quotation_number_r;
      this.showLoader.Quotation = false;
      this.showLoader.retrieveQuote = false;
     
     // this.savePolicyDetail(1);
      Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "An email will be sent to you shortly. If you need further assistance call us at 800-842", "error");

      stepper.previous();
    }

    else {

      stepper.next();
     // this.policyFee = 1;
      this.policyFee = 1;
      this.totalVariablePremium = 0;
      this.PlanDataJson = res;
      this.totalFixPremium = 0;
      this.plan_Name = this.PlanDataJson.planBenefitData.data[0].PlanName;
      this.plan_Id = this.PlanDataJson.planBenefitData.data[0].PlanId;
      this.benfPremIdArray = this.PlanDataJson.planBenefitData.data;
      this.webQuoteNumber = this.PlanDataJson.webQuotationNumber;
      this.quoteNumber = this.PlanDataJson.quotationNumber;

      this.showLoader.Quotation = false;
      this.showLoader.retrieveQuote = false;

      if(this.webQuoteNumber == '' || this.webQuoteNumber == null){
         stepper.previous();
      }

      this.PlanDataJson.planBenefitData.data.forEach((item, index) => {

        if (item.PlanBenefitType == 'OPTIONAL' && item.MultiOptionFlag == 1) {
          this.mulOption = item.multipleOptionData[0];
          this.PlanDataJson.planBenefitData.data[index].benefitSelectedData = item.multipleOptionData[0];
          this.PlanDataJson.planBenefitData.data[index].multiOptID = item.multipleOptionData[0].BenefitId;
          this.PlanDataJson.planBenefitData.data[index].Price = item.multipleOptionData[0].Price;
          this.PlanDataJson.planBenefitData.data[index].checked = false;
          this.PlanDataJson.planBenefitData.data[index].offerChecked = false;

        }

        if (item.PlanBenefitType == 'OPTIONAL' && item.MultiOptionFlag != 1) {
       
          this.PlanDataJson.planBenefitData.data[index].checked = false;
          this.PlanDataJson.planBenefitData.data[index].offerChecked = false;

        }

      });


      // let discount = this.PlanDataJson.thirdPartyAPIData.NcdDiscount.NcdPerc/100;
      this.discount = this.PlanDataJson.thirdPartyAPIData.NcdDiscount.NcdAmnt;
      this.tempBenefitData = this.PlanDataJson.planBenefitData.benefit_data;
      this.PlanDataJson.planBenefitData.benefit_data.forEach((item, index) => {

        this.totalFixPremium = Number(this.totalFixPremium) + Number(item.premium);
        // this.PlanDataJson.planBenefitData.benefit_data[index].premium = (item.premium - item.premium * discount);
      });

      
      this.tempTotalFixPremium = this.totalFixPremium;
    
      //  this.totalFixPremium = Math.round(this.tempTotalFixPremium - this.tempTotalFixPremium * discount);
      this.totalFixPremium = Math.round(this.tempTotalFixPremium - this.discount);

      this.totalFixPremium = Math.round(this.totalFixPremium + this.policyFee);
      this.tempTotalLoadFixPrem = this.totalFixPremium;
      this.VATAMT = Math.round((this.totalFixPremium + this.totalVariablePremium) * 0.05);
      this.Total_Primium = Math.round(this.totalFixPremium + this.totalVariablePremium + this.VATAMT);

      if(this.accept_self_declaration_1 || this.accept_self_declaration_2 || this.accept_self_declaration_3 || this.accept_self_declaration_4 || this.accept_self_declaration_5){
             this.getDiscountOnSelfDeclaration();
      }
   
      //this.savePolicyDetail(1);

    }

       });

  }


//----------- CONVERT DATE INTO DD/MM/YYYY FORMAT ------------//
  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat);
    //return ([ d.getFullYear(),pad(d.getMonth()+1),pad(d.getDate())].join('-'));
    return ([pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-'));

  }
  
  convertStartDate(inputFormat){
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat);
    //return ([ d.getFullYear(),pad(d.getMonth()+1),pad(d.getDate())].join('-'));
    return ([pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/'));

  }
  
  checkedCoverData() {
   
  }

  valueChange(data, item, $event) {
    //set the two-way binding here for the specific unit with the event
    data[item].checked = $event.checked;

  }

  PlanDataJson: any = {

    "quotationNumber": "",
    "productCode": "",

    "planBenefitData": {

      "BenefitId": 497,
      "PlanBenefitId": 1440,
      "PlanName": "Elite",
      "BenefitName": "National Ambulance Service (per person)",
      "PlanId": 52,
      "PerSeat": 0,
      "Deductible": null,
      "MinContribution": 0,
      "Rate": null,
      "Price": 0,
      "IsIncluded": 1,
      "PlanBenefitType": "INCLUDED",
      "CRS_BENEFIT_CODE": "40013",
      "DisplayOrder": 18,
      "CoverType": "C",
      "SumCovered": "AED 6,770",
      "MultiOptionFlag": null,
      "MultiOptionFlagName": null
    },
    "data": []


  };

  onMulOptionDataChange(eve, i, mulOption) {

    this.PlanDataJson.planBenefitData.data[i].benefitSelectedData = eve;
    this.PlanDataJson.planBenefitData.data[i].multiOptID = eve.BenefitId;
    this.PlanDataJson.planBenefitData.data[i].Price = eve.Price;
    this.PlanDataJson.planBenefitData.data[i].checked = false;
    this.addOptionalBenigits(1, 2);
  }
 

  callChangeStepper(event: any, stepper,frame) {

    // console.log(event);
    // console.log(event.previouslySelectedIndex)
    if(event.selectedIndex==2){ 
      // console.log("-----------------");
      event.selectedIndex = 1 ;
      frame.show();
      // stepper.previous();
    }

    return false ;
    this.floatingSelectedIndex = event.selectedIndex ;
    if (event.selectedIndex == 1) {

      //this.insertQuote(stepper, 0);
    }

    if (event.selectedIndex == 2) {
     
      if (this.checkStepper == false) {
     
       if (this.webQuoteNumber == '' ) {  // && this.retrieveQuoteForm.value.web_Quote_Num == ''
          this.getQuotation(stepper, 0, '');
         
        }
        else{
          this.updateQuotation(stepper, 0, '');
         
        }
         
      }

    }

    if (event.selectedIndex == 3) {

      //this.getQuotePlan(stepper, 0);
    }

  }
  ckhBk: any = [];
  addOptionalBenigits(id, event) {

    this.totalVariablePremium = 0;
    this.optionalBenefit = "" ;
    this.PlanDataJson.planBenefitData.data.forEach((item, index) => {

      if (item.checked == true && item.PlanBenefitType == 'OPTIONAL' && item.MultiOptionFlag!=1) {
        this.optionalBenefit = this.optionalBenefit+","+item.BenefitId;
        this.totalVariablePremium = Number(this.totalVariablePremium + item.Price);
       
      }

      if (item.checked == true && item.PlanBenefitType == 'OPTIONAL' && item.MultiOptionFlag==1) {
        this.optionalBenefit = this.optionalBenefit+","+this.mulOption.BenefitId;
        this.totalVariablePremium = Number(this.totalVariablePremium + item.Price);
       
      }

     

    });

    
  }

  checkOptionalBenefit(benefitId) { 


    if(typeof (benefitId) != undefined && typeof (benefitId) != "undefined" ){


    let benefitIdArray = benefitId.split(',');

    this.totalVariablePremium = 0;
    benefitIdArray.forEach((item1, index1) => {

      this.PlanDataJson.planBenefitData.data.forEach((item, index) => {
        if (item.BenefitId == item1) {
          this.PlanDataJson.planBenefitData.data[index].checked = true;
          this.totalVariablePremium = Number(this.totalVariablePremium + item.Price);
        }


      });

      this.PlanDataJson.planBenefitData.data.forEach((item, index) => {

        if (item.PlanBenefitType == 'OPTIONAL' && item.MultiOptionFlag == 1) {

          item.multipleOptionData.forEach((multiItem, multiIndex) => {
            if (multiItem.BenefitId == item1) {


              this.mulOption = item.multipleOptionData[multiIndex];
              this.PlanDataJson.planBenefitData.data[index].checked = true;
              this.PlanDataJson.planBenefitData.data[index].multiOptID = item.multipleOptionData[multiIndex].BenefitId;
              this.PlanDataJson.planBenefitData.data[index].Price = item.multipleOptionData[multiIndex].Price;

            }


          });

        }

      });

    });
    
  }
  this.addOptionalBenigits(1, 2);
  }

  public vatAMt:any; total_premium:any;
  getQuotePlan(frame) {

    if (frame != '') {
      if (this.accept_terms == false) {
        return;
      }

      frame.hide();
    }
 
    this.showLoader.Quotation = true;
    this.showLoader.referal = false;
    let vatAMt = Math.round(this.totalFixPremium + this.totalVariablePremium) * 0.05;
    this.vatAMt = vatAMt;
    let total_premium = this.totalFixPremium + this.totalVariablePremium + vatAMt
    this.total_premium = total_premium;
  
    //   this.PlanDataJson.planBenefitData.benefit_data = this.tempBenefitData 
    this.savePlanDetailArr = {
      quotation_number: this.webQuoteNumber,
      plan_id: this.plan_Id,
      total_premium: total_premium,
      base_premium: Math.round(this.totalFixPremium),
      optional_benefit_premiun: this.totalVariablePremium,
      admin_fees: this.policyFee,
      CRS_quote_number: this.quoteNumber,
      service_fees: 0,
      product_code: this.insuredDetailForm.value.adtnl_detail_insType,
      VAT: vatAMt,
      benefitPreId: this.benfPremIdArray,
    
    }

    this.motorQuoteService.insertPlanData(this.PlanDataJson.quotationNumber, this.PlanDataJson.PlanDataJson, Math.round(this.totalFixPremium),
      this.totalVariablePremium,
      this.policyFee,
      total_premium,
      this.PlanDataJson.planBenefitData.data,
      this.tempBenefitData,
      this.insuredDetailForm.value.adtnl_detail_repairTye,
      this.savePlanDetailArr,
      this.insuredDetailForm.value.adtnl_detail_insType.viewValue,
      'B2C',
      this.SchemeCode
    
    ).subscribe(res => {

              this.saveAdditionalInfo();
    });

  }

  //------------------------------------- SAVE POLICY DETAIL ---------------------------------------------//
  savePolicyDetail() {

    let policyDetail = {
      quotation_number: this.webQuoteNumber,
      CRS_quotation_number: this.quoteNumber,
      traffic_code: this.insuredDetailForm.value.rg_TC_num,
      license: this.insuredDetailForm.value.d_driv_lic_num,
      emirates: this.insuredDetailForm.value.rg_place_issue,
      address: this.insuredDetailForm.value.adtnl_detail_address,
      PO_box: this.insuredDetailForm.value.adtnl_detail_poBoxNum,
      occupation: '',
      national_id: this.insuredDetailForm.value.e_eid,
      arabic_name: '',
      home_office_number: '',
      start_date: this.convertDate(this.insuredDetailForm.value.polStartDate),
      end_date: '22/12/2019',
      color: '',
      chassis_no: this.insuredDetailForm.value.rg_chassis_num,
      engine: this.insuredDetailForm.value.rg_engin_num,
      plate: '54687',
      mortgage: this.insuredDetailForm.value.rg_mortgage,
      payment_mode: 'ONLINE',
      named_driver: this.insuredDetailForm.value.e_name,
      additional_driver_under_age: '0',
      IP_address: '',
      mobile: this.insuredDetailForm.value.adtnl_detail_mobile,
      code: this.insuredDetailForm.value.adtnl_detail_mbCode,
      driving_License_Front: this.insuredDetailForm.value.driving_Lic_Front,
      driving_license_Back: '',
      Reg_Card_Front: this.insuredDetailForm.value.reg_Card_Front,
      Reg_Card_Back: this.insuredDetailForm.value.reg_Card_Back,
      EID_Front: this.insuredDetailForm.value.emirated_ID_Front,
      EID_Back: this.insuredDetailForm.value.emirated_ID_Backk,
      dealer_quote: '',
      vhclPlateCategory: this.insuredDetailForm.value.rg_type,
      plateCode: this.insuredDetailForm.value.rg_plateCode,
      plateNumber: this.insuredDetailForm.value.rg_traffic_plate_num,
      area: this.insuredDetailForm.value.adtnl_detail_area,
      noOfDoors: this.nofDoors,
      gender: this.insuredDetailForm.value.e_gender,
      nationality: this.insuredDetailForm.value.e_nationality,
      source :'B2C'

    }

    this.motorQuoteService.savePolicyDetail(policyDetail, this.quoteDetailArr).subscribe(res => {

          // FOR PAYMENT
          let return_url = "http://k2key.in/motor_ins//dashboard";
          let site_url = "http://k2key.in/motor_ins/" + this._route.url;

          this.showLoader.Quotation = true;

          //----------------------------- NEW PAYMENT GATEWAY -----------------------------------------//
          // console.log(1)
        //Modified by Anju for Payment gateway Revamp
        this.motorQuoteService.paymentAuthNew(this.webQuoteNumber, site_url, return_url, 'MT', '1', '', 'B2C', 'adv').subscribe(res => {
            let payRes = res;
            if (payRes.status == 'Failed') {
                Swal.fire(payRes.res_msg);
                // this.paymentLoader = this.globalService.spinnerHide();
                this.showLoader.Quotation = false;
                return false;
            }

            else if (payRes.status == 'Success') {
                // alert(payRes.result.p_id)
                //this.paymentLoader = this.globalService.spinnerHide();
                this.showLoader.Quotation = false;
                // localStorage.setItem('Payment_Order_ID',res.result.tran_ref);
                localStorage.setItem('Payment_Order_ID', payRes.data.tran_ref);
                localStorage.setItem('Payment_Order_Tokan', payRes.data.token);
                localStorage.setItem('Payment_Quotation_Number', this.webQuoteNumber);

                window.location.href = payRes.data.redirect_url;

            }
        });


          //------------------------ OLD PAYMENT GATEWAY ---------------------------------//
          // this.motorQuoteService.paymentAuth(this.webQuoteNumber, site_url, return_url, 'MT', '1', '', 'B2C').subscribe(res => {
          //         let payRes = res;

          //         if (payRes.res_code == 101) {
          //           Swal.fire(payRes.res_msg);
          //           this.showLoader.Quotation = false;
          //           return false;
          //         }

          //         else if (payRes.result.response_code == 4012 && payRes.res_code == 1) {
          //           this.showLoader.Quotation = false;
          //           localStorage.setItem('Payment_Order_ID', payRes.result.p_id);
          //           localStorage.setItem('Payment_Order_Tokan', payRes.result.token);
          //           localStorage.setItem('Payment_Quotation_Number', this.webQuoteNumber);
                    
          //           window.location.href = payRes.result.payment_url;

          //         }

          //   });

    });

  }

  getQuotePlanEmail() {
    this.showLoader.Quotation = true;
   
    let vatAMt = (this.totalFixPremium + this.totalVariablePremium) * 0.05;
    let total_premium = this.totalFixPremium + this.totalVariablePremium + vatAMt;
  
    this.savePlanDetailArr = {
      quotation_number: this.webQuoteNumber,
      plan_id: this.plan_Id,
      total_premium: total_premium,
      base_premium: Math.round(this.totalFixPremium),
      optional_benefit_premiun: this.totalVariablePremium,
      admin_fees: this.policyFee,
      CRS_quote_number: this.quoteNumber,
      service_fees: 0,
      product_code: this.insuredDetailForm.value.adtnl_detail_insType,
      VAT: vatAMt,
      benefitPreId: this.benfPremIdArray,
    
    }

    this.motorQuoteService.insertPlanDataEmail(this.PlanDataJson.quotationNumber, this.PlanDataJson.PlanDataJson, this.totalFixPremium,
      this.totalVariablePremium,
      this.policyFee,
      total_premium,
      this.PlanDataJson.planBenefitData.data,
      this.tempBenefitData,
      this.insuredDetailForm.value.adtnl_detail_repairTye,
      this.savePlanDetailArr,
      this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.viewValue,
      'B2C',
      this.SchemeCode
    ).subscribe(res => {

      this.sendMail();
      this.showLoader.Quotation = false;
      //  this.editableStep.Quotation = false ;

      Swal.fire("", "Thank you for choosing Fidelity United for insuring your car. We have sent an email to your registered email with all the quotation details. Your Quotation Reference# " + this.webQuoteNumber, 'success');

    });
  }

  sendMail() {
    this.motorQuoteService.sendMail(this.webQuoteNumber,'B2C').subscribe(res => {

    });
  }

  //------------------------------------------ UPLOAD DRIVING LICENCE DOC (Third party API) -------------------------------------------------
  uploadDrivLicDoc(doc) {

    this.motorQuoteService.uploadDrivLicDoc(this.PlanDataJson.quotationNumber, doc).subscribe(res => {


    });
  }


  //----------------------------------- VIEW QUOTE PDF -------------------------------------------
  
  
  viewQuotePDF() {
    this.showLoader.Quotation = true;
    let vatAMt = (this.totalFixPremium + this.totalVariablePremium) * 0.05;
    let total_premium = this.totalFixPremium + this.totalVariablePremium + vatAMt;
  
    this.savePlanDetailArr = {
      quotation_number: this.webQuoteNumber,
      plan_id: this.plan_Id,
      total_premium: total_premium,
      base_premium: Math.round(this.totalFixPremium),
      optional_benefit_premiun: this.totalVariablePremium,
      admin_fees: this.policyFee,
      CRS_quote_number: this.quoteNumber,
      service_fees: 0,
      product_code: this.insuredDetailForm.value.adtnl_detail_insType,
      VAT: vatAMt,
      benefitPreId: this.benfPremIdArray,
      
    }
    
    this.motorQuoteService.insertPlanDataEmail(this.PlanDataJson.quotationNumber, this.PlanDataJson.PlanDataJson, Math.round(this.totalFixPremium),
      this.totalVariablePremium,
      this.policyFee,
      total_premium,
      this.PlanDataJson.planBenefitData.data,
      this.tempBenefitData,
      this.insuredDetailForm.value.adtnl_detail_repairTy,
      this.savePlanDetailArr,
      this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.viewValue,
      'B2C',
      this.SchemeCode
    ).subscribe(res => {

      
      this.motorQuoteService.getQuoteDetailPDF(this.webQuoteNumber,'B2C').subscribe(res => {

        
        this.showLoader.Quotation = false;
        setTimeout(
          function () {

          }.bind(this),
          600
        );
        let viewPDF = res;
        if (viewPDF.response_code == 1) {
          window.open(viewPDF.pdfPath, "_blank");
        }
      });
      //  this.editableStep.Quotation = false ;

    });

  }

 //------------------------------------- VALIDATE CHASSIS NUMBER ------------------------------------------------------------//
 validateChassisNum(chassisNo, stepper, type) {
 
  if (chassisNo == '') {

      return false;
  }

  
  this.showLoader.chasisNoButton = true;
  this.motorQuoteService.validateChassisNumber(chassisNo).subscribe(res => {
    let validateRes = res;

    if (validateRes.validateChassisNumber == '1') {
      Swal.fire(chassisNo + " " + "This Chassis number is already available. Please contact Fidelity United at 800 842", "error");
      this.showLoader.chasisNoButton = false;

      this.insuredDetailForm.get('isChassisValidate').setValue('');
      this.insuredDetailForm.get('rg_chassis_num').setValue('');
      return false;
    } else {

      this.motorQuoteService.getDetailsByVIN(chassisNo).subscribe(res => {
        this.showLoader.chasisNoButton = false;
        this.getVechileDetailData = res.vechileData;
        this.chassis_No_Details = this.getVechileDetailData.VehicleDetails;

        if (res.vechileData.StatusCode == 400 || res.vechileData.StatusCode == 422 || res.vechileData.ExpiresIn == 1200) {
          Swal.fire(" ", "Invalid Chassis No.", "error");
          if (type == 1) {
            this.insuredDetailForm.get('isChassisValidate').setValue('');
            this.insuredDetailForm.get('rg_vhcl_make').setValue('');
            this.insuredDetailForm.get('rg_model_year').setValue('');
            this.insuredDetailForm.get('rg_num_passenger').setValue('');
            this.insuredDetailForm.get('adtnl_detail_gccSpecified').setValue('');
            this.insuredDetailForm.get('rg_vhcl_model').setValue('');
            this.insuredDetailForm.get('adtnl_detail_trim').setValue('');
            this.insuredDetailForm.get('adtnl_detail_bodyType').setValue('');
            this.insuredDetailForm.get('adtnl_detail_cylinder').setValue('');
            this.insuredDetailForm.get('adtnl_detail_vhclValue').setValue('');
            this.insuredDetailForm.get('rg_chassis_num').setValue('');

          }

        }

        else {

          if (this.insuredDetailForm.value.rg_chassis_num != '') {
            this.insuredDetailForm.get('isChassisValidate').setValue('1');


            //VEHICLE YEAR
            let yearVal;
            this.vehimodelyrs.forEach((item, index) => {

              if (item.label == this.getVechileDetailData.VehicleDetails.General.ModelYear) {
                yearVal = item;
              }
            });

            this.insuredDetailForm.get('rg_model_year').setValue(yearVal);
            this.getVhclMakeData(this.getVechileDetailData.VehicleDetails.General.Make, yearVal, 3)

            // //VEHICLE Make ----- filter
            // let makeVal
            // this.vhclMakeArr.forEach((item, index) => {

            //   if (item.VehicleMakeName == this.getVechileDetailData.VehicleDetails.General.Make) {
            //     makeVal = item;
            //   }
            // });
            // this.insuredDetailForm.get('rg_vhcl_make').setValue(makeVal);

            //this.onVehiclAgeChange(yearVal);

            //Vehicle Cylinders
            let cylVal;
            this.vehicylinders.forEach((item, index) => {

              if (item.value == this.getVechileDetailData.VehicleDetails.Technical.EngineCylinders) {
                cylVal = item;
              }
            });
            this.insuredDetailForm.get('adtnl_detail_cylinder').setValue(cylVal);

            //Passenger Count
            let PasgerVal;
      
            this.vehiseatcaps.forEach((item, index) => {
              if (item.value == (this.getVechileDetailData.VehicleDetails.General.NoOfSeats - 1)) {
                PasgerVal = item;
              }
            });
            this.insuredDetailForm.get('rg_num_passenger').setValue(PasgerVal);

            this.GCC_Specification = this.getVechileDetailData.VehicleDetails.General.Region;
            if(this.getVechileDetailData.VehicleDetails.General.Region != '' && this.getVechileDetailData.VehicleDetails.General.Region != null){
                  let gcc_specification = this.getVechileDetailData.VehicleDetails.General.Region == 'GCC' ? 'No' : 'Yes';
                  this.insuredDetailForm.get('adtnl_detail_gccSpecified').setValue(gcc_specification);
          
            }else{
              this.insuredDetailForm.get('adtnl_detail_gccSpecified').setValue('');
            }
            

            //Vehicle Color 
            if (this.getVechileDetailData.VehicleDetails.General.ColourEN != '') {
              let colorVal;
              this.vhclColorArr.forEach((item, index) => {
                if (item.ColorName == this.getVechileDetailData.VehicleDetails.General.ColourEN) {
                  colorVal = item;
                }
              });

              this.insuredDetailForm.get('vhclColor').setValue(colorVal);
            }

            let enginNo = this.getVechileDetailData.VehicleDetails.General.EngineNo;
            this.insuredDetailForm.get('rg_engin_num').setValue(enginNo);

            this.nofDoors = this.getVechileDetailData.VehicleDetails.General.NoOfDoors;
          }


        }


      });

    }
  });
}

//------------------------------- VALIDATION FOR EMIRATES ID ----------------------------------------------//
 checkEID(value) {
  value = this.insuredDetailForm.value.e_eid;
  this.value = value;
 

  this.value = this.value.replace(/-/g, "");
  if (/[^0-9-]+/.test(this.value))
    this.invalidEID = true;


      // if (this.value.length != 15) {
     
      //   this.invalidEID = true;
      //   this.insuredDetailForm.get("eIDCheck").setValue('');
      //   return this.invalidEID;
      // }
  if (this.value == "111111111111111" || this.value == "000000000000000" || this.value == "222222222222222" || this.value == "333333333333333") {
    this.invalidEID = false;
    this.insuredDetailForm.get("eIDCheck").setValue('1');
 
    return this.invalidEID;
  }

  else {
    //The Luhn Algorithm.
    var nCheck = 0, nDigit = 0, bEven = false;
    this.value = this.value.replace(/\D/g, "");


    //784-1982-6961498-2

    for (let n = this.value.length - 1; n >= 0; n--) {

      var cDigit = this.value.charAt; nDigit = parseInt(this.value[n]);

      if (bEven) {
        if ((nDigit *= 2) > 9)
          nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;



    }

    if ((nCheck % 10) == 0) {
      //if valid, ok, check next
      this.invalidEID = false;
      this.insuredDetailForm.get("eIDCheck").setValue(1);
    
      return this.invalidEID;

    }
    else {
      this.invalidEID = true;
      this.insuredDetailForm.get("eIDCheck").setValue('');
     
      return this.invalidEID;
      //alert('Invalid Emirates ID. Please enter valid emirates Id including (-) dash/hypen.');
    }



  }

}
  //--------- VALIDATION FOR NUMBERS & DECIMAL NUM ------------//
  checkValidInputData(event: any, type) {

    this.globalService.checkValidInputData(event, type);
  }
  preventAllEvents(event: any) {

    this.globalService.preventAllEvents(event);
  }

  public existVehiclevalue: any;
  //------------------------------------------ GET VEHICLE VALUE -------------------------------------------------//
  updateValuation() {

    if (this.vhcleValueFlag == false && this.insuredDetailForm.value.adtnl_detail_insType.CoreProdCode == '4001_1') {
      if (this.insuredDetailForm.value.rg_vhcl_make != '' && this.insuredDetailForm.value.rg_vhcl_model != '' && this.insuredDetailForm.value.adtnl_detail_trim != '' && this.insuredDetailForm.value.adtnl_detail_bodyType != '') {

        this.showLoader.VehicleValue = true;


        let vehicleDetailArray = {
          chassisNum: this.insuredDetailForm.value.rg_chassis_num,
          insType: this.insuredDetailForm.value.adtnl_detail_insType,
          repairType: this.insuredDetailForm.value.rg_vhcl_make,
          vhclBodyType: this.insuredDetailForm.value.adtnl_detail_bodyType,
          vhclCylinder: this.insuredDetailForm.value.adtnl_detail_cylinder,
          vhclGCCSpecifird: this.insuredDetailForm.value.adtnl_detail_gccSpecified,
          vhclMake: this.insuredDetailForm.value.rg_vhcl_make,
          vhclModel: this.insuredDetailForm.value.rg_vhcl_model,
          vhclModelYr: this.insuredDetailForm.value.rg_model_year,
          vhclSeatCapcity: this.insuredDetailForm.value.rg_num_passenger,
          vhclTrim: this.insuredDetailForm.value.adtnl_detail_trim,
        }

        this.motorQuoteService.getMotorValuation(vehicleDetailArray).subscribe(res => {


          if (res.StatusCode == 200) {
            this.existVehiclevalue = 0;
            this.vehicleValueLimit.isSet = true;
            
                  this.vehicleValueLimit.startLimit = res.Valuation.Low;
                  this.vehicleValueLimit.endLimit = res.Valuation.High;
            
            this.insuredDetailForm.controls['adtnl_detail_vhclValue'].setValidators([Validators.required, Validators.min(res.Valuation.Low), Validators.max(res.Valuation.High)]);
            this.insuredDetailForm.controls['adtnl_detail_vhclValue'].updateValueAndValidity();
            this.insuredDetailForm.get('adtnl_detail_vhclValue').setValue(res.Valuation.Medium);
            this.getDeductibleValue();
          


          } else {

            this.existVehiclevalue = 1;
            this.vehicleValueLimit.startLimit = 0;
            this.vehicleValueLimit.endLimit = 300000;
            this.insuredDetailForm.controls['adtnl_detail_vhclValue'].setValidators([Validators.required]);
            this.insuredDetailForm.controls['adtnl_detail_vhclValue'].updateValueAndValidity();
            this.insuredDetailForm.get('adtnl_detail_vhclValue').setValue('');
            this.vehicleValueLimit.isSet = false;

          }

          this.showLoader.VehicleValue = false;

        });
      }
    } else {
      this.vhcleValueFlag = false;
    }


  }

  changeChasisValidation(type) {

    // commented for timing
    if (this.checkChassisValidation == false) {
      this.insuredDetailForm.get('isChassisValidate').setValue('');
      if (type == 1) {
        this.insuredDetailForm.get('rg_model_year').setValue('');
        this.insuredDetailForm.get('rg_vhcl_make').setValue('');
        this.insuredDetailForm.get('rg_vhcl_model').setValue('');
        this.insuredDetailForm.get('adtnl_detail_trim').setValue('');
        this.insuredDetailForm.get('adtnl_detail_bodyType').setValue('');
        this.insuredDetailForm.get('adtnl_detail_cylinder').setValue('');
        this.insuredDetailForm.get('rg_num_passenger').setValue('');
        this.insuredDetailForm.get('adtnl_detail_brandNew').setValue('0');
        this.insuredDetailForm.get('rg_plateCode').setValue('');
       
      }
    } else {
      this.checkChassisValidation = false;
    }

  }


  /************** Country Filter */

  private filterCountry() {
    if (!this.countryArr) {
      return;
    }
    // get the search keyword
    let search = this.country1FilterCtrl.value;
    if (!search) {
      this.filteredCountries.next(this.countryArr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredCountries.next(
      this.countryArr.filter(bank => bank.CountryName.toLowerCase().indexOf(search) > -1)
    );
  }


  private filterNationalCountry() {
    if (!this.countryArr) {
      return;
    }
    // get the search keyword
    let search = this.nationalFilterCtrl.value;
    if (!search) {
      this.filteredNationCountries.next(this.countryArr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredNationCountries.next(
      this.countryArr.filter(bank => bank.CountryName.toLowerCase().indexOf(search) > -1)
    );
  }




  private filterMortageBank() {
    if (!this.bankDetail) {
      return;
    }

    // get the search keyword
    let search = this.mortageBankFilterCtrl.value;

    if (!search) {
      this.filteredBank.next(this.bankDetail.slice());
      return;
    } else {
      search = search.toLowerCase();
    }


    // filter the banks
    this.filteredBank.next(
      this.bankDetail.filter(bank => bank.InstituteName.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterVechColor() {
    if (!this.vhclColorArr) {
      return;
    }

    // get the search keyword
    let search = this.vchColorFilterCtrl.value;
    if (!search) {
      this.filteredVchColor.next(this.vhclColorArr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }


    // filter the banks
    this.filteredVchColor.next(
      this.vhclColorArr.filter(bank => bank.ColorName.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterPlateCat() {
    if (!this.plateCatArray) {
      return;
    }

    // get the search keyword
    let search = this.plateFilterCtrl.value;
    if (!search) {
      this.filteredPlateCat.next(this.plateCatArray.slice());
      return;
    } else {
      search = search.toLowerCase();
    }


    // filter the banks
    this.filteredPlateCat.next(
      this.plateCatArray.filter(bank => bank.label.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterCode() {
    if (!this.codes) {
      return;
    }

    // get the search keyword
    let search = this.codeFilterCtrl.value;
    if (!search) {
      this.filteredCode.next(this.codes.slice());
      return;
    } else {
      search = search.toLowerCase();
    }


    // filter the banks
    this.filteredCode.next(
      this.codes.filter(bank => bank.viewValue.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterYear() {
    if (!this.vehimodelyrs) {
      return;
    }

    // get the search keyword
    let search = this.yearFilterCtrl.value;
    if (!search) {
      this.filteredYears.next(this.vehimodelyrs.slice());
      return;
    } else {
      search = search.toLowerCase();
    }


    // filter the banks
    this.filteredYears.next(
      this.vehimodelyrs.filter(bank => bank.label.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterMake() {
    if (!this.vhclMakeArr) {
      return;
    }
    // get the search keyword
    let search = this.makeFilterCtrl.value;
    if (!search) {
      this.filteredMakes.next(this.vhclMakeArr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredMakes.next(
      this.vhclMakeArr.filter(bank => bank.VehicleMakeName.toLowerCase().indexOf(search) > -1)
    );
  }

  private filterModel() {
    if (!this.vhclModelArr) {
      return;
    }
    // get the search keyword
    let search = this.modelFilterCtrl.value;
    if (!search) {
      this.filteredModels.next(this.vhclModelArr.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredModels.next(
      this.vhclModelArr.filter(bank => bank.VehicleModelName.toLowerCase().indexOf(search) > -1)
    );
  }

  updateQuotation(stepper, type, frame) {
    this.checkStepper = true;
    //frame.hide();
   
    // this.showLoader.vehicleDetail = true;
    this.showLoader.Quotation = true;
    if (type != 0){
      stepper.next();
    }
     
 
    let modifyValue = this.existVehiclevalue == 1 ? this.existVehiclevalue : null;
    // let dob = this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.code == '100' ? this.convertDate(this.insuredDetailForm.value.d_dob) : '';
     let repairType = this.insuredDetailForm.value.adtnl_detail_insType.value == 100 ? this.insuredDetailForm.value.adtnl_detail_repairTye : { value: 'Garage', viewValue: 'Garage' };
     let vehicle_value = this.insuredDetailForm.value.adtnl_detail_insType.value == 100 ? this.insuredDetailForm.value.adtnl_detail_vhclValue : 0;
     let brand_new = (this.insuredDetailForm.value.rg_model_year.value != this.maxYear || this.insuredDetailForm.value.rg_model_year.value != this.year || this.insuredDetailForm.value.rg_model_year.value != this.minyear) ? { code: '0', value: 'N' } : { code: '1', value: 'Y' };
     let vehicleType = this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.code == '100' ? { code: '1001', value: 'Private' } : { code: '1001', value: 'Private' };
     let policy_expire_date = this.insuredDetailForm.value.vhclTPLCoverage == 'No' ? this.nextDate : this.PrevDate;
     let emiratesId = this.insuredDetailForm.value.e_eid.replace(/-/gi, "");

    let motorArrayQuote = {
   
      quotation_number: this.quoteNumber,
      web_quote_number: this.webQuoteNumber,
      insured_type: this.insuredDetailForm.value.adtnl_detail_vhclOwnBy,
      insured_name: this.insuredDetailForm.value.e_name,
      gender: this.insuredDetailForm.value.e_gender,
      dob: this.convertDate(this.insuredDetailForm.value.d_dob),
      prospect_age: this.calucateAge(this.insuredDetailForm.value.d_dob, 1),
      nationality: this.insuredDetailForm.value.e_nationality,
      email: this.insuredDetailForm.value.adtnl_detail_email,
      mobile: this.insuredDetailForm.value.adtnl_detail_mobile,
      code: this.insuredDetailForm.value.adtnl_detail_mbCode,
      UAE_lic_age: this.convertDate(this.insuredDetailForm.value.d_issue_date),
      UAELicAge: this.calucateAge(this.insuredDetailForm.value.d_issue_date, 2),
      license_country: this.insuredDetailForm.value.e_nationality,
      NCD: this.insuredDetailForm.value.adtnl_detail_NCD,
      model_year: this.insuredDetailForm.value.rg_model_year,
      make: this.insuredDetailForm.value.rg_vhcl_make,
      model: this.insuredDetailForm.value.rg_vhcl_model,
      trim: this.insuredDetailForm.value.adtnl_detail_trim,
      body_type: this.insuredDetailForm.value.adtnl_detail_bodyType,
      cylinders: this.insuredDetailForm.value.adtnl_detail_cylinder,
      passenger: this.insuredDetailForm.value.rg_num_passenger,
      registered_date: this.convertDate(this.insuredDetailForm.value.rg_reg_date),
      GCCSpecified: this.insuredDetailForm.value.adtnl_detail_gccSpecified,
      vhclModified: this.insuredDetailForm.value.adtnl_detail_vhclModified,
      registered_place: this.insuredDetailForm.value.rg_place_issue,
      chassis_no: this.insuredDetailForm.value.rg_chassis_num,
      repair_type: repairType,
      sum_insured: vehicle_value,
      emirates_id: emiratesId,
      policy_type: this.insuredDetailForm.value.adtnl_detail_insType,
      ExistingCover: this.insuredDetailForm.value.vhclTPLCoverage,
      policy_expire_date: this.convertDate(policy_expire_date),
      brand_new: brand_new,
      customer_id: "0",
      lead_id: "0",
      NCD_docs: "No",
      registration_type: vehicleType,
      VEHENGINESIZE: '1',
      source: 'B2C',
      document_data: this.tempDocArray,
     // multiple_doc_data: this.tempMultipleDocData,
      GVM: this.insuredDetailForm.value.rg_gvm,
      origin: this.insuredDetailForm.value.rg_origin,
      policy_num: this.insuredDetailForm.value.rg_policy_num,
      eid_card_num: this.insuredDetailForm.value.e_cardNumber,
      reg_ins_exp_date: this.convertDate(this.insuredDetailForm.value.rg_ins_exp),
      reg_exp_date: this.convertDate(this.insuredDetailForm.value.rg_expiry_date),
      eid_exp_date: this.convertDate(this.insuredDetailForm.value.e_expiryDate),
      driv_lic_issue_date: this.convertDate(this.insuredDetailForm.value.d_issue_date),
      driv_lic_exp_date: this.convertDate(this.insuredDetailForm.value.d_expiry_date),
      driv_lic_issuePlace: this.insuredDetailForm.value.d_place_issue,
      name_in_arabic: this.insuredDetailForm.value.e_arabic_name,
      traffic_code: this.insuredDetailForm.value.rg_TC_num,
      license: this.insuredDetailForm.value.d_driv_lic_num,
      mortgage: this.insuredDetailForm.value.rg_mortgage,
      plate_number: this.insuredDetailForm.value.rg_traffic_plate_num,
      plate_code: this.insuredDetailForm.value.rg_plateCode,
      plate_category: this.insuredDetailForm.value.rg_type,
      driv_lic_TC_number: this.insuredDetailForm.value.d_TC_number,
      driv_lic_type: this.insuredDetailForm.value.d_lic_type,
      vehicleColor: this.insuredDetailForm.value.vhclColor,
      SchemeCode: this.SchemeCode,
      start_date: this.convertDate(this.insuredDetailForm.value.polStartDate),
      modifiedVhclValue: modifyValue,
      policy_status : 'NEW',
      deductible_value : this.insuredDetailForm.value.deductible,
      TRN_number : this.insuredDetailForm.value.TRN_num,
      trade_Lic_number :  this.insuredDetailForm.value.trade_lic_num,
      industry_type : this.insuredDetailForm.value.industry_type,
      transaction_type : this.insuredDetailForm.value.transaction_type.label,
      selfDecl : this.accept_self_declaration_1+","+this.accept_self_declaration_2+","+this.accept_self_declaration_3+","+this.accept_self_declaration_4+","+this.accept_self_declaration_5,
      motor_quote_type:'OCR'
    };
   

    this.quoteDetailArr = motorArrayQuote;

    var gender = "";
    if(this.insuredDetailForm.value.e_gender){
      gender =  this.insuredDetailForm.value.e_gender.value;

    }
    
    let policyDetail = {
      quotation_number: this.webQuoteNumber,
      CRS_quotation_number: this.quoteNumber,
      traffic_code: this.insuredDetailForm.value.rg_TC_num,
      license: this.insuredDetailForm.value.d_driv_lic_num,
      emirates: this.insuredDetailForm.value.rg_place_issue,
      address: this.insuredDetailForm.value.adtnl_detail_address,
      PO_box: this.insuredDetailForm.value.adtnl_detail_poBoxNum,
      occupation: '',
      national_id: this.insuredDetailForm.value.e_eid,
      arabic_name: '',
      home_office_number: '',
      start_date: this.convertDate(this.insuredDetailForm.value.polStartDate),
      end_date: '22/12/2019',
      color: '',
      chassis_no: this.insuredDetailForm.value.rg_chassis_num,
      engine: this.insuredDetailForm.value.rg_engin_num,
      plate: '54687',
      mortgage: this.insuredDetailForm.value.rg_mortgage,
      payment_mode: 'ONLINE',
      named_driver: this.insuredDetailForm.value.e_name,
      additional_driver_under_age: '0',
      IP_address: '',
      mobile: this.insuredDetailForm.value.adtnl_detail_mobile,
      code: this.insuredDetailForm.value.adtnl_detail_mbCode,
      driving_License_Front: this.insuredDetailForm.value.driving_Lic_Front,
      driving_license_Back: '',
      Reg_Card_Front: this.insuredDetailForm.value.reg_Card_Front,
      Reg_Card_Back: this.insuredDetailForm.value.reg_Card_Back,
      EID_Front: this.insuredDetailForm.value.emirated_ID_Front,
      EID_Back: this.insuredDetailForm.value.emirated_ID_Backk,
      dealer_quote: '',
      vhclPlateCategory: this.insuredDetailForm.value.rg_type,
      plateCode: this.insuredDetailForm.value.rg_plateCode,
      plateNumber: this.insuredDetailForm.value.rg_traffic_plate_num,
      area: this.insuredDetailForm.value.adtnl_detail_area,
      noOfDoors: this.nofDoors,
      gender: gender,
      nationality: this.insuredDetailForm.value.e_nationality,
      source :'B2C',
  

    }


    this.motorQuoteService.updateQuotation(motorArrayQuote,policyDetail).subscribe(res => {

      if (type != 0){
        stepper.next();
    }

      this.PlanDataJson.planBenefitData.benefit_data = [];
        this.showLoader.retrieveQuote = false;
        if (res.thirdPartyAPIData != null && res.thirdPartyAPIData != undefined) {
            let thirdPartyAPIResponse = res.thirdPartyAPIData.Status.Description.split(("no tariff available").toLowerCase());
        }

      if (res.response_code == 0) {
        this.showLoader.Quotation = false;
        this.showLoader.retrieveQuote = false;
        Swal.fire("Sorry! Plan not available.", " ", "error");

        stepper.previous();
      }

      else if (res.response_code == 4) {
        this.webQuoteNumber = res.web_quotation_number;
        this.showLoader.Quotation = false;
        this.showLoader.retrieveQuote = false;
     //   this.savePolicyDetail(1);
        Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "An email will be sent to you shortly. If you need further assistance call us at 800-842", "error");
  
        stepper.previous();
      }

      else if (res.response_code_ == 400 || res.response_code == 5) {
        this.showLoader.Quotation = false;
        this.showLoader.retrieveQuote = false;
        Swal.fire("Thank you for you request, based on your application details, the case needs to be referred for further review.", "An email will be sent to you shortly. If you need further assistance call us at 800-842", "error");
        stepper.previous();
      }

      else if(res.response_code_r == 7){
     
        this.showLoader.Quotation = false;
        this.showLoader.retrieveQuote = false;
  
        Swal.fire("", res.thirdPartyAPIData.Status.Description, "error");

        stepper.previous();
      }

      else {

        this.policyFee = 1;
        this.totalVariablePremium = 0;
        this.PlanDataJson = res;
        this.totalFixPremium = 0;
        this.plan_Name = this.PlanDataJson.planBenefitData.data[0].PlanName;
        this.plan_Id = this.PlanDataJson.planBenefitData.data[0].PlanId;
        this.benfPremIdArray = this.PlanDataJson.planBenefitData.data;
        this.webQuoteNumber = this.PlanDataJson.webQuotationNumber;
        this.quoteNumber = this.PlanDataJson.quotationNumber;
        this.showLoader.Quotation = false;
        this.showLoader.retrieveQuote = false;

        if(this.webQuoteNumber == '' || this.webQuoteNumber == null){
           stepper.previous();
        }
        this.PlanDataJson.planBenefitData.data.forEach((item, index) => {

          if (item.PlanBenefitType == 'OPTIONAL' && item.MultiOptionFlag == 1) {
            this.mulOption = item.multipleOptionData[0];
            this.PlanDataJson.planBenefitData.data[index].benefitSelectedData = item.multipleOptionData[0];
            this.PlanDataJson.planBenefitData.data[index].multiOptID = item.multipleOptionData[0].BenefitId;
            this.PlanDataJson.planBenefitData.data[index].Price = item.multipleOptionData[0].Price;
            this.PlanDataJson.planBenefitData.data[index].checked = false;
            this.PlanDataJson.planBenefitData.data[index].offerChecked = false;

          }

          if (item.PlanBenefitType == 'OPTIONAL' && item.MultiOptionFlag != 1) {
         
            this.PlanDataJson.planBenefitData.data[index].checked = false;
            this.PlanDataJson.planBenefitData.data[index].offerChecked = false;

          }

        });
       
        this.checkOptionalBenefit(this.optionalBenefit);
        
        let discount = this.PlanDataJson.thirdPartyAPIData.NcdDiscount.NcdPerc / 100;
        this.discount = this.PlanDataJson.thirdPartyAPIData.NcdDiscount.NcdAmnt;
        this.tempBenefitData = this.PlanDataJson.planBenefitData.benefit_data;
        this.PlanDataJson.planBenefitData.benefit_data.forEach((item, index) => {

          this.totalFixPremium = Number(this.totalFixPremium) + Number(item.premium);
        
        });

       
        this.tempTotalFixPremium = this.totalFixPremium;

        //  this.totalFixPremium = Math.round(this.tempTotalFixPremium - this.tempTotalFixPremium * discount);
        this.totalFixPremium = Math.round(this.tempTotalFixPremium - this.discount);
        this.totalFixPremium = Math.round(this.totalFixPremium + this.policyFee);
        this.tempTotalLoadFixPrem = this.totalFixPremium;
        this.VATAMT = ((this.totalFixPremium + this.totalVariablePremium) * 0.05);
        this.Total_Primium = (this.totalFixPremium + this.totalVariablePremium + this.VATAMT);


        if (this.tempTotalFixPremium<=500) {
            stepper.previous();
        }


      if(this.accept_self_declaration_1 || this.accept_self_declaration_2 || this.accept_self_declaration_3 || this.accept_self_declaration_4 || this.accept_self_declaration_5){
             this.getDiscountOnSelfDeclaration();
       } 
            
      }

    });

  }

  public editQuoteDocs: any; fileType: any;
  //---------------------------- GET DOCUMENTS DATA FOR RETRIEVE -----------------------------------------------------------//
  getDataForEditQuoteDocs(quoteNumber) {
    this.motorQuoteService.getDataForEditQuoteDoc(quoteNumber).subscribe(res => {

      this.editQuoteDocs = res.getDataForEditQuoteDoc;
      
      if(res.response_message!="Failed"){

          this.editQuoteDocs.forEach((item, index) => {
            var sDoc= false ;
            this.fileType = item.DocFileName.split(".");
            this.fileType = this.fileType[this.fileType.length - 1];
            this.fileType = this.fileType == "pdf" ? "PDF" : "IMG";
           
            if (item.DocumentType == "reg_Card_Front" && item.DocFilePath != '') {
              sDoc= true ;
              this.showLoader.reg_card_front = false;
              this.hideImages.reg_card_front = true;
              this.docUploadForm.get('reg_Card_FrontFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
              this.docUploadForm.get('reg_Card_FrontFileType').setValue(this.fileType);
              this.reg_card_front_flag=true;
            }

            if (item.DocumentType == "reg_Card_Back" && item.DocFilePath != '') {
              sDoc= true ;
            
              this.showLoader.reg_card_back = false;
              this.hideImages.reg_card_back = true;
              this.docUploadForm.get('reg_Card_BackFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
              this.docUploadForm.get('reg_Card_BackFileType').setValue(this.fileType);
              this.reg_card_back_flag=true;
            }

            if (item.DocumentType == "emirated_ID_Front" && item.DocFilePath != '') {
              sDoc= true ;
              this.showLoader.emirates_Id_front = false;
              this.hideImages.emirates_Id_front = true;
              this.docUploadForm.get('emirated_ID_FrontFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
              this.docUploadForm.get('emirated_ID_FrontFileType').setValue(this.fileType);
            }

            if (item.DocumentType == "emirated_ID_Back" && item.DocFilePath != '') {
              sDoc= true ;
              this.showLoader.emirates_Id_back = false;
              this.hideImages.emirates_Id_back = true;
              this.docUploadForm.get('emirated_ID_BackFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
              this.docUploadForm.get('emirated_ID_BackFileType').setValue(this.fileType);
            }

            if (item.DocumentType == "driving_Lic_Front" && item.DocFilePath != '') {
              sDoc= true ;
              this.showLoader.driving_Lic_front = false;
              this.hideImages.driving_Lic_front = true;
              this.docUploadForm.get('driving_Lic_FrontFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
              this.docUploadForm.get('driving_Lic_FrontFileType').setValue(this.fileType);
              this.driving_Lic_Front_flag=true;
            }

            if (item.DocumentType == "driving_Lic_Back" && item.DocFilePath != '') {
              sDoc= true ;
              this.showLoader.driving_Lic_back = false;
              this.hideImages.driving_Lic_back = true;
              this.docUploadForm.get('driving_Lic_BackFilePath').setValue(this.editQuoteDocs[index].DocFilePath);
              this.docUploadForm.get('driving_Lic_BackFileType').setValue(this.fileType);
              this.driving_Lic_Back_flag=true;
            }


          });

      }
    });
  }
  //----------------------------------------VALIDATION FOR AGE ------------------------------------------------//
  calucateAge(inputData, type) {
    if(inputData!=''){
      let timeDiff = Math.abs(Date.now() - inputData);
        if (type == 1) {
          return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
        }

        if (type == 2) {
          return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
        }
      
    }else{
               return '' ;
        }

  }

  renewPolicyData() {

    if (this.policyRenewForm.invalid) {
      this.validtnMsg1.policyRenew = true;
      return false;
    }

    //this.showLoader.policyRenew = true;


  }

  getPlateCode(regPlaceId, type) {

    let plateSource = this.retrieveQuoteForm.value.web_Quote_Num == '' ? regPlaceId : this.quoteDetail.regplace;

    if(typeof (plateSource) != undefined && typeof (plateSource) != "undefined" ){
    this.motorQuoteService.getPlateCode(this.language_code, plateSource).subscribe(res => {

      this.plateCodeArray = res.plateCodeData;

      if (type == 2 && typeof (this.plateCodeArray) != undefined && typeof (this.plateCodeArray) != "undefined") {
        //Plate COde

        console.log(this.quoteDetail);
        let plateCode = this.quoteDetail.PlateCode;
// console.log(this.quoteDetail.plateCode);
        var indexPlCode = this.plateCodeArray.findIndex(function (obj, k) {
          // console.log(obj.PlateCode +"========================"+ plateCode);
          return obj.PlateCode === plateCode;
        });
        let plCodeVal = this.plateCodeArray[indexPlCode];
        this.insuredDetailForm.get('rg_plateCode').setValue(plCodeVal);
      }
    });

  }


  }


  checkTermsCond(stepper) {
    this.accept_terms = !this.accept_terms;

  }


  checkChassisNo() {

    this.check_chassis_No = !this.check_chassis_No;

    if (this.check_chassis_No == true) {

      this.vehicleDetailForm.controls['chassisNum'].setValidators([Validators.required]);
      this.vehicleDetailForm.controls['isChassisValidate'].setValidators([Validators.required]);
    }
    else {
      this.vehicleDetailForm.get('chassisNum').setValue('');
      this.vehicleDetailForm.controls['chassisNum'].setValidators(null);
      this.vehicleDetailForm.controls['isChassisValidate'].setValidators(null);
    }

    this.vehicleDetailForm.controls['chassisNum'].updateValueAndValidity();
    this.vehicleDetailForm.controls['isChassisValidate'].updateValueAndValidity();
  }
  nonEditableFields(type) {
    //this.vehicleDetailForm.value.chassisNum != null && this.vehicleDetailForm.value.vhclGCCSpecifird != null && this.vehicleDetailForm.value.vhclTrim != undefined && this.vehicleDetailForm.value.vhclBodyType  != null && this.vehicleDetailForm.value.vhclCylinder  != null && this.vehicleDetailForm.value.vhclSeatCapcity  != null
  // alert(1)
    if (this.insuredDetailForm.value.rg_chassis_num != '' && this.insuredDetailForm.value.isChassisValidate == 1) {
      // alert(2)
      let vhclMake = localStorage.getItem("vehcl_make");
      let vhclModel = localStorage.getItem("vehcl_model");
      let vhclModelYear = localStorage.getItem("vehcl_model_year");
      let vhclTrim = localStorage.getItem("vehcl_trim");
      let vhclTrimVal = localStorage.getItem("vehcl_trim_val");
      let vhclBody = localStorage.getItem("vehcl_body");
      let gccSpecification = localStorage.getItem("GCC_Specification");
  
  
      if ((vhclMake != '' || vhclMake != null) && type == 1) {
  
        return true;
      }
  
      else if ((vhclModel != '' || vhclModel != null) && type == 2) {
  
        return true;
      }
  
      else if ((vhclModelYear != '' || vhclModelYear != null) && type == 3) {
  
        return true;
      }
  
      else if ((vhclTrim != '' || vhclTrim != null) && (vhclTrimVal == '' || vhclTrimVal == null) && type == 4) {
  
        return true;
      }
  
      else if ((vhclBody != '' || vhclBody != null) && type == 5) {
  
        return true;
      }
  
      else if ((gccSpecification != '' || gccSpecification != null) && type == 6) {
  // alert(3)
        return true;
      }
  
      else
        false;
  
    }
  
    //  if(type == 1 && this.vehicleDetailForm.value.chassisNum != '' && this.vehicleDetailForm.value.vhclGCCSpecifird != '' && this.vehicleDetailForm.value.vhclTrim != undefined && this.vehicleDetailForm.value.vhclBodyType  != '' && this.vehicleDetailForm.value.vhclCylinder  != '' && this.vehicleDetailForm.value.vhclSeatCapcity  != ''){
    //       return true;
    //   }
  
    //   else{
    //     return false;
    //   }
  }
  callSteps(stepper, e) { 
   
    // console.log(e);
    this.item=[{number: 1, open: true}];
        if (e == 1) {
          // console.log(stepper.selectedIndex)
            stepper.selectedIndex = 0;
        }
  }

  public showTerms :boolean = false;
  getTermsConditions(){

        this.showTerms = true;
        this.motorQuoteService.getTermsConditions('B2C').subscribe(res =>{
              if(res.response_code == 1){
                this.showTerms = false;
                this.termsAndCondition = res.termsAndCondition;
              }
                
        });
  }

  togglefloating(){
    this.showFloating=!this.showFloating;
    this.showFloatingcloseicon=!this.showFloatingcloseicon;
    this.showFloatingopenicon=!this.showFloatingopenicon;
  }
  togglepremiumfloating(){
  this.showpremiumFloating=!this.showpremiumFloating;
  this.premiumcloseicon=!this.premiumcloseicon;
  this.premiumopenicon=!this.premiumopenicon;
}


//////------------------------------------------ document upload with ocr --- hk -------------------/////

getIssuePlace(place) {
  
}

getRegCardData(type, docData) {
  if (type == 1) {
    this.document_data.Data = docData.Data != '' ? docData.Data : this.document_data.Data;

    if (this.document_data.Data['Chassis No.'] == "Not Found" || this.vehicleData.StatusCode == 422) {
      this.insuredDetailForm.get('rg_chassis_num').setValue('');
      this.insuredDetailForm.get('isChassisValidate').setValue('');


    } else {
      this.checkChassisValidation = true;
      this.insuredDetailForm.get('rg_chassis_num').setValue(this.document_data.Data['Chassis No.']);
      this.insuredDetailForm.get('isChassisValidate').setValue('1');

    }

      //GCC
      this.GCC_Specification = this.vehicleData.General.Region;
      if(this.vehicleData.General.Region != null && this.vehicleData.General.Region != ''){
               let gcc_specification = this.vehicleData.General.Region == 'GCC' ? 'No' : 'Yes';
               this.insuredDetailForm.get('adtnl_detail_gccSpecified').setValue(gcc_specification);
               this.nonEditableFields(6);
      }else{
            this.insuredDetailForm.get('adtnl_detail_gccSpecified').setValue('');
      }

    if (this.document_data.Data['G. V. W.'] == "Not Found") {
      this.insuredDetailForm.get('rg_gvm').setValue('');
    } else {
      this.insuredDetailForm.get('rg_gvm').setValue(this.document_data.Data['G. V. W.']);
    }

    let enginNumber = this.document_data.Data['Eng. No.'];
    this.insuredDetailForm.get('rg_engin_num').setValue(enginNumber.trim());
    
    this.nofDoors = this.vehicleData.General.NoOfDoors;
    //this.insuredDetailForm.get('rg_vhcl_model').setValue(this.document_data.Data['Expiry Date']);

    this.fileUploadFlag = true;
    //Model year
    let modelYear = this.vehicleData.General.ModelYear;
    var indexMYear = this.vehimodelyrs.findIndex(function (obj, k) {
      return obj.label === modelYear;
    });
    let yearVal = this.vehimodelyrs[indexMYear];
    this.insuredDetailForm.get('rg_model_year').setValue(yearVal);

    this.chnageData.rg_vhcl_make = this.vehicleData.General.Make;

   this.getVhclMakeData(this.vehicleData.General.Make, yearVal, 2);

  

    //NO OF PAASENGER
    let numOfPass = this.vehicleData.General.NoOfSeats;
    var iPassenger = this.vehiseatcaps.findIndex(function (obj, k) {
      return obj.viewValue == (numOfPass - 1);
    });
    
    let passengerVal = this.vehiseatcaps[iPassenger];
    this.insuredDetailForm.get('rg_num_passenger').setValue(passengerVal);

    //ORIGIN
    let origin = this.document_data.Data['Origin'];
    var indexOrin = this.countryArr.findIndex(function (obj, k) {
      return obj.CountryName === origin;
    });
    let originVal = this.countryArr[indexOrin];
    this.insuredDetailForm.get('rg_origin').setValue(originVal);

    //cylinders

    let cylVal;
    this.vehicylinders.forEach((item, index) => {

      if (item.value == this.vehicleData.Technical.EngineCylinders) {
        cylVal = item;
      }
    });

    this.insuredDetailForm.get('adtnl_detail_cylinder').setValue(cylVal);


  }


  if (type == 2) {

    this.document_data.Data = docData.Data != '' ? docData.Data : this.document_data.Data;

    let dateExp: string = this.document_data.Data['Exp. Date'];
    dateExp = dateExp.replace(/-/gi, "/");
    let dateReg: string = this.document_data.Data['Reg. Date'];
    dateReg = dateReg.replace(/-/gi, "/");
    let dateInsp: string = this.document_data.Data['Ins. Exp.'];
    dateInsp = dateInsp.replace(/-/gi, "/");
    let strTCNum: string = this.document_data.Data['T. C. No.'];

    if (this.document_data.Data['Exp. Date'] != "Not Found")
      this.insuredDetailForm.get('rg_expiry_date').setValue(this.dateConvert(dateExp));
    else {
      this.insuredDetailForm.get('rg_expiry_date').setValue('');
    }

    if (this.document_data.Data['Ins. Exp.'] != "Not Found")
      this.insuredDetailForm.get('rg_ins_exp').setValue(this.dateConvert(dateInsp));
    else {
      this.insuredDetailForm.get('rg_ins_exp').setValue('');
    }

    if (this.document_data.Data['Reg. Date'] != "Not Found")
      this.insuredDetailForm.get('rg_reg_date').setValue(this.dateConvert(dateReg));
    else {
      this.insuredDetailForm.get('rg_reg_date').setValue('');
    }

    this.insuredDetailForm.get('rg_policy_num').setValue(this.document_data.Data['Policy No.']);

    this.insuredDetailForm.get('rg_TC_num').setValue(this.document_data.Data['T. C. No.']);
    this.insuredDetailForm.get('rg_traffic_plate_num').setValue(this.document_data.Data['Traffic Plate No.']);

    if (this.insuredDetailForm.value.d_TC_number == '') {
      this.insuredDetailForm.get('d_TC_number').setValue(this.insuredDetailForm.value.rg_TC_num);
    }

    let plate_code_array = this.document_data.Data['Traffic Plate No.'].split('/');

    //Mortgage Bank
    if (this.document_data.Data['Mortgage By'] == "Not Found") {
      this.insuredDetailForm.get('rg_mortgage').setValue('');

    } else {
      let mBank = this.document_data.Data['Mortgage By'];
      var indexBank = this.bankDetail.findIndex(function (obj, k) {
        return obj.InstituteName.toLowerCase() === mBank.toLowerCase();
      });
      let bankVal = this.bankDetail[indexBank];
      this.insuredDetailForm.get('rg_mortgage').setValue(bankVal);
    }
    //REG PLACE
    let regPlace = this.document_data.Data['Place of Issue'];
   // this.plate_code = this.document_data.Data['Plate_code'] != "" ? this.document_data.Data['Plate_code'] : plate_code_array[0];

    if (strTCNum.length == 8) {
      var indexRegPlace = this.cityArr.findIndex(function (obj, k) {
        return obj.CityName === 'Dubai';
      });
      let placelVal = this.cityArr[indexRegPlace];
      this.insuredDetailForm.get('rg_place_issue').setValue(placelVal);
      this.getPlateCode(placelVal.CityName, 3);
    } else if (this.document_data.Data['Place of Issue'] != 'Not Found' && strTCNum.length != 8) {
      var indexRegPlace = this.cityArr.findIndex(function (obj, k) {
        return obj.CityName.toLowerCase() === regPlace.toLowerCase();
      });
      let placelVal = this.cityArr[indexRegPlace];
      this.insuredDetailForm.get('rg_place_issue').setValue(placelVal);
      this.getPlateCode(placelVal.CityName, 3);
    } else {
      this.insuredDetailForm.get('rg_place_issue').setValue('');
    }

    //REG TyPE == PLATE CATEGORY
    if (this.document_data.Data['Registration Type'] == "Not Found" || this.document_data.Data['Registration Type'] == null || this.document_data.Data['Registration Type'] == '') {
      this.insuredDetailForm.get('rg_type').setValue('');

    } else {
      let regType = this.document_data.Data['Registration Type'];
      var indexRegType = this.plateCatArray.findIndex(function (obj, k) {
        return obj.value.toLowerCase() === regType.toLowerCase();
      });
      let typelVal = this.plateCatArray[indexRegType];
      this.insuredDetailForm.get('rg_type').setValue(typelVal.value);

    }
   

  }
  // let yearVal = this.vehimodelyrs[indexMYear];
  // this.insuredDetailForm.get('rg_model_year').setValue(yearVal);

  // this.chnageData.rg_vhcl_make = this.vehicleData.General.Make;

  // this.getVhclMakeData(this.vehicleData.General.Make, yearVal, 2);

}

getDrivingLicData(type, docData) {
  if (type == 1) {

    this.document_data.Data = docData.Data != '' ? docData.Data : this.document_data.Data;

    let dateDob: string = this.document_data.Data['Date of Birth'];
    dateDob = dateDob.replace(/-/gi, "/");
    let dateIssue: string = this.document_data.Data['Issue Date'];
    dateIssue = dateIssue.replace(/-/gi, "/");
    let dateExp: string = this.document_data.Data['Expiry Date'];
    dateExp = dateExp.replace(/-/gi, "/");

    if (this.document_data.Data['License No.'] != 'Not Found') {
      this.insuredDetailForm.get('d_driv_lic_num').setValue(this.document_data.Data['License No.']);
    } else {
      this.insuredDetailForm.get('d_driv_lic_num').setValue('');
    }

   
    // else {
    //     this.insuredDetailForm.get('d_dob').setValue('');
    // }



    if (this.document_data.Data['Issue Date'] != "Not Found")
      this.insuredDetailForm.get('d_issue_date').setValue(this.dateConvert(dateIssue));
    else {
      this.insuredDetailForm.get('d_issue_date').setValue('');
    }


    if (this.document_data.Data['Expiry Date'] != "Not Found")
      this.insuredDetailForm.get('d_expiry_date').setValue(this.dateConvert(dateExp));
    else {
      this.insuredDetailForm.get('d_expiry_date').setValue('');
    }


    // Driving Reg. Place
    let regPlace = this.document_data.Data['Place of Issue'];
    if(regPlace != 'Not Found' && regPlace !='' && regPlace != null){
          var indexRegPlace = this.cityArr.findIndex(function (obj, k) {
            return obj.CityName.toLowerCase() === regPlace.toLowerCase();
          });
          let placelVal = this.cityArr[indexRegPlace];
        this.insuredDetailForm.get('d_place_issue').setValue(placelVal);
        if (placelVal != null && placelVal != undefined) {
            this.getIssuePlace(placelVal.CityName);
        }
    }
    

     // 11 - 02 -2021 : If emirates id file not uploaded then name should be taken from driving lic file
      if(this.insuredDetailForm.value.e_name == ''){
            if(this.document_data.Data['Name'] == "Not Found" ){
                    this.insuredDetailForm.get('e_name').setValue('');
            }else{
                    this.insuredDetailForm.get('e_name').setValue(this.document_data.Data['Name']);
            }
      }  
      if(this.insuredDetailForm.value.e_nationality == ''){
            // Nationality
            let nationality = this.document_data.Data['Nationality'];
            var indexNationality = this.countryArr.findIndex(function (obj, k) {
              return obj.CountryName === nationality;
            });
            let nationalVal = this.countryArr[indexNationality];
            this.insuredDetailForm.get('e_nationality').setValue(nationalVal);
     
      }
      if(this.insuredDetailForm.value.d_dob == ''){
            if(this.document_data.Data['Date of Birth'] != "Not Found" && this.insuredDetailForm.value.d_dob == '')
                  this.insuredDetailForm.get('d_dob').setValue(this.dateConvert(dateDob));
      }

     

  }
  if (type == 2) {
    this.document_data.Data = docData.Data != '' ? docData.Data : this.document_data.Data;
      if (this.document_data.Data['Permitted Vehicles'] != null && this.document_data.Data['Permitted Vehicles'].Count > 0) {
          let licType = this.document_data.Data['Permitted Vehicles'][0].Type;



          this.insuredDetailForm.get('d_lic_type').setValue(licType);
      }

    if (this.document_data.Data['Traffic Code No.'] != 'Not Found') {
      this.insuredDetailForm.get('d_TC_number').setValue(this.document_data.Data['Traffic Code No.'].trim());
    } else {
      this.insuredDetailForm.get('d_TC_number').setValue('');
    }

  }


}


getEmiratesIdData(type, docData) {

  if (type == 1) {
// console.log('eid');
    this.document_data.Data = docData.Data != '' ? docData.Data : this.document_data.Data;

    // if(this.document_data.Data['ID Number']=="Not Found" && this.document_data.Data['ID-NumberInArabic']!=''){ alert(1);
    //   this.motorQuoteService.translateInEnglish(this.document_data.Data['ID-NumberInArabic']).subscribe(res => {

    //   });
    // }

    this.insuredDetailForm.get('e_arabic_name').setValue(this.document_data.Data['NameInArabic']);

    if (this.document_data.Data['ID Number'] == "Not Found") {
          this.insuredDetailForm.get('e_eid').setValue('');
    } else{
            this.insuredDetailForm.get('e_eid').setValue(this.document_data.Data['ID Number']);
            // console.log(this.insuredDetailForm.value.e_eid);
    }

    if(this.document_data.Data['Name'] == "Not Found" ){

      this.insuredDetailForm.get('e_name').setValue('');

   } else{  this.insuredDetailForm.get('e_name').setValue(this.document_data.Data['Name']);  }
    

    // Nationality
    let nationality = this.document_data.Data['Nationality'];
    var indexNationality = this.countryArr.findIndex(function (obj, k) {
    
      return obj.CountryName === nationality;
    });
    let nationalVal = this.countryArr[indexNationality];
    this.insuredDetailForm.get('e_nationality').setValue(nationalVal);
             
  }

  if (type == 2) {
    this.document_data.Data = docData.Data != '' ? docData.Data : this.document_data.Data;

    let dateDob: string = this.document_data.Data['Date of Birth'];
    dateDob = dateDob.replace(/-/gi, "/");

    let dateExp: string = this.document_data.Data['Expiry Date'];
    dateExp = dateExp.replace(/-/gi, "/");

    this.insuredDetailForm.get('e_cardNumber').setValue(this.document_data.Data['Card Number']);
    this.insuredDetailForm.get('e_expiryDate').setValue(this.dateConvert(dateExp));

    let gender = this.document_data.Data['Sex']
    //Gender
    var indexGender = this.genders.findIndex(function (obj, k) {
      return obj.value === gender;
    });
    let gnderVal = this.genders[indexGender];
    this.insuredDetailForm.get('e_gender').setValue(gnderVal);

    if (this.document_data.Data['Date of Birth'] != "Not Found" && this.document_data.Data['Date of Birth'] != "")
      this.insuredDetailForm.get('d_dob').setValue(this.dateConvert(dateDob));

  }



}

dateConvert(inputFormat) {

  let vDOEntryArray = inputFormat.split('/');
  let DOEntry = new Date();
  DOEntry.setDate(vDOEntryArray[0]);
  DOEntry.setMonth(vDOEntryArray[1] - 1);
  DOEntry.setFullYear(vDOEntryArray[2]);

  return DOEntry;

}




onFileChange(event, docName, FiledName, files: FileList) {
  this.docNameType = FiledName;
  // console.log(event)
  // console.log(docName)
  // console.log(this.docNameType)
 this.docProperty = this.docNameType.split('_', 3)[2]; //Added By Anju for OCR Changes

  if (this.docNameType == 'reg_Card_Front') {
    this.reg_card_front_flag =true;
     this.showLoader.reg_card_front = true;
     this.checkreg_Card_Front_image=false;
 }

  if (this.docNameType == 'reg_Card_Back') {
    this.reg_card_back_flag =true;
     this.showLoader.reg_card_back = true;
     this.checkreg_Card_Back_image=false;
 }

  if (this.docNameType == 'emirated_ID_Front') {
   this.showLoader.emirates_Id_front = true;
 }

  if (this.docNameType == 'emirated_ID_Back') {
    this.showLoader.emirates_Id_back = true;
  }

  if (this.docNameType == 'driving_Lic_Front') {
    this.driving_Lic_Front_flag =true;
    this.showLoader.driving_Lic_front = true;
    this.checkdriving_Lic_Front_image=false;
  }

  if (this.docNameType == 'driving_Lic_Back') {
    this.driving_Lic_Back_flag =true;
    this.showLoader.driving_Lic_back = true;
    this.checkdriving_Lic_Back_image=false;
  }

//this.showTempFile(event.target.files[0],this.docNameType);
setTimeout(() => {
  this.showTempFile(event.target.files[0],this.docNameType);
     }, 2000);

var imageData = '';
// console.log(event.target.files[0].type);
if(event.target.files[0].type=="image/png" || "image/jpeg"==event.target.files[0].type){ 
  // console.log(`Image size before compressed: ${event.target.files[0].size} bytes.`);
  // alert(`Image size before compressed: ${event.target.files[0].size} bytes.`);
    this.compressImage.compress(event.target.files[0])
    .pipe(take(1))
    .subscribe(compressedImage => {

      
      // console.log(`Image size after compressed: ${compressedImage.size} bytes.`);
      // alert(`Image size after compressed: ${compressedImage.size} bytes.`);



      const formData = new FormData();
      formData.append('file', compressedImage);//Modified by Anju for OCR Changes
      formData.append('docName', docName);
      formData.append('source', 'B2C');
      formData.append('stage', 'QUOTE');
      formData.append('quoteType', 'std');
      formData.append('schemeCode', this.SchemeCode);
      formData.append('docProperty', this.docProperty);//Added by Anju for OCR Changes
  
  // Array.from(files).forEach(f => formData.append('file', f))

 // this.updateDocTemp(formData,FiledName);

  this.motorQuoteService.uploadDocuments(formData).subscribe(res => {
    let updateMemResponse = res;
    this.document_data = updateMemResponse.Document_data.Document_data;

    if (updateMemResponse.res_code == 1) {

      // this.showLoader.reg_card_front = false;
      // this.showLoader.reg_card_back = false;
      // this.showLoader.emirates_Id_front = false;
      // this.showLoader.emirates_Id_back = false;
      // this.showLoader.driving_Lic_front = false;
      // this.showLoader.driving_Lic_back = false;


      let fileType = updateMemResponse.File.split(".");
      fileType = fileType[fileType.length - 1];
      fileType = fileType == "pdf" ? "PDF" : "IMG";
     // alert("filetype: "+ fileType)
      let formArrayValue: any = this.docUploadForm.value;

      formArrayValue[FiledName] = updateMemResponse.File;
        formArrayValue[FiledName + "FilePath"] = updateMemResponse.FileDir;
        let tempDocumentArray = {
          file_name: FiledName,
          file_dir: updateMemResponse.FileDir,
          docName: updateMemResponse.File,
        }

        if (fileType == "pdf") {
          formArrayValue[FiledName + "FileType"] = "PDF";
        } else {
          formArrayValue[FiledName + "FileType"] = "IMG";
        }
       
    //  console.log(FiledName)
        if (FiledName == 'reg_Card_Front') {
          // alert(1)
          this.hideImages.reg_card_front = true;
         
          this.docUploadForm.get('reg_Card_FrontFilePath').setValue(updateMemResponse.FileDir);
          this.docUploadForm.get('reg_Card_Front').setValue(updateMemResponse.File);
          this.docUploadForm.get('reg_Card_FrontFileType').setValue(fileType);
          this.tempDocArray[0] = tempDocumentArray;
          this.showLoader.reg_card_front = false;
          this.checkreg_Card_Front_image=true;
          document.getElementById('myElementID').click();
         
        }
        if (FiledName == 'reg_Card_Back') {
          this.hideImages.reg_card_back = true;
          this.docUploadForm.get('reg_Card_BackFilePath').setValue(updateMemResponse.FileDir);
          this.docUploadForm.get('reg_Card_BackFileType').setValue(fileType);
          this.tempDocArray[1] = tempDocumentArray;
          this.showLoader.reg_card_back = false;
          this.checkreg_Card_Back_image=true;
          document.getElementById('myElementID').click();
        }  
        if (FiledName == 'emirated_ID_Front') {
          this.hideImages.emirates_Id_front = true;
     
          this.docUploadForm.get('emirated_ID_FrontFilePath').setValue(updateMemResponse.FileDir);
          this.docUploadForm.get('emirated_ID_FrontFileType').setValue(fileType);
          this.tempDocArray[2] = tempDocumentArray;
          this.showLoader.emirates_Id_front = false;
        }
        if (FiledName == 'emirated_ID_Back') {
          this.hideImages.emirates_Id_back = true;
          this.docUploadForm.get('emirated_ID_BackFilePath').setValue(updateMemResponse.FileDir);
          this.docUploadForm.get('emirated_ID_BackFileType').setValue(fileType);
          this.tempDocArray[3] = tempDocumentArray;
          this.showLoader.emirates_Id_back = false;
         
        }
        if (FiledName == 'driving_Lic_Front') {
          this.hideImages.driving_Lic_front = true;
         
          // let dateDob: string = this.document_data.Data['Date of Birth'];
          // dateDob = dateDob.replace(/-/gi, "/");
          // this.motorOcrForm.get('date_of_birth').setValue(dateDob);

          // this.motorOcrForm.get('insured_name').setValue(this.document_data.Data.Name);
          // this.motorOcrForm.get('drive_Lic_no').setValue(this.document_data.Data['License No']);
          this.docUploadForm.get('driving_Lic_FrontFilePath').setValue(updateMemResponse.FileDir);
          this.docUploadForm.get('driving_Lic_FrontFileType').setValue(fileType);
          this.tempDocArray[4] = tempDocumentArray;
          this.showLoader.driving_Lic_front = false;
          this.checkdriving_Lic_Front_image=true;
          document.getElementById('myElementID').click();
        }
        if (FiledName == 'driving_Lic_Back') {
          this.hideImages.driving_Lic_back = true;
          this.docUploadForm.get('driving_Lic_BackFilePath').setValue(updateMemResponse.FileDir);
          this.docUploadForm.get('driving_Lic_BackFileType').setValue(fileType);
          this.tempDocArray[5] = tempDocumentArray;
          this.showLoader.driving_Lic_back = false;
          this.checkdriving_Lic_Back_image=true;
          document.getElementById('myElementID').click();
        }

       
console.log(FiledName)
        formArrayValue["docRegCardFront"] = "";
        formArrayValue['docRegCardBack'] = "";
        formArrayValue["docEmiratedIdFront"] = "";
        formArrayValue['docEmiratedIdBack'] = "";
        formArrayValue["docDrivingLicFront"] = "";
        formArrayValue["docDrivingLicBack"] = "";

        if (this.document_data.Errocode == 106) {
          // alert(1)
            if (FiledName == 'reg_Card_Front') {
              // alert(1)
                this.hideImages.reg_card_front = false;
                this.docUploadForm.get('reg_Card_FrontFilePath').setValue('');
            }
            if (FiledName == 'reg_Card_Back') {
                this.hideImages.reg_card_back = false;
                this.docUploadForm.get('reg_Card_BackFilePath').setValue('');
            }
            if (FiledName == 'emirated_ID_Front') {
                this.hideImages.emirates_Id_front = false;
                this.docUploadForm.get('emirated_ID_FrontFilePath').setValue('');
            }
            if (FiledName == 'emirated_ID_Back') {
                this.hideImages.emirates_Id_back = false;
                this.docUploadForm.get('emirated_ID_BackFilePath').setValue('');
            }
            if (FiledName == 'driving_Lic_Front') {
                this.hideImages.driving_Lic_front = false;
                this.docUploadForm.get('driving_Lic_FrontFilePath').setValue('');
            }
            if (FiledName == 'driving_Lic_Back') {
              // alert(111)
                this.hideImages.driving_Lic_back = false;
                this.docUploadForm.get('driving_Lic_BackFilePath').setValue('');
            }
         
          Swal.fire("Invalid document type selected or the document resolution is very low to read the data.",
               "Please upload another clean copy and try. Sorry for the inconvenience.", "error");
               return false;
        }

        if (this.document_data.DocumentType == 'Emirates-Id' && this.document_data.DocumentSubType == 'Front') {

          this.getEmiratesIdData(1, this.document_data);
        }
        else if (this.document_data.DocumentType == 'Emirates-Id' && this.document_data.DocumentSubType == 'Back') {

          this.getEmiratesIdData(2, this.document_data);
        }
        else if (this.document_data.DocumentType == 'Driving License' && this.document_data.DocumentSubType == 'Front') {

          this.getDrivingLicData(1, this.document_data);
        }
        else if (this.document_data.DocumentType == 'Driving License' && this.document_data.DocumentSubType == 'Back') {

          this.getDrivingLicData(2, this.document_data);
        }
        else if (this.document_data.DocumentType == 'Vehicle License' && this.document_data.DocumentSubType == 'Back') {

          // this.insuredDetailForm.get('rg_vhcl_make').setValue('');
          // this.insuredDetailForm.get('rg_vhcl_model').setValue('');
          // this.insuredDetailForm.get('adtnl_detail_trim').setValue('');
          // this.insuredDetailForm.get('adtnl_detail_bodyType').setValue('');

          this.vehicleData = updateMemResponse.vechile_data.VehicleDetails;
         
          this.getRegCardData(1, this.document_data);
        }
        else if (this.document_data.DocumentType == 'Vehicle License' && this.document_data.DocumentSubType == 'Front') {

          this.getRegCardData(2, this.document_data);
        }

        else if (this.document_data.DocumentType == 'Vehicle License' && this.document_data.DocumentSubType == 'Both') {

              this.vehicleData = updateMemResponse.vechile_data.VehicleDetails;
              this.getRegCardData(1, this.document_data);
              this.getRegCardData(2, this.document_data);
        }

        else if (this.document_data.DocumentType == 'Driving License' && this.document_data.DocumentSubType == 'Both') {

              this.getDrivingLicData(1, this.document_data);
              this.getDrivingLicData(2, this.document_data);
        }

        else if (this.document_data.DocumentType == 'Emirates-Id' && this.document_data.DocumentSubType == 'Both') {
              
              this.getEmiratesIdData(1, this.document_data);
              this.getEmiratesIdData(2, this.document_data);
        }

        else if(this.document_data.DocumentType == 'Other-Document'){
          this.vehicleData = updateMemResponse.vechile_data.VehicleDetails;
          this.getRegCardData(1, this.document_data);
        }

    }
    
        if (updateMemResponse.response_code == 5) {
          this.showLoader.reg_card_front = false;
          this.showLoader.reg_card_back = false;
          this.showLoader.emirates_Id_front = false;
          this.showLoader.emirates_Id_back = false;
          this.showLoader.driving_Lic_front = false;
          this.showLoader.driving_Lic_back = false;
          Swal.fire("Please select valid file format.", "only .pdf, .jpg, .png and .jpeg file formats allowed.", 'error');
        }

        if (updateMemResponse.response_code == 6) {
          this.showLoader.reg_card_front = false;
          this.showLoader.reg_card_back = false;
          this.showLoader.emirates_Id_front = false;
          this.showLoader.emirates_Id_back = false;
          this.showLoader.driving_Lic_front = false;
          this.showLoader.driving_Lic_back = false;
          Swal.fire(updateMemResponse.response_status);
        }

  })
})
}
else{
  const formData = new FormData();
  formData.append('file', event.target.files[0]);
  formData.append('docName', docName);
  formData.append('source', 'B2C');
  formData.append('stage', 'QUOTE');
  formData.append('quoteType', 'std');
  formData.append('schemeCode', this.SchemeCode);
  formData.append('docProperty', this.docProperty);//Added by Anju for OCR Changes
  this.motorQuoteService.uploadDocuments(formData).subscribe(res => {
    let updateMemResponse = res;
      this.document_data = updateMemResponse.Document_data.Document_data;//Added by Anju for OCR Changes

    if (updateMemResponse.res_code == 1) {

      // this.showLoader.reg_card_front = false;
      // this.showLoader.reg_card_back = false;
      // this.showLoader.emirates_Id_front = false;
      // this.showLoader.emirates_Id_back = false;
      // this.showLoader.driving_Lic_front = false;
      // this.showLoader.driving_Lic_back = false;


      let fileType = updateMemResponse.File.split(".");
      fileType = fileType[fileType.length - 1];
      fileType = fileType == "pdf" ? "PDF" : "IMG";
     // alert("filetype: "+ fileType)
      let formArrayValue: any = this.docUploadForm.value;

      formArrayValue[FiledName] = updateMemResponse.File;
        formArrayValue[FiledName + "FilePath"] = updateMemResponse.FileDir;
        let tempDocumentArray = {
          file_name: FiledName,
          file_dir: updateMemResponse.FileDir,
          docName: updateMemResponse.File,
        }

        if (fileType == "pdf") {
          formArrayValue[FiledName + "FileType"] = "PDF";
        } else {
          formArrayValue[FiledName + "FileType"] = "IMG";
        }
       
     
        if (FiledName == 'reg_Card_Front') {
          this.hideImages.reg_card_front = true;
          // console.log(updateMemResponse.FileDir)
          // console.log(updateMemResponse.File)
          // console.log(fileType)
          this.docUploadForm.get('reg_Card_FrontFilePath').setValue(updateMemResponse.FileDir);
          this.docUploadForm.get('reg_Card_Front').setValue(updateMemResponse.File);
          this.docUploadForm.get('reg_Card_FrontFileType').setValue(fileType);
          this.tempDocArray[0] = tempDocumentArray;
          this.checkreg_Card_Front_image=true;
          document.getElementById('myElementID').click();
        }
        if (FiledName == 'reg_Card_Back') {
          this.hideImages.reg_card_back = true;
          this.docUploadForm.get('reg_Card_BackFilePath').setValue(updateMemResponse.FileDir);
          this.docUploadForm.get('reg_Card_BackFileType').setValue(fileType);
          this.tempDocArray[1] = tempDocumentArray;
          this.checkreg_Card_Back_image=true;
          document.getElementById('myElementID').click();
        }  
        if (FiledName == 'emirated_ID_Front') {
          this.hideImages.emirates_Id_front = true;
     
          this.docUploadForm.get('emirated_ID_FrontFilePath').setValue(updateMemResponse.FileDir);
          this.docUploadForm.get('emirated_ID_FrontFileType').setValue(fileType);
          this.tempDocArray[2] = tempDocumentArray;
        }
        if (FiledName == 'emirated_ID_Back') {
          this.hideImages.emirates_Id_back = true;
          this.docUploadForm.get('emirated_ID_BackFilePath').setValue(updateMemResponse.FileDir);
          this.docUploadForm.get('emirated_ID_BackFileType').setValue(fileType);
          this.tempDocArray[3] = tempDocumentArray;
        }
        if (FiledName == 'driving_Lic_Front') {
          this.hideImages.driving_Lic_front = true;
         
          let dateDob: string = this.document_data.Data['Date of Birth'];
          dateDob = dateDob.replace(/-/gi, "/");
          // this.motorOcrForm.get('date_of_birth').setValue(dateDob);

          // this.motorOcrForm.get('insured_name').setValue(this.document_data.Data.Name);
          // this.motorOcrForm.get('drive_Lic_no').setValue(this.document_data.Data['License No']);
          this.docUploadForm.get('driving_Lic_FrontFilePath').setValue(updateMemResponse.FileDir);
          this.docUploadForm.get('driving_Lic_FrontFileType').setValue(fileType);
          this.tempDocArray[4] = tempDocumentArray;
          this.checkdriving_Lic_Front_image=true;
          document.getElementById('myElementID').click();
        }
        if (FiledName == 'driving_Lic_Back') {
          this.hideImages.driving_Lic_back = true;
          this.docUploadForm.get('driving_Lic_BackFilePath').setValue(updateMemResponse.FileDir);
          this.docUploadForm.get('driving_Lic_BackFileType').setValue(fileType);
          this.tempDocArray[5] = tempDocumentArray;
          this.checkdriving_Lic_Back_image=true;
          document.getElementById('myElementID').click();
        }

       

        formArrayValue["docRegCardFront"] = "";
        formArrayValue['docRegCardBack'] = "";
        formArrayValue["docEmiratedIdFront"] = "";
        formArrayValue['docEmiratedIdBack'] = "";
        formArrayValue["docDrivingLicFront"] = "";
        formArrayValue["docDrivingLicBack"] = "";

        if (this.document_data.Errocode == 106) {
          if (FiledName == 'reg_Card_Front') {
            this.hideImages.reg_card_front = false;
            this.docUploadForm.get('reg_Card_FrontFilePath').setValue('');
          }
          if (FiledName == 'reg_Card_Back') {
            this.hideImages.reg_card_back = false;
            this.docUploadForm.get('reg_Card_BackFilePath').setValue('');
          }
          if (FiledName == 'emirated_ID_Front') {
            this.hideImages.emirates_Id_front = false;
            this.docUploadForm.get('emirated_ID_FrontFilePath').setValue('');
          }
          if (FiledName == 'emirated_ID_Back') {
            this.hideImages.emirates_Id_back = false;
            this.docUploadForm.get('emirated_ID_BackFilePath').setValue('');
          }
          if (FiledName == 'driving_Lic_Front') {
            this.hideImages.driving_Lic_front = false;
            this.docUploadForm.get('driving_Lic_FrontFilePath').setValue('');
          }
          if (FiledName == 'driving_Lic_back') {
            this.hideImages.driving_Lic_back = false;
            this.docUploadForm.get('driving_Lic_BackFilePath').setValue('');
          }
         
          Swal.fire("Invalid document type selected or the document resolution is very low to read the data.",
               "Please upload another clean copy and try. Sorry for the inconvenience.", "error");
               return false;
        }

        if (this.document_data.DocumentType == 'Emirates-Id' && this.document_data.DocumentSubType == 'Front') {
          // console.log('sdfhsfsjffsfs');
          this.getEmiratesIdData(1, this.document_data);
        }
        else if (this.document_data.DocumentType == 'Emirates-Id' && this.document_data.DocumentSubType == 'Back') {

          this.getEmiratesIdData(2, this.document_data);
        }
        else if (this.document_data.DocumentType == 'Driving License' && this.document_data.DocumentSubType == 'Front') {

          this.getDrivingLicData(1, this.document_data);
        }
        else if (this.document_data.DocumentType == 'Driving License' && this.document_data.DocumentSubType == 'Back') {

          this.getDrivingLicData(2, this.document_data);
        }
        else if (this.document_data.DocumentType == 'Vehicle License' && this.document_data.DocumentSubType == 'Back') {

          // this.insuredDetailForm.get('rg_vhcl_make').setValue('');
          // this.insuredDetailForm.get('rg_vhcl_model').setValue('');
          // this.insuredDetailForm.get('adtnl_detail_trim').setValue('');
          // this.insuredDetailForm.get('adtnl_detail_bodyType').setValue('');

          this.vehicleData = updateMemResponse.vechile_data.VehicleDetails;
         
          this.getRegCardData(1, this.document_data);
        }
        else if (this.document_data.DocumentType == 'Vehicle License' && this.document_data.DocumentSubType == 'Front') {

          this.getRegCardData(2, this.document_data);
        }

        else if (this.document_data.DocumentType == 'Vehicle License' && this.document_data.DocumentSubType == 'Both') {

              this.vehicleData = updateMemResponse.vechile_data.VehicleDetails;
              this.getRegCardData(1, this.document_data);
              this.getRegCardData(2, this.document_data);
        }

        else if (this.document_data.DocumentType == 'Driving License' && this.document_data.DocumentSubType == 'Both') {

              this.getDrivingLicData(1, this.document_data);
              this.getDrivingLicData(2, this.document_data);
        }

        else if (this.document_data.DocumentType == 'Emirates-Id' && this.document_data.DocumentSubType == 'Both') {
              
              this.getEmiratesIdData(1, this.document_data);
              this.getEmiratesIdData(2, this.document_data);
        }

        else if(this.document_data.DocumentType == 'Other-Document'){
          this.vehicleData = updateMemResponse.vechile_data.VehicleDetails;
          this.getRegCardData(1, this.document_data);
        }

    }
    
        if (updateMemResponse.response_code == 5) {
          this.showLoader.reg_card_front = false;
          this.showLoader.reg_card_back = false;
          this.showLoader.emirates_Id_front = false;
          this.showLoader.emirates_Id_back = false;
          this.showLoader.driving_Lic_front = false;
          this.showLoader.driving_Lic_back = false;
          Swal.fire("Please select valid file format.", "only .pdf, .jpg, .png and .jpeg file formats allowed.", 'error');
        }

        if (updateMemResponse.response_code == 6) {
          this.showLoader.reg_card_front = false;
          this.showLoader.reg_card_back = false;
          this.showLoader.emirates_Id_front = false;
          this.showLoader.emirates_Id_back = false;
          this.showLoader.driving_Lic_front = false;
          this.showLoader.driving_Lic_back = false;
          Swal.fire(updateMemResponse.response_status);
        }

  })
}



}

showTempFile(tempFile,FiledName){

  // console.log(tempFile);
  // console.log(FiledName);
  // console.log(this.docNameType);


   var reader = new FileReader();
		reader.readAsDataURL(tempFile);
		var mimeType = tempFile.type;
    let fileType = '';
     if (mimeType.match(/image\/*/) == null) {
     fileType = "PDF";
    }else{

      fileType = "IMG";

    }
		reader.onload = (_event) => {
		
      if (this.docNameType == 'reg_Card_Front') {
        // alert(1)
        this.showLoader.reg_card_front = false;
    }
   
     if (this.docNameType == 'reg_Card_Back') {
        this.showLoader.reg_card_back = false;
    }
   
     if (this.docNameType == 'emirated_ID_Front') {
      this.showLoader.emirates_Id_front = false;
    }
   
     if (this.docNameType == 'emirated_ID_Back') {
       this.showLoader.emirates_Id_back = false;
     }
   
     if (this.docNameType == 'driving_Lic_Front') {
       this.showLoader.driving_Lic_front = false;
     }
   
     if (this.docNameType == 'driving_Lic_Back') {
       this.showLoader.driving_Lic_back = false;
     }
      // this.docUploadForm.get('reg_Card_FrontFilePath').setValue(reader.result);
		// console.log(FiledName)

    if (FiledName == 'reg_Card_Front') {
      // console.log(reader)
          // this.hideImages.reg_card_front = true;
          this.docUploadForm.get('reg_Card_FrontFilePath').setValue(reader.result);
          // this.docUploadForm.get('reg_Card_Front').setValue(updateMemResponse.File);
          this.docUploadForm.get('reg_Card_FrontFileType').setValue(fileType);
        //  this.tempDocArray[0] = tempDocumentArray;
        }
        if (FiledName == 'reg_Card_Back') {
          // this.hideImages.reg_card_back = true;
          this.docUploadForm.get('reg_Card_BackFilePath').setValue(reader.result);
          this.docUploadForm.get('reg_Card_BackFileType').setValue(fileType);
        //  this.tempDocArray[1] = tempDocumentArray;
        }  
        if (FiledName == 'emirated_ID_Front') {
          // this.hideImages.emirates_Id_front = true;
     
          this.docUploadForm.get('emirated_ID_FrontFilePath').setValue(reader.result);
          this.docUploadForm.get('emirated_ID_FrontFileType').setValue(fileType);
          // this.tempDocArray[2] = tempDocumentArray;
        }
        if (FiledName == 'emirated_ID_Back') {
          this.hideImages.emirates_Id_back = true;
          this.docUploadForm.get('emirated_ID_BackFilePath').setValue(reader.result);
          this.docUploadForm.get('emirated_ID_BackFileType').setValue(fileType);
          // this.tempDocArray[3] = tempDocumentArray;
        }
        if (FiledName == 'driving_Lic_Front') {
          // this.hideImages.driving_Lic_front = true;
         
          this.docUploadForm.get('driving_Lic_FrontFilePath').setValue(reader.result);
          this.docUploadForm.get('driving_Lic_FrontFileType').setValue(fileType);
          // this.tempDocArray[4] = tempDocumentArray;
        }
        if (FiledName == 'driving_Lic_Back') {
          // this.hideImages.driving_Lic_back = true;
          this.docUploadForm.get('driving_Lic_BackFilePath').setValue(reader.result);
          this.docUploadForm.get('driving_Lic_BackFileType').setValue(fileType);
          // this.tempDocArray[5] = tempDocumentArray;
        }
      
    }

}

updateDocTemp(formData,FiledName){
      this.motorQuoteService.uploadDocumentsTemp(formData).subscribe(res =>{
          let formArrayValue: any = this.docUploadForm.value;
          
      let fileType = res.image_url.split(".");
      fileType = fileType[fileType.length - 1];
      fileType = fileType == "pdf" ? "PDF" : "IMG";

      if (fileType == "pdf") {
        formArrayValue[FiledName + "FileType"] = "PDF";
      } else {
        formArrayValue[FiledName + "FileType"] = "IMG";
      }
       
          if (FiledName == 'reg_Card_Front') {
            this.hideImages.reg_card_front = true;
            this.showLoader.reg_card_front = false;

            this.docUploadForm.get('reg_Card_FrontFilePath').setValue(res.image_url);
          }
          if (FiledName == 'reg_Card_Back') {
          
            this.docUploadForm.get('reg_Card_BackFilePath').setValue(res.image_url);
          
          }  
          if (FiledName == 'emirated_ID_Front') {
            this.hideImages.emirates_Id_front = true;
            this.showLoader.emirates_Id_front = false;
            this.docUploadForm.get('emirated_ID_FrontFilePath').setValue(res.image_url);
            // console.log("1");
          
          }
          if (FiledName == 'emirated_ID_Back') {
         
            this.docUploadForm.get('emirated_ID_BackFilePath').setValue(res.image_url);
        
          }
          if (FiledName == 'driving_Lic_Front') {
        
            this.docUploadForm.get('driving_Lic_FrontFilePath').setValue(res.image_url);
          }
          if (FiledName == 'driving_Lic_Back') {
          
            this.docUploadForm.get('driving_Lic_BackFilePath').setValue(res.image_url);
          }
             
        });
}


showMultipleDoc(fileName, documentType) {
  this.multipleImg = fileName;
  this.showAllDocImg = 1;
  this.ImagedocumentType = documentType;
}
modalboxclose() {
  this.showAllDocImg = 0;
}

public deductCnt = 0 ;
getDeductibleValue(){

  if( this.insuredDetailForm.value.adtnl_detail_insType.CoreProdCode == '4001_1'){
       
                let deductibleArray = {
                    lob_code : 'MT',
                    policy_type : this.insuredDetailForm.value.adtnl_detail_insType,
                    model_year : this.insuredDetailForm.value.rg_model_year,
                    vechile_make : this.insuredDetailForm.value.rg_vhcl_make,
                    vechile_model : this.insuredDetailForm.value.rg_vhcl_model,
                    body_type : this.insuredDetailForm.value.adtnl_detail_bodyType,
                    number_of_seat : this.insuredDetailForm.value.rg_num_passenger,
                    sum_insured : this.insuredDetailForm.value.adtnl_detail_vhclValue,
                    brand_new : this.insuredDetailForm.value.adtnl_detail_brandNew,
                    scheme_code : this.SchemeCode
                }

                this.motorQuoteService.getDeductible(deductibleArray).subscribe(res =>{
                
                  if(res.getDeductibleData[0].ExcessAmt==null && this.deductCnt<=4){

                      setTimeout(()=>{                           //<<<---using ()=> syntax
                        this.getDeductibleValue();
                      }, 3000);
                      this.deductCnt++;
                     
                  }
                    this.insuredDetailForm.get('deductible').setValue(res.getDeductibleData[0].ExcessAmt);
                
                });

    }
}

insuredVechOwnedBy(){

  if (this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.viewValue == 'Corporate') {

      this.insuredDetailForm.controls['industry_type'].setValidators([Validators.required]);
      this.insuredDetailForm.controls['vat_register'].setValidators([Validators.required]);
      this.insuredDetailForm.controls['e_eid'].setValidators(null);
      this.insuredDetailForm.controls['d_dob'].setValidators(null);
      this.insuredDetailForm.controls['e_nationality'].setValidators(null);
      this.insuredDetailForm.controls['d_driv_lic_num'].setValidators(null);
      this.insuredDetailForm.controls['d_place_issue'].setValidators(null);
      this.insuredDetailForm.controls['d_issue_date'].setValidators(null);
      this.insuredDetailForm.controls['d_expiry_date'].setValidators(null);
      this.insuredDetailForm.controls['d_TC_number'].setValidators(null);
      this.insuredDetailForm.controls['e_gender'].setValidators(null);
      this.insuredDetailForm.controls['trade_lic_num'].setValidators([Validators.required]);
            this.insuredDetailForm.controls['vat_register'].valueChanges.subscribe(obj => {
      
              if (obj == 'Yes') {
        
                this.insuredDetailForm.controls['TRN_num'].setValidators([Validators.required]);
              }
              else {
                    
                this.insuredDetailForm.controls['TRN_num'].setValidators(null);
            
              }
                this.insuredDetailForm.controls['TRN_num'].updateValueAndValidity();
          
            });

  }
  else {
        
        this.insuredDetailForm.controls['industry_type'].setValidators(null);
        this.insuredDetailForm.controls['vat_register'].setValidators(null);
        this.insuredDetailForm.controls['d_dob'].setValidators([Validators.required]);
        this.insuredDetailForm.controls['e_nationality'].setValidators([Validators.required]);
        this.insuredDetailForm.controls['e_eid'].setValidators([Validators.required]);
        this.insuredDetailForm.controls['d_driv_lic_num'].setValidators([Validators.required]);
        this.insuredDetailForm.controls['d_place_issue'].setValidators([Validators.required]);
        this.insuredDetailForm.controls['d_issue_date'].setValidators([Validators.required]);
        this.insuredDetailForm.controls['d_expiry_date'].setValidators([Validators.required]);
        this.insuredDetailForm.controls['d_TC_number'].setValidators([Validators.required]);
        this.insuredDetailForm.controls['e_gender'].setValidators([Validators.required]);
        this.insuredDetailForm.controls['trade_lic_num'].setValidators(null);
  }

        this.insuredDetailForm.controls['industry_type'].updateValueAndValidity();
        this.insuredDetailForm.controls['vat_register'].updateValueAndValidity();
        this.insuredDetailForm.controls['d_dob'].updateValueAndValidity();
        this.insuredDetailForm.controls['e_nationality'].updateValueAndValidity(); 
        this.insuredDetailForm.controls['e_eid'].updateValueAndValidity();
        this.insuredDetailForm.controls['d_driv_lic_num'].updateValueAndValidity();
        this.insuredDetailForm.controls['d_place_issue'].updateValueAndValidity();
        this.insuredDetailForm.controls['d_issue_date'].updateValueAndValidity();
        this.insuredDetailForm.controls['d_expiry_date'].updateValueAndValidity();
        this.insuredDetailForm.controls['d_TC_number'].updateValueAndValidity();
        this.insuredDetailForm.controls['e_gender'].updateValueAndValidity();
        this.insuredDetailForm.controls['trade_lic_num'].updateValueAndValidity();
}

 //------------------------------ GET VEHICLE MAKE DATA ---------------------------------//
 getVhclMakeData(vhcleMake, year, type) {
  // alert(1)
    this.showLoader.vhclMake = true;
  
    this.motorQuoteService.getVhclMake(this.insuredDetailForm.value.adtnl_detail_insType.viewValue, this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.viewValue, '0', this.language_code, this.country, year, this.SchemeCode).subscribe(res => {
      this.vhclMakeArr = res.vechileMakeValuesData;
      let makeVal;
      this.showLoader.vhclMake = false;
        if ((type == 2 || type == 1) && typeof (this.vhclMakeArr) != undefined && typeof (this.vhclMakeArr) != "undefined") {
          //VEHICLE Make ----- filter
        

          // console.log("-------------------------");
          // console.log(vhcleMake);
          this.vhclMakeArr.forEach((item, index) => {
            if (item.VehicleMakeName == vhcleMake) {
              makeVal = item;
              // console.log(makeVal);
            }
          });
          this.insuredDetailForm.get('rg_vhcl_make').setValue(makeVal);
          // this.vehicleData.General.Make = makeVal.VehicleMakeId;
            if (makeVal != null && makeVal != undefined) {
                if (type == 2)
                    this.getVhclModel(makeVal.VehicleMakeId, 2, year);
                else
                    this.getVhclModel(makeVal.VehicleMakeId, 1, year);
            }
        }

        if (type == 3 && typeof (this.vhclMakeArr) != undefined && typeof (this.vhclMakeArr) != "undefined") {
            //VEHICLE Make ----- filter
            let makeVal;
            this.vhclMakeArr.forEach((item, index) => {
                if (item.VehicleMakeName == vhcleMake) {
                    makeVal = item;
                }
            });

            this.insuredDetailForm.get('rg_vhcl_make').setValue(makeVal);
            if (makeVal != null && makeVal != undefined) {
                this.chassis_No_Details.General.Make = makeVal.VehicleMakeId;
                this.getVhclModel(makeVal.VehicleMakeId, 3, year);
            }
        }

          if (res.response_code == 1) {
            // this.onVehiclAgeChange(year);
            this.filteredMakes.next(this.vhclMakeArr.slice());
          }
          this.showLoader.vhclMake = false;
          this.onVehiclAgeChange(this.insuredDetailForm.value.rg_model_year);
      });
    
  }

onChangeTransactionType(transaction_type){

    if ( transaction_type == 'Renewal') {
     
        this.insuredDetailForm.controls['rg_plateCode'].setValidators([Validators.required]);
        this.insuredDetailForm.controls['rg_traffic_plate_num'].setValidators([Validators.required]);
        this.insuredDetailForm.controls['vhclColor'].setValidators([Validators.required]);

    }
    else {
   
        this.insuredDetailForm.controls['rg_plateCode'].setValidators(null);
        this.insuredDetailForm.controls['rg_traffic_plate_num'].setValidators(null);
        this.insuredDetailForm.controls['vhclColor'].setValidators(null);

      }
   
      this.insuredDetailForm.controls['rg_plateCode'].updateValueAndValidity();
      this.insuredDetailForm.controls['rg_traffic_plate_num'].updateValueAndValidity();
      this.insuredDetailForm.controls['vhclColor'].updateValueAndValidity();
}

onPrevPolChange(prevPolDate){

    if(this.toDay < prevPolDate &&  prevPolDate < new Date(new Date().setDate(new Date().getDate() + 13))){
      this.policyMinDate = new Date(Date.now());
      this.policyMaxDate = new Date(new Date(prevPolDate).setDate(prevPolDate.getDate() + 1));
      this.insuredDetailForm.get('polStartDate').setValue(this.policyMinDate);
    }else{
      this.policyMinDate = new Date(Date.now());
      this.policyMaxDate = new Date(new Date().setDate(new Date().getDate() + 13));
      this.insuredDetailForm.get('polStartDate').setValue(this.toDay);
    }
}

termsAndConditions(frame, type) {
  this.type=type;
  frame.show();
  this.getTermsConditions();
}


getRetrieveQuoteData() {

  if (this.retrieveQuoteForm.invalid) {
          this.validtnMsg = true;
    return false;
  }

 
  this.showLoader.retrieveQuote = true;
  this.motorQuoteService.getRetrieveQuote(this.retrieveQuoteForm.value.web_Quote_Num,'',this.retrieveQuoteForm.value.mob_Num,this.retrieveQuoteForm.value.email, 'BTCPORTAL','adv').subscribe(res => {

    if (res.response_code == 1) {
       
      if(res.quotationDetailsDataForCustomer[0].QuoteType!="OCR"){
        localStorage.setItem("retriveOCR",'NONOCR');
        localStorage.setItem("retriveOCRQuote",this.retrieveQuoteForm.value.web_Quote_Num);
        localStorage.setItem("retriveOCRCode",'');
        localStorage.setItem("retriveOCRmob_Num",this.retrieveQuoteForm.value.mob_Num);
        localStorage.setItem("retriveOCRemail",this.retrieveQuoteForm.value.email);
        this._route.navigate(["/MT_Quote"]);
        return false ;
      }


      // console.log("----------------------");
      this.selected.setValue(0);
      this.webQuoteNumber = this.retrieveQuoteForm.value.web_Quote_Num;
      this.retrieveData = res.quotationDetailsDataForCustomer;
      let resData = this.retrieveData[0];
      this.quoteDetail = this.retrieveData[0];
      this.quoteDetail.plateCode = resData.PlateCode;
      this.vhcleValueFlag = true;
      this.quoteStatus = resData.StatusDesc;
      this.optionalBenefit = this.quoteDetail.SelectedBenefits;
    
      //IN CASE OF B2C - DOCUMENTS ARE NOT GETTING BECAUSE OF WE DON'T SAVE THEM BEFORE INSERT/UPDATE QUOTE
      this.getDataForEditQuoteDocs(this.retrieveQuoteForm.value.web_Quote_Num);  
    
     
                this.insuredDetailForm.get('e_name').setValue(resData.InsuredName);
                this.insuredDetailForm.get('adtnl_detail_email').setValue(resData.InsuredEmail);
                this.insuredDetailForm.get('adtnl_detail_mobile').setValue(resData.InsuredMobile);
                this.insuredDetailForm.get('rg_chassis_num').setValue(resData.ChassisNumber);
                this.checkChassisValidation = true;
                this.insuredDetailForm.get('isChassisValidate').setValue('1');
                this.insuredDetailForm.get('adtnl_detail_gccSpecified').setValue(resData.HasGCCSpecification);
                this.insuredDetailForm.get('adtnl_detail_vhclModified').setValue(resData.IsModified);
                this.insuredDetailForm.get('e_eid').setValue(resData.EmiratesIdNumber);
                this.insuredDetailForm.get('rg_engin_num').setValue(resData.EngineNumber);
                this.insuredDetailForm.get('adtnl_detail_poBoxNum').setValue(resData.POBox);
                this.insuredDetailForm.get('rg_TC_num').setValue(resData.TrafficFileNo);
                this.insuredDetailForm.get('d_driv_lic_num').setValue(resData.LicenceNumber);
                console.log(this.dateConvert(resData.InsuredDOB));
                this.insuredDetailForm.get('d_dob').setValue(this.dateConvert(resData.InsuredDOB));
                //this.insuredDetailForm.get('d_dob').setValue(new Date(resData.InsuredDOB));// Commented by Anju for B2C Stabilization
                this.insuredDetailForm.get('adtnl_detail_vhclValue').setValue(resData.SumInsured);
              
                this.insuredDetailForm.get('rg_reg_date').setValue(this.dateConvert(resData.FirstRegistrationDate));
                this.insuredDetailForm.get('adtnl_detail_brandNew').setValue(resData.IsBrandNew);
                this.insuredDetailForm.get('rg_gvm').setValue(resData.VehGVM);
                this.insuredDetailForm.get('rg_policy_num').setValue(resData.PrvInsPolNo);
                this.insuredDetailForm.get('e_cardNumber').setValue(resData.NationalIdCardNo);

                // this.insuredDetailForm.get('e_arabic_name').setValue(resData.ArabicName);    FOR NAME IN ARABIC
                this.insuredDetailForm.get('d_TC_number').setValue(resData.DLTCNo);
                this.insuredDetailForm.get('trade_lic_num').setValue(resData.TradeLicNumber);
                this.insuredDetailForm.get('TRN_num').setValue(resData.TaxRegNumber);
                
                if(resData.RegistrationNumber !== '' && resData.RegistrationNumber != null){
                        this.insuredDetailForm.get('rg_traffic_plate_num').setValue(resData.RegistrationNumber);
                }

                // if(this.quoteStatus == 'REFERRED' &&  (this.businessSource == 'DIRECT' && this.partnerId == 1 && this.userType == 'POS' && this.userRole == 'TELEMARKETING')){
                //       let  mediumValue =  resData.ValuationMedium;
                //       let updatedValue =  Math.round((mediumValue * (20/ 100)));
                //       this.vehicleValueLimit.startLimit = Number(mediumValue) - Number(updatedValue);
                //       this.vehicleValueLimit.endLimit =  Number(mediumValue) + Number(updatedValue);

                // }else{
                //       this.vehicleValueLimit.startLimit = resData.ValuationLow;
                //       this.vehicleValueLimit.endLimit =  resData.ValuationHigh;
                //     }

                  this.vehicleValueLimit.startLimit = resData.ValuationLow;
                  this.vehicleValueLimit.endLimit =  resData.ValuationHigh;
                  this.vehicleValueLimit.isSet = true;


                  if(resData.InsuranceExpiryDate == null || resData.InsuranceExpiryDate == '1900-01-01 00:00:00.000'){
                    this.insuredDetailForm.get('rg_ins_exp').setValue(null);
                  }else{
                    this.insuredDetailForm.get('rg_ins_exp').setValue(new Date(resData.InsuranceExpiryDate));
                  }
  
                  if(resData.DrivingLicExpDate == null || resData.DrivingLicExpDate == '1900-01-01 00:00:00.000'){
                    this.insuredDetailForm.get('d_expiry_date').setValue(null);
                  }else{
                    this.insuredDetailForm.get('d_expiry_date').setValue(new Date(resData.DrivingLicExpDate));
                  }
  
                  if(resData.DrivingLicIssueDate == null || resData.DrivingLicIssueDate == '1900-01-01 00:00:00.000'){
                    this.insuredDetailForm.get('d_issue_date').setValue(null);
                  }else{
                    this.insuredDetailForm.get('d_issue_date').setValue(new Date(resData.DrivingLicIssueDate));
                  }
  
                  if(resData.NationalIdExpDate == null || resData.NationalIdExpDate == '1900-01-01 00:00:00.000'){
                    this.insuredDetailForm.get('e_expiryDate').setValue(null);
                  }else{
                    this.insuredDetailForm.get('e_expiryDate').setValue(new Date(resData.NationalIdExpDate));
                  }
  
                  if(resData.RegistrationExpiryDate == null || resData.RegistrationExpiryDate == '1900-01-01 00:00:00.000'){
                    this.insuredDetailForm.get('rg_expiry_date').setValue(null);
                  }else{
                    this.insuredDetailForm.get('rg_expiry_date').setValue(new Date(resData.RegistrationExpiryDate));
                  }

                
                  if(resData.InsuredType=="Corporate"){
                   this.insuredDetailForm.get('vat_register').setValue(resData.IsTaxReg);
                  }
                //Vehicle own by  
                var indexOwnBy = this.insvehibys.findIndex(function (obj, k) {
                  return obj.value === resData.InsuredType;
                });
                let ownByVal = this.insvehibys[indexOwnBy];
                this.insuredDetailForm.get('adtnl_detail_vhclOwnBy').setValue(ownByVal);

                //Policy Type
                var indexInsType = this.instypes.findIndex(function (obj, k) {
                  return obj.viewValue.toLowerCase() === resData.PolicyType.toLowerCase();
                });
                let insVal = this.instypes[indexInsType];
                this.insuredDetailForm.get('adtnl_detail_insType').setValue(insVal);

                //Mobile Code
                var indexCode = this.codes.findIndex(function (obj, k) {
                  return obj.viewValue === resData.MobileCode;
                });
            //   let codeVal = this.codes[indexCode];
            //   this.insuredDetailForm.get('adtnl_detail_mbCode').setValue(codeVal);

                //NCD
                var indexNCD = this.NCDData.findIndex(function (obj, k) {
                  return obj.NCDDescription === resData.NCD;
                });
                let NCDVal = this.NCDData[indexNCD];
                this.insuredDetailForm.get('adtnl_detail_NCD').setValue(NCDVal);

                //VEHICLE YEAR
                let yearVal;
                this.vehimodelyrs.forEach((item, index) => {
                  if (item.label == resData.VehicleModelYear) {
                    yearVal = item;
                  }
                });
                this.insuredDetailForm.get('rg_model_year').setValue(yearVal);

                 //Business Type 
                 let businessTypeVal;
                 if(resData.BusinessCategory != null && resData.BusinessCategory != ''){
                    this.industryTypeArr.forEach((item, index) => {
                      if (item.IndustryName.toLowerCase() == resData.BusinessCategory.toLowerCase()) {
                          businessTypeVal = item;
                      }
                    });
                    this.insuredDetailForm.get('industry_type').setValue(businessTypeVal);
                 }

                // Transaction Type
                var transType;
                this.transactionTypeArray.forEach((item, index) => {
                    if (item.label.toLowerCase() == resData.TransType.toLowerCase()) {
                          transType = item;
                    }
                });
                this.insuredDetailForm.get('transaction_type').setValue(transType);
                this.onChangeTransactionType(this.insuredDetailForm.value.transaction_type.label);

                this.quoteDetail.VehicleMake = resData.VehicleMake;
                this.quoteDetail.VehicleModel = resData.VehicleModel;
                this.quoteDetail.TrimCode = resData.TrimCode;
                this.quoteDetail.TrimName = resData.TrimName;
                this.quoteDetail.BodyType = resData.BodyType;
                this.retrieve_repair_type = resData.RepairType;
                this.quoteDetail.RepairType = resData.RepairType;
         
                this.getVhclMakeData(this.quoteDetail.VehicleMake, yearVal, 1);

                //Gender
                var indexGender = this.genders.findIndex(function (obj, k) {
                  return obj.value === resData.InsuredGender;
                });
                let gnderVal = this.genders[indexGender];
                this.insuredDetailForm.get('e_gender').setValue(gnderVal);

                //Vehicle Cylinders
                var indexCylinder = this.vehicylinders.findIndex(function (obj, k) {
                  return obj.value === resData.VehicleCylinders;
                });
                let cylVal = this.vehicylinders[indexCylinder];
                this.insuredDetailForm.get('adtnl_detail_cylinder').setValue(cylVal);

              //Passenger Count
              var indexPasger = this.vehiseatcaps.findIndex(function (obj, k) {
                return obj.value == resData.PassengersCount;
              });
              let PasgerVal = this.vehiseatcaps[indexPasger];
              this.insuredDetailForm.get('rg_num_passenger').setValue(PasgerVal);

                // Nationality
                var indexNationality;
                console.log(this.countryArr)
                this.countryArr.forEach((item, index) => {
                  if (item.CountryName == resData.InsuredNationality) {
                    indexNationality = item;
                  }
                });
                this.insuredDetailForm.get('e_nationality').setValue(indexNationality);

                //Mortagage bank
                if (resData.Mortgage != '' || resData.Mortgage != null) {
                  var indexBank = this.bankDetail.findIndex(function (obj, k) {
                    return obj.InstituteName === resData.Mortgage;
                  });
                  let bankVal = this.bankDetail[indexBank];
                  if (resData.Mortgage == "Not Mortgaged")
                    this.insuredDetailForm.get('rg_mortgage').setValue(this.bankDetail[0]);
                  else
                    this.insuredDetailForm.get('rg_mortgage').setValue(bankVal);
                }
                else {
                  this.insuredDetailForm.get('rg_mortgage').setValue('');
                }

                

           
               //REG PLACE
               if(resData.RegistrationPlace != '' && resData.RegistrationPlace != null && resData.RegistrationPlace != undefined ){

                    var indexPlace = this.cityArr.findIndex(function (obj, k) {
                      return obj.CityName === resData.RegistrationPlace;
                    });
                    let placeal = this.cityArr[indexPlace];
                    this.insuredDetailForm.get('rg_place_issue').setValue(placeal);
                    this.quoteDetail.regplace = placeal.CityName;
                    this.getPlateCode(this.quoteDetail.regplace, 2);
              }

              //plateCode
              console.log(this.plateCodeArray)
              var indexPlateCode = this.plateCodeArray.findIndex(function (obj, k) {
                return obj.PlateCode == resData.PlateCode;
              });
              let PlateCode = this.plateCodeArray[indexPlateCode];
             console.log(PlateCode)
              this.insuredDetailForm.get('rg_plateCode').setValue(PlateCode);

              //Plate category
              if( resData.PlateCategory != null){
                      var indexPlCat = this.plateCatArray.findIndex(function (obj, k) {
                        return obj.label.toLowerCase() === resData.PlateCategory.toLowerCase();
                      });
                      let plCatVal = this.plateCatArray[indexPlCat];
                      if(typeof (plCatVal) !="undefined")
                            this.insuredDetailForm.get('rg_type').setValue(plCatVal.value);
              }else{
                    this.insuredDetailForm.get('rg_type').setValue('');
              }
             

              //Vehicle Color
              if (resData.VehicleColor == null || resData.VehicleColor == '') {
                this.insuredDetailForm.get('vhclColor').setValue('');
              } else {
                var indexColor = this.vhclColorArr.findIndex(function (obj, k) {
                  return obj.ColorName === resData.VehicleColor;
                });
                let clrVal = this.vhclColorArr[indexColor];
                this.insuredDetailForm.get('vhclColor').setValue(clrVal);
              }


              //Active policy type 
              if (this.insuredDetailForm.value.adtnl_detail_brandNew == 1) {
                this.insuredDetailForm.get('vhclTPLCoverage').setValue(this.activePolicyTypeArr[1]);
              }
              // if (this.insuredDetailForm.value.adtnl_detail_brandNew != 1) {
              //   this.insuredDetailForm.get('vhclTPLCoverage').setValue(this.activePolicyTypeArr[1]);
              // }
              else {
                if (resData.CurrentInsurance == "Yes") {
                  this.insuredDetailForm.get('vhclTPLCoverage').setValue(this.activePolicyTypeArr[2]);
                } else {
                  this.insuredDetailForm.get('vhclTPLCoverage').setValue(this.activePolicyTypeArr[1]);

                }
              }

            //Origin
            var indexNationality;
            if (resData.Origin != '') {
              this.countryArr.forEach((item, index) => {
                if (item.CountryName == resData.Origin) {
                  indexNationality = item;
                }
              });
              this.insuredDetailForm.get('rg_origin').setValue(indexNationality);
            } else {
              this.insuredDetailForm.get('rg_origin').setValue('');
            }

            //Driving Lic. issue place
            if (resData.DrivingLicIssuePlace != '') {
              var indexPlace = this.cityArr.findIndex(function (obj, k) {
                return obj.CityName === resData.IssuePlace;
              });
              let drivLicPlace = this.cityArr[indexPlace];

                if(typeof (drivLicPlace) !="undefined" && drivLicPlace!="undefined"){
                    this.insuredDetailForm.get('d_place_issue').setValue(drivLicPlace);
                    this.getIssuePlace(drivLicPlace.CityName);
                  }
            } else {
              this.insuredDetailForm.get('d_place_issue').setValue('');
            }

            this.showLoader.retrieveQuote = false;
          
            Swal.fire("Your quotation has been successfully retrieved.", "Kindly note the premium may change based on the company pricing policy", "success");
            this.selected.setValue(0);
            this.expandedDetails=true;
            // console.log(this.expandedDetails)
            this.item=[{number: 1, open: true}];
            this.getDeductibleValue();
       
    }

  });

  
}

  getDiscountOnSelfDeclaration(){

       this.motorQuoteService.getDiscountOnSelfDeclaration(this.webQuoteNumber,"MT").subscribe(res =>{
            this.selfDeclarationDiscount = res.PromoDiscount;
           
            const  copy = JSON.parse(JSON.stringify(this.tempBenefitData));
            let base_Primium = copy;
            let  discountAmt = 0;
            this.PlanDataJson.planBenefitData.benefit_data = this.tempBenefitData;
            this.totalFixPremium = this.tempTotalLoadFixPrem;
            let discount:number = Number(this.PlanDataJson.thirdPartyAPIData.NcdDiscount.NcdAmnt);
            let policyFee:number = 0 ;

            this.tempBenefitData.forEach((item, index) => {
                if (this.insuredDetailForm.value.adtnl_detail_insType.value == 200 && item.Code == '40099') {
                    //get policy fee
                    policyFee = item.premium ;
                }
            });
            
                this.tempBenefitData.forEach((item, index) => {
            
                  // FOR COMPRENSIVE
                  if (this.insuredDetailForm.value.adtnl_detail_insType.value == 100 && item.Code == '40001') {
              
                   let newBasePremium = this.totalFixPremium;
                 
                  // console.log('newBasePremium : '+newBasePremium );
                    discountAmt = Math.round(((this.selfDeclarationDiscount * Number(newBasePremium)) / 100));   //FOR BY PERCENT
                      //  console.log('discount amount : ',discountAmt);
                    
                        let totalPrem:number = 0 ;
                        totalPrem += newBasePremium;
                        totalPrem += discount ;
                        totalPrem -= discountAmt;
                       
                        // console.log('Total Pre : ', totalPrem);
                       
                              if(Number(base_Primium[1].premium) > Number(base_Primium[0].premium)){ 
                                    totalPrem -= parseInt(base_Primium[0].premium);   
                              }else{
                                    totalPrem -= parseInt(base_Primium[1].premium);
                              }
                 
                              this.PlanDataJson.planBenefitData.benefit_data[index].premium = totalPrem;
                    
                  }
            
                  // FOR TPL
                  if (this.insuredDetailForm.value.adtnl_detail_insType.value == 200 && item.Code == '40002') {
            
                    let newBasePremium = this.totalFixPremium;
                 
              
                          discountAmt = Math.round(((this.selfDeclarationDiscount * Number(newBasePremium)) / 100));  //FOR BY PERCENT
                           
                            let totalPrem:number = 0 ;
                          
                            if(this.PlanDataJson.planBenefitData.benefit_data[index].Code == 40002){
                                    totalPrem = parseInt(base_Primium[index].premium) - discountAmt;   
                            }
            
                              this.PlanDataJson.planBenefitData.benefit_data[index].premium = totalPrem;
                   
                  
                  }
            
                });
            
                this.totalFixPremium = Math.round(Number(this.totalFixPremium) - Number(discountAmt));
                // console.log('Base Premium : ', this.totalFixPremium);
                this.VATAMT = (this.totalFixPremium + this.totalVariablePremium) * 0.05;
                // console.log('VAT AMOUNT : ',this.VATAMT);
                this.Total_Primium = Number(this.totalFixPremium) + Number(this.totalVariablePremium) + Number(this.VATAMT);
                // console.log('Total Premium : ',this.Total_Primium);
                this.specialDiscount();
                this.tempBenefitData =  copy ;
          });
  }

 
getSelfDeclarartionData(){
  this.motorQuoteService.getSelfDeclarartionData().subscribe(res =>{
        if(res.response_code == 1){
                this.selfDeclarartionArr = res.self_declaration;
                this.selfDeclQueArr = res.self_declaration_question;
                this.selfDeclLevel1 = res.self_declaration_level1;
                this.selfDeclLevel2 = res.self_declaration_level2;
                
               
        }
  });
}

checkSelfDeclarationCondition(type,event){

  // console.log(event);
    if(type == 1){
      this.accept_self_declaration_1 = event.checked;
    }

    if(type == 2){
      this.accept_self_declaration_2 = event.checked;
    }

    if(type == 3){
      this.accept_self_declaration_3 = event.checked;
    } 

    if(type == 4){
        this.accept_self_declaration_4 = event.checked;
    } 

    if(type == 5){
      this.accept_self_declaration_5 = event.checked;
    }
      
    }

    //Added by Anju for Get  Quote flow without decalaration
    continueOnCreateQuotation(stepper){
        if (this.webQuoteNumber == '') {
            this.getQuotation(stepper, 1, '');
        } else {
            this.updateQuotation(stepper, 1, '');
        }
    }

  continueOnSelfDeclarion(stepper,type, frame){
      if(this.webQuoteNumber == ''){
            this.getQuotation(stepper,1, frame);
      }else{
            this.updateQuotation(stepper,1, frame);
      }
  }

  //openSelfDeclartnModal(frame){

  //  // console.log(this.insuredDetailForm);
  //    if(this.insuredDetailForm.invalid){
  //      // console.log("enter");

  //      return false ;
  //    }else{

  //      frame.show();
  //    }


        
  //}
  showMultiQuotationImg = 0 ;
  quotationmultimg = 0;
  closeQuotationImg(){

  }


  specialDiscount(){
          this.motorQuoteService.getSpecialDiscount().subscribe(res =>{
                  if(res.response_code == 1){
                      this.specialDiscountAmt = res.special_discount;

                      this.totalFixPremium = Math.round(Number(this.totalFixPremium) - Number(this.specialDiscountAmt));
                      // console.log('Base Premium 1 : ', this.totalFixPremium);
                      this.VATAMT = (this.totalFixPremium + this.totalVariablePremium) * 0.05;
                      // console.log('VAT AMOUNT 1 : ',this.VATAMT);
                      this.Total_Primium = Number(this.totalFixPremium) + Number(this.totalVariablePremium) + Number(this.VATAMT);
                      // console.log('Total Premium 1 : ',this.Total_Primium);
                  }
          });
  }

  getTORewiewPage(stepper,ProgressModel,type){

  this.stepper1=stepper;
this.ProgressModel=ProgressModel;
    // console.log(1)
      if(this.reg_card_front_flag == false){
            Swal.fire("", "Please upload the vehicle registration front copy", 'error');    
            return false;
      }
      
      else if(this.reg_card_back_flag == false){
          Swal.fire("", "Please upload the vehicle registration back copy", 'error');    
          return false;
      }

      else if(this.driving_Lic_Front_flag == false){
              Swal.fire("", "Please upload driving license front copy", 'error');    
              return false;
      } else if(this.driving_Lic_Back_flag == false){
              Swal.fire("", "Please upload driving license back copy", 'error');    
              return false;
      }else  if(this.checkreg_Card_Front_image == false || this.checkreg_Card_Back_image == false ||  this.checkdriving_Lic_Front_image == false || this.checkdriving_Lic_Back_image == false){
        // alert(1)
       ProgressModel.show();
        //  this.getTORewiewPage(stepper,ProgressModel,2);
     }else{
       ProgressModel.hide();
       stepper.next();

     }
     
                     
      
  
    
                  // if(this.checkreg_Card_Front_image == true && this.checkreg_Card_Back_image == true &&  this.checkdriving_Lic_Front_image == true && this.checkdriving_Lic_Back_image == true){
                  //   ProgressModel.hide();
                  //   stepper.next();
                  // }else{
                  //   this.getTORewiewPage(stepper,ProgressModel,2);
                  //   }
  

        
        //  else{
        //   ProgressModel.hide();
        //   stepper.next();

        // }
       
      
  }

  demo(){
    //  console.log(2)
     if(this.checkreg_Card_Front_image == true && this.checkreg_Card_Back_image == true &&  this.checkdriving_Lic_Front_image == true && this.checkdriving_Lic_Back_image == true){
     this.ProgressModel.hide();
    this.stepper1.next();
     }

 
  }

   //----------------------------------------- SAVE ADDITIONAL INFO (THIRD PARTY API)--------------------------------------------------------
   saveAdditionalInfo() {

  

   
   
 
    // let name = this.insuredDetailForm.value.adtnl_detail_vhclOwnBy.code == '100' ? this.insuredDetailForm.value.name : this.insuredDetailForm.value.companyName;

   

    this.motorQuoteService.saveAdditionalInfo(this.quoteNumber, this.webQuoteNumber, this.insuredDetailForm.value.rg_mortgage, 'N', '', this.insuredDetailForm.value.adtnl_detail_insType, this.insuredDetailForm.value.rg_engin_num, this.insuredDetailForm.value.e_gender.value,'B2C',this.paymentMode).subscribe(res => {
    
      // this.additionalDetailForm.get('docDriving_license_Back').setValue('');
      // this.additionalDetailForm.get('docDriving_License_Front').setValue('');
      // this.additionalDetailForm.get('docReg_Card_Front').setValue('');
      // this.additionalDetailForm.get('docReg_Card_Back').setValue('');
      // this.additionalDetailForm.get('docEID_Front').setValue('');
      // this.additionalDetailForm.get('docEID_Back').setValue('');
      // this.additionalDetailForm.get('docDealerQuote').setValue('');
      // this.additionalDetailForm.get('docOther').setValue('')
    
      if (res.response_code == 1) {

        if(this.type == 1){
          this.savePolicyDetail();
        }
        if(this.type == 2){
          this.sendPaymentLink();
        }
     
  


      
      }

    });
   

    //stepper.next();

  }

  // ------------------------------------------FUNCTION FOR SEND PAYMENT LINK---------------------------------------------------
sendPaymentLink()
{
  this.motorQuoteService.sendPaymentLink(this.webQuoteNumber,'MT','B2C',this.insuredDetailForm.value.adtnl_detail_email).subscribe(res =>{
       this._route.navigate(["/motorquote"]);
    Swal.fire("", "Thank you for choosing Fidelity United for insuring your car. We have sent an email to your registered email with a payment link", 'success');

  });
}

}












