import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from "./_service/auth.guard";
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LandingAuthUserComponent } from './layouts/landing-auth-user/landing-auth-user.component';
import { LoginUserComponent } from './layouts/login-user/login-user.component';
import {DashboardComponent } from './dashboard/dashboard.component';
import { MotorquoteComponent} from './motorquote/motorquote.component';
import { MotorquoteModule } from './motorquote/motorquote.module';
import {ThankyouComponent } from './thankyou/thankyou.component';
import { CancelpaymentComponent  } from './cancelpayment/cancelpayment.component';
import { OcrmainComponent } from './ocrmain/ocrmain.component';
import { MotorpaymentinvoiceComponent } from './motorpaymentinvoice/motorpaymentinvoice.component';
import { WithocrComponent } from './ocrmain/withocr/withocr.component';
import { WinnerComponent } from './winner/winner.component';
import { UserregistrationComponent } from './userregistration/userregistration.component';


const routes: Routes =[



  {
    path : '',
    component : OcrmainComponent,
    pathMatch: 'full',
    
  },

  {
    path : 'motorquote',
    component : OcrmainComponent,
    
    
  },

  {
    path : 'MT_Quote',
    component : OcrmainComponent,
  
  },

  {
    path : 'MT_Quote/:Quote_No',
    component : OcrmainComponent
  },

  {
    path : 'payment_status',
    component : ThankyouComponent
  },
  {
    path : 'motoradvquote/:quotationNumber',
    component : OcrmainComponent,
  
  },

  {
    path : 'cancelpayment/:Res_Code',
    component : CancelpaymentComponent
  },
  
  {
    path: 'MT_Quote',
    component: OcrmainComponent,
    pathMatch: 'full',
  },
  {
    path: 'motorpay/:QuotationNumber/quote/payment',
    component: MotorpaymentinvoiceComponent,
  },
  {
    path: 'motor/thankyou',
    component: ThankyouComponent,
  },
  {
    path: 'winner',
    component: WinnerComponent,
  },
  {
    path: 'registration',
    component: UserregistrationComponent,
  },

];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
       useHash: false
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
