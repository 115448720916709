import { Component, OnInit } from '@angular/core';
import { IssuepolicyService } from '../_service/issuepolicy.service';
import { GlobalService } from "../_service/global.service";
import { Router,ActivatedRoute } from '@angular/router';
import { MotorquoteService } from 'app/_service/motorquote.service';

@Component({
  selector: 'app-motorpaymentinvoice',
  templateUrl: './motorpaymentinvoice.component.html',
  styleUrls: ['./motorpaymentinvoice.component.scss']
})
export class MotorpaymentinvoiceComponent implements OnInit {

  public quoteNum:any; totalPremium :any; quoteDetail:any = []; paymentLoader:boolean = false; quote_Status:any; paymentMsgShow:boolean = false;
  toalContriLoader: boolean =false;
  quoteDetailData: any;
  toalContri: any;
  quoitationID: any;
  retrieveData: any;
  localStorDta: any;
  partnerId: any;
  trans_ref: any;
  // public issuePolicyService: IssuepolicyService
  constructor(public _route: Router,public _activatedroute: ActivatedRoute,public globalService:GlobalService,public issuePolicyService: IssuepolicyService,public motorQuoteService:MotorquoteService) { }

  ngOnInit() {
    // console.log(1)
    this.toalContriLoader = this.globalService.spinnerHide();
    
    this.paymentLoader = this.globalService.spinnerHide();
    this.localStorDta =  this.globalService.getLocalStorageData();
    // this.partnerId = this.localStorDta.Partner_ID;
    const routeParam = this._activatedroute.snapshot.params;
    if(routeParam){

      this.quoteNum = routeParam.QuotationNumber;
      this.getMotorVehcleDetail();

      }

  }

  getMotorVehcleDetail(){
    this.toalContriLoader = this.globalService.spinnerShow();
    this.motorQuoteService.getRetrieveQuote1(this.quoteNum,'BTBPORTAL','PAYMENT').subscribe(res => {
              if(res.response_code == 1){
                this.toalContriLoader = this.globalService.spinnerHide();
                  this.retrieveData = res.quotationDetailsDataForCustomer;
                  let resData =  this.retrieveData[0];

                  this.totalPremium = resData.TotalPremium;
                  this.quote_Status = resData.StatusDesc;
                
                  localStorage.setItem('Payment_Quotation_Number', this.quoteNum);

                    if(this.quote_Status == "ISSUED"){
                          this.paymentMsgShow = true;
                     
                      }

              }
    });
  }

  payTabPayment(){

    this.paymentMsgShow = false;
   
   let return_url = "http://localhost:4200/dashboard";
   let site_url = "http://localhost:4200/" + this._route.url;

  this.paymentLoader = this.globalService.spinnerShow();

        //Modified by Anju for payment gateway revamp
        this.motorQuoteService.paymentAuthNew(this.quoteNum, site_url, return_url, 'MT', 1, '', "B2C", "adv").subscribe(res => {
            let payRes = res;

            if (payRes.status == 'Failed') {
                alert(payRes.res_msg);
                this.paymentLoader = this.globalService.spinnerHide();
                return false;
            }

            else if (payRes.status == 'Success') {
                this.trans_ref = payRes.data.tran_ref;
                // localStorage.setItem('Trans_Ref',this.trans_ref);
                // localStorage.setItem('TokenId',payRes.result.token);
                this.paymentLoader = this.globalService.spinnerHide();
                localStorage.setItem('Payment_Order_ID', payRes.data.tran_ref);
                localStorage.setItem('Payment_Order_Tokan', payRes.data.token);
                localStorage.setItem('Payment_Quotation_Number', this.quoteNum);

                window.location.href = payRes.data.redirect_url;
                // window.location.href = res.result.redirect_url;   //new payment
                // window.location.href = payRes.result.payment_url;


            }


        });


    }

}